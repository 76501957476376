import React, {useState, useEffect} from "react";
import styled from "styled-components";
import { Collapse, Space, Row, Col, Statistic } from "antd";
import { ActionButton, CustomInput } from "../../common";
import _ from "lodash";
import { HuParams, Circle } from "../../../utils/Models"

const { Panel } = Collapse;

type Props = {
    collection: Array<any>;
    callback: (item: string) => void;
    onStateChange: (item: string[] | string) => void;
    buttonText?: string;
    addConfiguration?: JSX.Element;
    collapseState: string[] | string
};

const CustomCollapse = (props: Props): any => {
    const { collection, callback, addConfiguration, collapseState, onStateChange  } = props;

    const [configurationName, setConfigurationName] = useState("");
    const [confActiveKey, setConfActiveKey] = useState<string[]>([``])

    const onConfigurationChange = (item: string[] | string) => { 
        const key = _.isArray(item) ? item : [item];
        setConfActiveKey(key);
    }

    const getActiveId = () => {
        return collection.find(c => c.active)?.id
    }

    return (
        <StyledCollapse onChange={e => onStateChange(e)} activeKey={collapseState}>
            <Panel header='Konfiguracje' key='CONFIGURATIONS'>
                <StyledCollapse onChange={e => onConfigurationChange(e)} activeKey={confActiveKey[0] !== `` ? confActiveKey : getActiveId()}>
                    <Row gutter={[16, 16]}>
                        <Col>
                            <Title>Filtruj po nazwie konfiguracji</Title>
                        </Col>
                        <Col>
                            <CustomInput type='text' value={configurationName} onChange={setConfigurationName} />
                        </Col>
                    </Row>

                    {collection.map((item: any) => {
                        const {
                          mADeviation,
                          mA,
                          kV,
                          kVDeviation,
                          name,
                        }: {circle: Circle, roiCircle: Circle, name: string } & HuParams = JSON.parse(item.configuration);


                        if(name && !name.includes(configurationName)){
                            return;
                        }

                        return (
                            <StyledPanel key={item.id} header={name} >
                                <Space direction='vertical'>
                                    <Row gutter={[16, 16]}>
                                        <Col span={24}>
                                            <CustomStatistic title='Filtruj po nazwie konfiguracji' value={name} />
                                        </Col>
                                    </Row>
                                    <Row gutter={[16, 16]}>
                                        <Col span={12}>
                                            <CustomStatistic title='kV' value={kV} />
                                        </Col>
                                        <Col span={12}>
                                            <CustomStatistic title='mA' value={mA} />
                                        </Col>
                                    </Row>
                                    <Row gutter={[16, 16]}>
                                        <Col span={12}>
                                            <CustomStatistic title='Odchylenie kV' value={kVDeviation} />
                                        </Col>
                                        <Col span={12}>
                                            <CustomStatistic title='Odchylenie mA' value={mADeviation} />
                                        </Col>
                                    </Row>
                                    {item.active === true ? (
                                        <ActiveLabel>Aktywna </ActiveLabel>
                                    ) : (
                                        <ActionButton
                                            label={props.buttonText || "Aktywuj"}
                                            onClick={() => callback(item)}
                                        />
                                    )}
                                </Space>
                            </StyledPanel>
                        );
                    })}
                </StyledCollapse>
            </Panel>
            <Panel header='Dodaj konfigurację' key='ADD_CONFIGURATION'>
                {addConfiguration}
            </Panel>
        </StyledCollapse>
    );
};

const StyledCollapse = styled(Collapse)`
    background-color: rgb(31, 56, 83);
    color: #ffffff;
    text-align: center;
    .ant-collapse-item {
        .ant-collapse-header {
            color: #ffffff;
        }
    }
`;

const ActiveLabel = styled.label`
    font-size: 20px;
`;

const StyledPanel = styled(Panel)`
    .anticon.anticon-right.ant-collapse-arrow {
        color: #ffffff;
    }
`;

const Title = styled.h4`
    font-size: 16px;
`;

const CustomStatistic = styled(Statistic)`
    .ant-statistic-content {
        font-size: 18px;
    }
`;

export default CustomCollapse;

export { StyledCollapse };
