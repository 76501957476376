import React from 'react';
import { Card } from 'antd';
import styled from 'styled-components';

type Props = {
    title: string,
    children?: any,
    style?: any,
    icon?: React.ReactNode
}

const MainCard = (props: Props) => {
    return (
        <StyledCard title={props.title} style={props?.style} extra={props.icon}>
            {props.children}
        </StyledCard>
    )
}

const StyledCard = styled(Card)`
    width: 100%;

    .ant-card-head {
        border-bottom: 0px;
    }

    .ant-card-body {
        padding-top: 0px;
    }

    .ant-card-head-title {
        color: #55595D;
    }
`
export default MainCard;