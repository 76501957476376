import React, { createContext, useContext, useReducer } from "react";
import { Action, Location } from "./utils/Models"
const StoreContext = createContext({});

type State = {
    locations: Array<Location>;
    count: number;
};

const reducer = (state: State, action: Action): any => {
    switch (action && action.type) {
        case "ADD":
            const location: Location | undefined = action && action.location;
            const count = state.count + 1;

            return {
                count: count,
                locations: [...state.locations, { url: location && location.url, name: location && location.name, count: count }]
            };

        case "REMOVE":    
            const locations = state.locations.filter(l => (l?.count || 0 ) <= (action.location?.count || 0))

            return {
                locations: locations,
                count: locations.length
            };
            
        case "CLEAR":
            return {
                locations: [],
                count: 0
            };
        
        case "AFTERRELOAD":
            return {
                locations: action.locations && action.locations,
                count: action.locations && action.locations.length
            }

        default:
            throw new Error(`Unhandled action type: ${action && action.type}`);
    }
};

export const StoreProvider = ({ children }: any) => {
    const [state, dispatch] = useReducer(reducer, { locations: [], count: 0 });

    window.addEventListener("beforeunload", () => {    
        const { locations } = state;
        window.sessionStorage.setItem('locations', JSON.stringify(locations));
    });

    const removeLast = (location: Location) => {
        const { locations } = state;

        if (locations.length > 1) {
            dispatch({
                type: "REMOVE",
                location
            });
        }
    };

    const setToindex = (index: number) => {
        if (index > - 1) {
            dispatch({
                type: "REMOVE",
                location:{ 
                    url: "",
                    name: "",
                    count: index
                }
            });
        }
    };

    const DispatchAdd = (collection: any, id: string, url: string) => {
        if(!id)
            return;

        const item = collection.find((c: any) => c.id === id);
        
        dispatch({
            type: "ADD",
            location: {
                url: url,
                name: item?.name
            },
        });
    };

    const DispatchAfterReload = (locations: Array<Location>) => {
        dispatch({
            type: "AFTERRELOAD",
            locations: locations
        })
    }

    return (
        <StoreContext.Provider value={{ state, dispatch, removeLast, setToindex, DispatchAdd, DispatchAfterReload }}>
            {children}
        </StoreContext.Provider>
    );
};

export const useStore = () => useContext(StoreContext);

export const withStore = (Component: any) => {
    return (props: any) => {
        return <Component store={useStore()} {...props} />;
    };
};
