import React, { Component } from 'react';
import { Row, Col, Button, Upload, Spin, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { api } from '../api';
import CustomSelect from '../components/common/CustomSelect';
import { uploadDicomsToHardware } from '../utils/dicomHelpers';
import CustomSpin from '../components/common/CustomSpin';
import ResourceTile  from '../components/common/ResourceTile';

type Hardware = {
    id: string;
    name: string;
    shortName?: any;
    manufacturer?: any;
    serialNumber?: any;
    localisation?: any;
    deviceType?: any;
    outpostId: string;
    communicationProtocolId?: any;
    discriminator?: any;
    lastTestResultTime?: Date;
    nextTestResultTime?: Date;
}

type Outpost = {
    id: string;
    customerId: string;
    name: string;
    address?: any;
    reportTemplate?: any;
    lastTestResultTime?: Date;
    nextTestResultTime?: Date;
}

type HardwareInfo = {
    hardware: Hardware;
    outpost: Outpost;
}

type Props = {}

type State = {
    hardwares: HardwareInfo[];
    fileList: Array<any>;
    selectedHardwareId: string;
    uploading: boolean
}
class UploadDicomView extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        
        this.state = {
            hardwares: [],
            fileList: [],
            selectedHardwareId: '',
            uploading: false
        }
    }
    
    
    componentDidMount() {
        this.fetchHardwares();
    }
    
    render() {
        const { hardwares, fileList, selectedHardwareId, uploading } = this.state;
        
        return (
            <Spin spinning={uploading} size='large' indicator={<CustomSpin tip='Przesyłanie plików...' />}>
                <Row gutter={[16, 16]}>
                    <Col span={24} style={{fontSize: 20}}>Wybierz urządzenie</Col>
                    <Col span={24}>
                        <Row gutter={[16, 16]}>
                                {hardwares.map(obj => (
                                    <Col md={6} span={24} key={obj.hardware.id}>
                                        <ResourceTile
                                            id={obj.hardware.id}
                                            title={`${obj.outpost.name} > ${obj.hardware.name}`}
                                            onClick={(hardwareId) => this.setState({ selectedHardwareId: hardwareId })}
                                            color={obj.hardware.id === selectedHardwareId ? '#1B1E24' : '#F35958'}
                                            type='basic'
                                        />
                                    </Col>
                                ))}
                        </Row>
                    </Col>
                    <Col span={24}>
                        <Upload onChange={this.onChange} fileList={fileList} multiple={true} customRequest={this.dummyRequest}>
                            <Button icon={<UploadOutlined />} disabled={!selectedHardwareId}>Dodaj pliki DICOM</Button>
                        </Upload>
                    </Col>
                    {selectedHardwareId && fileList.length > 0 &&
                        <Col span={24}>
                            <Button onClick={this.onFinish}>Prześlij</Button>
                        </Col>
                    }
                </Row>
            </Spin>
        );
    }

    fetchHardwares = () => {
        api
            .get('/hardwares')
            .then(res => res.data)
            .then(hardwares => this.setState({ hardwares: this.filterHardwares(hardwares) }));
    }

    filterHardwares = (hardwares: HardwareInfo[]) => {
        const customerId = localStorage.getItem('customerId');

        if (!customerId)
            return hardwares;

        const filteredHardwares = hardwares.filter(h => h.outpost.customerId == customerId); 
        return filteredHardwares;
    }

    onChange = ({ _, fileList }: any) => {
        this.setState({
            fileList: fileList
        });
    }

    onFinish = () => {
        const { fileList, selectedHardwareId } = this.state;

        if (fileList.length === 0)
            return message.error('Musisz dodać co najmniej 1 plik DICOM.');

        if (!selectedHardwareId)
            return message.error('Musisz wybrać aparat, do którego plik DICOM ma zostać przypisany.');

        try {
            this.setState({ uploading: true })
            const originFilesList = fileList.map(el => el.originFileObj);
            uploadDicomsToHardware(originFilesList, selectedHardwareId, this.handleFinishUpload);
        } catch {
            this.clearState();
            return message.error('Wystąpił nieoczekiwany problem. Skontaktuj się z administratorem strony.');
        }   
    }

    handleFinishUpload = () => {
        this.clearState();
        return message.success('Pomyślnie przesłano pliki dicom.');
    }

    clearState = () => {
        this.setState({
            fileList: [],
            selectedHardwareId: '',
            uploading: false
        })
    }

    // to avoid default antd uploading action
    dummyRequest = ({ file, onSuccess }: any) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    };
}

export default UploadDicomView;