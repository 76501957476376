import React, { Component } from 'react';
import { Row, Col } from 'antd';
import styled from 'styled-components';
import { CustomTestCard, MainCard, CustomTable, CustomInput, IconButton, CustomChart, HistoryFilters } from '../components/common';
import { IRouter } from '../utils/Interfaces';
import calculateNextStudyDate from '../utils/calculateNextStudyDate';
import { api } from '../api';
import { 
    ArtefactsHistory, 
    HighResolutionContrastHistory, 
    HuWaterPhantomHistory, 
    UniformityHistory, 
    NoiseLevelHistory, 
    GeometricCorrectnessHistory, 
    TableMovementHistory,
    LocalisationLightsHistory,
    HuDensityHistory
} from "../components/tests/history"
import CustomSpin from "../components/common/CustomSpin"
 
type State = {
    filters: {
        selectedTimeOption: string,
        date: any,
    }
    type: string | null,
    results: Array<any>
    validTimeMinutes: number,
    isLoading: boolean
}

class TestHistoryView extends Component<IRouter, State> {
    constructor(props: any) {
        super(props);
        
        this.state = {
            filters: {
                selectedTimeOption: 'all',
                date: null
            },
            type: null,
            results: [],
            validTimeMinutes: 0,
            isLoading: true
        }
    }
    
    componentDidMount() {
        const { testId } = this.props.match.params;
        
        api
            .get(`test-results/test-case/${testId}`)
            .then(res => res.data)
            .then(historyInfo => this.unpackHistoryData(historyInfo))
            .catch(err => console.error(err))
            .finally(() => this.setState({isLoading: false}));
    }
    

    render() {
        const { type, results, validTimeMinutes, isLoading } = this.state;

        if (isLoading) {
            return <CustomSpin />;
        }

        if (type === 'artefacts') {
            return <ArtefactsHistory router={this.props} results={results} validTime={validTimeMinutes} calculateNextStudy={calculateNextStudyDate}/>
        }

        if (type === 'highContrast') {
            return <HighResolutionContrastHistory router={this.props} results={results} validTime={validTimeMinutes} calculateNextStudy={calculateNextStudyDate} />
        }

        if (type === 'water_phantom') {
            return <HuWaterPhantomHistory router={this.props} results={results} validTime={validTimeMinutes} calculateNextStudy={calculateNextStudyDate}/>
        }
        
        if (type === 'uniformity') {
            return <UniformityHistory router={this.props} results={results} validTime={validTimeMinutes} calculateNextStudy={calculateNextStudyDate}/>
        }
        
        if (type === 'noise_level') {
            return <NoiseLevelHistory router={this.props} results={results} validTime={validTimeMinutes} calculateNextStudy={calculateNextStudyDate}/>
        }

        if (type === 'geometricCorrectness') {
            return <GeometricCorrectnessHistory router={this.props} results={results} validTime={validTimeMinutes} calculateNextStudy={calculateNextStudyDate}/>
        } 
        
        if (type === 'table_movement') {
            return <TableMovementHistory router={this.props} results={results} validTime={validTimeMinutes} calculateNextStudy={calculateNextStudyDate}/>
        } 
        
        if (type === 'hu_density') {
            return <HuDensityHistory router={this.props} results={results} validTime={validTimeMinutes} calculateNextStudy={calculateNextStudyDate}/>
        }

        if (type === 'LOCALISATION_LIGHTS') {
            return <LocalisationLightsHistory router={this.props} results={results} validTime={validTimeMinutes} calculateNextStudy={calculateNextStudyDate}/>
        }
        
        return <>Niepoprawny typ testu</>
    }
    
    unpackHistoryData = (data: any) => {
        this.setState({
            type: data.type,
            results: data.results,
            validTimeMinutes: data.validTime
        })
    }
    
    handleChangeToNewTest = () => {
        this.props.history.push(this.props.location.pathname.replace(/[^/]*$/, 'perform-test'));
    }

    handleFiltersChange = (filterName: string, newValue: any) => {
        this.setState(prevState => {
            prevState.filters[filterName] = newValue;
            return prevState;
        })
    }

    handleFiltersSubmit = () => {
        alert('Filtrowanie!');
    }
}

const columns = [
    {
        title: '',
        dataIndex: 'column1',
        key: 'column1',
    },
    {
        title: 'POMIAR',
        dataIndex: 'column2',
        key: 'column2',
    },
    {
        title: 'ODNIESIENIE',
        dataIndex: 'column3',
        key: 'column3',
    },
    {
        title: 'ODCHYLENIE',
        dataIndex: 'column4',
        key: 'column4',
    },
]

const values = [
    { column1: 'Napięcie komora lewa', column2: 70.00, column3: 70, column4: '0.0%' },
    { column1: 'mAs komora lewa', column2: 13.00, column3: 13.2, column4: '1.5%' },
    { column1: 'Napięcie komora lewa', column2: 70.00, column3: 70, column4: '0.0%' },
    { column1: 'mAs komora lewa', column2: 13.00, column3: 13.2, column4: '1.5%' },
    { column1: 'Napięcie komora lewa', column2: 70.00, column3: 70, column4: '0.0%' },
    { column1: 'mAs komora lewa', column2: 13.00, column3: 13.2, column4: '1.5%' },
]

const columns2 = [
    {
        title: 'WYNIKI HISTORYCZNE',
        dataIndex: 'column1',
        key: 'column1',
    }
]

const values2 = [
    { column1: '2020-08-25 09:21' },
    { column1: '2020-07-23 09:21' },
    { column1: '2020-06-18 09:21' },
    { column1: '2020-08-25 09:21' },
    { column1: '2020-07-23 09:21' },
    { column1: '2020-06-18 09:21' },
    { column1: '2020-08-25 09:21' },
    { column1: '2020-07-23 09:21' },
    { column1: '2020-06-18 09:21' },
    { column1: '2020-08-25 09:21' },
    { column1: '2020-07-23 09:21' },
    { column1: '2020-06-18 09:21' },
    { column1: '2020-08-25 09:21' },
    { column1: '2020-07-23 09:21' },
    { column1: '2020-06-18 09:21' },
    { column1: '2020-08-25 09:21' },
    { column1: '2020-07-23 09:21' },
    { column1: '2020-06-18 09:21' },
    { column1: '2020-08-25 09:21' },
    { column1: '2020-07-23 09:21' },
    { column1: '2020-06-18 09:21' },
    { column1: '2020-08-25 09:21' },
    { column1: '2020-07-23 09:21' },
    { column1: '2020-06-18 09:21' },
    { column1: '2020-08-25 09:21' },
    { column1: '2020-07-23 09:21' },
    { column1: '2020-06-18 09:21' },
]

const GreyStyledRow = styled(Row)`
    background: #F6F7F8;
    padding: 20px 0px 20px 0px;
`

const WhiteStyledRow = styled(Row)`
    background: #FFFFFF;
    padding: 20px 0px 20px 0px;
`

export default TestHistoryView;