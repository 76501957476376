import React from "react";
import { ResponsiveLine } from "@nivo/line";
import { Card } from "antd";
import styled from "styled-components";
import { Markers } from "../../utils/Models"
const CustomChart = (props: Props) => {
    return (
        <StyledCard
            title={
                <ChartTitle>
                    {props.title ? props.title : "Wykres okresowy"}
                </ChartTitle>
            }
        >
            <ChartContainer>
                <MyResponsiveLine {...props}/>
            </ChartContainer>
        </StyledCard>
    );
};

type Props = {
    data: any;
    title?: string;
    legendDirection?: "column" | "row";
    yMax?:number;
    yMin?:number;
    markers?: Markers;
    legend?: any

};

type MyResponsiveLineProps = {
    data: any;
    legendDirection?: "column" | "row";
    yMax?:number,
    yMin?:number,
    markers?: Markers,
    legend?: any
};

const MyResponsiveLine = (props: MyResponsiveLineProps) => (
    <ResponsiveLine
        data={props.data}
        margin={{ top: 10, right: 110, bottom: 50, left: 60 }}
        xScale={{
            type: "time",
            format: "%Y-%m-%d %H:%M:%S",
            precision: "minute",
        }}
        colors={{ scheme: "nivo" }}
        xFormat='time:%Y-%m-%d %H:%M:%S'
        yScale={{
            type: "linear",
            min: props?.yMin || "auto",
            max: props?.yMax || "auto",
            stacked: false,
            reverse: false,
        }}
        axisLeft={{
            legendOffset: 12,
            tickPadding: 20,
        }}
        axisBottom={{
            format: "%b %d",
            tickValues: "every 2 weeks",
            legendOffset: -12,
        }}
        enableGridX={false}
        curve='monotoneX'
        enablePointLabel={true}
        pointSize={16}
        pointBorderWidth={1}
        pointBorderColor={{
            from: "color",
            modifiers: [["darker", 0.3]],
        }}
        markers={props?.markers}
        useMesh={true}
        enableSlices='x'
        sliceTooltip={({ slice }) => (
            <div
                style={{
                    background: "white",
                    padding: "9px 12px",
                    border: "1px solid #ccc",
                }}
            >
                <strong>
                    {slice.points && slice.points[0].data.xFormatted}
                </strong>
                {slice.points.map(point => (
                    <div
                        key={point.id}
                        style={{
                            color: point.serieColor,
                            padding: "3px 0",
                        }}
                    >
                        {point.serieId} : {point.data.yFormatted}
                    </div>
                ))}
            </div>
        )}
        legends={props?.legend || [
            {
                anchor: "bottom-right",
                direction: props?.legendDirection || "column",
                justify: false,
                translateX: 97,
                translateY: 0,
                itemsSpacing: 0,
                itemDirection: "left-to-right",
                itemWidth: 80,
                itemHeight: 45,
                itemOpacity: 0.75,
                symbolSize: 12,
                symbolShape: "circle",
                symbolBorderColor: "rgba(0, 0, 0, .5)",
                effects: [
                    {
                        on: "hover",
                        style: {
                            itemBackground: "rgba(0, 0, 0, .03)",
                            itemOpacity: 1,
                        },
                    },
                ],
            },
        ]}
    />
);

const ChartContainer = styled.div`
    height: 500px;
`;

const StyledCard = styled(Card)`
    .ant-card-head {
        border: none;
    }

    .ant-card-body {
        padding: 0;
    }
`;

const ChartTitle = styled.p``;

export default CustomChart;
