import React, { useState } from "react";
import { Row, Col, Space, Collapse, Typography, Divider, Button, InputNumber, Tooltip } from "antd";
import { EditOutlined, SaveOutlined, DeleteOutlined } from '@ant-design/icons';
import { CustomInput, NewTestConfigurationCard } from "../../common";
import { Point, Circle, ParamType } from "../../../utils/Models";
import styled from "styled-components";
import { StyledCollapse } from "./CustomCollapse";

const { Panel } = Collapse;
const { Text } = Typography;

type Configuration = {
    testNumber: number;
    kVDeviation: number;
    mADeviation: number;
    averagekV: number;
    averagemA: number;
    kV: string | number;
    mA: string | number;
    hu: number[];
    name: string;
};

type Props = {
    testNumber: number;
    points: Point[];
    circles: Circle[];
    roiMeasurementRatio: number;
    configuration: Configuration;
    handleOnAddMeasurmentClick: (area: {averageHu: number, index: number, active: boolean, pointsAmount: number}) => void;
    handleroiMeasurementRatioChange: (value: number) => void;
    handleHistoryResults: (state: boolean, newConfigId: string) => void;
    handleConfigurationNameChange: (name: string) => void;
    handleParamsChange: (label: ParamType, value: number) => void;
    handleRemoveCircle: (c: Circle) => void;
    handleRemoveArea: (index: number) => void;
    referenceTestCases: any[];
    canChoosePoints: boolean;
    huAreas: () => JSX.Element[] | JSX.Element;
    onFinish: () => void;
    areasInfo: Array<{averageHu: number, index: number, active: boolean, pointsAmount: number, name: string, editing: boolean}>,
    handleAddArea: () => void;
    handleChangeAreaName: (area: {averageHu: number, index: number, active: boolean, pointsAmount: number, name: string}, newName: string) => void;
    setEditableStateToArea: (index: number, state: boolean) => void;
};

const Configuration = ({
    testNumber,
    circles,
    roiMeasurementRatio,
    handleParamsChange,
    handleOnAddMeasurmentClick,
    handleHistoryResults,
    huAreas,
    handleRemoveCircle,
    handleRemoveArea,
    handleConfigurationNameChange,
    onFinish,
    handleroiMeasurementRatioChange,
    handleChangeAreaName,
    referenceTestCases,
    canChoosePoints,
    configuration: { mADeviation, kVDeviation, averagekV, averagemA, name, kV, mA },
    areasInfo,
    handleAddArea,
    setEditableStateToArea
}: Props) => {
    const isEditingAreaName = areasInfo.find(a => a.editing);
    const [currentInputValue, setCurrentInputValue] = useState<string>("");

    const handleActivateEditing = (area: any) => {
        setEditableStateToArea(area.index, true)
        setCurrentInputValue(area.name);
    }

    const handleSaveNewInputValue = (area: any) => {
        setEditableStateToArea(area.index, false)
        handleChangeAreaName(area, currentInputValue);
        setCurrentInputValue("");
    }

    return (
        <NewTestConfigurationCard
            testName='Hu różnych gęstości'
            configName={name}
            onFinish={onFinish}
            onNameChange={handleConfigurationNameChange}>
            <Space direction='vertical' style={{ width: "100%" }}>
                <Row gutter={[16, 16]} align="bottom">
                    <Col span={12} >
                        <Row>Średnica ROI</Row>
                        <CInputNumber
                           color='#D779F2'
                            defaultValue={100}
                            min={0}
                            max={100}
                            disabled={circles.length > 0}
                            value={roiMeasurementRatio}
                            formatter={(value: any) => `${value}%`}
                            parser={(value: any) => value.replace('%', '')}
                            onChange={e => handleroiMeasurementRatioChange(parseFloat(`${e}`))}
                        />
                    </Col>
                </Row>

                <CustomInput
                    type='number'
                    color='#D779F2'
                    value={parseFloat(kVDeviation.toFixed(2))}
                    label='Odchylenie kV'
                    onChange={e => handleParamsChange("kVDeviation", e)}
                    min={0}
                />
                <CustomInput type='number' color='#D779F2' value={kV} label='Średnie kV' disabled />
                <CustomInput
                    type='number'
                    color='#D779F2'
                    value={parseFloat(mADeviation.toFixed(2))}
                    label='Odchylenie mA'
                    onChange={e => handleParamsChange("mADeviation", e)}
                    min={0}
                />
                <CustomInput type='number' color='#D779F2' value={mA} label='Średnie mA' disabled />

                <StyledCollapse activeKey='1'>
                    <Panel header='Obszary pomiaru' key='1'>
                        {areasInfo.map(area => (
                            <Row align='middle' style={{height: 50, backgroundColor: area.active ? "#D3D3D3": "#fff"}}>
                                <Col span={10} style={{ paddingLeft: 10 }}>
                                    <CustomInput value={area.editing ? currentInputValue : area.name} onChange={(e) => setCurrentInputValue(e)} disabled={!area.editing} style={{ marginBottom: 0 }} />
                                </Col>
                                {!isEditingAreaName &&  
                                    <Col span={4}>
                                        <Tooltip title="Zmień nazwę obszaru">
                                            <Button icon={<EditOutlined />} onClick={() => handleActivateEditing(area)}/>
                                        </Tooltip>
                                    </Col>}
                                {isEditingAreaName && area.editing &&
                                     <Col span={4}>
                                        <Tooltip title="Zapisz">
                                            <Button icon={<SaveOutlined />} onClick={() => handleSaveNewInputValue(area)}/>
                                        </Tooltip>
                                    </Col>    
                                }
                                <Col span={8}>
                                    (HU: {area.averageHu}) 
                                </Col>
                                {area.pointsAmount >= 3 &&  
                                    <Col span={2}>
                                        <Tooltip title="Usuń">
                                            <Button 
                                                onClick={() => handleRemoveArea(area.index)}
                                                icon={<DeleteOutlined /> } 
                                                style={{ backgroundColor: '#FFF', color: '#f5222d' }}
                                            />      
                                        </Tooltip>
                                    </Col>
                                }
                            </Row>
                        ))}
                        <Button onClick={handleAddArea} style={{ marginTop: 10 }}>Dodaj obszar</Button>
                    </Panel>
                </StyledCollapse>
                <Divider />
            </Space>
        </NewTestConfigurationCard>
    );
};

const CInputNumber = styled(InputNumber)`
    border-left: ${props => props.color && `3px solid ${props.color}`};
    width: 100%;
    margin-bottom: 8px;
`

export default Configuration;
