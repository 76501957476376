import styled from "styled-components"

const Title = styled.h4`
    font-size: 16px;
`;



const Text = {
    Title: Title
}

export { 
    Title
}

export default Text;
