import React, { Component } from "react";
import { Row, Col, Typography, Card, Divider, Collapse, Space, Spin, message, Statistic } from "antd";
import { MainCard, CenteredButton, CustomUpload, ActionButton, CustomTable, GreenCheckIcon, RedCrossIcon } from "../common";
import styled from "styled-components";
import _ from "lodash";
import { IRouter } from "../../utils/Interfaces";
import DwvComponent from "../common/Dwv/DwvComponent.js";
import { api } from "../../api";
import { Circle, Point, ParamType } from "../../utils/Models";

const { Panel } = Collapse;
const { Text } = Typography;

type Params = {
    kVDeviation: number;
    mADeviation: number;
    averagekV: number;
    averagemA: number;
    kV: number;
    mA: number;
    hu: string[];
};

type State = {
    configurations: { active: boolean; configuration: string; id: string; testCaseId: string }[];
    configuration: { params: { testNumber: number; referenceTestCases: any; circles: Circle[] } & Params; id: string; references: any };
    params: Params;
    isDicomLoaded: boolean;
    canChoosePoints: boolean;
    points: Point[];
    draw: boolean;
    hu: string[];
    performHu: boolean;
    loading: boolean;
    dataSource: any[];
    testPassed: boolean;
};

type Props = {
    router: IRouter;
    thumbnails: React.ReactNode;
    dicom: any;
    selectedDicomId: string | any;
    mainCardReloadIcon: React.ReactNode;
    fetchThumbnails: () => void;
    onLoadingDicomFinish: () => void;
    onSavingTestStart: () => void;
    onSavingTestFinish: () => void;
};

class HuDensityTest extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            configurations: [],
            configuration: {
                params: {
                    testNumber: 0,
                    referenceTestCases: [],
                    averagekV: 0,
                    averagemA: 0,
                    kVDeviation: 0,
                    mADeviation: 0,
                    kV: 0,
                    mA: 0,
                    hu: [],
                    circles: [],
                },
                references: null,
                id: "",
            },
            params: { kVDeviation: 5, mADeviation: 5, averagekV: 0, averagemA: 0, kV: 0, mA: 0, hu: [] },
            isDicomLoaded: false,
            canChoosePoints: false,
            points: [],
            draw: false,
            hu: [],
            performHu: false,
            loading: false,
            dataSource: [],
            testPassed: false,
        };
    }

    componentDidMount() {
        this.fetchTestConfigurations();
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        if (!_.isEqual(prevState.hu, this.state.hu) && !_.isEmpty(this.state.hu)) {
            this.handleTableResult();
        }
    }

    render() {
        const {
            dataSource,
            draw,
            configuration: {
                params: { circles }
            },
            performHu,
            loading
        } = this.state;

        return (
            <Spin spinning={loading} tip='Trwa wykonuwanie testu!'>
                <Row justify='space-between'>
                    <Col>
                        <PerformExaminationDetailsCard
                            type='inner'
                            title={
                                <>
                                    <span>Hu różnych gęstości</span>
                                    <CenteredButton
                                        onClick={() => this.saveTestResult()}
                                        label='Zachowaj wynik testu'
                                    />
                                    <CenteredButton
                                        // onClick={() => this.openModal()}
                                        label='Opis procedury'
                                    />
                                </>
                            }>
                        </PerformExaminationDetailsCard>
                        <MainCard title='Wyniki pomiaru'>
                            <CustomTable columns={this.columns} data={dataSource} />
                        </MainCard>
                    </Col>
                    <Col xxl={{span: 12}} xl={{span: 11}} lg={{span: 10}}>
                        <MainCard title='Obrazy w teście'>
                            <DwvComponent
                                {...this.props}
                                handleDicomLoading={this.handleIsDicomLoadedState}
                                handleAfterDraw={this.handleAfterDraw}
                                updateParams={this.handleParamsChange}
                                circles={circles}
                                drawCircles={draw}
                                performHu={performHu}
                                getHuResult={this.getHuResult}
                                lineStroke={2}
                            />
                        </MainCard>
                    </Col>
                    <Col span={4}>
                        <MainCard title='Ostatnio dodane' icon={this.props.mainCardReloadIcon}>
                            <SideBar>{this.props.thumbnails}</SideBar>
                        </MainCard>
                    </Col>
                </Row>
            </Spin>
        );
    }

    handleAfterDraw = () => {
        this.setState({
            draw: false,
            canChoosePoints: false,
            points: [],
        });
    };

    handleIsDicomLoadedState = (state: boolean) => {
        if(state){
            this.setState({ isDicomLoaded: state }, () => this.performTest());
        }
        this.setState({ isDicomLoaded: state });
    };

    configurationList = (item: any) => {
        const data = JSON.parse(item.configuration);

        const {
            result: {
                params: { kV, mA, kVDeviation, mADeviation },
            },
        } = data;

        return (
            <>
                <Row gutter={[16, 16]}>
                    <Col span={12}>
                        <CustomStatistic title='kV' value={kV} />
                    </Col>
                    <Col span={12}>
                        <CustomStatistic title='mA' value={mA} />
                    </Col>
                </Row>
                <Row gutter={[16, 16]}>
                    <Col span={12}>
                        <CustomStatistic title='Odchylenie kV' value={kVDeviation} />
                    </Col>
                    <Col span={12}>
                        <CustomStatistic title='Odchylenie mA' value={mADeviation} />
                    </Col>
                </Row>
                <Row gutter={[16, 16]}>
                    <Col span={12} />
                    <Col span={12} />
                </Row>
            </>
        );
    };

    fetchTestConfigurations = () => {
        const { testId } = this.props.router.match.params;

        api.get(`/test-configurations/test-case/${testId}`)
            .then(res => res.data)
            .then(data => data.configurations)
            .then(con => {
                if(_.isEmpty(con) || con === null){
                    setTimeout(() =>  { 
                        return this.props.router.history.push(
                            this.props.router.location.pathname.replace(/[^/]*$/, 'settings'));
                        }
                    , 1000)
                }
                const activeConfiguration = con.find((c: any) => c.active === true);
                const {
                    result: { params },
                    references
                } = JSON.parse(activeConfiguration.configuration);
                this.setState({ configurations: con, configuration: { id: activeConfiguration.id, params, references: references.find((r: any) => r.active) } });
            })
            .catch(err => console.error(err));
    };

    huAreas = () => {
        const { hu } = this.state;

        if (hu.length === 0) {
            return;
        }

        const huResult: JSX.Element[] = [];

        for (const i in hu) {
            const value = (
                <>
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <Text>Średnie HU na obszarze {i + 1}</Text>
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col span={24}>{parseFloat(hu[i]).toFixed(2)}</Col>
                    </Row>
                </>
            );
            huResult.push(value);
        }
        return huResult;
    };

    saveTestResult = () => {
        const {
            params,
            configuration: {
                id,
                params: { testNumber, circles },
            },
            dataSource,
            isDicomLoaded,
            testPassed,
            hu,
        } = this.state;

        const { testId } = this.props.router.match.params;

        if (!isDicomLoaded)
            return message.error("Nie wybrano pliku Dicom");

        if (!id)
            return message.error("Brak aktywnej konfiguracji");

        if (circles.length !== testNumber)
            return message.error("Nie wystraczjąca ilość pomiarów");

        this.props.onSavingTestStart()

        const result = {
            values: { ...params, hu },
            dataSource: dataSource,
        };

        const body = {
            Passed: testPassed,
            Deactivated: true,
            TestData: JSON.stringify(result),
            TestConfigurationId: id,
            DicomId: this.props.selectedDicomId
        };

        api
            .post(`test-results/test-case/${testId}`, { ...body })
            .then(() => setTimeout(() => this.props.onSavingTestFinish(), 2000))
            .catch(err => console.error(err));

    };

    handleConfigurationAdd = () => {
        this.props.router.history.push(this.props.router.location.pathname.replace(/[^/]*$/, "test-results"));
    };

    getHuResult = (result: any) => {
        this.setState({
            hu: result,
            loading: false,
            performHu: false,
        });
    };

    handleParamsChange = (label: ParamType, value: number) => {
        switch (label) {
            case "kV":
                this.setState(prevState => {
                    return { params: { ...prevState.params, kV: value } };
                });
                break;
            case "mA":
                this.setState(prevState => {
                    return { params: { ...prevState.params, mA: value } };
                });
                break;
            case "mADeviation":
                this.setState(prevState => {
                    return { params: { ...prevState.params, mADeviation: value } };
                });
                break;
            case "kVDeviation":
                this.setState(prevState => {
                    return { params: { ...prevState.params, kVDeviation: value } };
                });
                break;
            case "averagekV":
                this.setState(prevState => {
                    return { params: { ...prevState.params, averagekV: value } };
                });
                break;

            case "averagemA":
                this.setState(prevState => {
                    return { params: { ...prevState.params, averagemA: value } };
                });
                break;

            default:
                break;
        }
    };

    performTest = () => {
        const { isDicomLoaded, configuration: {id, params : { circles }} } = this.state;
        if(!isDicomLoaded){
            return message.warn("Nie wybrano pliku Dicom");
        }
        if(id === ""){
            return message.warn("Brak aktywnej konfiguracji")
        }
        if(circles.length === 0){
            return message.warn("Błędna")
        }
        this.setState({ draw: true, performHu: true });
    };

    handleTableResult = () => {
        const {
            params: { kV, mA, averagemA, hu, averagekV, kVDeviation, mADeviation },
            configuration: { references }
        } = this.state;

        let dataResult: any[] = [];

        this.state.hu.map((h, idx) => {
            const huPomiar = parseFloat(h);
            const huRef = references && references.avgHu.length > idx ? parseFloat(references.avgHu[idx]) : 0;
            const huResult = {
                key: idx,
                name: `Obszar ${idx + 1}`,
                measurement: huPomiar.toFixed(3),
                reference: references ? huRef.toFixed(2) : '--',
                deviation: (huPomiar - huRef).toFixed(2),
                result: references ? Math.abs(huPomiar - huRef) < 20 : true,
            };
            dataResult.push(huResult);
        });

        const kResultDeviation = references ? (references.kV / kV - 1) * 100 : 0;
        const kVResult = {
            key: this.state.hu.length,
            name: "kV",
            measurement: kV,
            reference: references ? references.kV : '--',
            deviation: kResultDeviation,
            result: references ? Math.abs(kResultDeviation) < kVDeviation : true,
        };

        dataResult.push(kVResult);

        const mResultDeviation = references ? (references.mA / mA - 1) * 100 : 0;
        const mAResult = {
            key: hu.length,
            name: "mA",
            measurement: mA,
            reference: references ? references.mA : '--',
            deviation: mResultDeviation,
            result: references ? Math.abs(mResultDeviation) < mADeviation : true,
        };
        dataResult.push(mAResult);

        const passed = references ? _.isUndefined(_.find(dataResult, ["result", false])) : true;
        const finalResult = {
            key: hu.length + 2,
            name: "Wynik testu",
            result: passed,
        };
        dataResult.push(finalResult);

        this.setState({ dataSource: dataResult, testPassed: passed });
    };

    columns = [
        {
            title: "Nazwa",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Pomiar",
            dataIndex: "measurement",
            key: "measurement",
        },
        {
            title: "Odniesienie",
            dataIndex: "reference",
            key: "reference",
        },
        {
            title: "Odchylenie",
            dataIndex: "deviation",
            key: "deviation",
        },
        {
            title: "Poprawny",
            key: "result",
            dataIndex: "result",
            render: (result: boolean) => (result ? <GreenCheckIcon /> : <RedCrossIcon />),
        },
    ];
}

const PerformExaminationDetailsCard = styled(Card)`
    .ant-card-head-title {
        font-size: 24px;
        font-weight: 600;
        color: #505458;
        padding-top: 25px;
        border: none;
    }
`;

const SideBar = styled.div`
    overflow: auto;
    height: 80vh;
`;

const CustomStatistic = styled(Statistic)`
    .ant-statistic-content {
        font-size: 18px;
    }
`;

export default HuDensityTest;
