import React, { Component } from 'react';
import { Col, Row, message, Spin, Divider, Statistic, Collapse, Space, Table, Button } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import 'moment'
import { PlusSquareOutlined } from "@ant-design/icons"
import moment from 'moment';
import { NewTestConfigurationCard, MainCard, CustomInput, CustomTable, ActionButton } from '../../common';
import styled from "styled-components";
import { api } from '../../../api';
import { IRouter } from '../../../utils/Interfaces';
import DwvComponent from "../../common/Dwv/DwvComponent";
import { Point, HighContrastDeviation, HighContrastConfiguration, HighContrastTestResult, HighContrastReference } from "../../../utils/Models";
import { NEW_CONFIG_INFO, showConfirmModal } from '../../../utils/modalConfirm';

type Props = {
    loadingDicom: boolean;
    id: any,
    selectedConfigId: string | null,
    configurations: Array<any>,
    handleChangeConfigId: (id: string) => void,
    fetchConfigurations: () => void,
    router: IRouter,
    onLoadingDicomFinish: () => void,
    mainCardReloadIcon: React.ReactNode,
    fetchThumbnails: () => void,
    thumbnails: React.ReactNode,
}

type State = {
    previousTests: Array<any>,
    selectedRows: Array<any>,
    loading: boolean,
    configName: string,
    radius: number,
    dwvState: any;
    areaClicked: boolean,
    clickedPosition: Point,
    reset: boolean,
    deviationSettings: HighContrastDeviation,
    newReferenceName: string
    collapseActiveKeys: any
}

export default class HighContrastSettings extends Component<Props, State> {
    constructor(props: any) {
        super(props)

        this.state = {
            previousTests: [],
            selectedRows: [],
            loading: false,
            configName:  moment().format("YYYY-MM-DD HH:mm"),
            radius: 10,
            dwvState: {},
            areaClicked: false,
            clickedPosition: {
                x: -1, 
                y: -1,
            },
            reset: false,
            deviationSettings: {
                kV: 10,
                mAs: 10,
                MTF50: 10,
            },
            newReferenceName: moment().format("YYYY-MM-DD HH:mm"),
            collapseActiveKeys: null
        }
    }

    componentDidMount() {
        this.fetchPreviousTests();
    }

    componentDidUpdate(prevProps: Props, _: State) {
        if (!this.props.loadingDicom && prevProps.loadingDicom) {
            this.toggleReset();
            this.setState({
                configName: moment().format("YYYY-MM-DD HH:mm"),
                radius: 10,
                deviationSettings: {
                    kV: 10,
                    mAs: 10,
                    MTF50: 10,
                }
            })
        }
    }

    render() {
        const { configurations, handleChangeConfigId } = this.props;
        const { loading, collapseActiveKeys } = this.state;
        const activeId = this.getActiveItemId();
        return (
            <Row justify="start">
                <Col span={8}>
                    <Spin spinning={loading} delay={300}>
                        <StyledCollapse>
                            <StyledPanel header='Konfiguracje' key='MAINPANEL'>
                                <StyledCollapse accordion activeKey={ collapseActiveKeys } onChange={(key: any) => {
                                    handleChangeConfigId(key);
                                    this.onChangeCollapse(key);
                                }}
                                >
                                    <StyledPanel key="CONFIGURATION" header="Nowa konfiguracja">
                                            <NewTestConfigurationCard
                                                testName="Rozdzielczość Wysokokontrastowa"
                                                configName={this.state.configName}
                                                onNameChange={(value) => this.setState({ configName: value })}
                                                children={this.generateConfigurationParameters()}
                                                onFinish={() => this.handleSaveConfiguration()} 
                                            />
                                    </StyledPanel>
                                    {configurations.map((item: any, key: number) => {
                                        let configurationName = JSON.parse(item.configuration).name;
                                        if (item.id === activeId) {
                                            configurationName += ' (Aktywna)';
                                        }
                                        return(
                                            <StyledPanel key={item.id} header={`${configurationName}`}>
                                                <Space direction='vertical'>
                                                    {this.showSelectedConfigParams(item)}
                                                </Space>
                                                <StyledCollapse key="REFERENCE">
                                                    {item.id === activeId && 
                                                        <StyledPanel key="NEW" header="Dodaj wartości odniesienia">
                                                            {this.generateReferenceParameters()}
                                                        </StyledPanel>
                                                    }
                                                    {JSON.parse(item.configuration).referenceArray && JSON.parse(item.configuration).referenceArray.map((ref: HighContrastReference) => {
                                                        let referenceName = ref.name;
                                                        if (ref.active) {
                                                            referenceName += ' (Aktywna)';
                                                        }
                                                        return (
                                                            <StyledPanel key={ref.id} header={referenceName}>
                                                                <CustomStatistic title="Odniesienie kV" value={ref.kV} />
                                                                <CustomStatistic title="Odniesienie mA" value={ref.mA} />
                                                                <CustomStatistic title="Odniesienie MTF50" value={ref.MTF50} />
                                                                <Divider />
                                                                {item.id === activeId && !ref.active && <Button onClick={() => this.handleActivateReference(ref.id)}>Aktywuj</Button>}
                                                            </StyledPanel>
                                                        )
                                                    })}
                                                </StyledCollapse>
                                            </StyledPanel>
                                        )
                                    })}
                                </StyledCollapse>
                            </StyledPanel>
                        </StyledCollapse>
                    </Spin>
                </Col>
                <Col span={12}>
                        <MainCard title='Obrazy w teście'>
                        <DwvComponent
                            {...this.props}
                            {...this.state}
                            onStateChange={this.changeDwvState}
                            canZoom={true}
                            highContrast
                            setAreaClicked={this.setAreaClickedHandler}
                            setClickedPosition={this.setClickedPosition}
                        />
                        </MainCard>
                </Col>
                <Col span={4}>
                    <MainCard title="Ostatnio dodane" icon={this.props.mainCardReloadIcon}>
                        <SideBar>
                            {this.props.thumbnails}
                        </SideBar>
                    </MainCard>
                </Col>
            </Row>
        )
    }

    fetchPreviousTests = () => {
        api
            .get(`test-results/test-case/${this.props.id}`)
            .then(res => res.data)
            .then(res => this.setState({ previousTests: res.results }));
    }

    getActiveItemId = () => {
        const { configurations } = this.props;
        if (configurations.length >= 1){
            return configurations.find(conf => conf.active == true)?.id;
        }
    }

    handleSaveConfiguration = () => {
        const { dwvState, radius, clickedPosition, configName, deviationSettings } = this.state;
        if (!this.validate(dwvState, radius, clickedPosition, configName, deviationSettings))
            return

        this.setState({ loading: true });


        const config: HighContrastConfiguration = {
            name: this.state.configName,
            radius: radius,
            clickedPosition: clickedPosition,
            deviationSettings: deviationSettings,
            references: []
        }

        api.post(`test-configurations/test-case/${this.props.id}`, { Content: JSON.stringify(config) })
            .then((newConfig) => {
                message.success("Utworzono nową konfigurację");
                this.setState({ 
                    configName: '', 
                    radius: 10, 
                    deviationSettings: {
                        kV: 10,
                        mAs: 10,
                        MTF50: 10
                    },
                });
                this.toggleReset();
                this.props.fetchConfigurations();
                this.setState({ collapseActiveKeys: [newConfig.data.id] })
            })
            .catch(() => message.warning("Nie udało się utworzyć konfiguracji"))
            .finally(() => this.setState({ loading: false }));
    }

    showSelectedConfigParams = (item: any) => {
        const configuration: HighContrastConfiguration = item && JSON.parse(item.configuration)
        const { radius, deviationSettings, clickedPosition } = configuration;
        if (!configuration) {
            return <p>Nie udało się pobrać konfiguracji</p>
        }
        return (
            <>
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <CustomStatistic title="promień okręgu ROI mm" value={radius} />
                        <CustomStatistic title="Środek ROI [x, y]" value={`[${Math.floor(+clickedPosition.x)}, ${Math.floor(+clickedPosition.y)}]`} />
                        <CustomStatistic title="Odchylenie kV (%)" value={+deviationSettings.kV} />
                        <CustomStatistic title="Odchylenie mA (%)" value={+deviationSettings.mAs} />
                        <CustomStatistic title="Odchylenie MTF50 (%)" value={+deviationSettings.MTF50} />
                    </Col>
                </Row>
            </>
        )
    }

    generateConfigurationParameters = () => {
        const { radius, areaClicked, deviationSettings } = this.state;
    

        return (
            <Col span={24}>
                <Row>
                    <Col span={24}>
                        <CustomInput type="number" label={"Promień okręgu ROI (mm)"} value={radius} onChange={(e) => this.setState({radius: e})}/>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <CustomInput type="number" label={"Odchylenie kV (%)"} value={deviationSettings.kV} 
                            onChange={(e) => this.setDeviationParameter("kV", e)}/>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <CustomInput type="number" label={"Odchylenie mA (%)"} value={deviationSettings.mAs} 
                            onChange={(e) => this.setDeviationParameter("mAs", e)}/>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <CustomInput type="number" label={"Odchylenie MTF50 (%)"} value={deviationSettings.MTF50} 
                            onChange={(e) => this.setDeviationParameter("MTF50", e)}/>
                    </Col>
                </Row>
                <Divider />
                <Row justify="start">
                    <Col>
                        {areaClicked && 
                        <PlusSquareOutlined style={{ fontSize: '24px', padding: '5px'}} onClick={this.toggleReset}/>}
                    </Col>
                </Row>
            </Col>)
    }

    calculateAverage = (tag: 'kV' | 'mA' | 'MTF50') => {
        const { selectedRows } = this.state;
        if (selectedRows.length > 0) {
            let sum = 0;
            selectedRows.forEach(row => {
                sum += +row[tag];
            })
            return +(sum / selectedRows.length);
        }
        return 0;
    }

    changeDwvState = (newState: any) => {
        this.setState({ dwvState: newState });
    };

    setAreaClickedHandler = () => {
        const { radius } = this.state;
        if (radius <= 0) 
            return

        this.setState({
            areaClicked: true
        });
    }

    setClickedPosition = (point: Point) => {
        this.setState({ clickedPosition: point });
    }

    setDeviationParameter = (key: "kV" | "mAs" | "MTF50", value: number) => {
        const { deviationSettings } = this.state;
        deviationSettings[key] = value;
        this.setState({ deviationSettings: deviationSettings });
    }

    toggleReset = () => {
        this.setState(prevState => ({ reset: !prevState.reset, areaClicked: false, clickedPosition: { x: -1, y: -1 } }));
    }

    onChangeCollapse = (keys: any) => {
        if (keys === "CONFIGURATION" ) {
            return showConfirmModal(
                () => this.expandCollapse("CONFIGURATION"),
                NEW_CONFIG_INFO
            );
        } else {
            this.expandCollapse(keys);
        }
    }

    expandCollapse = (key: string) => {
        this.setState({ collapseActiveKeys: key });
    }

    validate = (dwvState: any, radius: number, clickedPosition: Point, configName: String, deviationSettings: HighContrastDeviation): boolean => {
        if (!dwvState.version) {
            message.warning("Nie wczytano pliku dicom");
            return false
        }

        if (typeof radius !== "number" || radius <= 0) {
            message.warning("Niepoprawny promień ROI");
            return false
        }
        
        if (clickedPosition.x === -1 || clickedPosition.y === -1) {
            message.warning("Nie zaznaczono środka ROI");
            return false
        }

        if (!configName || configName.trim().length === 0) {
            message.warning("Nie podano nazwy nowej konfiguracji");
            return false
        }

        for (let [key, value] of Object.entries(deviationSettings)) {
            if (typeof value !== "number" || value < 0) {
                message.warning(`Niepoprawna wartość: ${value}, parametru: ${key}`);
            }
        }
        
        return true
    }

    generateReferenceParameters = () => {
        const { previousTests, selectedRows, newReferenceName } = this.state;
        const currentConfigurationId = this.getActiveItemId()

        const data: any[] = []

        previousTests && previousTests.forEach(test => {
            if (test.result.testConfigurationId === currentConfigurationId) {
                const parsedData: HighContrastTestResult = JSON.parse(test.result.testData);
                const testData = {
                    id: test.result.id,
                    date: test.result.dateOfTest,
                    radius: parsedData.configuration.radius,
                    kV: parsedData.kV,
                    mA: parsedData.mA,
                    MTF50: parsedData.MTF50
                }
                data.push(testData);
            }
        });

        const rowSelection = {
            onChange: (_: any, selectedRows: any) => {
                this.setState({
                    selectedRows: [...selectedRows]
                })
            },
        }

        const dataSource = [{
            kV: parseFloat(this.calculateAverage('kV').toFixed(2)),
            mA: parseFloat(this.calculateAverage('mA').toFixed(2)),
            MTF50: parseFloat(this.calculateAverage('MTF50').toFixed(3)),
        }]


        return (
            <>
                <p>Wartości z kV, mA i MTF50 z poprzednich pomiarów</p>
                <Divider />
                <Row justify="center">
                    <CustomInput label="Nazwa referencji" value={newReferenceName} onChange={(e) => this.setState({ newReferenceName: e })} />
                </Row>
                <Row justify={'space-around'}>
                    <Col>
                        <Table
                            rowSelection={{
                                ...rowSelection
                            }}
                            columns={this.historyResultsColumns}
                            dataSource={data}
                            rowKey={(record) => record.id}
                            size="small" scroll={{ x: 500 }}
                        />
                    </Col>
                    <Col span={18}>
                        <CustomTable data={dataSource} columns={this.paramsConfigColumns}/>
                    </Col>
                </Row>
                <Row>
                    <ActionButton disabled={previousTests.length < 1 || selectedRows.length < 1} onClick={() => this.saveReferenceParamaters(dataSource[0], previousTests)} label="Dodaj wartości odniesienia"/>
                </Row>
            </>
        )
    }

    saveReferenceParamaters = (dataSource: {kV: number, mA: number, MTF50: number}, previousTests: any[]) => {
        if (previousTests.length < 1)
            return 

        const { configurations } = this.props;

        const { confId, confBody } = this.getActiveIdAndBodyFromJson(configurations);

        const newReference = {
            ...dataSource, active: true, id: uuidv4(), name: this.state.newReferenceName
        }

        const references = this.setActiveReference(confBody.references, newReference);

        confBody.references = [...references];
        
        this.setState({ loading: true });

        api
            .patch(`test-cases/test-configurations/${confId}`, { Content: JSON.stringify(confBody) })
            .then(() => {
                this.setState({
                    newReferenceName: moment().format("YYYY-MM-DD HH:mm"),
                });
                message.success("Utworzono nową referencję");
                this.handleRecalculateActiveConfTestResults(newReference);
                this.props.fetchConfigurations();
            })
            .catch(() => message.warning("Nie udało się utworzyć referencji"))
            .finally(() => this.setState({ loading: false }));

    }

    getActiveIdAndBodyFromJson(configurations: any): { confId: string, confBody: HighContrastConfiguration } {
        const active = configurations.find((conf: any) => conf.active)

        const activeConfiguration = JSON.parse(active.configuration)

        return {
            confId: active.id,
            confBody: activeConfiguration
        }
    }

    handleActivateReference = (refId: string) => {
        const { confId, confBody } = this.getActiveIdAndBodyFromJson(this.props.configurations);


        const toBeActive = confBody.references.find(ref => ref.id === refId);

        if (toBeActive) 
            confBody.references = [...this.setActiveReference(confBody.references, toBeActive)];
        
        this.setState({ loading: true });

        api
            .patch(`test-cases/test-configurations/${confId}`, { Content: JSON.stringify(confBody) })
            .then(() => this.handleRecalculateActiveConfTestResults(toBeActive))
            .then(() => this.props.fetchConfigurations())
            .then(() =>  message.success("Aktywowano referencję"))
            .catch(() => message.warning("Nie udało się aktywować referencji"))
            .finally(() => this.setState({ loading: false }));
    }

    setActiveReference = (references: HighContrastReference[], newReference: HighContrastReference) => {
        const activeReference = references.find(reference => reference.active);

        if (activeReference)
            activeReference.active = false;

        if (!newReference.active) 
            newReference.active = true;
        
        if (!references.find(reference => reference.id === newReference.id))
            references.push(newReference);

        return references
    }

    handleRecalculateActiveConfTestResults = async (activatedReference?: HighContrastReference) => {
        if (!activatedReference)
            return

        const { previousTests, selectedRows } = this.state;

        const testResultsToChangeIds = selectedRows.map(el => el.id);
        
        const testsToChange = previousTests.filter(test => testResultsToChangeIds.indexOf(test.result.id) >= 0);

        for (let test of testsToChange) {
            await this.changeTestDataAndSaveToDatabase(test, activatedReference);
        }

    }

    changeTestDataAndSaveToDatabase = async (test: any, newReference: HighContrastReference) => {
        const resultTestData = JSON.parse(test.result.testData);

        const configuration: HighContrastConfiguration = resultTestData.configuration;
        
        const mAdeviation = configuration.deviationSettings.mAs;
        const kVdeviation = configuration.deviationSettings.kV;
        const MTFdeviation = configuration.deviationSettings.MTF50;

        let passed = true;


        if (Math.abs((newReference.kV / resultTestData.kV - 1) * 100) > kVdeviation){
            passed = false;
        }

        if (Math.abs((newReference.mA / resultTestData.mA - 1) * 100) > mAdeviation){
            passed = false;
        }

        if (Math.abs((newReference.MTF50 / resultTestData.MTF50 - 1) * 100) > MTFdeviation){
            passed = false;
        }

        configuration.references = [...this.setActiveReference(configuration.references, newReference)];

        const testData = {...resultTestData, configuraton: configuration }

        const requestBody = {
            passed: passed,
            deactivated: true,
            testData: JSON.stringify(testData)  
        }

        await api
                .patch(`test-results/${test.result.id}`, {...requestBody})
                .catch(err => console.error(err));
        
    } 

    historyResultsColumns = [
        {
            title: 'Data',
            dataIndex: 'date',
            key: 'id',
            render: (_: any) => moment(_).format('L HH:mm')
        },
        {
            title: 'Promień',
            dataIndex: 'radius',
            key: 'radius'
        },
        {
            title: 'kV',
            dataIndex: 'kV',
            key: 'kv'
        },
        {
            title: 'mA',
            dataIndex: 'mA',
            key: 'ma'
        },
        {
            title: 'MTF50',
            dataIndex: 'MTF50',
            key: 'mtf'
        }
    ]

    paramsConfigColumns = [
        {
            title: 'średnie kV',
            dataIndex: 'kV',
            key: 'kV'
        },
        {
            title: 'średnie mA',
            dataIndex: 'mA',
            key: 'mA'
        },
        {
            title: 'średnie MTF50',
            dataIndex: 'MTF50',
            key: 'mtf'
        }
    ]
}

const CustomStatistic = styled(Statistic)`
    .ant-statistic-content {
        font-size: 18px;
    }
`

const Header = styled.div`
    font-size: 20px;
`

const StyledCollapse = styled(Collapse)`
    background-color: rgb(31, 56, 83);
    color: #ffffff;
    text-align: center;
    .ant-collapse-item {
        .ant-collapse-header {
            color: #ffffff;
        }
    }
`;

const StyledPanel = styled(Collapse.Panel)`
    .anticon.anticon-right.ant-collapse-arrow {
        color: #ffffff;
    }
`;

const ActiveLabel = styled.label`
    font-size: 20px;
`;

const SideBar = styled.div`
    overflow: auto;
    height: 80vh;
`