import { Circle, Point } from './Models'; 

export type pixel = {
    index: number,
    value: number
}

export const getPixelsInsideCircle = (circle: Circle, width: number, pixelArray: Array<pixel | any>): Array<pixel> => {
    const radiusSqaured = circle.r * circle.r;
    const initValue =pixelArray.length > 0 ? pixelArray[0].index : 0;
    let x = initValue % width;
    let y = Math.floor(initValue / width);
    
    const pixelsInRange: Array<pixel> = [];

    for (let i=0; i<pixelArray.length; i++) {
        for (let j=0; j<pixelArray[i].length; j++) {
            const dx = i - circle.center.x;
            const dy = j - circle.center.y;
            const distanceSquared = (dx * dx) + (dy * dy);

            if (distanceSquared <= radiusSqaured) {
                pixelsInRange.push(pixelArray[i][j]);
            }    
        }
    }
    
    return pixelsInRange;
}


export const getPixelsInsideCircleOnSurface = (circle: Circle, width: number, surface: Array<number>): Array<number> => {
    const radiusSqaured = circle.r * circle.r;
    let y = 0;
    let x = 0;
    const pixelsInRange: any[] = [];

    surface.forEach((pixel: number) => {
        x++
        if (x % width === 0) {
            y++
            x = 0;
        }
        const dx = x - circle.center.x;
        const dy = y - circle.center.y;
        const distanceSquared = (dx * dx) + (dy * dy);

        if (distanceSquared <= radiusSqaured) {
            pixelsInRange.push(pixel);
        }
    })
    return pixelsInRange;
}

export const getPixelsOutsideInnerCircle = (outerCircle: Circle, innerCircle:Circle, width: number, pixelArray: Array<any>): Array<pixel> => {
    const outerCircleRadiusSquared = outerCircle.r * outerCircle.r;
    const innerCircleRadiusSquared = innerCircle.r * innerCircle.r;
    let x = 0;
    let y = 0;
    const pixelsInRange: Array<any> = [];

    pixelArray.forEach((pixel, index) => {
        x++;
        if (x % width === 0) {
            y++
            x = 0;
        }
        const dx = x - outerCircle.center.x;
        const dy = y - outerCircle.center.y;
        const distanceSquared = (dx * dx) + (dy * dy);
        if (distanceSquared <= outerCircleRadiusSquared && distanceSquared > innerCircleRadiusSquared) {
            pixelsInRange.push(pixel)
        }
    });
    return pixelsInRange
}

export const getAveragePixelValue = (pixelArray: Array<pixel> ): number => {
    const sum = pixelArray.reduce((prev, next) => prev += next.value, 0);
    return sum / pixelArray.length;
}

export const findValuesOnYAxis = (circle: Circle, width: number, pixelArray: Array<pixel>, maxValue: number): Array<pixel> => {
    const result: Array<pixel> = [];
    let index = 0
    for (let pos = 0; pos < pixelArray.length; pos++) {
        if(pixelArray[pos].value === maxValue) {
            index = pos;
            break;
        }
    }
    return pixelArray.slice(index - circle.r + 1, index + circle.r);
}

export const calculateDistance = (pointA: Point, pointB: Point): number => {
    const dx = pointB.x = pointA.x;
    const dy = pointB.y - pointB.x;
    const dxSquared = dx*dx;
    const dySquared = dy*dy;

    return Math.sqrt( dxSquared + dySquared);
}

export const getMaxValue = (pixelArray: Array<pixel>) => {
    const copy = [...pixelArray];
    return copy.sort((a, b) => a.value - b.value)[pixelArray.length - 1];
}

export const transformToGrayScale = (pixelData: Uint8ClampedArray): Array<number> => {
    const imgData = []
    let j = 0
    for (let i = 1; i < pixelData.length; i++) {
        j++
        if (i === 1 || j === 4){
            imgData.push(pixelData[i]);
            j = 0;
        }
    }
    return imgData
}