import React, { Component } from 'react';
import { Row, Col } from 'antd';
import styled from 'styled-components';
import { NewTestConfigurationCard } from '../components/common';
import { IRouter } from '../utils/Interfaces';

type State = {
    refValues: Array<number>,
    newConfigName: string
}

class NewConfigView extends Component<IRouter, State> {
    state = {
        refValues: [0, 0, 0, 0, 0],
        newConfigName: ''
    }
    
    render() {
        const { refValues, newConfigName } = this.state;

        return (
            <Row justify="center">
                <Col xs={24} sm={24} md={8} span={8}>
                    <NewTestConfigurationCard 
                        testName='Powtarzalność'
                        refValues={refValues}
                        configName={newConfigName}
                        onNameChange={this.handleChangeName}
                        onValueChange={this.handleChangeRefValue}
                        children={<></>}
                    />
                </Col>
            </Row>
        );
    }

    handleChangeName = (newName: string) => {
        this.setState({
            newConfigName: newName
        })
    }

    handleChangeRefValue = (newValue: number, index?: number) => {
        if (index !== undefined) {
            this.setState(prevState => {
                prevState.refValues[index] = newValue;
                return prevState;
            })
        }
    }

}

export default NewConfigView;