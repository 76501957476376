import React from 'react';
import { DatePicker } from 'antd';
import styled from 'styled-components';

export type Props =  {
    value: any,
    onChange: (date: any) => void
}

const CustomDatePicker = (props : Props) => {
    return (
        <StyledDatePicker
            value={props.value}
            onChange={props.onChange}
        />
    )
}

const StyledDatePicker = styled(DatePicker)`
    width: 100%;
`

export default CustomDatePicker;