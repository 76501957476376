import React, {useState} from 'react'
import styled, { StyledComponent } from "styled-components"
import { Button } from "antd";
import { WLSetting } from "../../../utils/Models"

type Props = {
    onClick: () => void,
    disabled?: boolean
} 

const ArtefactValidButton = ({onClick, disabled}: Props) => {
    return (
        <CButton isActive={!disabled} type="link" disabled={disabled} onClick={onClick}>
            [Prawidłowy]
        </CButton>
    )
}

const CButton = styled(Button)<{isActive: boolean}>`
    &:hover, &:active, &:focus {
        color: ${props => (props.isActive ? '#388e3c' : '#6abf69')} ;
    }
    color: ${props => (props.isActive ? '#388e3c' : '#6abf69')} ;
`






export default ArtefactValidButton
