import React from 'react';
import { Button } from 'antd';

type Props = {
    label?: string,
    displayLoadingSpinner?: boolean,
    style?: any,
    onClick?: () => void,
    disabled?: boolean,
}

type IconButtonProps = {
    icon?: React.ReactNode,
    label?: string,
    color?: string,
    style?: any,
    width?: string,
    displayLoadingSpinner?: boolean,
    noPadding?: boolean,
    onClick?: () => void,
}

export const PrimaryButton = (props: Props) => {
    return (
        <Button 
            htmlType="submit"
            type="primary" 
            style={{ background: '#099F92', color: '#FFFFFF', paddingLeft: 20, paddingRight: 20, ...props.style }}
            loading={props.displayLoadingSpinner}
            onClick={props.onClick}
            disabled={props.disabled || false}
        >
            {props.label}
        </Button>
    )
}

export const ActionButton = (props: Props) => {
    return (
        <Button 
            type="primary" 
            style={{ background: '#1B3148', color: '#FFFFFF', paddingLeft: 20, paddingRight: 20, width: '100%', ...props.style }}
            loading={props.displayLoadingSpinner}
            onClick={props.onClick}
            disabled={props.disabled}
        >
            {props.label}
        </Button>
    )
}

export const CenteredButton = (props: Props) => {
    return (
        <Button 
            type="primary" 
            style={{ 
                ...props.style,
                background: '#1f3853', 
                color: '#FFFFFF', 
                paddingLeft: 20,
                paddingRight: 20, 
                display: "flex", 
                justifyContent:"center", 
                width:"100%", 
                border: "none",
                marginBottom:"20px"
                
            }}
            loading={props.displayLoadingSpinner}
            onClick={props.onClick}
            disabled={props.disabled || false}
        >
            {props.label}
        </Button>
    )
}

export const IconButton = (props: IconButtonProps) => {
    return (
        <Button 
            type="primary" 
            icon={props.icon || null}
            style={{ 
                background: props.color || '#0082D4', 
                width: props.width, 
                color: '#FFFFFF', 
                paddingLeft: props.noPadding ? 0 : 20, 
                paddingRight: props.noPadding ? 0 : 20, 
                ...props.style
            }}
            loading={props.displayLoadingSpinner}
            onClick={props.onClick}
        >
            {props.label}
        </Button>
    )
}