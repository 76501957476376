import React, { Component } from "react";
import { Upload, Modal } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import styled, { ThemeProvider } from "styled-components";

function getBase64(file: any) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

type State = {
    previewVisible: boolean;
    previewImage: string;
    previewTitle: string;
    fileList: any;
};

export default class CustomUpload extends Component<{}, State> {
    constructor(props: any) {
        super(props);

        this.state = {
            previewVisible: false,
            previewImage: "",
            previewTitle: "",
            fileList: [],
        };
    }

    render() {
        const { previewVisible, previewImage, fileList, previewTitle } = this.state;

        const uploadButton = (
            <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>Dodaj zdjęcie</div>
            </div>
        );

        return (
            <>
                <ThemeProvider theme={{ width: `${(fileList.length + 1) * 150}px` }}>
                    <UploadContainer>
                        <Upload
                            action='https://www.mocky.io/v2/5cc8019d300000980a055e76'
                            listType='picture-card'
                            fileList={fileList}
                            onPreview={this.handlePreview}
                            onChange={this.handleChange}>
                            {uploadButton}
                        </Upload>
                    </UploadContainer>
                </ThemeProvider>
                <Modal
                    visible={previewVisible}
                    title={previewTitle}
                    footer={null}
                    onCancel={this.handleCancel}
                >
                    <img
                        alt='example'
                        style={{ width: "100%" }}
                        src={previewImage}
                    />
                </Modal>
            </>
        );
    }

    handleCancel = () => this.setState({ previewVisible: false });

    handlePreview = async (file: any) => {

        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }

        this.setState({
            previewImage: file.url || file.preview,
            previewVisible: true,
            previewTitle:
                file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
        });
    };

    handleChange = (e: any) => this.setState({ fileList: e.fileList });
}


const UploadContainer = styled.div`
    width:100%;
    overflow: auto;
    
    .ant-upload-picture-card-wrapper{
        width: ${(props: any) => props.theme.width};
        overflow:auto;
    }
`;


