import React from 'react';
import { Menu, Dropdown, Row, Col } from 'antd';
import { SettingOutlined, LogoutOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

type Props = {
    onLogout: () => void,
}

const NavigationDropdown = (props: Props) => {
    const menu = (
        <Menu>
            <Menu.Item>
                <Link to="/dicom/upload">
                    Dodaj pliki DICOM
                </Link>
            </Menu.Item>
            <Menu.Item>
                <Link to="/choose-customer">
                    Zmień placówkę
                </Link>
            </Menu.Item>
            <Menu.Item style={{ borderBottom: "1px solid lightgrey"}}>
              <a href={"/api/gateway/installer/" + localStorage.getItem('customerId')} >
                    Pobierz instalator bramki DICOM
              </a>
            </Menu.Item>
            <Menu.Item onClick={props.onLogout}>
                <Row>
                    <Col>
                        <LogoutOutlined style={{ fontWeight: 900}} />
                    </Col>
                    <Col offset={2}> 
                        Wyloguj się
                    </Col>
                </Row>
            </Menu.Item>
        </Menu>
      );

      return (
          <Dropdown overlay={ menu } trigger={['click']} placement='bottomRight'>
               <StyledSettingOutlined />
          </Dropdown>
      )
}

const StyledSettingOutlined = styled(SettingOutlined)`
    font-size: 18px !important;
    cursor: pointer;
    color: grey;
`

export default NavigationDropdown;
