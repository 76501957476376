import React from 'react';
import { Modal } from 'antd';
import styled from 'styled-components';
import ReactPlayer from 'react-player';

export type Props = {
    visible: boolean,
    title?: string,
    closeModal: () => void,
    steps?: Array<string>
}

const ProcedureDescriptionModal = (props: Props) => {
    return (
        <CustomModal
            visible={props.visible}
            title={props.title || "Tytuł"}
            footer={null}
            onCancel={props.closeModal}
        >
            <Content>
                <ul>
                    {props.steps && props.steps.map(step =>
                        <li key={step}>{step}</li>
                    ) || steps.map(step =>
                        <li key={step}>{step}</li>
                    )}
                </ul>
                <PlayerWrapper>
                    <StyledReactPlayer
                        url='https://www.youtube.com/watch?v=_QdPW8JrYzQ'
                        width='100%'
                        height='100%'
                    />
                </PlayerWrapper>
            </Content>
        </CustomModal>
    )
}

const steps = [
    "Umieść fantom do testów pola świetlnego na statywie",
    "Ustaw SID na 100 cm",
    "Ustaw promień centralny na środek fantomu. Upewnij się, że linie centrujące pola świetlnego tzw. „krzyż” są prostopadłe do wszystkich krawędzi fantomu",
    "Ustaw kolimację przysłon promieniowania do (oznaczonego na fantomie) pola o rozmiarze 26x26 cm",
    "Ustaw aparat rtg w trybie „statyw z kratką”.",
    "Włóż do szyn kolimatora aluminiowy filtr jednorodny 25 mm",
    "Ustaw wysokie napięcie na 75kV i natężenie prądu na 200mA.",
    "Wykonaj ekspozycję",
    "Wyślij otrzymany obraz do systemu Qadra",
    "Wykonaj pomiary na obrazie w systemie Qadra",
];

const Content = styled.div`
    background: #E5E9EC;
    color: #6F7B8A;
    padding: 20px;
    font-size: 13px;
`

const CustomModal = styled(Modal)`
    .ant-modal-body {
        padding: 0;
    }
`

const PlayerWrapper = styled.div`
    position: relative;
    padding-top: 56.25%;
`

const StyledReactPlayer = styled(ReactPlayer)`
    position: absolute;
    top: 0;
    left: 0;
`
export default ProcedureDescriptionModal;