import React, { useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { Card, Row, message } from 'antd';
import { FilePdfFilled } from '@ant-design/icons';

import { IconButton } from './Buttons';
import dateFormats from '../constants/dateFormats';

import { api } from '../../api';

export type Props = {
    resultId?: string,
    title?: string,
    performer?: string | null,
    date?: string | null,
    expirationDate?: string | null,
    result?: boolean | null,
    leftBorderColor?: string
}

const CustomTestCard = (props: Props) => {
    const [downloading, setDownloading] = useState(false);
    
    const download = (blob: any, filename: string) => {       
        var a = document.createElement('a');
        a.href = URL.createObjectURL(blob);
        a.download = filename;
        a.click();
    }

    const downloadReport = () => {
        setDownloading(true);
        
        api
            .get(`/test-results/${props.resultId}/report`)
            .then(res => res.data)
            .then(html => {
                var mywindow = window.open('', 'PRINT');

                if (!mywindow)
                    return ;

                mywindow.document.write(html);

                mywindow.document.close(); // necessary for IE >= 10
                mywindow.focus(); // necessary for IE >= 10
            
                mywindow.print();
                mywindow.close();
            
                return true;
            })
            // .then(content => download(content, `${props.title}-${props.date}.pdf`))
            .catch(() => message.error('Wystąpił błąd podczas pobierania raportu. Skontaktuj się z administracją.'))
            .finally(() => setDownloading(false));
    }
    
    return (
        <StyledCard
            title={props.title}
            leftBorderColor={props.leftBorderColor || '#8DF279'}
        >
            <Row>Wykonał(a): {props.performer || '-'}</Row>
            <Row>Data wykonania: {props.date ? moment(props.date).format(dateFormats.DATE_TIME_SHORT) : '-'}</Row>
            <Row>Wygasa: {props.expirationDate ? moment(props.expirationDate).format(dateFormats.DATE_TIME_SHORT) : '-'}</Row>
            {props.result !== null && 
                <Row>Wynik testu: {props.result ? 'prawidłowy' : 'nieprawidłowy'}</Row>
            }
            {props.resultId &&
                <RowWithMargin>
                    <IconButton 
                        onClick={downloadReport}
                        icon={<FilePdfFilled />}
                        label='Pobierz raport'
                        displayLoadingSpinner={downloading}
                    />
                </RowWithMargin>
            }
        </StyledCard>
    )
}


const StyledCard = styled(Card)<{leftBorderColor: string}>`
width: 100%;

.ant-card-head {
    border-left: 3px solid ${props => props.leftBorderColor};
}
.ant-card-body {
    border-left: 3px solid ${props => props.leftBorderColor};
}
.ant-card-head-title {
    font-size: 20px;
}
`

const RowWithMargin = styled(Row)`
margin-top: 8px;
`
export default CustomTestCard;