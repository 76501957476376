import React from 'react';
import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useStore } from '../../store';

const NavigationBreadcrumb = () => {
    const { state, dispatch, removeLast }:any = useStore();
    
    const handleOnClick = (location: any) => removeLast(location)
    
    return (
        <StyledBreadcrumb>
            {state.locations.map((l: any) =>(
                <Breadcrumb.Item key={l.url} onClick={()=>handleOnClick(l)}>
                    <Link to={l.url}>{l.name}</Link>
                </Breadcrumb.Item>)
            )}
        </StyledBreadcrumb>
    )
}

const StyledBreadcrumb = styled(Breadcrumb)`
    .ant-breadcrumb-link > a {
        color: #0d638f !important;
    }

    .ant-breadcrumb-separator {
        color: #FFFFFF;
    }
`

export default NavigationBreadcrumb;