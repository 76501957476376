import React from 'react';
import { Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

export const NEW_CONFIG_INFO = 'Utworzenie nowej konfiguracji spowoduje, że nie będzie można korzystać z konfiguracji utworzonych wcześniej. Jeśli konieczny bedzie powrót do wcześniej utworzonej konfiguracji, konieczne będzie stworzenie jej od zera. Czy chcesz kontynuować?';

export const showConfirmModal = (callback: (param?: any) => void, title: string) => {
    return (
        Modal.confirm({
            title: title,
            icon: React.createElement(ExclamationCircleOutlined),
            okText: "Tak",
            onOk: callback,
            cancelText: "Nie",
        })
    );
}

export const showErrorModal = (title: string) => {
    return (
        Modal.error({
            title: title,
            okText: "Ok",
        })
    );
}