import React from "react";
import { Col, Row, Divider, Button } from "antd";
import { CustomInput, ActionButton, IconButton } from "../../common";
import styled from "styled-components";
import { WLSetting, Window } from "../../../utils/Models"
import { PlusCircleOutlined } from "@ant-design/icons";

type Props = {
    configName: string;
    onFinish: () => void;
    onNameChange: (e: string) => void;
    children?: JSX.Element | JSX.Element[];
    WLSettings: Window;
    changeWLSetting: (param: 'width' | 'level', index: number, newValue: number) => void
    changeWLName: (index: number, newValue: string) => void
    addNewWLSetting: () => void;
    deleteWLSetting: (index: number) => void;
};

const WindowConfiguration = ({ configName, WLSettings, onFinish, changeWLSetting, changeWLName, onNameChange, addNewWLSetting, deleteWLSetting }: Props) => {
    return (
        <ConfigCard>
            <InputsArea>
                <Row gutter={[16, 8]}>
                    <Col span={24}>
                        <Header>Nowa konfiguracja</Header>
                    </Col>
                    <Col span={24}>
                        <CustomInput label={<GreyLabel>Nazwa konfiguracji</GreyLabel>} value={configName} onChange={onNameChange} />
                    </Col>
                    <StyledRow>
                        {WLSettings.WL.map((wl, index) => {
                            const nameLabel = (
                                <Row justify='space-between' style={{width: '100%'}} align='middle'>
                                    <Col>Nazwa</Col>
                                    <Col>
                                        <Button type='link' style={{padding: 0}} onClick={() => deleteWLSetting(index)}>
                                            Usuń okno
                                        </Button>
                                    </Col>
                                </Row>
                            );
                            
                            return (
                                <>
                                    <Divider />
                                    <Col span={24}>
                                        <CustomInput
                                            label={nameLabel}
                                            value={wl.name}
                                            onChange={e => changeWLName(index, e)}
                                        />
                                    </Col>
                                    <Col span={24}>
                                        <CustomInput
                                            label='Szerokość okna'
                                            type='number'
                                            value={wl.width}
                                            onChange={e => changeWLSetting("width", index, e)}
                                            step={1}
                                        />
                                    </Col>
                                    <Col span={24}>
                                        <CustomInput
                                            label='Wysokość okna'
                                            type='number'
                                            value={wl.level}
                                            onChange={e => changeWLSetting("level", index, e)}
                                            step={1}
                                        />
                                    </Col>
                                </>
                            );
                        })} 
                        <Col span={12}>
                            <IconButton
                                onClick={addNewWLSetting}
                                icon={<PlusCircleOutlined />}
                                label='Dodaj nowe okno'
                            />
                        </Col>
                    </StyledRow>
                </Row>
            </InputsArea>
        </ConfigCard>
    );
};

export default WindowConfiguration;

const ConfigCard = styled.div`
    width: 100%;
`;
const StyledRow = styled(Row)`
    padding: 20px;
`;

const Header = styled.div`
    font-size: 20px;
`;

const InputsArea = styled.div`
    background: #ffffff;
    padding: 20px;
`;

const GreyLabel = styled.span`
    color: #817b94;
`;
