import React, { Component } from 'react';
import { Row, Col, message, Collapse, Button, Tooltip, Spin, Statistic } from 'antd';
import { AimOutlined, DeleteOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import moment from 'moment';
import { NewTestConfigurationCard, CustomInput, MainCard } from '../../common';
import DwvComponent from '../../common/Dwv/DwvComponent';
import { Circle, Point } from '../../../utils/Models';
import { getCircleThrough3Point } from "../../../utils/mathHelpers";
import { api } from '../../../api';
import { IRouter } from '../../../utils/Interfaces';
import { showConfirmModal, NEW_CONFIG_INFO } from '../../../utils/modalConfirm';

const { Panel } = Collapse;

type Props = {
    selectedConfigId: string | null,
    configurations: Array<any>,
    handleChangeConfigId: (id: string) => void,
    fetchConfigurations: () => void,
    router: IRouter,
    fetchThumbnails: () => void,
    thumbnails: React.ReactNode,
    dicom: any,
    loadingDicom: boolean,
    // activateConfiguration: (configId: string) => void,
    onLoadingDicomFinish: () => void;
    mainCardReloadIcon: React.ReactNode,
}

type State = {
    mode: 'new' | 'view',
    settings: {
        name: string,
        diameter: number,
        ROINumber: number,
        distance: number,
        kV: number,
        kVDeviation: number,
        mA: number,
        mADeviation: number,
    },
    dwvState: any,
    circle: Circle,
    points: Point[],
    shouldDraw: boolean,
    selectingPoints: boolean,
    pixelSpacing: { xSpacing: number, ySpacing: number },
    circles: Array<Circle>,
    loading: boolean,
    openedKeys: any,
    resetDrawings: boolean,
}

class UniformitySettings extends Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            mode: 'view',
            settings: {
                name: moment().format("YYYY-MM-DD HH:mm"),
                diameter: 0,
                ROINumber: 4,
                distance: 5,
                kV: 0,
                kVDeviation: 0,
                mA: 0,
                mADeviation: 0
            },
            dwvState: null,
            points: [],
            circle: { center: { x: 0, y: 0 }, r: 0 },
            shouldDraw: false,
            selectingPoints: false,
            pixelSpacing: { xSpacing: 0, ySpacing: 0 },
            circles: [],
            loading: false,
            openedKeys: [],
            resetDrawings: false
        }
    }
    
    componentDidUpdate(prevProps: Props, _: State) {
        if (prevProps.loadingDicom && !this.props.loadingDicom)
            this.resetSettings();
    }
    
    render() {
        const { selectedConfigId, configurations, handleChangeConfigId } = this.props;
        const { settings, circle, points, pixelSpacing, loading, openedKeys, resetDrawings } = this.state;

        return (
            <Row gutter={[24, 24]}>
                <Col span={8}>
                    <StyledCollapse>
                        <StyledPanel header="Konfiguracja" key="MAINPANEL">
                            <Spin spinning={loading}>
                                <StyledCollapse activeKey={openedKeys} onChange={this.onCollapse} accordion={true}>                            
                                    <StyledPanel header="Nowa Konfiguracja" key="CONFIGURATION">
                                        <NewTestConfigurationCard
                                            testName='Jednorodność obrazu'
                                            configName={settings.name}
                                            onNameChange={(e) => this.setState({ settings: { ...settings, name: e } })}
                                            children={this.generateInputs()}
                                            onFinish={this.addConfiguration} />
                                    </StyledPanel>
                                    {configurations.map(config => {
                                        const configurationObj = JSON.parse(config.configuration);
                                        
                                        return (
                                            <StyledPanel
                                                header={configurationObj.name + (config.active ? ' (aktywna)' : '')}
                                                key={config.id}
                                            >   
                                            <Row justify={"space-around"}>
                                                <Col>
                                                    <Statistic title="Średnica fantomu w cm" value={configurationObj.diameter} />
                                                </Col>
                                                <Col>
                                                    <Statistic title="Odległość od środka w cm" value={configurationObj.distance} />
                                                </Col>
                                            </Row>
                                            <Row justify={"space-around"}>
                                                <Col>
                                                    <Statistic title="Odniesienie KV" value={configurationObj.kV} />
                                                </Col>
                                                <Col>
                                                    <Statistic title="Odchylenie KV" value={`${configurationObj.kVDeviation}%`} />
                                                </Col>
                                            </Row>
                                            <Row justify={"space-around"}>
                                                <Col>
                                                    <Statistic title="Odniesienie MA" value={configurationObj.mA} />
                                                </Col>
                                                <Col>
                                                    <Statistic title="Odchylenie MA" value={`${configurationObj.mADeviation}%`} />
                                                </Col>
                                            </Row>
                                            <Row justify="center">
                                                <Col>
                                                    <Statistic title="Ilość ROI" value={configurationObj.ROINumber}/>
                                                </Col>
                                            </Row>
                                            </StyledPanel>
                                        )
                                    })}
                                </StyledCollapse>
                            </Spin>
                        </StyledPanel>
                    </StyledCollapse>
                </Col>
                <Col span={11}>
                <MainCard title="Obrazy w teście">
                    <DwvComponent
                            {...this.props}
                            uniformity={true}
                            points={points}
                            dicom={this.props.dicom}
                            onStateChange={this.onStateChange}
                            onCanvasClick={this.handleAddPoint}
                            reset={resetDrawings}
                            circle={circle}
                            processPixelSpacing={this.unpackAndStorePixelSpacing}
                            settings={settings}
                            pixelSpacing={pixelSpacing}
                            onKVChange={(newKV: number) => this.handleUpdateSettings('kV', newKV)}
                            onMAChange={(newMA: number) => this.handleUpdateSettings('mA', newMA)} 
                    />
                </MainCard>
                </Col>
                <Col span={5} >
                    <MainCard title="Ostatnio dodane" icon={this.props.mainCardReloadIcon}>
                        <SideBar>
                            {this.props.thumbnails}
                        </SideBar>
                    </MainCard>
                </Col>
            </Row>
        );
    }

    resetSettings = () => {
        this.setState(prevState => {
            return {
                settings: { ...prevState.settings,
                    diameter: 0,
                    ROINumber: 4,
                    distance: 5,
                    kV: 0,
                    kVDeviation: 0,
                    mA: 0,
                    mADeviation: 0,
                },
                points: [],
                circle: { center: { x: 0, y: 0 }, r: 0 },
                shouldDraw: true
            }
        })
    }

    onCollapse = (keys: any) => {
        if (!keys)
            return this.setState({ openedKeys: []});
        
        if (keys.indexOf("CONFIGURATION") >= 0 && this.props.configurations.length > 0) {
            return showConfirmModal(
                () => this.expandCollapse(keys),
                NEW_CONFIG_INFO
            );
        }

        this.expandCollapse(keys);
    }
    
    expandCollapse = (keys: any) => {
        this.props.handleChangeConfigId(keys);
        return this.setState({ openedKeys: keys });
    }

    onStateChange = (newState: any) => { this.setState({ dwvState: newState }) }

    isDicomLoaded = () => { return this.state.dwvState !== null; }

    unpackAndStorePixelSpacing = (spacing: string) => {
        const backslashIndex = spacing.indexOf('\\'); // single backslash
        
        if (!backslashIndex) { return ; }

        const xSpacing = spacing.slice(0, backslashIndex);
        const ySpacing = spacing.slice(backslashIndex + 1, spacing.length);
        
        this.setState({
            pixelSpacing: { xSpacing: parseFloat(xSpacing), ySpacing: parseFloat(ySpacing) }
        })
    }

    handleUpdateSettings = (param: 'kV' | 'mA' | 'diameter' | 'ROINumber' | 'distance' | 'kVDeviation' | 'mADeviation', newValue: number) => {
        this.setState(prevState => { 
            return { 
                settings: {
                    ...prevState.settings, 
                    [param]: newValue
                }
            }
        });
    }

    addConfiguration = () => {
        const { settings } = this.state;
        const { testId } = this.props.router.match.params;
        
        if (!settings.name || settings.diameter === 0) {
            message.error('Musisz wybrać nazwę konfiguracji oraz zaznaczyć koło ROI.');
            return ;
        }

        const body = {
            Content: JSON.stringify(settings)
        }
        
        this.setState({ loading: true });

        api
            .post(`test-configurations/test-case/${testId}`, body)
            .then(newConfig => {
                this.setState({
                    settings: {
                        name: '',
                        diameter: 0,
                        ROINumber: 4,
                        distance: 5,
                        kV: 0,
                        kVDeviation: 0,
                        mA: 0,
                        mADeviation: 0,
                    },
                    mode: 'view',
                    openedKeys: [newConfig.data.id]  
                })
                message.success('Dodano nową konfigurację.');
                this.props.fetchConfigurations();
            })
            .catch(err => console.error(err))
            .finally(() => this.setState({ loading: false }));
    }

    handleDeleteDrawings = () => {
        this.setState(prevState => ({ resetDrawings: !prevState.resetDrawings, points: []}));
        this.resetSettings();
    }

    handleAddPoint = (x: number, y: number) => {        
        if (this.state.points.length >= 3) {
            message.error('Już zaznaczyłeś(aś) wszystkie potrzebne punkty.');
            return ;
        }
        
        if (!this.state.selectingPoints) {
            message.error('Najpierw wybierz tryb zaznaczania punktów.');
            return ;
        }

        const point: Point = { x: x, y: y };
        this.setState((prevState: State) => {
            return { points: [...prevState.points, point] }
        }, () => {
            const { points } = this.state;

            if (points.length === 3) {
                const circle = getCircleThrough3Point(points[0], points[1], points[2]);
                const diameter = circle.r * 2;
                const realDiameter = this.calculateRealDiameterFromImageDiameter(diameter);

                this.setState({
                    circle: circle,
                    settings: {
                        ...this.state.settings,
                        diameter: parseFloat(realDiameter.toString())
                    },
                    shouldDraw: true
                });
            }
        })
    }

    calculateRealDiameterFromImageDiameter = (imageDiameter: number) => {
        const { pixelSpacing } = this.state;
        if (!pixelSpacing.xSpacing) {
            return 0;
        }
        const distanceMM = imageDiameter * pixelSpacing.xSpacing;
        const distanceCM = distanceMM / 10;
        return distanceCM.toFixed(2); 
    } 

    generateInputs = () => {
        const { settings, points } = this.state;
        
        return (
            <>
                <Col span={20}>
                    <CustomInput
                        label='Średnica fantomu (cm)'
                        type='number'
                        value={settings.diameter}
                        onChange={e => this.handleUpdateSettings('diameter', e)}
                        min={0} />
                </Col>
                <Col span={2}>
                    {this.isDicomLoaded() &&
                        <Tooltip title="Zaznacz trzy punkty na obwodzie fantomu">
                            <Button 
                                onClick={() => this.setState({ selectingPoints: true })}
                                icon={<AimOutlined />} 
                                style={{ marginTop: 22, backgroundColor: '#0082D4', color: '#FFFFFF' }}
                            />      
                        </Tooltip>
                    }
                </Col>
                <Col span={2}>
                    {points.length >= 3 &&
                        <Tooltip title="Narysuj od nowa">
                            <Button 
                                onClick={this.handleDeleteDrawings}
                                icon={<DeleteOutlined />} 
                                style={{ marginTop: 22, backgroundColor: '#FFF', color: '#f5222d' }}
                            />      
                        </Tooltip>
                    }
                </Col>
                <Col span={24}>
                    <CustomInput
                        label='Ilość ROI'
                        type='number'
                        value={settings.ROINumber}
                        step={1}
                        min={0}
                        onChange={e => this.handleUpdateSettings('ROINumber', e)}/>
                </Col>
                <Col span={24}>
                    <CustomInput
                        label='Odległość punktów pomiarowych od środka (cm)'
                        type='number'
                        value={settings.distance}
                        min={0}
                        onChange={e => this.handleUpdateSettings('distance', e)}/>
                </Col>
                <Col span={24}>
                    <CustomInput
                        label='Odniesienie KV'
                        type='number'
                        value={settings.kV}
                        min={0}
                        step={1}
                        onChange={e => this.handleUpdateSettings('kV', e)}/>
                </Col>
                <Col span={24}>
                    <CustomInput
                        label='Odchylenie KV (%)'
                        type='number'
                        value={settings.kVDeviation}
                        min={0}
                        onChange={e => this.handleUpdateSettings('kVDeviation', e)}/>
                </Col>
                <Col span={24}>
                    <CustomInput
                        label='Odniesienie MA'
                        type='number'
                        value={settings.mA}
                        min={0}
                        step={1}
                        onChange={e => this.handleUpdateSettings('mA', e)}
                    />
                </Col>
                <Col span={24}>
                    <CustomInput
                        label='Odchylenie MA (%)'
                        type='number'
                        value={settings.mADeviation}
                        min={0}
                        onChange={e => this.handleUpdateSettings('mADeviation', e)}
                    />
                </Col>
            </>
        )
    }
}

const SideBar = styled.div`
    overflow: auto;
    height: 80vh;
`;

const StyledCollapse = styled(Collapse)`
    background-color: rgb(31, 56, 83);
    color: #ffffff;
    text-align: center;
    .ant-collapse-item {
        .ant-collapse-header {
            color: #ffffff;
        }
    }
`;

const StyledPanel = styled(Panel)`
    .anticon.anticon-right.ant-collapse-arrow {
        color: #ffffff;
    }
`;

export default UniformitySettings;