import React from "react";
import { Table } from "antd";
import styled from "styled-components";

export type Props = {
    columns: Array<any>;
    data: Array<any>;
    loading?: boolean;
    onRow?: any;
    cursor?: string;
    size?: "middle" | "small",
};

const EMPTY_TEXT = "Brak danych";

const CustomTable = (props: Props) => {
    return (
        <StyledTable
            dataSource={props.data}
            columns={props.columns}
            pagination={false}
            loading={props.loading || false}
            bordered={true}
            onRow={props.onRow}
            cursor={props.cursor || "default"}
            locale={{ emptyText: EMPTY_TEXT }}
            size={props.size || "middle"}
        />
    );
};

const StyledTable = styled(Table)<{ cursor: string }>`
    thead > tr > th {
        background: #ecf0f2;
        text-align: center;
        color: #6f7b8a;
        font-weight: 600;
        font-size: 10px;
        padding: 10px;
    }

    tbody > tr > td {
        text-align: center;
        font-size: 12px;
        padding: 8px;
        cursor: ${props => props.cursor};
        &:first-child {
            text-align: left;
        }
    }
`;
export default CustomTable;
