import { QuestionCircleOutlined } from '@ant-design/icons';
import styled from "styled-components"

const StyledQuestionIcon = styled(QuestionCircleOutlined)`
    margin-left: 10px;
    padding-top: 15px;
    font-size: 20px;
    color: #1f3853;
    &:hover{
        cursor: pointer;
    }
`;

export default StyledQuestionIcon;