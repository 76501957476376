import moment from 'moment';

export const filterResults = (results: Array<any>, selectedTimeOption: string, customDate?: string) => {
    return results.filter(object => {
           if (selectedTimeOption === 'month')
               return moment(object.result.dateOfTest) > moment().subtract('1', 'months');
           
           if (selectedTimeOption === 'year')
               return moment(object.result.dateOfTest) >  moment().subtract('1', 'years');

           if (selectedTimeOption === 'custom')
               return moment(object.result.dateOfTest).format('L') === moment(customDate).format('L');

           return true;
        })
}