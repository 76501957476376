import React, { Component } from 'react';
import { Row, Col, Table } from 'antd';
import styled from 'styled-components';
import moment from 'moment';

import { CustomTestCard, MainCard, CustomTable, IconButton, HistoryFilters, RedCrossIcon, GreenCheckIcon } from '../../common';
import { IRouter } from '../../../utils/Interfaces';
import { HistoryResultRowsDesign } from '../../constants/historyResultRowsDesign'
import { filterResults } from '../../common/FilterResults'
import { HighContrastReference, HighContrastTestResult } from '../../../utils/Models';

type State = {
    filters: {
        selectedTimeOption: string,
        date: any
    }
    selectedResult: any,
}

type Props = {
    router: IRouter,
    results: Array<any>,
    validTime: number,
    calculateNextStudy: (dateOfTest: any, validTime: number) => string,
}

class HighResolutionContrastHistory extends Component<Props, State> {
    constructor(props: any) {
        super(props);
        
        this.state = {
            filters: {
                selectedTimeOption: 'all',
                date: null,
            },
            selectedResult: null,
        }
    }

    render() {
        const { results, validTime, calculateNextStudy } = this.props;
        const { selectedResult } = this.state;

        if (!selectedResult && results.length > 0) {
            this.changeSelectedResult(results[0]);
        }
        return (
            <>
                <Row gutter={[24, 24]}>
                    <Col span={4}>
                        <GreyStyledRow justify="center">
                            <IconButton
                                onClick={this.handleChangeToNewTest}
                                label='Nowy test'
                            />
                        </GreyStyledRow>
                        <WhiteStyledRow justify="center">
                            <Col span={18}>
                                <HistoryFilters 
                                    filters={this.state.filters}
                                    onChange={this.handleFiltersChange}
                                    onFinish={this.handleFiltersSubmit}
                                />
                            </Col>
                            <Col span={18}>
                                <CustomTable
                                    columns={this.historyResultsColumns}
                                    data={filterResults(results, this.state.filters.selectedTimeOption, this.state.filters.date || null)}
                                    cursor='pointer'
                                    onRow={(record: any) => {
                                        return {
                                          onClick: () => this.changeSelectedResult(record)
                                        }
                                    }}
                                />
                            </Col>
                        </WhiteStyledRow>
                    </Col>
                    <Col span={20}>
                        <Row gutter={[24, 24]}>
                            <Col span={24}>
                                <CustomTestCard
                                    resultId={selectedResult && selectedResult.result.id}
                                    title='Rozdzielczość wysokokontrastowa'
                                    performer={selectedResult ? `${selectedResult.user.firstName} ${selectedResult.user.lastName}` : null}
                                    date={selectedResult ? selectedResult.result.dateOfTest : null}
                                    expirationDate={selectedResult ? calculateNextStudy(selectedResult.result.dateOfTest, validTime) : null}
                                    result={selectedResult ? selectedResult.result.passed : null}
                                />
                            </Col>
                            <Col span={24}>
                                <MainCard title='Wyniki pomiaru'>
                                    <CustomTable columns={this.resultColumns} data={this.getInitialSettingsData()} />
                                </MainCard>
                            </Col>
                        </Row>
                    </Col>

                </Row>

            </>
        );
    }
    
    handleChangeToNewTest = () => {
        this.props.router.history.push(this.props.router.location.pathname.replace(/[^/]*$/, 'perform-test'));
    }

    changeSelectedResult = (newResult: any) => {
        this.setState({
            selectedResult: newResult,
        })
    }

    handleFiltersChange = (filterName: string, newValue: any) => {
        this.setState(prevState => {
            prevState.filters[filterName] = newValue;
            return prevState;
        })
    }

    getInitialSettingsData = () => {
        const { selectedResult } = this.state;

        if (!selectedResult)
            return [];

        const testData:HighContrastTestResult = JSON.parse(selectedResult.result.testData)

        if (!testData.configuration)
            return [];
        
        const reference = testData.configuration.references.find(ref => ref.active);        

        if (!reference)
            return this.returnNoReferenceValues(testData)

        const result = this.calculateResult(testData);
        
        if (!result)
            return [];

        const values: any[] = [
            {
                name: "kV",
                value: testData.kV,
                reference: reference.kV,
                deviation: ((reference.kV / testData.kV - 1) * 100).toFixed(2) + '%',
                result: result.kV
            },
            {
                name: "mA",
                value: testData.mA,
                reference: reference.mA,
                deviation: ((reference.mA / testData.mA - 1) * 100).toFixed(2) + '%',
                result: result.mA
            }, 
            {
                name: "MTF50",
                value: testData.MTF50,
                reference: reference.MTF50,
                deviation: ((reference.MTF50 / testData.MTF50 - 1) * 100).toFixed(2) + '%',
                result: result.MTF50
            }, 
            {
                name: "Promień",
                value: testData.configuration.radius
            }
        ];

        return values;
    }

    returnNoReferenceValues = (testData:HighContrastTestResult) => {
        const values: any[] = [
            {
                name: "kV",
                value: testData.kV,
                reference: "-",
                deviation: Math.abs(Math.round((0 / testData.kV - 1) * 100)) + '%',
                result: <GreenCheckIcon/>
            },
            {
                name: "mA",
                value: testData.mA,
                reference: "-",
                deviation: Math.abs(Math.round((0 / testData.mA - 1) * 100)) + '%',
                result: <GreenCheckIcon/>
            },
            {
                name: "MTF50",
                value: testData.MTF50,
                reference: "-",
                deviation: Math.abs(Math.round((0 / testData.MTF50 - 1) * 100)) + '%',
                result: <GreenCheckIcon/>
            }, 
            {
                name: "Promień",
                value: testData.configuration.radius
            }
        ];
        return values;
    }

    handleFiltersSubmit = () => {
        alert('Filtrowanie!');
    }

    calculateResult = (testData: HighContrastTestResult) => {
        const activeRef:HighContrastReference | undefined = testData.configuration.references.find(ref => ref.active);
        if (!activeRef)
            return

        return {
            mA: this.isValidParamResult(
                activeRef.mA, 
                testData.mA, 
                +testData.configuration.deviationSettings.mAs
            ),
            kV: this.isValidParamResult(
                activeRef.kV, 
                testData.kV, 
                +testData.configuration.deviationSettings.kV
            ),
            MTF50: this.isValidParamResult(
                activeRef.MTF50, 
                testData.MTF50, 
                +testData.configuration.deviationSettings.MTF50
            )
        }          
    }

    isValidParamResult = (averageParameter: number, parameterFromTestCase: number, deviationFromConfig: number) => {
        if (averageParameter === 0)
            return <GreenCheckIcon/>

        const currentDeviation = Math.abs(Math.round((averageParameter / parameterFromTestCase - 1) * 100));
        
        if (currentDeviation > deviationFromConfig)
            return <RedCrossIcon/>

        return <GreenCheckIcon/>
    }

    resultColumns = [
        {
            title: 'Nazwa',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Pomiar',
            dataIndex: 'value',
            key: 'value',
        },
        {
            title: "Odniesienie",
            dataIndex: 'reference',
            key: 'reference'
        },
        {
            title: "Odchylenie",
            dataIndex: 'deviation',
            key: 'deviation'

        },
        {
            title: "Poprawny",
            dataIndex: 'result',
            key: 'result'
        }
    ]

    historyResultsColumns = [
        {
            title: 'WYNIKI HISTORYCZNE',
            dataIndex: 'date',
            key: 'date',
            render: (_: any, row: any) => {
                const { selectedResult } = this.state;
                return {
                  props: {
                    style: 
                        {
                            background: selectedResult && selectedResult.result.id === row.result.id ? HistoryResultRowsDesign.SELECTED_RESULT_BACKGROUND_COLOR : null, 
                            color: selectedResult && selectedResult.result.id === row.result.id ? HistoryResultRowsDesign.SELECTED_RESULT_TEXT_COLOR : null, 
                            borderLeft: row.result.passed ? HistoryResultRowsDesign.BORDER_LEFT_TEST_PASSED_COLOR : HistoryResultRowsDesign.BORDER_LEFT_TEST_NOT_PASSED_COLOR, 
                            textAlign: HistoryResultRowsDesign.TEXT_ALIGN }
                        },
                    children: moment(row.result.dateOfTest).format('YYYY-MM-DD HH:mm')
                };
            }
        }
    ]
}


const GreyStyledRow = styled(Row)`
    background: #F6F7F8;
    padding: 20px 0px 20px 0px;
`

const WhiteStyledRow = styled(Row)`
    background: #FFFFFF;
    padding: 20px 0px 20px 0px;
`

export default HighResolutionContrastHistory;