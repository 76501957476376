import React, { Component } from 'react';
import { ResourceTilesPage } from '../components/common';
import { IRouter } from '../utils/Interfaces';
import { TestCase } from '../utils/Models';
import { api } from '../api';
import { withStore } from "../store";
import CustomSpin from "../components/common/CustomSpin"


type State = {
    testCases: Array<TestCase>,
    isLoading: boolean
}

class TestCasesView extends Component<IRouter, State> {
    constructor(props: any) {
        super(props);
        
        this.state = {
            testCases: [],
            isLoading: true
        }
    }
    
    componentDidMount() {
        this.fetchTestCases();
        this.props.store.setToindex(4);
    }
    
    render() {
        const { testCases, isLoading } = this.state;

        if (isLoading) {
            return <CustomSpin />;
        }

        return (
            <ResourceTilesPage 
                resources={testCases}
                onTileClick={this.chooseTestCase}
                isTestSuitPage={true}
                onHistoryClick={this.handleHistoryClick}
                onPerformTestClick={this.handlePerformTestClick}
                onSettingsClick={this.handleSettingsClick}
            />
        );
    }

    fetchTestCases = () => {
        const { testId } = this.props.match.params;

        api
            .get(`test-cases/test-suit/${testId}`)
            .then(res => res.data)
            .then(res => this.setState({ testCases: res}))
            .finally(() => this.setState({ isLoading: false }))
    }

    chooseTestCase = (id: string) => {
        
    }

    handleHistoryClick = (id: string) => {
        const url = `/test-case/${id}/test-results`;
        this.handleDispatch(url,id)
        this.props.history.push(url);
    }
    
    handlePerformTestClick = (id: string) => {
        const url = `/test-case/${id}/perform-test`;
        this.handleDispatch(url,id)
        this.props.history.push(url)
    }
    
    handleSettingsClick = (id: string) => {
        const url = `/test-case/${id}/settings`;
        this.handleDispatch(url,id);
        this.props.history.push(url);
    }

    handleDispatch = (url: string,id: string,) => {
        const {testCases} = this.state;
        
        this.props.store.DispatchAdd(testCases, id, url);

        this.props.history.push(url);
    }

}


export default withStore(TestCasesView) ;