export const createTimeToNextStudyString = (minutesDifference: any) => {
    if (!minutesDifference) {
        return 'Brak danych';
    }

    const MINUTES_IN_HOUR = 60;
    const MINUTES_IN_DAY = 1440;
    const MINUTES_IN_MONTH = 43200;

    if (Math.abs(minutesDifference) < MINUTES_IN_HOUR) {
        const minutes = Math.abs(minutesDifference)
        return minutesDifference > 0 ? `za ${minutes} minut` : `${minutes} minut temu`;
    }

    if (Math.abs(minutesDifference) < MINUTES_IN_DAY) {
        const hours = Math.abs(Math.floor(minutesDifference / MINUTES_IN_HOUR));
        return minutesDifference > 0 ? `za ${hours} godzin` : `${hours} godzin temu`;
    }

    if (Math.abs(minutesDifference) < MINUTES_IN_MONTH) {
        const days = Math.abs(Math.floor(minutesDifference / MINUTES_IN_DAY));
        return minutesDifference > 0 ? `za ${days} dni` : `${days} dni temu`;
    }

    const months = Math.abs(Math.floor(minutesDifference / MINUTES_IN_MONTH));
    return minutesDifference > 0 ? `za ${months} miesiące` : `${months} miesiące temu`;
}