import React, { Component } from "react";
import {
    Row,
    Col,
    Card,
    Typography,
    message,
    Spin
} from "antd";
import {
    MainCard,
    CenteredButton,
    CustomTable,
    ProcedureDescriptionModal,
    GreenCheckIcon,
    RedCrossIcon
} from "../common";
import DwvComponent from "../../components/common/Dwv/DwvComponent";
import styled from "styled-components";
import _ from "lodash";
import { api } from "../../api";
import { IRouter } from "../../utils/Interfaces";
import { Circle, ParamType } from "../../utils/Models";
import { TEXT_RESULT_COLUMNS } from "../constants/table"

const { Text } = Typography;
type State = {
    visible: boolean;
    draw: boolean;
    loading: boolean;
    isDicomLoaded: boolean;
    performStdDev: boolean;
    roiCircle: Circle;
    dwvState: any;
    drawings: any;
    dicom: any;
    configuration: any;
    configurationId: string | null;
    dataSource: any;
    params: {
        kV: number,
        mA: number
    }
    testResult: any;
    stdDev: string | null;
    result: boolean;
};

type Props = {
    router: IRouter,
    thumbnails: React.ReactNode,
    dicom: any,
    selectedDicomId: string | any,
    mainCardReloadIcon: React.ReactNode,
    fetchThumbnails: () => void,
    onLoadingDicomFinish: () => void,
    onSavingTestStart: () => void,
    onSavingTestFinish: () => void
}

export default class NoiceLevelTest extends Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            visible: false,
            params: {
                kV: 0,
                mA: 0,
            },
            dwvState: {},
            drawings: [],
            dicom: null,
            configuration: [],
            configurationId: null,
            draw: false,
            roiCircle: { center: { x: 0, y: 0, offsetX: 0, offsetY: 0 }, r: 0 },
            isDicomLoaded: false,
            dataSource: [],
            testResult: null,
            performStdDev: false,
            stdDev: null,
            loading: false,
            result: false,
        };
    }

    componentDidMount() {
        this.fetchConfigurations();
    }

    componentDidUpdate(_: Props, prevState: State) {
        if (prevState.stdDev !== this.state.stdDev) {
            this.getHuWaterTableData();
        }
        if(prevState.isDicomLoaded !== this.state.isDicomLoaded && this.state.isDicomLoaded){
            this.setState({ draw:true, performStdDev: true })
        }
    }

    render() {
        const {
            visible,
            draw,
            roiCircle,
            dataSource,
            performStdDev,
            configuration,
        } = this.state;

        return (
            <>
                <Row justify='space-between'>
                    <Col>
                        <PerformExaminationDetailsCard
                            type="inner"
                            title={
                                <>
                                    <span>Poziom szumu</span>
                                    <CenteredButton
                                        onClick={() => this.saveTestResult()}
                                        label="Zachowaj wynik testu"
                                    />
                                    <CenteredButton
                                        onClick={() => this.openModal()}
                                        label="Opis procedury"
                                    />
                                </>
                            }>
                            {!_.isEmpty(configuration) &&
                                <>
                                    <Row gutter={[16, 16]}>
                                        <Col span={12}>
                                            <Text strong>Średnica fantomu</Text>
                                        </Col>
                                        <Col span={12}>{`${(
                                            (2 *
                                                (configuration.circle.r *
                                                    parseFloat(
                                                        configuration.pixelSpacing
                                                    ))) /
                                            10
                                        ).toFixed(2)} cm`}</Col>
                                    </Row>
                                    <Row gutter={[16, 16]}>
                                        <Col span={12}>
                                            <Text strong>Średnica ROI</Text>
                                        </Col>
                                        <Col span={12}>{`${(
                                            2 *
                                            0.4 *
                                            ((configuration.circle.r *
                                                parseFloat(
                                                    configuration.pixelSpacing
                                                )) /
                                                10)
                                        ).toFixed(2)} cm`}</Col>
                                    </Row>
                                    <Row gutter={[16, 16]}>
                                        <Col span={12}>
                                            <Text strong>Odchylenie kV</Text>
                                        </Col>
                                        <Col span={12}>{configuration.kVDeviation}</Col>
                                    </Row>
                                    <Row gutter={[16, 16]}>
                                        <Col span={12}>
                                            <Text strong>Odchylenie mA</Text>
                                        </Col>
                                        <Col span={12}>{configuration.mADeviation}</Col>
                                    </Row>
                                    <>
                                        {this.returnConfigurationReferenceParams(configuration)}
                                    </>
                                </>
                            }
                        </PerformExaminationDetailsCard>
                        <MainCard title="Wyniki pomiaru">
                            <CustomTable
                                columns={TEXT_RESULT_COLUMNS}
                                data={dataSource}
                            />
                        </MainCard>
                    </Col>
                    <Col xxl={{span: 12}} xl={{span: 11}} lg={{span: 10}}>
                        <MainCard title="Obrazy w teście">
                            <Spin
                                spinning={this.state.loading}
                                tip="Trwa wykonywanie testu...">
                                <DwvComponent
                                    {...this.props}
                                    draw={draw}
                                    circle={roiCircle}
                                    countStdDev={performStdDev}
                                    handleDicomLoading={this.handleDicomLoading}
                                    updateParams={this.handleParamsChange}
                                    getHuResult={this.getHuResult}
                                    displayMoveButton={true}
                                    handleDrag={this.handleDragCircle}
                                />
                            </Spin>
                        </MainCard>
                    </Col>
                    <Col span={4}>
                        <MainCard title="Ostatnio dodane" icon={this.props.mainCardReloadIcon}>
                            <SideBar>
                                {this.props.thumbnails}
                            </SideBar>
                        </MainCard>
                    </Col>
                </Row>
                <ProcedureDescriptionModal
                    visible={visible}
                    title="Opis procedury"
                    closeModal={this.closeModal}
                />
            </>
        );
    }

    handleParamsChange = (label: ParamType, value: number) => {
        const { params: { kV, mA } } = this.state;

        switch (label) {
            case "kV":
                this.updateParams(value, mA);
                break;
            case "mA":
                this.updateParams(kV, value);
                break;
            default:
                break;
        }
    };

    updateParams = (
        kV: number,
        mA: number,
    ) => {
        this.setState({
            params: {
                kV,
                mA,
            },
        });
    };

    handleRedirectToSettingsView = () => {
        this.props.router.history.push(
            this.props.router.location.pathname.replace(/[^/]*$/, "settings")
        );
    };

    returnConfigurationReferenceParams = (configuration: any) => {
        if(configuration.references.length === 0)
            return (<p>Brak odniesienia</p>)
        const activeReference = configuration.references.find((ref: any) => ref.active === true);
        
        return (
            <>
                <Row gutter={[16, 16]}>
                    <Col span={12}>
                        <Text strong>
                            Odniesienie:
                        </Text>
                    </Col>
                </Row>
                <Row gutter={[16, 16]}>
                    <Col span={2} />
                    <Col span={10}>
                        <Text strong>kV: {activeReference.averagekV}</Text>
                    </Col>
                    <Col span={12}>
                    </Col>
                </Row>
                <Row gutter={[16, 16]}>
                    <Col span={2} />
                    <Col span={10}>
                        <Text strong>mA: {activeReference.averagemA}</Text>
                    </Col>
                    <Col span={12}>
                    </Col>
                </Row>
                <Row gutter={[16, 16]}>
                    <Col span={2} />
                    <Col span={10}>
                        <Text strong>Odch.Stand.: {activeReference.averageNoise}</Text>
                    </Col>
                    <Col span={12}>
                    </Col>
                </Row>
            </>
        )
    }


    handleDragCircle = (newAttrs: any) => {
        this.setState(prevState => ({
            ...prevState,
            roiCircle: {
                ...prevState.roiCircle,
                center: {
                    ...prevState.roiCircle.center,
                    offsetX: newAttrs.x,
                    offsetY: newAttrs.y,
                }
            },
            performStdDev: true
        }));
    }

    handleDicomLoading = (state: boolean) => {
        if(state){
            return this.setState({ isDicomLoaded: state, draw: true, performStdDev: true },); 
        }
        return this.setState({ draw: false, performStdDev: false}); 
    };

    openModal = () => {
        this.setState({ visible: true });
    };

    closeModal = () => {
        this.setState({ visible: false });
    };

    getHuResult = (result: any) => {
        this.setState({
            stdDev: result,
            loading: false,
            performStdDev: false,
        });
    };

    saveTestResult = () => {
        const {
            params,
            configurationId,
            dataSource,
            isDicomLoaded,
        } = this.state;

        const { testId } = this.props.router.match.params;

        if (!isDicomLoaded) {
            message.error("Nie wybrano pliku Dicom");
        } else if (!configurationId) {
            message.error("Brak aktywnej konfiguracji");
        } else {

            this.props.onSavingTestStart();

            const result = {
                values: params,
                testData: dataSource,
            };

            const body = {
                Passed: this.state.result,
                Deactivated: true,
                TestData: JSON.stringify(result),
                TestConfigurationId: configurationId,
                DicomId: this.props.selectedDicomId
            };

            api.post(`test-results/test-case/${testId}`, { ...body })
                .then(() => setTimeout(() => this.props.onSavingTestFinish(), 2000))
                .catch(err => console.error(err));
        }
    };

    fetchConfigurations = () => {
        const {
            match: {
                params: { testId },
            },
        } = this.props.router;

        api.get(`test-configurations/test-case/${testId}`)
            .then(res => res.data)
            .then(res => {
                const activeConfiguration = res.configurations.find((c: any) => c.active === true);
                if (!activeConfiguration) {
                    message.error('Brak aktywnej konfiguracji. Przekierowano do ustawień.')
                    return this.handleRedirectToSettingsView();
                }

                const config = JSON.parse(activeConfiguration.configuration);

                const { circle : { center , r } } = config;
                
                const roiCircle: Circle = { center , r: (0.4 * r)};

                this.setState({ configuration: config, roiCircle, configurationId: activeConfiguration.id });
            })
            .catch(er => { })
    };

    getConfigurationReference = () => {
        const { configuration } = this.state;
        return configuration.references.find((ref:any) => ref.active === true);
    }

    getHuWaterTableData = () => {
        const { params: { kV, mA }, stdDev, configuration } = this.state;
        let dataSource = [];

        const parseStdDev = stdDev && parseFloat(stdDev);
        const fixedStdDev = parseStdDev && parseStdDev.toFixed(2);
        
        const currentReference = this.getConfigurationReference();
        const deviationStdDev = currentReference && parseStdDev ? (currentReference.averageNoise / parseStdDev - 1) * 100 : 0;
        const resultStdDev = Math.abs(deviationStdDev) < 15;
        const stdDevData = {
            key: "1",
            name: "Wartość Odch.Stand.",
            measurement: fixedStdDev,
            reference: currentReference ? currentReference.averageNoise : '--',   
            deviation: currentReference ? `${deviationStdDev.toFixed(2)} %` : '0%',
            result: currentReference ? resultStdDev : true,
        };

        dataSource.push(stdDevData);

        const kv_deviation = (parseFloat(currentReference ? currentReference.averagekV: 0) / kV - 1) * 100; 
        const kv_result = Math.abs(kv_deviation) < configuration.kVDeviation;   

        const kVData = {
            key: "2",
            name: "kV",
            measurement: kV,
            reference: currentReference ? currentReference.averagekV : '--', 
            deviation: currentReference ? `${kv_deviation.toFixed(2)} %` : '0%',
            result: currentReference ? kv_result : true,
        };

        dataSource.push(kVData);

        const mA_deviation = ( (currentReference ? currentReference.averagemA : 0) / mA - 1) * 100; 

        const mA_result = Math.abs(mA_deviation) < configuration.mADeviation; 
        const mAData = {
            key: "3",
            name: "mA",
            measurement: mA,
            reference: currentReference ? currentReference.averagemA : '--', 
            deviation: currentReference ? `${mA_deviation.toFixed(2)} %` : '0%',
            result: currentReference ? mA_result : true,
        };

        dataSource.push(mAData);

        let measurmentResult = resultStdDev && mA_result && kv_result;
        if(!currentReference) {
            measurmentResult = true;
        }

        const result = {
            key: "4",
            name: "Wynik testu",
            result: measurmentResult
        };

        dataSource.push(result);

        this.setState({
            dataSource: dataSource,
            result: measurmentResult,
        });
    };
}

const PerformExaminationDetailsCard = styled(Card)`
    .ant-card-head-title {
        font-size: 24px;
        font-weight: 600;
        color: #505458;
        padding-top: 25px;
        border: none;
    }
`;

const SideBar = styled.div`
    overflow: auto;
    height: 80vh;
`;
