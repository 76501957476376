import React from 'react';
import { Row, Col } from 'antd';
import { CustomSelect, CustomDatePicker, ActionButton } from '../common';

 
type Props = {
    showFilterBtn?: boolean
    filters: {
        selectedTimeOption: string,
        date: any
    },
    onChange: (filterName: string, value: any) => void,
    onFinish: () => void,
}

const HistoryFilters = (props: Props) => {
    const options = [
        {value: 'all', label: 'Wszystkie'},
        {value: 'month', label: 'Ostatni miesiąc'},
        {value: 'year', label: 'Ostatni rok'},
        {value: 'custom', label: 'Konkretna data'},
    ];
    
    return (
        <Row gutter={[0, 8]}>
            <Col span={24}>
                <CustomSelect 
                    options={options}
                    selectedOption={props.filters.selectedTimeOption}
                    onChange={(value) => props.onChange('selectedTimeOption', value)}
                />
            </Col>

            {props.filters.selectedTimeOption === 'custom' && 
                <Col span={24}>
                    <CustomDatePicker 
                        value={props.filters.date}
                        onChange={(date) => props.onChange('date', date)}
                    />
                </Col>
            }
            {props.showFilterBtn === true ?
            <Col span={24}>
                <ActionButton 
                    label='Filtruj' 
                    onClick={props.onFinish}
                />
            </Col>
            : null}
        </Row>
    )
}

export default HistoryFilters;