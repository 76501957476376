import { api } from '../api';
import _ from 'lodash';

export const uploadDicoms = (files: FileList, testCaseId: string, callback: () => void) => {
    Array.from(files).forEach(async (file, index) => {
        let formData = new FormData();
        formData.append("dicom", file);
        
        await api
            .post(`dicom/test-case/${testCaseId}`, formData)
            .catch(err => console.error(err))
        
        if (index === files.length - 1 && _.isFunction(callback)) {
            callback();
        }
    })
}

export const uploadDicomsToHardware = (files: Array<File>, hardwareId: string, callback: () => void) => {    
    files.forEach(async (file, index) => {        
        let formData = new FormData();
        formData.append("dicom", file);
        
        await api
            .post(`dicom/hardware/${hardwareId}`, formData)
        
        if (index === files.length - 1 && _.isFunction(callback)) {
            callback();
        }
    })
}

