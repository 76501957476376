import React, { Component } from 'react';
import { Row, Col, Divider } from 'antd';
import styled from 'styled-components';
import moment from 'moment';
import _ from 'lodash';

import { CustomTestCard, MainCard, CustomTable, IconButton, HistoryFilters, GreenCheckIcon, RedCrossIcon } from '../../common';
import { IRouter } from '../../../utils/Interfaces';
import { HistoryResultRowsDesign } from '../../constants/historyResultRowsDesign'
import { filterResults } from '../../common/FilterResults'

type State = {
    filters: {
        selectedTimeOption: string,
        date: any
    }
    selectedResult: any,
    testData: any
}

type Props = {
    router: IRouter,
    results: Array<any>,
    validTime: number,
    calculateNextStudy: (dateOfTest: any, validTime: number) => string,
}

class ArtefactsHistory extends Component<Props, State> {
    constructor(props: any) {
        super(props);
        
        this.state = {
            filters: {
                selectedTimeOption: 'all',
                date: null,
            },
            selectedResult: null,
            testData: null
        }
    }

    render() {
        const { results, validTime, calculateNextStudy } = this.props;
        const { selectedResult } = this.state;
        if (!selectedResult && results.length > 0) {
            this.changeSelectedResult(results[0]);
        }

        const { testData } = this.state;

        return (
            <>
                <Row gutter={[24, 24]}>
                    <Col span={4}>
                        <GreyStyledRow justify="center">
                            <IconButton
                                onClick={this.handleChangeToNewTest}
                                label='Nowy test'
                            />
                        </GreyStyledRow>
                        <WhiteStyledRow justify="center">
                            <Col span={18}>
                                <HistoryFilters 
                                    filters={this.state.filters}
                                    onChange={this.handleFiltersChange}
                                    onFinish={this.handleFiltersSubmit}
                                />
                            </Col>
                            <Col span={18}>
                                <CustomTable
                                    columns={this.historyResultsColumns}
                                    data={filterResults(results, this.state.filters.selectedTimeOption, this.state.filters.date || null)}
                                    cursor='pointer'
                                    onRow={(record: any) => {
                                        return {
                                          onClick: () => this.changeSelectedResult(record)
                                        }
                                    }}
                                />
                            </Col>
                        </WhiteStyledRow>
                    </Col>
                    <Col span={20}>
                        <Row gutter={[24, 24]}>
                            <Col span={24}>
                                {
                                    <CustomTestCard
                                        resultId={selectedResult && selectedResult.result.id}
                                        title='Artefakty'
                                        performer={selectedResult ? `${selectedResult.user.firstName} ${selectedResult.user.lastName}` : null}
                                        date={selectedResult ? selectedResult.result.dateOfTest : null}
                                        expirationDate={selectedResult ? calculateNextStudy(selectedResult.result.dateOfTest, validTime) : null}
                                        result={selectedResult ? selectedResult.result.passed : null}
                                    />
                                }
                            </Col>
                            <Col span={8}>
                                <MainCard title='Parametry wejściowe'>
                                    {testData && testData.params.map((param: any) => 
                                        <Row>
                                            <Divider />
                                            <Col span={24}><b>{param.name}</b></Col>
                                            <Col span={24}>Szerokość: {param.width}</Col>
                                            <Col span={24}>Poziom: {param.level}</Col>
                                        </Row>
                                    )}
                                </MainCard>
                            </Col>
                            <Col span={16}>
                                <MainCard title='Wyniki pomiaru'>
                                    <Col span={16}>
                                        <CustomTable
                                            columns={this.resultColumns}
                                            data={selectedResult && this.getArtefactsTableData(testData.drawings)}
                                        />
                                    </Col>
                                </MainCard>
                            </Col>
                        </Row>
                    </Col>

                </Row>

            </>
        );
    }
    
    handleChangeToNewTest = () => {
        this.props.router.history.push(this.props.router.location.pathname.replace(/[^/]*$/, 'perform-test'));
    }

    changeSelectedResult = (newResult: any) => {
        this.setState({
            selectedResult: newResult,
            testData: JSON.parse(newResult.result.testData)
        })
    }

    getArtefactsTableData = (drawings: any) => {        
        let values: Array<any> = [];

        this.state.testData.params.forEach((param: any) => {
            const artefactsNumber = drawings.filter((d: any) => _.isEqual(d.WL, param)).length;
            const row = {
                wl: `W/L ${param.width}/${param.level}`,
                artefacts: artefactsNumber,
                result: artefactsNumber === 0
            };
            values.push(row);
        })

        const finalResultRow = {
            wl: 'Wynik końcowy',
            result: drawings.length === 0
        };
        values.push(finalResultRow);

        return values;
    }

    handleFiltersChange = (filterName: string, newValue: any) => {
        this.setState(prevState => {
            prevState.filters[filterName] = newValue;
            return prevState;
        })
    }

    handleFiltersSubmit = () => {
        alert('Filtrowanie!');
    }
    
    resultColumns = [
        {
            title: 'Ustawienia okna',
            dataIndex: 'wl',
            key: 'wl',
        },
        {
            title: 'Artefakty',
            dataIndex: 'artefacts',
            key: 'artefacts',
        },
        {
            title: 'Wynik',
            dataIndex: 'result',
            key: 'result',
            render: (result: boolean) => result ? <GreenCheckIcon /> : <RedCrossIcon />
        },
    ]
    
    historyResultsColumns = [
        {
            title: 'WYNIKI HISTORYCZNE',
            dataIndex: 'date',
            key: 'date',
            render: (_: any, row: any) => {
                const { selectedResult } = this.state;
                return {
                  props: {
                    style: 
                        {
                            background: selectedResult && selectedResult.result.id === row.result.id ? HistoryResultRowsDesign.SELECTED_RESULT_BACKGROUND_COLOR : null, 
                            color: selectedResult && selectedResult.result.id === row.result.id ? HistoryResultRowsDesign.SELECTED_RESULT_TEXT_COLOR : null, 
                            borderLeft: row.result.passed ? HistoryResultRowsDesign.BORDER_LEFT_TEST_PASSED_COLOR : HistoryResultRowsDesign.BORDER_LEFT_TEST_NOT_PASSED_COLOR, 
                            textAlign: HistoryResultRowsDesign.TEXT_ALIGN }
                        },
                    children: moment(row.result.dateOfTest).format('YYYY-MM-DD HH:mm')
                };
            }
        }
    ]
}


const GreyStyledRow = styled(Row)`
    background: #F6F7F8;
    padding: 20px 0px 20px 0px;
`

const WhiteStyledRow = styled(Row)`
    background: #FFFFFF;
    padding: 20px 0px 20px 0px;
`

export default ArtefactsHistory;