import moment from 'moment';

export const getMinutesDifferenceToNextStudy = (nextStudyDate: string | undefined): number => {
    return moment(nextStudyDate).diff(moment(), 'minutes')
}

export const calculateProgressPercentage = (minutesToNextStudy: number): number => {
    const MINUTES_IN_YEAR = 525600;
    const timeLeft = minutesToNextStudy > 0 ? minutesToNextStudy : 0;

    const percentage = (1 - (timeLeft / MINUTES_IN_YEAR)) * 100;
    return percentage > 0 ? percentage : 0;
}

export const getTileColor = (minutesToNextStudy: number): string => {
    if(minutesToNextStudy <= 1440 && minutesToNextStudy >= 0)
        return "#EAB800";
    else if(minutesToNextStudy > 1440)
        return "#0090D9"
    return '#F35958'
}