import React, { Component } from "react";
import { Row, Col, Card, message, Button, Form, Input } from "antd";
import { CustomInput } from '../common';
import styled from "styled-components";
import _, { result } from "lodash";

import {
    MainCard,
    CenteredButton,
    CustomTable,
    ProcedureDescriptionModal,
    GreenCheckIcon,
    RedCrossIcon
} from "../common";
import { api } from "../../api";
import { IRouter } from "../../utils/Interfaces";
import { FormInstance } from "antd/lib/form";

type State = {
    visible: boolean;
    results: Array<any>;
    mesaurement: {
        frontMovement: number
        backMovement: number,
    }
};

type Props = {
    router: IRouter,
    onSavingTestStart: () => void,
    onSavingTestFinish: () => void
}

export default class TableMovementTest extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            visible: false,
            results: [],
            mesaurement: {
                frontMovement: 0,
                backMovement: 0
            }
        };
    }

    formRef = React.createRef<FormInstance>();

    render() {
        const { visible, results, mesaurement } = this.state;
        
        return (
            <>
                <Row justify='space-between'>
                    <Col>
                        <PerformExaminationDetailsCard
                            type="inner"
                            title={
                                <>
                                    <span>Ruch stołu</span>
                                    <CenteredButton
                                        onClick={this.saveTestResult}
                                        label="Zachowaj wynik testu"
                                    />
                                    <CenteredButton
                                        onClick={() => this.openModal()}
                                        label="Opis procedury"
                                    />
                                </>
                            }></PerformExaminationDetailsCard>
                        <MainCard title="Wyniki pomiaru">
                            <CustomTable
                                columns={this.columns}
                                data={results}
                            />
                        </MainCard>
                    </Col>
                    <Col xxl={{span: 16}} xl={{span: 14}} lg={{span: 14}}>
                        <MainCard
                            title="Pomiar"
                            style={{ height: "100%" }}
                        >
                            Rzeczywiste przesunięcie stołu do przodu (mm)
                            <CustomInput 
                                value={mesaurement.frontMovement}
                                onChange={this.onChangeFrontMovement}
                                type='number' 
                                placeholder="X mm"
                                step={1} 
                            />

                            Rzeczywiste przesunięcie stołu do tyłu (mm)
                            <CustomInput 
                                value={mesaurement.backMovement}
                                onChange={this.onChangeBackMovement}
                                type='number' 
                                placeholder="X m" 
                                step={1} 
                            />
                        </MainCard>
                    </Col>
                </Row>
                <ProcedureDescriptionModal
                    visible={visible}
                    title="Opis procedury"
                    steps={steps}
                    closeModal={this.closeModal}
                />
            </>
        );
    }

    onChangeFrontMovement = (newMovement: number) => {
        this.onFinish({front: newMovement, back: this.state.mesaurement.backMovement});
        
        this.setState(prevState => ({
            mesaurement: {
                ...prevState.mesaurement,
                frontMovement: newMovement
            }
        }))
    }
    
    onChangeBackMovement = (newMovement: number) => {
        this.onFinish({front: this.state.mesaurement.frontMovement, back: newMovement});

        this.setState(prevState => ({
            mesaurement: {
                ...prevState.mesaurement,
                backMovement: newMovement 
            }
        }))
    }

    saveTestResult = () => {
        const { results } = this.state;
        const { testId } = this.props.router.match.params;

        if(_.isEmpty(results)) 
            return message.error("Nie przeprowadzono testu !");

        this.props.onSavingTestStart();

        const testData = { results };

        const body = {
            Passed: results[2].result || false,
            Deactivated: true,
            TestData: JSON.stringify(testData),
            TestConfigurationId: "00000000-0000-0000-0000-000000000000",
        };

        api
            .post(`test-results/test-case/${testId}`, { ...body })
            .then(() => setTimeout(() => this.props.onSavingTestFinish(), 2000))
            .catch(err => console.error(err));
    };

    openModal = () => {
        this.setState({ visible: true });
    };

    closeModal = () => {
        this.setState({ visible: false });
    };

    onFinish = (values: {front: number, back: number}) => {
        
        const { back, front } = values;
        const resultData = [];

        const frontDeviation = Math.abs(front - 300);
        const frontData = {
            key: "1",
            name: "Przesuniecie w przód",
            measure: front,
            reference: 300,
            deviation: frontDeviation.toFixed(0),
            result: (frontDeviation <= 1),
        }; 
        resultData.push(frontData);

        const backDeviation = Math.abs(back - 300);
        const backData = {
            key: "2",
            name: "Przesuniecie w tył",
            measure: back,
            reference: 300,
            deviation: backDeviation.toFixed(0),
            result: (backDeviation <= 1),
        };
        resultData.push(backData);

        const finalResult = {
            key: "3",
            name: "Wynik testu",
            result: frontData.result && backData.result,
        };
        resultData.push(finalResult);

        this.setState({ results: resultData })
    };

    columns = [
        {
            title: "Nazwa",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Pomiar",
            dataIndex: "measure",
            key: "measure"
        },
        {
            title: "Odniesienie",
            dataIndex: "reference",
            key: "reference",
            render: (reference: number) => reference && reference.toFixed(0),
        },
        {
            title: "Odchylenie",
            dataIndex: "deviation",
            key: "deviation",
        },
        {
            title: "Poprawny",
            dataIndex: "result",
            key: "result",
            render: (result: boolean) => (result ? <GreenCheckIcon /> : <RedCrossIcon />),
        },
    ];
}

const steps = [
    "Umieść fantom do testów pola świetlnego na statywie",
    "Ustaw SID na 100 cm",
    "Ustaw promień centralny na środek fantomu. Upewnij się, że linie centrujące pola świetlnego tzw. „krzyż” są prostopadłe do wszystkich krawędzi fantomu",
    "Ustaw kolimację przysłon promieniowania do (oznaczonego na fantomie) pola o rozmiarze 26x26 cm",
    "Ustaw aparat rtg w trybie „statyw z kratką”.",
    "Włóż do szyn kolimatora aluminiowy filtr jednorodny 25 mm",
    "Ustaw wysokie napięcie na 75kV i natężenie prądu na 200mA.",
    "Wykonaj ekspozycję",
    "Wyślij otrzymany obraz do systemu Qadra",
    "Wykonaj pomiary na obrazie w systemie Qadra",
];

const PerformExaminationDetailsCard = styled(Card)`
    .ant-card-head-title {
        font-size: 24px;
        font-weight: 600;
        color: #505458;
        padding-top: 25px;
        border: none;
    }
`;

const SubmitButton = styled(Button)`
    background: #1b3148;
    color: #ffffff;
`;
