import React from 'react';
import { Select } from 'antd';
import styled from 'styled-components';

const { Option } = Select;

type Option = {
    value: any,
    label: string
}

export type Props =  {
    options: Array<Option>,
    selectedOption: any,
    onChange: (value: any) => void
}

const CustomSelect = (props : Props) => {
    return (
        <StyledSelect value={props.selectedOption} onChange={(e) => props.onChange(e)}>
            {props.options && props.options.map(option => 
                <Option key={option.value} value={option.value}>{option.label}</Option>    
            )}
        </StyledSelect>
    )
}

const StyledSelect = styled(Select)`
    width: 100%;
`

export default CustomSelect;