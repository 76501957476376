import React, { Component } from "react";
import { Row, Col, Card, Button, message, Divider, Spin } from "antd";
import styled from "styled-components";
import _ from "lodash";
import {
    MainCard,
    CenteredButton,
    CustomTable,
    CustomUpload,
    ProcedureDescriptionModal,
    RedCrossIcon,
    GreenCheckIcon,
} from "../common";
import DwvComponent from "../common/Dwv/DwvComponent";
import { api } from "../../api";
import { IRouter } from "../../utils/Interfaces";
import { Window, WLSetting } from "../../utils/Models";
import { ArtefactValidButton } from "./artefacts";

type State = {
    visible: boolean;
    activeConfiguration: {
        name: string;
        window: Window;
        artefacts: any;
        dwvState: { drawings: any; drawingsDetails: any };
    };
    selectedConfigurationId: string | null;
    dwvState: any;
    newWL: WLSetting;
    drawings: any[];
    drawingsDetails: any;
    selectedTool: any;
    performTest: boolean;
    dicomActions: {
        isLoaded: boolean;
    };
    artefactsOffset: { id: string; x: number | undefined; y: number | undefined }[];
    loading: boolean;
    allWindowsApproved: boolean
};

type Props = {
    router: IRouter,
    thumbnails: React.ReactNode,
    dicom: any,
    selectedDicomId: string | any,
    mainCardReloadIcon: React.ReactNode,
    fetchThumbnails: () => void,
    onLoadingDicomFinish: () => void,
    onSavingTestStart: () => void,
    onSavingTestFinish: () => void
}

export default class ArtefactsTest extends Component<Props, State> {
    constructor(props: any) {
        super(props);

        this.state = {
            visible: false,
            activeConfiguration: {
                artefacts: [],
                name: "",
                window: {
                    WL: [],
                    name: "",
                },
                dwvState: {
                    drawings: null,
                    drawingsDetails: null,
                },
            },
            artefactsOffset: [],
            selectedConfigurationId: null,
            dwvState: {},
            newWL: { width: null, level: null, name: '' },
            drawings: [],
            drawingsDetails: null,
            selectedTool: null,
            dicomActions: {
                isLoaded: false,
            },
            performTest: false,
            loading: false,
            allWindowsApproved: false
        };
    }

    componentDidMount() {
        this.fetchTestConfigurations();
    }

    render() {
        const {
            visible,
            loading,
            newWL,
            performTest,
            selectedTool,
            activeConfiguration: {
                window: { WL },
                artefacts,
            },
            activeConfiguration,
            drawings,
            drawingsDetails,
            dwvState,
        } = this.state;

        return (
            <Spin spinning={loading}>
                <Row gutter={[24, 24]}>
                    <Col span={9}>
                        <PerformExaminationDetailsCard
                            type='inner'
                            title={
                                <>
                                    <span>Artefakty</span>
                                    <CenteredButton onClick={this.saveTestResult} label='Zachowaj wynik testu' />
                                    <CenteredButton onClick={() => this.openModal()} label='Opis procedury' />
                                </>
                            }>
                            {this.handleConfigurationDisplay()}
                        </PerformExaminationDetailsCard>
                        {performTest && (
                            <MainCard title='Wyniki pomiaru'>
                                <CustomTable columns={this.columns} data={this.getArtefactsTableData(artefacts)} />
                            </MainCard>
                        )}
                    </Col>
                    <Col span={11}>
                        <MainCard title='Obrazy w teście'>
                                <DwvComponent
                                    activeConfiguration={activeConfiguration}
                                    selectedTool={selectedTool}
                                    drawings={drawings}
                                    drawingsDetails={drawingsDetails}
                                    dwvState={dwvState}
                                    handleDragDrawing={this.handleDragDrawing}
                                    performTest={performTest}
                                    newWL={newWL}
                                    {...this.props}
                                    onStateChange={this.changeDwvState}
                                    handleDicomLoading={this.handleDicomLoading}
                                    addNewShape={this.addNewShapeToList}
                                    deleteShape={this.deleteShapeFromList}
                                    canZoom={true}
                                    artifact
                                />
                        </MainCard>
                    </Col>
                    <Col span={4}>
                        <MainCard title='Ostatnio dodane' icon={this.props.mainCardReloadIcon}>
                            <SideBar>{this.props.thumbnails}</SideBar>
                        </MainCard>
                    </Col>
                </Row>
                <ProcedureDescriptionModal
                    visible={visible}
                    title='Opis procedury'
                    steps={steps}
                    closeModal={this.closeModal}
                />
            </Spin>
        );
    }

    openModal = () => {
        this.setState({ visible: true });
    };

    closeModal = () => {
        this.setState({ visible: false });
    };

    fetchTestConfigurations = () => {
        const { testId } = this.props.router.match.params;

        api
            .get(`/test-configurations/active/test-case/${testId}`)
            .then(res => res.data)
            .then(data => {
                if(data.configurations === null || _.isEmpty(data.configurations)){
                    setTimeout(() =>  { 
                        return this.props.router.history.push(
                            this.props.router.location.pathname.replace(/[^/]*$/, 'settings'));
                        }
                    , 1000)
                }
                this.handleParseConfiguration(data);
            })
            .catch(err => console.error(err));
    };

    handleDicomLoading = () => {
        const {
            activeConfiguration: {
                window: { WL },
            },
        } = this.state;

        const newWL = WL.length > 0 && WL[0];

        if (newWL) {
            return this.setState(prevState => {
                return { dicomActions: { ...prevState.dicomActions, isLoaded: true }, newWL: newWL, performTest: true };
            });
        }
        return this.setState(prevState => {
            return { dicomActions: { ...prevState.dicomActions, isLoaded: true, performTest: true } };
        });
    };

    handleParseConfiguration = (data: any) => {
        if (_.isEmpty(data)) {
            return;
        }
        const {
            configurations: { id, configuration },
        } = data;

        const configurationData = configuration && JSON.parse(configuration);

        this.setState(prevState => {
            return {
                selectedConfigurationId: id,
                drawings: configurationData.artefacts,
                activeConfiguration: configurationData,
            };
        });
    };

    handleRemoveDrawing = (id: string) => {
        this.setState(prevState => {
            return {
                activeConfiguration: {
                    ...prevState.activeConfiguration,
                    artefacts: prevState.activeConfiguration.artefacts.filter((a: any) => a.id !== id),
                },
            };
        });
    };

    handleDragDrawing = (attrs: any) => {
        const { id, x, y } = attrs;

        const offset = { id, x, y };

        this.setState(prevState => {
            const hasOffset = prevState.artefactsOffset.find(o => o.id === id);
            if (!hasOffset) {
                return { artefactsOffset: [...prevState.artefactsOffset, offset], performTest: true };
            }
            const artefactsOffset = prevState.artefactsOffset.map(o => (o.id === id ? offset : o));
            return { artefactsOffset, performTest: true };
        });
    };

    handleConfigurationDisplay = () => {
        const { activeConfiguration, newWL, artefactsOffset } = this.state;
        if (activeConfiguration.name === "") {
            return;
        }
        const {
            window: { WL },
            artefacts,
        } = activeConfiguration;

        return (
            <>
                {WL.map((WLParam: any, index: number) => {
                    const filteredArtefacts = artefacts.filter((d: any) => _.isEqual(d.WL, WLParam));
                    return (
                        <React.Fragment key={`W/L: ${WLParam.width}/${WLParam.level}`}>
                            {index !== 0 && <Divider />}
                            <CLabel>
                                <span 
                                    onClick={() => this.changeToolToDraw(WLParam)}
                                    style={{fontWeight: 'bold', cursor: 'pointer'}}
                                >
                                    {`Ustawienia okna "${WLParam.name}" `}
                                </span>
                                {_.isEqual(newWL, WLParam) && "(Aktywne) "}
                                {`W/L: ${WLParam.width}/${WLParam.level}`}
                                <ArtefactValidButton
                                    onClick={() => this.handleClickValidButton(WLParam)}
                                    disabled={filteredArtefacts.length > 0 || !_.isEqual(newWL, WLParam)}
                                />
                                <Button
                                    type='link'
                                    disabled={!_.isEqual(newWL, WLParam)}
                                    style={{ padding: 0 }}
                                    onClick={() => this.changeToolToDraw(WLParam)}>
                                    [Dodaj/Edytuj artefakt]
                                </Button>
                            </CLabel>
                            {filteredArtefacts.map((d: any, index: number) => {
                                const offset = artefactsOffset.find(c => c.id === d.id);
                                if (!d.radiusX || !d.radiusY)
                                    return ;
                                
                                if (offset) {
                                    return (
                                        <React.Fragment key={d.id}>
                                            <Row>{`Artefakt ${index + 1}, 
                                            (${(d.x + offset.x).toFixed(2)}, 
                                            ${(d.y + offset.y).toFixed(2)}, 
                                            ${(d.radiusX).toFixed(2)}, ${(d.radiusY).toFixed(2)})`}</Row>
                                        </React.Fragment>
                                    );
                                }
                                return (
                                    <React.Fragment key={d.id}>
                                        <Row>{`Artefakt ${index + 1}, (${(d.x).toFixed(2)}, ${(d.y).toFixed(2)}, ${(d.radiusX).toFixed(2)}, ${
                                            (d.radiusY).toFixed(2)
                                        })`}</Row>
                                    </React.Fragment>
                                );
                            })}
                        </React.Fragment>
                    );
                })}
            </>
        );
    };

    changeToolToDraw = (WLParam: WLSetting) => {
        if (!this.state.allWindowsApproved && (this.state.newWL.level == null || this.state.newWL.width == null)) {
            return message.error("Wybierz najpierw odpowiednie ustawienie okna");
        }

        this.setState({ selectedTool: "Draw", newWL: WLParam });
    };

    handleClickValidButton = (WLParam: WLSetting) => {
        const { activeConfiguration } = this.state;
        const {
            window: { WL },
        } = activeConfiguration;

        const index = WL.findIndex((el) => el === WLParam)

        if (index >= WL.length - 1)
            return this.unselectAllWindowSettings();

        return this.changeToolToDraw(WL[index+1]);
    }
    
    unselectAllWindowSettings = () => {
        return this.setState({ newWL: { name: '', width: null, level: null }, allWindowsApproved: true });
    }

    saveTestResult = () => {
        const { dwvState, drawings, selectedConfigurationId, activeConfiguration, artefactsOffset } = this.state;

        this.setState({ loading: true });

        setTimeout(() => this.setState({ loading: false }), 2000);

        if (!activeConfiguration || !selectedConfigurationId || !drawings) {
            message.error("Błąd wykonania testu");
            return;
        }

        this.props.onSavingTestStart();

        const finalDrawings = drawings.map((d: any) => {
            const offset = artefactsOffset.find(a => a.id === d.id);
            if (offset) {
                return {
                    ...d,
                    id: d.id,
                    x: d.x + offset.x,
                    y: d.y + offset.y,
                };
            }
            return d;
        });

        const dwvStateCopy = Object.assign({}, dwvState);

        if (dwvStateCopy.drawings && dwvStateCopy.drawings.children && dwvStateCopy.drawings.children.length > 0) {
            const children = dwvStateCopy.drawings.children[0].children;
    
            children.map((c: any) => {
                const { id } = c.attrs;
                const offset = artefactsOffset.find(a => a.id === id);
                if (offset) {
                    c.children[0].attrs.x = c.children[0].attrs.x + offset.x;
                    c.children[0].attrs.y = c.children[0].attrs.y + offset.y;
                    c.children[1].attrs.x = c.children[1].attrs.x + offset.x;
                    c.children[1].attrs.y = c.children[1].attrs.y + offset.y;
                }
            });
        }

        const result = {
            dwvState: dwvStateCopy,
            drawings: finalDrawings,
            params: activeConfiguration.window.WL,
        };

        const body = {
            Passed: drawings.length === 0,
            Deactivated: true,
            TestData: JSON.stringify(result),
            TestConfigurationId: selectedConfigurationId,
            DicomId: this.props.selectedDicomId,
        };

        const { testId } = this.props.router.match.params;

        api
            .post(`test-results/test-case/${testId}`, { ...body })
            .then(() => setTimeout(() => this.props.onSavingTestFinish(), 2000))
            .catch(err => console.error(err));
    };

    changeDwvState = (newState: any) => {
        this.setState({
            dwvState: newState,
        });
    };

    addNewShapeToList = (id: string) => {
        const {
            dwvState,
            activeConfiguration: { artefacts },
        } = this.state;
        let newShapeData: any = null;

        dwvState.drawings &&
            dwvState.drawings.children.forEach((el: any) => {
                newShapeData = el.children.find(
                    (child: any) => !artefacts.find((c: any) => c.id === id) && child.attrs.id === id
                );
            });

        if (newShapeData) {
            const { x, y, radiusX, radiusY } = newShapeData.children[0].attrs;
            const newShape = {
                id: id,
                WL: this.state.newWL,
                x: x,
                y: y,
                radiusX: radiusX,
                radiusY: radiusY,
            };

            this.setState(prevState => {
                return {
                    activeConfiguration: {
                        ...prevState.activeConfiguration,
                        artefacts: [...prevState.activeConfiguration.artefacts, newShape],
                    },
                    drawings: [...prevState.drawings, newShape],
                    selectedTool: "Scroll",
                };
            });
        }
    };

    deleteShapeFromList = (id: string) => {
        this.setState(prevState => {
            return {
                ...prevState,
                drawings: prevState.drawings.filter((d: any) => d.id !== id),
                activeConfiguration: {
                    ...prevState.activeConfiguration,
                    artefacts: [...prevState.activeConfiguration.artefacts.filter((d: any) => d.id !== id)],
                },
            };
        });
    };

    getArtefactsTableData = (drawings: any) => {
        let values: Array<any> = [];
        this.state.activeConfiguration &&
            this.state.activeConfiguration.window.WL.forEach((wl: any) => {
                const artefactsNumber = drawings.filter((d: any) => _.isEqual(d.WL, wl)).length;
                const row = {
                    wl: `W/L ${wl.width}/${wl.level}`,
                    artefacts: artefactsNumber,
                    result: artefactsNumber === 0,
                };

                values.push(row);
            });

        const finalResultRow = {
            wl: 'Wynik końcowy',
            result: drawings.length === 0
        };
        values.push(finalResultRow);
        
        return values;
    };

    columns = [
        {
            title: "Ustawienia okna",
            dataIndex: "wl",
            key: "wl",
        },
        {
            title: "Artefakty",
            dataIndex: "artefacts",
            key: "artefacts",
        },
        {
            title: "Wynik",
            dataIndex: "result",
            key: "result",
            render: (result: boolean) => result ? <GreenCheckIcon /> : <RedCrossIcon />
        },
    ];
}

const steps = [
    "Umieść fantom do testów pola świetlnego na statywie",
    "Ustaw SID na 100 cm",
    "Ustaw promień centralny na środek fantomu. Upewnij się, że linie centrujące pola świetlnego tzw. „krzyż” są prostopadłe do wszystkich krawędzi fantomu",
    "Ustaw kolimację przysłon promieniowania do (oznaczonego na fantomie) pola o rozmiarze 26x26 cm",
    "Ustaw aparat rtg w trybie „statyw z kratką”.",
    "Włóż do szyn kolimatora aluminiowy filtr jednorodny 25 mm",
    "Ustaw wysokie napięcie na 75kV i natężenie prądu na 200mA.",
    "Wykonaj ekspozycję",
    "Wyślij otrzymany obraz do systemu Qadra",
    "Wykonaj pomiary na obrazie w systemie Qadra",
];

const PerformExaminationDetailsCard = styled(Card)`
    .ant-card-head-title {
        font-size: 24px;
        font-weight: 600;
        color: #505458;
        padding-top: 25px;
        border: none;
    }
`;

const SideBar = styled.div`
    overflow: auto;
    height: 80vh;
`;

const CLabel = styled.div``;
