import React, { Component } from "react";
import { Row, Col, Button, message, Spin, Collapse, Checkbox, Modal, Input, Tooltip } from "antd";
import { AimOutlined, DeleteOutlined } from '@ant-design/icons';
import {
    MainCard,
    CustomInput,
    NewTestConfigurationCard
} from "../../common";
import DwvComponent from '../../common/Dwv/DwvComponent';
import styled from "styled-components";
import { Point, Circle, ParamType } from "../../../utils/Models";
import { getCircleThrough3Point, handleRadiusToCm } from "../../../utils/mathHelpers";
import { api } from "../../../api";
import { IRouter } from "../../../utils/Interfaces";
import moment from 'moment';
import _ from 'lodash';
import { NEW_CONFIG_INFO } from "../../../utils/modalConfirm";
import { couldStartTrivia, createNamedExports } from "typescript";

const { Panel } = Collapse;

type configuration = {
    id: string;
    active: boolean;
    testCaseId: string;
    configuration: {
        name: string;
        state: any;
        circle: Circle;
        kVDeviation: number;
        mADeviation: number;
        references: Array<referenceConfig>;
        pixelSpacing: string | null;
    }
}

type referenceConfig = {
    name: string,
    active: boolean;
    averagekV: number;
    averagemA: number;
    averageNoise: number;
    choosenTests: Array<any>
}

type State = {
    visible: boolean;
    name: string,
    historyVisible: boolean;
    points: Array<Point> | [];
    draw: boolean;
    circle: Circle;
    canChoosePoints: boolean;
    isPointsButtonActive: boolean;
    kVDeviation: number;
    mADeviation: number;
    newReference: referenceConfig;
    dicomState: any;
    loading: boolean;
    pixelSpacing: string | null;
    mode: 'REFERENCE' | 'CONFIGURATION';
    results: Array<any>;
    choosenTestsForReference: Array<any>;
    configurations: Array<any>;
    mainPanelKey: any;
    secondaryPanelKey: any;
    resetDrawings: boolean;
};

type Props = {
    id: string;
    router: IRouter;
    thumbnails: React.ReactNode,
    dicom: any,
    loadingDicom: boolean;
    selectedDicomId: string | any,
    selectedConfigId: string | null,
    fetchThumbnails: () => void,
    handleChangeConfigId: (id: string) => void,
    onLoadingDicomFinish: () => void;
    mainCardReloadIcon: React.ReactNode,
};

class NoiseLevelSettings extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            visible: false,
            name: moment().format("YYYY-MM-DD HH:mm"),
            historyVisible: false,
            draw: false,
            canChoosePoints: false,
            isPointsButtonActive: false,
            loading: false,
            points: [],
            circle: { center: { x: 0, y: 0 }, r: 0 },
            newReference: {
                name: moment().format("YYYY-MM-DD HH:mm"),
                active: true,
                averagekV: 0,
                averagemA: 0,
                averageNoise: 0,
                choosenTests: []
            },
            kVDeviation: 10,
            mADeviation: 10,
            dicomState: null,
            pixelSpacing: null,
            mode: 'CONFIGURATION',
            results: [],
            choosenTestsForReference: [],
            configurations: [],
            mainPanelKey: '',
            secondaryPanelKey: '',
            resetDrawings: false,
        };
    }

    componentDidMount() {
        this.fetchLastTestResults();
        this.fetchConfigurations();
    }

    componentDidUpdate(prevProps: Props) {
        if (!this.props.loadingDicom && prevProps.loadingDicom) {
            this.setState({
                draw: false,
                points: [],
                circle: { center: { x: 0, y: 0 }, r: 0 },
                kVDeviation: 10, 
                mADeviation: 10
            }, () => this.handleDeleteDrawings())
        }
    }

    render() {
        const {
            points,
            draw,
            circle,
            loading,
            mode,
            configurations,
            mainPanelKey,
            resetDrawings
        } = this.state;

        return (
            <Spin spinning={loading} tip="Trwa zapisywanie konfiguracji">
                <Row gutter={[24, 24]}>
                    <Col span={8}>
                        <StyledCollapse defaultActiveKey={[]} >
                            <StyledPanel header="Konfiguracja" key="MAINPANEL">
                                <StyledCollapse accordion activeKey={mainPanelKey} onChange={(key) => this.handleChangeMainPanelKeys(key)}>
                                    <StyledPanel header="Nowa Konfiguracja" key="CONFIGURATION">
                                        <Title> Parametry wejściowe </Title>
                                        <NewTestConfigurationCard
                                            children={this.returnTestConfigurationCardContent(this.state)}
                                            testName={"Poziom Szumu"}
                                            configName={this.state.name}
                                            onNameChange={(value: string) => this.setState({ name: value })}
                                            onFinish={() => this.saveParams()}
                                        />
                                    </StyledPanel>
                                    {configurations.map(config => {
                                        const configuration = this.configurationFromJson(config)
                                        return (this.returnConfigurationContent(configuration))
                                    })}
                                </ StyledCollapse>
                            </StyledPanel>
                        </StyledCollapse>
                    </Col>
                    <Col
                        style={{ display: mode === 'CONFIGURATION' ? "block" : "none" }}
                        span={11}
                    >
                        <MainCard title="Obrazy w teście">
                            <DwvComponent
                                {...this.props}
                                dicom={this.props.dicom}
                                onCanvasClick={this.addPoint}
                                draw={draw}
                                handleDrag={this.handleDrag}
                                circle={circle}
                                points={points}
                                updateParams={this.handleParamsChange}
                                handleDicomLoading={this.handleDicomLoading}
                                handleDicomChange={this.handleDicomChange}
                                handleSave={this.handleSave}
                                displayMoveButton={true}
                                noiseLevel={true}
                                reset={resetDrawings}
                            />
                        </MainCard>
                    </Col>
                    <Col span={5} order={3} style={{ display: mode === 'CONFIGURATION' ? "block" : "none" }}>
                        <MainCard title="Ostatnio dodane" icon={this.props.mainCardReloadIcon}>
                            <SideBar>
                                {this.props.thumbnails}
                            </SideBar>
                        </MainCard>
                    </Col>
                </Row>
            </Spin>
        );
    }

    returnTestConfigurationCardContent = ({ isPointsButtonActive, points, circle, kVDeviation, mADeviation, pixelSpacing }: State) => {
        const { center, r } = circle;
        return (
            <Row gutter={[16, 8]} justify="space-between">
                <Col span={20}>
                    <CustomInput
                        type="number"
                        color="#D779F2"
                        label="Średnica fantomu"
                        onChange={e => this.handleRadiusChange(e)}
                        value={(2 * handleRadiusToCm(r, pixelSpacing)).toFixed(2)}
                        disabled={r > 0}
                        min={0}
                    />
                </Col>
                <Col span={2}>
                    <Tooltip title='Zaznacz trzy punkty na obwodzie fantomu'>
                        <Button
                            disabled={!isPointsButtonActive || points.length === 3}
                            onClick={() => this.setState({ canChoosePoints: true })}
                            icon={<AimOutlined />}
                            style={{ marginTop: 22, backgroundColor: "#0082D4", color: "#FFFFFF" }}
                        />
                    </Tooltip>
                </Col>
                <Col span={2}>
                    {points.length >= 3 &&
                        <Tooltip title="Narysuj od nowa">
                            <Button 
                                onClick={this.handleDeleteDrawings}
                                icon={<DeleteOutlined />} 
                                style={{ marginTop: 22, backgroundColor: "#FFF", color: "#f5222d" }}
                            />      
                        </Tooltip>
                    }
                </Col>
                <Col span={12}>
                    <CustomInput
                        color="#D779F2"
                        label="Środek okręgu [ x, y ]"
                        disabled={true}
                        value={`[ ${(center.x + (circle.center.offsetX || 0)).toFixed(2)} , ${(center.y + (circle.center.offsetY || 0)).toFixed(2)} ]`}
                    />
                </Col>
                <Col span={12}>
                    <CustomInput
                        color="#D779F2"
                        label="Odchylenie kV"
                        value={kVDeviation}
                        onChange={e => this.setState({ kVDeviation: e })}
                        type="number"
                        min={0}
                    />
                </Col>
                <Col span={12}>
                    <CustomInput
                        color="#D779F2"
                        label="Odchylenie mA"
                        value={mADeviation}
                        onChange={e => this.setState({ mADeviation: e })}
                        type="number"
                        min={0}
                    />
                </Col>
            </Row>
        )
    }

    handleDeleteDrawings = () => {
        this.setState(prevState => ({ resetDrawings: !prevState.resetDrawings, canChoosePoints: true, draw: false, points: [], circle: { center: { x: 0, y: 0 }, r: 0 }}));
    }

    configurationFromJson = (configuration: any): configuration => {
        return { ...configuration, configuration: JSON.parse(configuration.configuration) };
    }

    handleDrag = (newAttrs: any) => {
        this.setState(prevState => {
            const { circle } = prevState;
            return {
                circle: {
                    ...circle,
                    center: {
                        ...circle.center,
                        offsetX: newAttrs.x,
                        offsetY: newAttrs.y
                    }
                }
            }
        })
    }

    returnConfigurationContent = (configuration: configuration) => {
        const { newReference } = this.state;
        
        return (
            <StyledPanel
                header={configuration.configuration.name + (configuration.active ? '(aktywna)' : '')}
                key={configuration.id}
            >
                <Row>
                    Średnica fantomu:
                    {this.calculateDiameterCmBasedOnPixelSpacing(
                    configuration.configuration.circle.r,
                    parseFloat(configuration.configuration.pixelSpacing as string)
                )} cm
                </Row>
                <Row>
                    X: {(configuration.configuration.circle.center.x).toFixed(2)}
                </Row>
                <Row>
                    Y: {(configuration.configuration.circle.center.y).toFixed(2)}
                </Row>
                <Row>
                    Odchylenie mA: {configuration.configuration.mADeviation}
                </Row>
                <Row>
                    Odchylenie kV: {configuration.configuration.kVDeviation}
                </Row>

                <StyledCollapse activeKey={this.state.secondaryPanelKey} onChange={key => this.handleChangeSecondaryPanelKeys(key)}>
                    {configuration.active && 
                        <StyledPanel header="Dodaj wartość odniesienia" key="REFERENCES">
                            <Row>
                                Nazwa:
                                <Input value={newReference.name} onChange={(e) => this.onChangeReferenceName(e.target.value)}/>
                            </Row>
                            <Row>
                                Odch.Stand.: {this.getAverageValue('StdDev')}
                            </Row>
                            <Row>
                                kV: {this.getAverageValue('kV')}
                            </Row>
                            <Row>
                                mA: {this.getAverageValue('mA')}
                            </Row>
                            <Row style={{marginTop: 15}}>
                                Wybierz pomiary
                            </Row>
                            {this.state.results
                                .filter(config => config.result.testConfigurationId === this.props.selectedConfigId)
                                .map(config => {
                                    const dataSource = JSON.parse(config.result.testData).testData;
                                    const StdDev = dataSource.find((ds: any) => ds.key === "1");

                                    const reference = {
                                        id: config.result.id,
                                        StdDev: StdDev && StdDev.measurement,
                                        kV: JSON.parse(config.result.testData).values.kV,
                                        mA: JSON.parse(config.result.testData).values.mA,
                                        date: config.result.dateOfTest
                                    }

                                    return (
                                        <Row style={{marginBottom: 8}}>
                                            <CCheckbox
                                                key={config.result.id}
                                                onChange={e => this.handleSelectedReferenceChange(e.target.checked, reference)}
                                                checked={this.state.choosenTestsForReference.map(r => r.id).indexOf(config.result.id) >= 0}
                                            >
                                                <Row>Pomiar {moment(config.result.dateOfTest).format("YYYY-MM-DD HH:mm")}</Row>
                                                <Row>
                                                    Odch.Stand. {reference.StdDev},
                                                    kV {reference.kV},
                                                    mAs {reference.mA},
                                                </Row>
                                            </CCheckbox>
                                        </Row>
                                    );
                                })}
                            <Button onClick={this.handleSaveNewReference}> Zapisz wartość odniesienia</Button>
                        </StyledPanel>
                    }
                    {this.configurationHaveReferences(configuration.configuration)
                        ?
                        this.returnConfigurationReferences(configuration.configuration.references)
                        : null
                    }
                </StyledCollapse>
            </StyledPanel>
        )
    }

    onChangeReferenceName = (name: string) => {
        this.setState(prevState => ({
            newReference: {
                ...prevState.newReference,
                name: name
            }
        }));
    }

    returnConfigurationReferences = (references: Array<referenceConfig>) => {
        const ref = references.map((reference: referenceConfig, index: number) => {
            let referenceName = reference.name;
            if (reference.active) { 
                referenceName += ' (aktywna)'; 
            }

            return (
                <StyledPanel header={referenceName} key={index}>
                    <Row>kV {reference.averagekV}</Row>
                    <Row>mA {reference.averagemA} </Row>
                    <Row>Poziom szumu {reference.averageNoise}</Row>
                    {reference.choosenTests && reference.choosenTests.map((test: any, index: number) => (
                        <Col span={24} style={{ textAlign: 'left', marginTop: 8 }}>
                            {index + 1}.
                            Pomiar {moment(test.date).format("YYYY-MM-DD HH:mm")},
                            Odch.Stand. {test.StdDev},
                            kV {test.kV},
                            mA {test.mA}
                        </Col>
                    ))}
                    {reference.active
                        ? <p>Aktywna</p>
                        : <Button onClick={() => this.activateReference(reference)}>Aktywuj</Button>}
                </StyledPanel>
            )
        })
        return ref
    }

    openModal = () => {
        this.setState({ visible: true });
    };

    configurationHaveReferences = (configuration: configuration["configuration"]) => {
        return configuration.references.length >= 1
    }

    handleSelectedReferenceChange = (checked: Boolean, reference: { id: string, StdDev: number, kV: number, mA: number, date: Date }) => {
        this.setState(prevState => ({
            choosenTestsForReference: checked ? [...prevState.choosenTestsForReference, reference] : prevState.choosenTestsForReference.filter(r => r.id !== reference.id),
        }), () => this.setState(prevState => ({
            newReference: {
                ...prevState.newReference,
                averagemA: parseFloat(this.getAverageValue('mA') || '0'),
                averagekV: parseFloat(this.getAverageValue('kV') || '0'),
                averageNoise: parseFloat(this.getAverageValue('StdDev') || '0'),
                choosenTests: prevState.choosenTestsForReference
            },
        })))
    }

    fetchLastTestResults = () => {
        const { testId } = this.props.router.match.params;

        api
            .get(`test-results/test-case/${testId}`)
            .then(res => res.data)
            .then(res => this.setState({ results: res.results }))
    }

    fetchConfigurations = () => {
        const { testId } = this.props.router.match.params;
        api
            .get(`test-configurations/test-case/${testId}`)
            .then(res => res.data)
            .then(res => this.setState({ configurations: res.configurations }));

    }

    activateReference = (reference: referenceConfig) => {
        const currentConf = this.getCurrentConfigurationParams();
        const params = JSON.parse(currentConf.configuration as any);

        const previousActive = params.references.find((ref: referenceConfig) => ref.active == true);
        const currentlyActive = params.references.find((ref: referenceConfig) =>
            ref.averageNoise === reference.averageNoise &&
            ref.averagekV === reference.averagekV &&
            ref.averagemA === reference.averagemA &&
            reference.active === false
        )
        if (previousActive) {
            previousActive.active = false;
        }
        currentlyActive.active = true;

        this.setState({ loading: true, newReference: currentlyActive }, () => {
            const updatedConf = {
                Content: JSON.stringify({
                    ...params
                })
            }
            this.handleSaveChanges(currentConf, updatedConf);
        });
    }

    handleDicomLoading = (state: boolean) => {
        this.setState({ isPointsButtonActive: state });
    };

    getAverageValue = (param: 'StdDev' | 'kV' | 'mA') => {
        const { choosenTestsForReference } = this.state;
        const average = choosenTestsForReference.reduce((a, b) => a + parseFloat(b[param]), 0) / choosenTestsForReference.length;
        return average ? average.toFixed(2) : 0
    }

    handleChangeMainPanelKeys = (keys: Array<string> | string) => {
        this.setState({ mainPanelKey: keys });
        if (_.isString(keys)) {
            return this.handleKeyAsString(keys as string);
        }

        this.handleKeyAsArray(keys as Array<string>);
    }

    handleChangeSecondaryPanelKeys = (keys: Array<string> | string) => {
        this.setState({ secondaryPanelKey: keys });

        if (_.isString(keys)) {
            return this.handleKeyAsString(keys as string);
        }

        this.handleKeyAsArray(keys as Array<string>);
    }

    handleKeyAsString = (key: string) => {
        if (key === "CONFIGURATION") {
            const text = NEW_CONFIG_INFO;
            if (this.state.configurations.length > 0) {
                this.showPromptModal((p) => { }, '', text, () => { this.setState({ mainPanelKey: this.props.selectedConfigId || '' }) })
            }
            
            this.setState({ mode: "CONFIGURATION" });
        }

        // if (this.isReferencePanelSelected(key)) {
        //     this.setState({ mode: "REFERENCE" });
        // }

        if (this.isConfigurationPanelSelected(key)) {
            this.props.handleChangeConfigId(key);
        }
    }

    handleKeyAsArray = (key: Array<string>) => {
        if (key && key.length >= 1) {
            this.handleKeyAsString(key[0]);
        }
    }

    isConfigurationPanelSelected = (key: string) => {
        return key && key.length > 30;
    }

    isReferencePanelSelected = (key: string) => {
        return !isNaN(+key) || key === "REFERENCES";
    }

    closeModal = () => {
        this.setState({ visible: false });
    };

    handleHistoryResults = (state: boolean) => {
        this.setState({ historyVisible: state });
    };

    handleRadiusChange = (value: number) => {
        this.setState(prevState => {
            const newCircle: Circle = {
                center: prevState.circle.center,
                r: value,
            };
            return { circle: newCircle };
        });
    };

    handleDicomChange = () => { };

    addPoint = (a: number, b: number) => {
        const { points, canChoosePoints } = this.state;

        if (points.length < 3 && canChoosePoints) {
            const point: Point = { x: a, y: b };
            this.setState({ points: [...points, point] }, () => {
                if (this.state.points.length === 3) {
                    const circle: Circle = getCircleThrough3Point(
                        this.state.points[0],
                        this.state.points[1],
                        this.state.points[2]
                    );
                    this.setState({
                        draw: true,
                        circle: circle,
                        canChoosePoints: false,
                    });
                }
            });
        }
    };

    calculateDiameterCmBasedOnPixelSpacing = (r: number, pixelSpacing: number) => {
        return (2 * r * pixelSpacing / 10).toFixed(2);
    }

    saveParams = () => {
        const { circle, points, dicomState, choosenTestsForReference } = this.state;

        if (circle.r <= 0) {
            message.warning(
                `Nie utworzono okręgu, ilość zaznaczonych punktów ${points.length}`
            );
            return;
        }

        if (choosenTestsForReference.length < 5) {
            const text = 'Wykonaj teraz 5 pomiarów, które utworzą wartości odniesienia. Po wykonaniu tych pomiarów, wróć do tej konfiguracji i uzupełnij wartości odniesienia.';
            this.showPromptModal(this.handleSave, dicomState, text);
        } else {
            this.handleSave(dicomState);
        }
    };

    showPromptModal = (handleOkCallback: (p: any) => void, param: any, text: string, handleCancelCallback?: () => void) => {
        Modal.confirm({
            content: text,
            okText: 'TAK',
            cancelText: 'NIE',
            onOk() {
                handleOkCallback(param);
            },
            onCancel() {
                if (handleCancelCallback) {
                    handleCancelCallback();
                }
            }
        })
    }

    handleSave = (state: any) => {
        const { circle, pixelSpacing, name, kVDeviation, mADeviation } = this.state;

        const { id } = this.props;

        this.setState({ dicomState: state, loading: true });

        const resultCircle: Circle = {
            r: circle.r,
            center: {
                x: circle.center.x + (circle.center.offsetX || 0),
                y: circle.center.y + (circle.center.offsetY || 0),
            }
        } 

        const config: configuration["configuration"] = {
            name: name,
            circle: resultCircle,
            state: state,
            pixelSpacing: pixelSpacing && pixelSpacing.split("\\")[0],
            kVDeviation: kVDeviation,
            mADeviation: mADeviation,
            references: []
        };

        api.post(`test-configurations/test-case/${id}`, { Content: JSON.stringify(config) })
            .then(() => {
                message.success("Utworzono nową konfigurację");
                this.fetchConfigurations();
            })
            .then(() => this.clearState())
            .catch(err => message.warning("Nie udało się utworzyć konfiguracji"))
            .finally(() => this.setState({ loading: false }))
    };

    handleRecalculateChoosenTestsResults = async () => {
        const { results, newReference } = this.state;
        const configuration = this.getCurrentConfigurationParams();
        const parsedConfiguration = JSON.parse(`${configuration.configuration}`);
        const testPerformedOnCurrentConfigId = results.filter(this.findSelectedTests);

        for (let test of testPerformedOnCurrentConfigId) {
            await this.changeTestDataAndSaveToDatabase(test, newReference, parsedConfiguration);
        }
    }

    clearState = () => {
        this.setState({
            newReference: {
                name: moment().format("YYYY-MM-DD HH:mm"),
                active: true,
                averagekV: 0,
                averagemA: 0,
                averageNoise: 0,
                choosenTests: []
            },
            kVDeviation: 0,
            mADeviation: 0,
            results: [],
            choosenTestsForReference: [],
            points: [],
            circle: { center: { x: 0, y: 0 }, r: 0 },
            name: moment().format("YYYY-MM-DD HH:mm"),
        })
    }

    changeTestDataAndSaveToDatabase = async (test: any, newReference: referenceConfig, currentConfiguration: configuration["configuration"]) => {
        const newTestData = []
        const testData = JSON.parse(test.result.testData).testData;
        const params = JSON.parse(test.result.testData).values;

        const oldStdDevData = testData.find((config: any) => config.key === "1");

        const StdDevDeviation = ((newReference.averageNoise / +oldStdDevData.measurement) - 1) * 100
        const resultStdDev = Math.abs(StdDevDeviation) < 15;

        const StdDevData = {
            key: "1",
            name: "Wartość Odch.Stand.",
            measurement: oldStdDevData.measurement,
            reference: newReference.averageNoise,
            deviation: `${StdDevDeviation.toFixed(2)}%`,
            result: resultStdDev,
        };

        newTestData.push(StdDevData);

        const kv_deviation = (parseFloat(`${newReference.averagekV}`) / params.kV - 1) * 100;
        const kv_result = Math.abs(kv_deviation) < currentConfiguration.kVDeviation;

        const kVData = {
            key: "2",
            name: "kV",
            measurement: params.kV,
            reference: newReference.averagekV,
            deviation: `${kv_deviation.toFixed(2)}%`,
            result: kv_result,
        };
        newTestData.push(kVData);

        const mA_deviation = ((newReference.averagemA / params.mA) - 1) * 100;

        const mA_result = Math.abs(mA_deviation) < currentConfiguration.mADeviation;
        const mAData = {
            key: "3",
            name: "mA",
            measurement: params.mA,
            reference: newReference.averagemA,
            deviation: `${mA_deviation.toFixed(2)}%`,
            result: mA_result,
        };
        newTestData.push(mAData);


        const result = {
            key: "4",
            name: "Wynik testu",
            result: resultStdDev && mA_result && kv_result
        };

        newTestData.push(result);

        const patchTestResultContent = {
            values: params,
            testData: newTestData
        }

        const requestBody = {
            Passed: resultStdDev && mA_result && kv_result,
            TestData: JSON.stringify(patchTestResultContent),
            Deactivated: test.result.deactivated
        }

        await api
            .patch(`test-results/${test.result.id}`, { ...requestBody })
            .catch(err => console.error(err));

    }

    findSelectedTests = (config: any) => {
        const { selectedConfigId } = this.props;
        const { choosenTestsForReference } = this.state;
        return config.result.testConfigurationId === selectedConfigId && choosenTestsForReference.find(test => test.id === config.result.id)
    }

    handleSaveNewReference = () => {
        this.setState({ loading: true });
        const currentConf = this.getCurrentConfigurationParams();
        const { newReference } = this.state;
        const params = JSON.parse(currentConf.configuration as any);
        if (_.isArray(params.references)) {
            const activeRef = params.references.find((ref: referenceConfig) => ref.active == true);
            if (activeRef) {
                activeRef.active = false;
            }
            params.references.push(newReference);
        }

        const updatedConf = {
            Content: JSON.stringify({
                ...params
            })
        }

        this.handleSaveChanges(currentConf, updatedConf);
    }

    handleSaveChanges = (currentConf: any, updatedConf: any) => {
        api
            .patch(`/test-cases/test-configurations/${currentConf.id}`, updatedConf)
            .then(res => res.data)
            .then(() => this.fetchConfigurations())
            .then(() => this.handleRecalculateChoosenTestsResults())
            .then(() => this.clearState())
            .catch(err => console.error(err))
            .finally(() => this.setState({ loading: false }));
    }

    getCurrentConfigurationParams = (): configuration => {
        const { selectedConfigId } = this.props;
        const { configurations } = this.state;

        return configurations.find(conf => conf.id === selectedConfigId);
    }

    handleParamsChange = (label: ParamType, value: number) => {
        if (label !== "pixelSpacing")
            return

        this.setState({ pixelSpacing: `${value}` });
    }
}

const Title = styled.h4`
    font-size: 16px;
`;

const StateButton = styled(Button)`
    background: ${(props: any) => {
        return props.disabled ? "" : "#1f3853";
    }};
    &:active,
    &:hover,
    &::after,
    &:focus {
        background: ${(props: any) => {
        return props.disabled ? "" : "#1f3853";
    }};
    }
    margin-top: 20px;
    color: #ffffff;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    justify-content: center;
    width: 100%;
    border: none;
    margin-bottom: 15px;
`;

const SideBar = styled.div`
    overflow: auto;
    height: 80vh;
`

const StyledCollapse = styled(Collapse)`
    background-color: rgb(31, 56, 83);
    color: #ffffff;
    text-align: center;
    .ant-collapse-item {
        .ant-collapse-header {
            color: #ffffff;
        }
    }
`;

const StyledPanel = styled(Panel)`
    .anticon.anticon-right.ant-collapse-arrow {
        color: #ffffff;
    }
`;

const CCheckbox = styled(Checkbox)`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 0px !important ;
`;
export default NoiseLevelSettings;
