import React, { Component } from "react";
import { Row, Col, Card, message, Statistic  } from "antd";
import { PlusSquareOutlined } from "@ant-design/icons"
import { MainCard, CenteredButton, ProcedureDescriptionModal, CustomInput, ActionButton, RedCrossIcon, GreenCheckIcon, CustomTable } from "../common";
import { HighContrastConfiguration, HighContrastTestResult } from "../../utils/Models"
import DwvComponent from '../common/Dwv/DwvComponent';
import { api } from '../../api';
import { IRouter } from '../../utils/Interfaces';
import styled from "styled-components";
import _ from "lodash";

type configObject = {
    id: string,
    active: boolean,
    params: any,
    testCaseId: string,
}

type State = {
    dicom: any,
    visible: boolean,
    configuration: HighContrastConfiguration,
    dwvState: any,
    newWL: any,
    drawings: any,
    selectedTool: any,
    result: number,
    kV: number,
    mA: number,
    activeConfigurationId: any,
    configurations: Array<any>,
    performTest: boolean,
    draw: boolean
}

type Props = {
    router: IRouter,
    thumbnails: React.ReactNode,
    dicom: any,
    selectedDicomId: string | any,
    mainCardReloadIcon: React.ReactNode,
    fetchThumbnails: () => void,
    onLoadingDicomFinish: () => void,
    onSavingTestStart: () => void,
    onSavingTestFinish: () => void,
    loadingDicom: boolean
}

type Tags = {
    KVP: number, 
    MA: number
}

export default class HighResolutionContrastTest extends Component<Props, State> {
    constructor(props: any) {
        super(props)

        this.state = {
            dicom: null,
            visible: false,
            configuration: { 
                name: "default",
                radius: 10, 
                clickedPosition: { x: 0, y: 0 }, 
                deviationSettings: {
                    mAs: 10,
                    kV: 10,
                    MTF50: 10,
                },
                references: [],
            },
            dwvState: {},
            newWL: {},
            drawings: [],
            selectedTool: null,
            kV: 0,
            mA: 0,
            result: 0,
            activeConfigurationId: null,
            configurations: [],
            performTest: false,
            draw: false,
        }
    }
    
    componentDidMount() {
        this.fetchConfigurations();
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        if (this.state.dwvState && prevProps.loadingDicom === true && this.props.loadingDicom === false) {
            this.setState({ draw: !prevState.draw });
            this.togglePerformTest()
        }
    }

    render() {
        const { visible, activeConfigurationId, configuration, performTest, result, kV, mA } = this.state;

        return (
            <>
                <Row justify='space-between'>
                    <Col>
                        <PerformExaminationDetailsCard
                            type="inner"
                            title={
                                <>
                                    <span style={{fontSize: "18px"}}>Rozdzielczość wysokokontrastowa</span>
                                    <CenteredButton  onClick={() => this.saveTestResult()} label="Zachowaj wynik testu" />
                                    <CenteredButton onClick={() => this.openModal()} label="Opis procedury" />
                                </>}>
                                <Title> Parametry wejściowe </Title>
                                {activeConfigurationId
                                    ?
                                    <Col> 
                                        <CustomStatistic title={"Promień okręgu ROI (mm)"} value={configuration.radius} />
                                        <CustomStatistic title={"Środek ROI [x, y]"} value={`${configuration.clickedPosition.x}, ${configuration.clickedPosition.y}`} />
                                        <CustomStatistic title={"Odchylenie kV (%)"} value={+configuration.deviationSettings.kV} />
                                        <CustomStatistic title={"Odchylenie mA (%)"} value={+configuration.deviationSettings.mAs} />
                                        <CustomStatistic title={"Odchylenie MTF50 (%)"} value={+configuration.deviationSettings.MTF50} />

                                    </Col>
                                    
                                    : 
                                    <Col>
                                        {this.generateInputs()}
                                    </Col>
                                }
                        </PerformExaminationDetailsCard>
                        <MainCard title="Wyniki pomiaru">
                            <CustomTable columns={this.resultColumns} data={this.generateTestResult(configuration, kV, mA, result)} />
                        </MainCard> 
                    </Col>
                    <Col xxl={{span: 12}} xl={{span: 11}} lg={{span: 10}}>
                        <MainCard title="Obrazy w teście">
                            <DwvComponent
                                {...this.state}
                                {...this.props}
                                onStateChange={this.changeDwvState}
                                addNewShape={this.addNewShapeToList}
                                deleteShape={this.deleteShapeFromList}
                                setResultOfTest={this.setResultOfTest}
                                highContrast
                                performTest={performTest}
                                updateParams={this.updateParams}
                            />
                        </MainCard>
                    </Col>
                    <Col span={4}>
                        <MainCard title="Ostatnio dodane" icon={this.props.mainCardReloadIcon}>
                            <SideBar>
                                {this.props.thumbnails}
                            </SideBar>
                        </MainCard>
                    </Col>
                </Row>
                <ProcedureDescriptionModal visible={visible} title="Opis procedury" steps={steps} closeModal={this.closeModal} />
            </>
        );
    }

    fetchConfigurations = () => {
        const { testId } = this.props.router.match.params;
        api
            .get(`test-configurations/active/test-case/${testId}`)
            .then(res => res.data)
            .then(obj => { 
                if(_.isEmpty(obj.configurations) || obj.configurations === null){
                    setTimeout(() =>  { 
                        return this.props.router.history.push(
                            this.props.router.location.pathname.replace(/[^/]*$/, 'settings'));
                        }
                        , 1000)
                    }
                    const parsedConfig: HighContrastConfiguration = JSON.parse(obj.configurations.configuration); 
                    this.setState({ 
                        activeConfigurationId: obj.configurations.id, 
                        configuration: {
                            ...parsedConfig
                        }
                    });
            })
            .catch(err => console.error(err))
    }
    
    handleConfigurationLoad = (configObj: any) => {
        const configurations = configObj.configurations;
        const activeConfigurationId = configurations.length >= 1 ? configurations.find((conf: configObject) => conf.active === true).id : null;
        this.setState({
            configurations: configurations,
            activeConfigurationId: activeConfigurationId
        })
        return activeConfigurationId;
    }

    getActiveConfigurationParams = (id: string) => {
        const { configurations } = this.state;
        const activeConfigurationParams = JSON.parse(configurations.find(config => config.id === id).configuration);
        this.setState({ activeConfigurationId: id, 
                        configuration: { ...activeConfigurationParams }
        });
    }

    handleChangeToConfigurationView = () => {
        const { testId } = this.props.router.match.params;
        this.props.router.history.push(`/test-case/${testId}/settings`);
    }
    
    openModal = () => { this.setState({ visible: true }) }

    closeModal = () => { this.setState({ visible: false }) }

    changeDwvState = (newState: any) => {
        this.setState({
            dwvState: newState
        })
    }

    addNewShapeToList = (id: string) => {
        const { dwvState } = this.state;        
        let newShapeData: any = null;
        dwvState.drawings && dwvState.drawings.children.forEach((el: any) => {
            newShapeData = el.children.find((child: any) => child.attrs.id === id);
        })

        if (newShapeData) {
            const { x, y, radiusX, radiusY } = newShapeData.children[0].attrs;
            const newShape = {
                id: id,
                WLid: this.state.newWL.id,
                x: x,
                y: y,
                radiusX: radiusX,
                radiusY: radiusY
            };

            this.setState(prevState => {
                if (prevState.drawings.indexOf(newShape) === -1) {
                    prevState.drawings.push(newShape);
                }
                return {
                    ...prevState,
                    selectedTool: 'Scroll'
                }
            })
        }
    }

    deleteShapeFromList = (id: string) => {
        this.setState(prevState => {
            return {
                ...prevState,
                drawings: prevState.drawings.filter((d: any) => d.id !== id)
            }
        })    
    }

    saveTestResult = () => {
        const { dwvState, drawings, configuration, activeConfigurationId, result, kV, mA } = this.state;

        const isDuable = this.checkForNecessaryTestParams();

        if (!isDuable)
            return ;

        this.props.onSavingTestStart()

        const testResult: HighContrastTestResult = {
            dwvState: dwvState,
            drawings: drawings,
            configuration: configuration,
            MTF50: +result,
            kV: kV,
            mA: mA,
        };

        const body = {
            Passed: this.generateTestResult(configuration, kV, mA, result).find((el: any) => el.final).result,
            Deactivated: true,
            TestData: JSON.stringify(testResult),
            TestConfigurationId: activeConfigurationId,
            DicomId: this.props.selectedDicomId
        }
        const { testId } = this.props.router.match.params;

        api
            .post(`test-results/test-case/${testId}`, { ...body })
            .then(() => setTimeout(() => this.props.onSavingTestFinish(), 2000))
            .catch(err => console.error(err));   
        
    }

    checkForNecessaryTestParams = ():boolean => {
        const { kV, mA, dwvState } = this.state;
        
        if(!dwvState.version) {
            message.warning("Nie załadowano pliku Dicom.");
            return false
        }

        if(!kV || kV == 0 || !mA || mA == 0) {
            message.warning("Należy podać parametry mA i kV.");
            return false
        }

        return true;
    }

    generateInputs = () => {
        const { configuration } = this.state;
        
        return (
            <>
                <Col span={24}>
                    <CustomInput
                        color="#D779F2"
                        label='Promień okręgu ROI (mm)'
                        type='number'
                        step={1}
                        min={1}
                        value={configuration.radius}
                        disabled={true}
                    />
                </Col>
            </>
        )
    }

    setParamsFromTagsHandler = (tags: Tags) => {
        if(tags) {
            this.setState({
                mA: tags.MA,
                kV: tags.KVP
            });
        }
    }

    updateParams = (tag: "kV"| "mA", value: number) => {
       if (tag === "kV"){
           return this.setState({ kV: value });
       } 

       if (tag === 'mA') {
           return this.setState({ mA: value });
       }    
    }

    handleKVchange = (e: number) => {
        this.setState({
            kV: e
        })
    }

    handleMAchange = (e: number) => {
        this.setState({
            mA: e
        })
    }

    setResultOfTest = (result: number) => {
        this.setState({ result: result })
    }

    togglePerformTest = () => {
        if(this.checkForNecessaryTestParams()) {
            this.setState(prevState => ({ performTest: !prevState.performTest }));
        }
    }

    clearResultData = () => {
        this.setState({result: 0})
    }

    generateTestResult = (configuration: HighContrastConfiguration, kV: number, mA: number, MTF50: number) => {
        const reference = configuration.references && configuration.references.find(el => el.active);
        const kVDeviation = reference && kV !== 0 ? (reference.kV / kV - 1) * 100 : 0;
        const mADeviation = reference && mA !== 0 ? (reference.mA / mA - 1) * 100 : 0;
        const MTFDeviation = reference && MTF50 !== 0 ? (reference.MTF50 / MTF50 - 1) * 100 : 0;
        
        const testResult: any = [
            { 
                name: 'kV', 
                measurement: kV, 
                reference: reference ? reference.kV : '--', 
                deviation: Math.round(kVDeviation * 100) / 100, 
                get result() { return reference ? Math.abs(this.deviation) < configuration.deviationSettings.kV : true } 
            },
            { 
                name: 'mA', 
                measurement: mA, 
                reference: reference ? reference.mA : '--', 
                deviation: Math.round(mADeviation * 100) / 100,
                get result() { return reference ? Math.abs(this.deviation) < configuration.deviationSettings.mAs : true } 
            },
            { 
                name: 'MTF50', 
                measurement: MTF50, 
                reference: reference ? reference.MTF50 : '--', 
                deviation: Math.round(MTFDeviation * 100) / 100, 
                get result() { return reference ? Math.abs(this.deviation) < configuration.deviationSettings.MTF50 : true } 
            },
        ];

        const finalResult = {
            name: 'Wynik testu',
            final: true, 
            result: testResult.findIndex((el: any) => el.result === false) === -1
        };

        testResult.push(finalResult);
        return testResult;
    }

    resultColumns = [
        {
            title: "Nazwa",
            dataIndex: "name",
            key: "name"
        },
        {
            title: "Pomiar",
            dataIndex: "measurement",
            key: "measurement"
        },
        {
            title: "Odniesienie",
            dataIndex: "reference",
            key: "reference"
        },  
        {
            title: "Odchylenie %",
            dataIndex: "deviation",
            key: "deviation"
        },  
        {
            title: "Poprawny",
            key: "result",
            dataIndex: "result",
            render: (result: boolean) => result ? <GreenCheckIcon /> : <RedCrossIcon />
        },
    ]
}

const steps = [
   " Brak algorytmu.  W oknie konfiguracji definiujemy promień koła. Wykonanie testu polega na wskazanie środka koła na wybranym jednym obrazie. Na podstawie informacji o środku (wskazanie) i promieniu (konfiguracja) system rysuje koło i w jego środku coś liczy z pixeli które znajdują się wewnątrz. "
]

const PerformExaminationDetailsCard = styled(Card)`
    .ant-card-head-title {
        font-size: 24px;
        font-weight: 600;
        color: #505458;
        padding-top: 25px;
        border: none;
    }
`;

const SideBar = styled.div`
    overflow: auto;
    height: 80vh;
`

const Title = styled.h4`
    font-size: 16px;
`
const CustomStatistic = styled(Statistic)`
    .ant-statistic-content {
        font-size: 16px;
    }
`
