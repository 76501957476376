import React from "react";
import styled from 'styled-components';
import { Row, Modal, Menu, Dropdown } from "antd";
import { SettingOutlined } from '@ant-design/icons';
import _ from 'lodash';
import dwv from "dwv";
import TagsTable from "./TagsTable";
import "./DwvComponent.css";
import {
    getPixelsInsideCircle,
    getPixelsInsideCircleOnSurface,
    transformToGrayScale,
} from "../../../utils/highContrastTestMath";
import { getCircleThrough3Point } from '../../../utils/mathHelpers';

dwv.gui.getElement = dwv.gui.base.getElement;
dwv.image.decoderScripts = {
    jpeg2000: "assets/dwv/decoders/pdfjs/decode-jpeg2000.js",
    "jpeg-lossless": "assets/dwv/decoders/rii-mango/decode-jpegloss.js",
    "jpeg-baseline": "assets/dwv/decoders/pdfjs/decode-jpegbaseline.js",
    rle: "assets/dwv/decoders/dwv/decode-rle.js",
};
dwv.gui.prompt = (str1, str2) => null;

const DROPBOX_CLASS_NAME = 'dropBox';
const BORDER_CLASS_NAME = 'dropBoxBorder';
const HOVER_CLASS_NAME = 'hover';

class DwvComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            tools: {
                Scroll: {},
                ZoomAndPan: {},
                WindowLevel: {},
                Draw: {
                    options: ["Ellipse"],
                    type: "factory",
                    events: ['draw-create', 'draw-change', 'draw-move', 'draw-delete']
                },
            },
            selectedTool: "",
            dataLoaded: false,
            dwvApp: null,
            metaData: [],
            showDicomTags: false,
            frame: 0,
            clickedArea: { x: undefined, y: undefined },
            mmFactor: { row: undefined, col: undefined },
            pixelSpacing: null
        };
    }

    componentDidMount() { 
        this.initializeDwv(); 
    }

    componentWillUnmount() {
        const { dwvApp } = this.state;
        this.props.saveState(dwvApp)
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.dicom !== prevProps.dicom && this.props.dicom !== null) {
            const arr = [this.props.dicom];
            this.state.dwvApp.loadFiles(arr);
        }

        if (this.props.draw && this.props.draw !== prevProps.draw && !this.props.highContrast) {
            this.drawStateCircle();
        }

        if (this.props.drawCircles) { 
            this.drawStateCircles(); 
        }

        if (this.props.reset && !this.props.highContrast && !this.props.uniformity && !this.props.waterPhantom && !this.props.noiseLevel) { 
            this.removeDrawings(); 
        }

        if (this.props.selectedTool !== prevProps.selectedTool) {
            this.onChangeTool(this.props.selectedTool);
        }

        if (this.props.points !== prevProps.points && !_.isEmpty(this.props.points)) {
            const { dwvApp } = this.state;
            dwvApp.deleteDraws();

            if (this.props.points.length <= 3) {
                this.props.points.forEach(point => {
                    this.drawCircle(point, 1.5);
                })
            }
        }

        
        if (!_.isEqual(this.props.newWL, prevProps.newWL) && this.props.newWL) {
            const { newWL } = this.props;
            const { dwvApp } = this.state;
            
            if (dwvApp.getViewController()) {
                dwvApp.getViewController().setWindowLevel(newWL.level, newWL.width);
                this.onGroupVisibility();
            }
        }

        if (this.shouldDrawCircles(this.props, prevProps, this.state, prevState)) {
            this.state.dwvApp.deleteDraws();
            this.drawCircles(this.state, this.props);
        }

        if (this.props.performHu) { 
            this.performHu(); 
        }

        if (this.props.countSnr) { 
            this.performSnr(); 
        }

        if (this.props.countStdDev) { 
            this.performStdDev(); 
        }

        if (this.props.highContrast && this.props.performTest !== prevProps.performTest) {
            try {
                const MTF50 = this.performHCTest();
                this.props.setResultOfTest(MTF50.toFixed(3));
            }
            catch (err) {
                alert(err);
            }
        }

        if ((this.props.highContrast || this.props.uniformity || this.props.waterPhantom || this.props.noiseLevel) && this.props.reset !== prevProps.reset) {
            this.removeDrawings();
        }

        if (this.props.highContrast && 
            this.props.configuration &&
            this.props.configuration.clickedPosition && 
            this.props.draw !== prevProps.draw) {
            const radiusInPixels = Math.round(this.props.configuration.radius / this.state.pixelSpacing);
            this.drawCircle(this.props.configuration.clickedPosition, radiusInPixels)
        }

        if (this.props.huDensity && this.props.canChoosePoints) 
            this.setDrawingLayerVisible();
        

        if (this.props.huDensity && !this.props.canChoosePoints)
            this.setTextLayerVisible();

        if (!this.props.huDensity)
            this.setDrawingLayerVisible();
    }

    render() {
        const {
            dataLoaded,
            metaData,
            showDicomTags,
            selectedTool,
        } = this.state;

        const dropdownList = (
            <Menu>
                {dataLoaded &&
                    <Menu.Item key="0">
                        <a onClick={() => this.handleTagsModalVisibility(true)}>Tagi DICOM</a>
                    </Menu.Item>
                }
                {this.props.displayMoveButton && dataLoaded &&
                    <Menu.Item key="1">
                        <a onClick={() => this.turnOnMoveDrawingFeatures()}>Uruchom przesuwanie kształtów</a>
                    </Menu.Item>
                }
                {this.props.canZoom && dataLoaded &&
                    <Menu.Item key="2">
                        <a onClick={() => this.onChangeTool('ZoomAndPan')}>Uruchom scrollowanie</a>
                    </Menu.Item>
                }
                {!dataLoaded && 
                    <Row justify='center' style={{padding: 10}}>Brak dostępnych opcji. Wczytaj plik DICOM.</Row>
                }
            </Menu>
        );

        return (
            <div id='dwv'>
                <Row justify='end'>
                    <Dropdown overlay={dropdownList} trigger={['click']}>
                        <Row justify='end' style={{width: '50%', marginBottom: 30, marginTop: 10}}>
                            <StyledSettingOutlined /> 
                        </Row>
                    </Dropdown>
                </Row>
                {selectedTool === 'Draw' && <Row>Dodawanie / Edycja artefaktu...</Row>}
                <Modal
                    visible={showDicomTags}
                    onCancel={() => this.handleTagsModalVisibility(false)}
                    width={1000}
                    footer={null}
                >
                    <TagsTable data={metaData} />
                </Modal>

                <div className='layerContainer'>
                    <div className='dropBox dropBoxBorder'>
                        {this.props.dropBoxText || "Wybierz obraz z listy po prawej stronie"}
                    </div>
                    <canvas id="textLayer" width={500} height={500}>
                    </canvas>
                    <canvas
                        className='imageLayer'
                        onClick={e => this.onCanvasClick(e)}
                        onMouseMove={e => this.onCanvasMouseMove(e)}
                    >
                        Only for HTML5 compatible browsers...
                    </canvas>
                    <div className='drawDiv'></div>
                </div>
                {this.getFramesNumberOfDicom() && `Frame: ${this.state.frame} / ${this.getFramesNumberOfDicom()}`}
            </div>
        );
    }

    setDrawingLayerVisible = () => document.getElementById('textLayer').style.zIndex = 0;
    
    setTextLayerVisible = () => document.getElementById('textLayer').style.zIndex = 1;

    onCanvasClick = e => {
        const { dwvApp, pixelSpacing } = this.state;

        const layer = dwvApp.getImageLayer();

        const { width, height } = dwvApp.getImageData();

        const canvas = layer.getCanvas();

        const xFactor = width / canvas.width;
        const yFactor = height / canvas.height;

        const x = (e.clientX - e.target.getBoundingClientRect().left) * xFactor;
        const y = (e.clientY - e.target.getBoundingClientRect().top) * yFactor;

        if (this.props.highContrast && this.props.setClickedPosition) {
            this.removeDrawings();
            this.props.setClickedPosition({ x: parseFloat(x.toFixed(2)), y: parseFloat(y.toFixed(2)) });
            const radiusInPixels = Math.round(this.props.radius / pixelSpacing);
            return this.drawCircle({x: x, y: y}, radiusInPixels);
        }

        this.props.onCanvasClick(x, y);
    };

    onCanvasMouseMove = e => {
        if (!this.props.points || this.props.points.length !== 2)
            return ;
        
        const { dwvApp } = this.state;
        dwvApp.deleteDraws();
        const layer = dwvApp.getImageLayer();

        const { width, height } = dwvApp.getImageData();

        const canvas = layer.getCanvas();

        const xFactor = width / canvas.width;
        const yFactor = height / canvas.height;

        const x = (e.clientX - e.target.getBoundingClientRect().left) * xFactor;
        const y = (e.clientY - e.target.getBoundingClientRect().top) * yFactor;

        let pointsCopy = [...this.props.points];
        let tempPoint = { x: x, y: y };
        pointsCopy.push(tempPoint);

        const circle = getCircleThrough3Point(pointsCopy[0], pointsCopy[1], pointsCopy[2]);
        this.drawCircle(circle.center, circle.r, 'orange', 2);
    }

    removeDrawings = () => {
        const { dwvApp } = this.state;
        this.setState({
            clickedArea: { x: undefined, y: undefined }
        })
        dwvApp.deleteDraws();
    }

    shouldDrawCircles(props, prevProps, state, prevState) {
        if (props.settings && props.dicom) {
            if (props.settings.diameter !== prevProps.settings.diameter) 
                return true;

            if (props.settings.ROINumber !== prevProps.settings.ROINumber) 
                return true;
        
            if (props.settings.distance !== prevProps.settings.distance) 
                return true;
            
            if (!_.isEqual(state.dwvApp, prevState.dwvApp)) 
                return true;
        }
        return false;
    }

    initializeDwv = () => {
        var app = new dwv.App();

        app.init({
            containerDivId: "dwv",
            tools: this.state.tools,
        });

        
        this.addEventListeners(app);
        this.setState({ dwvApp: app });
        this.setupDropbox(app);
        dwv.utils.loadFromUri(window.location.href, app);
    };

    updateDrawingState = async (mode = null, id = null) => {
        const { dwvApp } = this.state;
        const state = new dwv.State();
        const jsonStr = state.toJSON(dwvApp);
        const json = JSON.parse(jsonStr);
        await this.props.onStateChange(json);

        if (mode === 'add') {
            if (!this.props.artifact) {
                this.props.addNewShape(id)
                return
            }
            let shape = null;

            if (json.drawings && id) {
                json.drawings.children.forEach(drawing => {
                  shape = drawing.children.find(child => child.attrs.id === id)
                })
            }

            if (shape && this.shouldRemoveLastDrawing(shape.children[0].attrs)) {
                const { drawingsDetails, drawings } = this.removeLastDrawingFromDrawings(json, id)
                dwvApp.deleteDraws();
                dwvApp.setDrawings(drawings, drawingsDetails)
                this.setState({ dwvApp: dwvApp });
                return
            }

            this.props.addNewShape(id);
        }

        if (mode === 'delete') {
            this.props.deleteShape(id);
        }
    }

    shouldRemoveLastDrawing = ({ radiusX, radiusY}) => !radiusX || !radiusY

    removeLastDrawingFromDrawings = (dwvJsonData, drawingId) => {
        const drawingsChildren = this._removeDrawingFromDrawingsChildren(dwvJsonData, drawingId);
        const drawingsDetails = this._removeDrawingFromDrawingsDetails(dwvJsonData, drawingId)
        const { drawings } = dwvJsonData;
        drawings.children[0].children = drawingsChildren;
        return {
            drawingsDetails: drawingsDetails,
            drawings: drawings
        }
    }

    performHu = () => {
        const { circle, circles } = this.props;

        if (circles && _.isArray(circles) && !_.isEmpty(circles)) {
            const huValues = [];

            circles.map(circle => {
                const hu = this.calculateHu(circle);
                huValues.push(hu);
            })

            this.props.getHuResult(huValues);
        }
        else {
            const hu = this.calculateHu(circle);
            this.props.getHuResult(hu);
        }
    }

    calculateHu = (circle) => {
        const { dwvApp } = this.state;        
        const image_object = dwvApp.getImage();
        const curent_img_index = dwvApp.getViewController().getCurrentFrame();
        const { center: { x, y }, r } = circle;

        
        const pixelsInsideCircle = getPixelsInsideCircleOnSurface(
            circle,
            image_object.getGeometry().getSize().getNumberOfColumns(), 
            image_object.getBuffer()[curent_img_index]
        );

        const rsi = image_object.getRescaleSlopeAndIntercept(curent_img_index)
        // pixelsInsideCircle może być typu unsigned int, a po konwersji na hunsfieldy musimy mieć signed. dlatego nie użyłem map()
        const huValues = []
        pixelsInsideCircle.forEach( v => huValues.push(rsi.apply(v)));
        
        if (pixelsInsideCircle.length === 0)
            return 0;
                
        const sumOfHuFactors = huValues.reduce((sum, v) => sum + v, 0)
        const hu = sumOfHuFactors / huValues.length;
        return hu;
    }

    map1DArrayTo2DArray = (pixelData, width) => {
        const height = pixelData.length / width;

        let array = [];

        for (let i=0; i<height; i++) {
            array[i] = [];
        }

        for (let i=0; i<pixelData.length; i++) {
            const x = i % width;
            const y = Math.floor(i / width);
            array[x][y] = pixelData[i];
        }

        return array;
    }

    performHCTest = () => {
        const { dwvApp, pixelSpacing } = this.state;
        const [x , y] = [Math.floor(this.props.configuration.clickedPosition.x), Math.floor(this.props.configuration.clickedPosition.y)];
        const { configuration: { radius } } = this.props;
        const current_img_index = dwvApp.getViewController().getCurrentFrame();
        const image = dwvApp.getImage();
        const rsi = image.getRescaleSlopeAndIntercept(current_img_index)
        const width = image.getGeometry().getSize().getNumberOfColumns();

        let arrayWithPixels = [];
        image.getBuffer()[current_img_index].forEach( v => arrayWithPixels.push(rsi.apply(v)));
        arrayWithPixels = this.map1DArrayTo2DArray(arrayWithPixels, width);
        const roi = { center: { x: x, y: y }, r: Math.round(radius / pixelSpacing) }
    
        const sizeRoiHalfX = Math.round(roi.r / 2.0);
        const sizeRoiHalfY = Math.round(roi.r / 2.0);
        
        let x1 = roi.center.x;
        let y1 = roi.center.y;
        let meanback = 0.0;
        const pixelsInPointedCircle = getPixelsInsideCircle(roi, width, arrayWithPixels);
        const rawPixels = pixelsInPointedCircle.map(el => el);
        const max = Math.max(...rawPixels)

        const cm = this.centreOfMass(max, roi, arrayWithPixels, width);
        x1 = Math.round(cm.x);
        y1 = Math.round(cm.y);        

        let iter = 0;

        for (let i=x1-sizeRoiHalfX; i<x1+sizeRoiHalfX; i++) {
            for (let j=0; j<=4; j++) {
                meanback += this.getValueOfPixel(arrayWithPixels, i, y1-sizeRoiHalfY+j);
                meanback += this.getValueOfPixel(arrayWithPixels, i, y1+sizeRoiHalfY-j);
                
            }
        }
        for (let i=y1-sizeRoiHalfY; i<y1+sizeRoiHalfY; i++) {
            for (let j=0; j<=4; j++) {
                meanback += this.getValueOfPixel(arrayWithPixels, x1-sizeRoiHalfX+j, i);
                meanback += this.getValueOfPixel(arrayWithPixels, x1+sizeRoiHalfX-j, i);
            }
        }
        
        
        meanback /= (10.0 * sizeRoiHalfX * 2.0 + 10.0 * sizeRoiHalfY * 2.0)

        let distances = [];
        let PSFtemp = [];
        let N = 0;
        const pixel = 0.43;
        for (let i=0; i<sizeRoiHalfX*2; i++) {
            for (let j=0; j<sizeRoiHalfY*2; j++) {
                const posX = x1 - sizeRoiHalfX + i;
                const posY = y1 - sizeRoiHalfY + j;
                const distance = Math.sqrt(Math.pow((x1-posX)*pixel, 2) + Math.pow((y1-posY)*pixel, 2));
                distances[N] = distance;
                const pixelValue = this.getValueOfPixel(arrayWithPixels, posX, posY);
                PSFtemp[N] = pixelValue - meanback;
                N++;
            }
        }
        let tempMean = 0.0;
        let noDupes = 0;
        let totalDupes = 0;

        const weirdNumber = -130000;
        
        for (let i=0; i<N; i++) {
            tempMean = PSFtemp[i];
            noDupes = 0;

            for (let j=0; j<N; j++) {
                if (j!=i && (PSFtemp[j]>weirdNumber) && PSFtemp[i]>weirdNumber) {
                    if (distances[i] == distances[j]) {
                        noDupes++;
                        totalDupes++;
                        tempMean += PSFtemp[j];
                        PSFtemp[j] = weirdNumber;
                    }
                }
            }

            if (noDupes > 0) {
                PSFtemp[i] = tempMean / (noDupes + 1);
            }
        }

        let PSF = [];
        let distances2 = [];
        let counter = 0;

        for (let i=0; i<N; i++) {
            if (PSFtemp[i] > weirdNumber) {
                PSF[counter] = PSFtemp[i];
                distances2[counter] = distances[i];
                counter++;
            }
        }

        for (let i=0; i<counter; i++) {
            for (let j=0; j<counter; j++) {
                if (distances2[j] > distances2[i]) {
                    tempMean = distances2[i];
                    distances2[i] = distances2[j];
                    distances2[j] = tempMean;
                    tempMean = PSF[i];
                    PSF[i] = PSF[j];
                    PSF[j] = tempMean; 
                }
            }
        }

        
        const samplespace = pixel / 32.0;
        // const samplespace = 0.025;

        let numberOfSamples = Math.round(distances2[distances2.length-1] / samplespace) + 1;
        let noAdditionalPads;
        
        if(numberOfSamples < 512) {
            noAdditionalPads = 512 - numberOfSamples;
        }
        else if(numberOfSamples < 1024) {
            noAdditionalPads = 1024 - numberOfSamples;
        }
        else if(numberOfSamples < 2048){
            noAdditionalPads = 2048 - numberOfSamples;
        }
        else{
            noAdditionalPads=4096-numberOfSamples;
        }

        let PSFinterp = [];
        let distanceInterp = [];
        
        let k;
        for (k=0; k<numberOfSamples+noAdditionalPads; k++) {
            distanceInterp[k] = k * samplespace;
            PSFinterp[k] = this.interpolate(distances2, PSF, distanceInterp[k]);
        }

        for (k=numberOfSamples; k<numberOfSamples+noAdditionalPads; k++) {
            distanceInterp[k] = k * samplespace;
            PSFinterp[k] = 0.0;
        }

        numberOfSamples += noAdditionalPads;

        let tempsFx = [];
        let tempsFy = [];

        for (let i=0; i<numberOfSamples; i++) {
            tempsFx[i] = -distanceInterp[distanceInterp.length-1-i];
            tempsFx[i+distanceInterp.length-1] = distanceInterp[i];
            tempsFy[i] = PSFinterp[PSFinterp.length-1-i];
            tempsFy[i+distanceInterp.length-1] = PSFinterp[i];
        }

        let q = this.slowRTF1D(tempsFy);
        
        let mod = [];
        for (let i=0; i<q.length; i++) {
            mod[i] = Math.sqrt(Math.pow(q[i][0], 2) + Math.pow(q[i][1], 2));
        }

        let frequencies = [];
        let MTF = [];
        const weirdRadius = 0.14;

        let d = {
            x: [],
            y: []
        };

        for (let i=0; i<mod.length; i++) {
            frequencies[i] = i / (samplespace * 2.0 * k);
            const OTF = tempsFx[i] == 0.0 ? 1.0 : Math.sin((tempsFx[i]*weirdRadius) / (weirdRadius * tempsFx[i])); 
            mod[i] /= Math.sqrt(OTF * OTF);
            MTF[i] = mod[i] / mod[0];
 
            d.x = [...d.x, frequencies[i]];
            d.y = [...d.y, MTF[i]];
        }

        const MTF50 = this.interpolateYValue(MTF, frequencies, 0.5);
        return MTF50;
    }

	centreOfMass = (max, roi, arrayWithPixels, width) => {       
        const threeshold = max * 3.0 / 4.0;
        let cm = { x: 0.0, y: 0.0 };
        let total = 0.0;
        const roiX = { start: roi.center.x - roi.r, end: roi.center.x + roi.r };
        const roiY = { start: roi.center.y - roi.r, end: roi.center.y + roi.r };
        for (let x=roiX.start; x<roiX.end; x++) {
            for (let y=roiY.start; y<roiY.end; y++) {
                const currentPixelValue = this.getValueOfPixel(arrayWithPixels, x, y);
                if (currentPixelValue >= threeshold) {
                    cm.x += currentPixelValue * x;
                    cm.y += currentPixelValue * y;
                    total += currentPixelValue;
                }
            }
        }

        if (total === 0.0) {
            throw(new Error('Error in calculating centreOfMass: total == 0.0'));
        }

        cm.x /= total;
        cm.y /= total;
        return cm;
	}

    getValueOfPixel = (arrayOfPixels, x, y) => {
        const obj = arrayOfPixels[x][y];
        return obj;
    }

    interpolate = (xDataIn, yDataIn, xPos) => {
        if (xPos < xDataIn[0]) {
            if ((xDataIn[1] - xDataIn[0]) != 0.0) {
                const b = (yDataIn[1] - yDataIn[0]) / (xDataIn[1] - xDataIn[0])
                const c = yDataIn[0] - b * xDataIn[0];
                return b * xPos + c;
            }
        }
        if (xPos > xDataIn[xDataIn.length-1]) {
            if (xDataIn[xDataIn.length-1] - xDataIn[xDataIn.length-2] != 0.0) {
				const b = (yDataIn[yDataIn.length-1] - yDataIn[yDataIn.length-2]) / (xDataIn[xDataIn.length-1] - xDataIn[xDataIn.length-2]);
                const c = yDataIn[yDataIn.length-1] - b * xDataIn[xDataIn.length-1];
                return b * xPos + c;
            }
        }

        for (let ind=0; ind<xDataIn.length; ind++) {
            if (xDataIn[ind] == xPos) {
                return yDataIn[ind];
            }
            else if (xDataIn[ind] > xPos) {
                if ((xDataIn[ind] - xDataIn[ind-1]) != 0.0) {
                    const b = (yDataIn[ind] - yDataIn[ind-1]) / (xDataIn[ind] - xDataIn[ind-1]);
                    const c = yDataIn[ind] - b * xDataIn[ind];
                    return b * xPos + c;
                }
                throw ("Duplicate x positions found cannot interpolate in x data");
            }
        }

        throw ("Duplicate x positions found cannot interpolate in x data");
    }

    slowRTF1D = (realIn) => {
        const N = realIn.length;
        let S = new Array(N);
        for (let i=0; i<N; i++) {
            S[i] = new Array(2);
        }

        let RSm = 0.0;
        let ISm = 0.0;
        
        for (let m=0; m<N; m++) {
            RSm = 0.0;
            ISm = 0.0;
            for (let k=0; k<N; k++) {
                RSm += realIn[k] * Math.cos(2 * Math.PI * k * m / N);
                ISm -= realIn[k] * Math.sin(2 * Math.PI * k * m / N);
            }
            S[m][0] = RSm;
            S[m][1] = ISm;
        }
        
        return S;
    }

    interpolateYValue = (yIn, xIn, yValueIn) => {
        let pos1 = -1;
        let pos2 = -1;
        
        for (let i=0; i<yIn.length; i++) {
            if (pos1 < 0 && yIn[i] <= yValueIn) {
                if (yIn[i] == yValueIn) {
                    return xIn[i];
                }
                pos1 = i;
            }
        }
        for (let i=0; i<yIn.length; i++) {
            if (pos2 < 0 && yIn[i] > yValueIn) {
                pos2 = i;
            }
        }

        if (pos2 > pos1) {
            if (pos2 >= 0) {
                let m, c;
                if (pos2 > 0) {
                    m = (xIn[pos2]-xIn[pos2-1]) / (yIn[pos2]-yIn[pos2-1]);
					c = xIn[pos2] - m*yIn[pos2];
                }
                else {
					m = (xIn[pos2+1]-xIn[pos2]) / (yIn[pos2+1]-yIn[pos2]);
					c = xIn[pos2] - m*yIn[pos2];
                }
                return m * yValueIn + c;
            }
        }
        else {
            if(pos1 >= 0) {
                let m, c;
				if(pos1 > 0) {
                    m = (xIn[pos1]-xIn[pos1-1]) / (yIn[pos1]-yIn[pos1-1]);
					c = xIn[pos1] - m*yIn[pos1];
                }
                else {
                    m = (xIn[pos1+1]-xIn[pos1]) / (yIn[pos1+1]-yIn[pos1]);
					c = xIn[pos1] - m*yIn[pos1];
                }
				return m * yValueIn + c;
			}
        }
        
        throw('This line should not be reached.');
    }

    performStdDev = () => {
        const { circle: { r, center: { x, y, offsetX, offsetY } } } = this.props;
        
        const newCircle = {
            x: x + (offsetX || 0),
            y: y + (offsetY || 0),
            r: r
        };

        const stdDev = this.countStdDev(newCircle.x, newCircle.y, newCircle.r);
        this.props.getHuResult(stdDev);
    }

    countStdDev = (x, y, r) => {
        const { dwvApp } = this.state;

        const image_object = dwvApp.getImage();
        const curent_img_index = dwvApp.getViewController().getCurrentFrame();

        const circleObj = { center: { x: x, y: y }, r: r };

        const pixelsInsideCircle = getPixelsInsideCircleOnSurface(
            circleObj,
            image_object.getGeometry().getSize().getNumberOfColumns(), 
            image_object.getBuffer()[curent_img_index]
        );
        const rsi = image_object.getRescaleSlopeAndIntercept(curent_img_index)
        // pixelsInsideCircle może być typu unsigned int, a po konwersji na hunsfieldy musimy mieć signed. dlatego nie użyłem map()
        const huValues = [];
        pixelsInsideCircle.forEach( v => huValues.push(rsi.apply(v)));

        const avgPixelsValue = (huValues.reduce((p, c) => p + c, 0) / huValues.length);
        const sum = huValues.reduce((sum, e) => sum += Math.pow((e - avgPixelsValue), 2), 0)
        const deviation = Math.sqrt(sum / (huValues.length - 1) || 1);

        return deviation || 0;
    }

    performSnr = () => {
        const { circle: { r, center: { x, y, offsetX, offsetY } } } = this.props;
        
        const newCircle = {
            x: x + (offsetX || 0),
            y: y + (offsetY || 0),
            r: r
        };

        const snr = this.countSnr(newCircle.x, newCircle.y, newCircle.r);
        this.props.getHuResult(snr);
    }

    countSnr = (x, y, r) => {
        const { dwvApp } = this.state;

        const image_object = dwvApp.getImage();
        const curent_img_index = dwvApp.getViewController().getCurrentFrame();

        const circleObj = { center: { x: x, y: y }, r: r };

        const pixelsInsideCircle = getPixelsInsideCircleOnSurface(
            circleObj,
            image_object.getGeometry().getSize().getNumberOfColumns(), 
            image_object.getBuffer()[curent_img_index]
        );
        const rsi = image_object.getRescaleSlopeAndIntercept(curent_img_index)
        // pixelsInsideCircle może być typu unsigned int, a po konwersji na hunsfieldy musimy mieć signed. dlatego nie użyłem map()
        const huValues = [];
        pixelsInsideCircle.forEach( v => huValues.push(rsi.apply(v)));

        const avgPixelsValue = (huValues.reduce((p, c) => p + c, 0) / huValues.length);
        const sum = huValues.reduce((sum, e) => sum += Math.pow((e - avgPixelsValue), 2), 0)
        const deviation = Math.sqrt(sum / (huValues.length - 1) || 1);
        const snr = avgPixelsValue / deviation;

        return snr || 0;
    }

    addEventListeners = app => {
        app.addEventListener("load-start", () => {
            this.props.handleDicomLoading(false);
        });

        app.addEventListener("load", () => this.onLoad(app));

        app.addEventListener("load-end", () => this.onLoadEnd(app));

        app.addEventListener('frame-change', (event) => {
            this.setState({ frame: event.frame })
        });

        app.addEventListener('error', (event) => {
            console.error(event.error);
            alert("Wystąpił błąd podczas wczytywania. Sprawdź logi w celu uzyskania szczegółów.");
        });

        app.addEventListener('draw-move', this.onDrawMove);

        app.addEventListener('draw-change', () => {
            this.updateDrawingState();
        });

        app.addEventListener('draw-create', (obj) => {
            this.updateDrawingState('add', obj.id);
        });

        app.addEventListener('draw-delete', (obj) => {
            this.props.removeDrawing(obj.id);
            this.updateDrawingState('delete', obj.id);
        });

        window.addEventListener('resize', app.onResize);
    };

    onDrawMove = (e) => {
        const { dwvApp } = this.state;
        const state = new dwv.State();
        const jsonStr = state.toJSON(dwvApp);
        const json = JSON.parse(jsonStr);

        json.drawings.children.forEach(drawing => {
            const searchedDrawing = drawing.children.find(el => el.attrs.id === e.id);
            if (searchedDrawing) {
                this.props.handleDragDrawing(searchedDrawing.attrs);
            }
        })
    }

    onLoad = (app) => {
        const metaData = dwv.utils.objectToArray(app.getMetaData());
        this.setState({
            metaData: metaData,
        });

        if (!this.props.readOnly) { 
            this.updateDrawingState(); 
        }

        this.onChangeTool('Scroll');
        this.hideDropbox();
        this.setState({ dataLoaded: true });
    }

    onLoadEnd = (app) => {
        const pixelSpacing = this.getTagByName('PixelSpacing');
        const imagerPixelSpacing = this.getTagByName('ImagerPixelSpacing');
        const pixelFactor = [pixelSpacing, imagerPixelSpacing].filter(value => value)[0]
        const pixelSpacingValue = pixelFactor.value.split('\\')[0];
        
        this.setState({ pixelSpacing: pixelSpacingValue });
        this.props.setPixelParam(pixelSpacingValue);
        this.updatePropsParams();
        this.props.handleDicomLoading(true);

        if (_.isFunction(this.props.onLoadingDicomFinish)) {
            this.props.onLoadingDicomFinish();
        }

        if (this.props.updateImageData) {
            const image_data = app.getImage();
            const img_index = app.getViewController().getCurrentFrame();
            this.props.updateImageData(image_data, img_index);
        }
        this.onSetDrawing();
        this.onGroupVisibility();

        if (this.props.settings) {
            this.drawCircles(this.state, this.props);
        };

        if (this.props.newWL && app.getViewController()) {
            app.getViewController().setWindowLevel(this.props.newWL.level, this.props.newWL.width);
            this.onGroupVisibility();
        }
    }

    getFramesNumberOfDicom = () => {
        const { metaData } = this.state;

        if (metaData) {
            const frameInfo = metaData.find(el => el.name === "NumberOfFrames");
            const frames = frameInfo && frameInfo.value;
            return frames && parseInt(frames) - 1; // to start from 0;
        }
    };

    getRescaleTags = () => {
        const { metaData } = this.state;

        let rescaleSlope = 0;
        let rescaleIntercept = 0;

        metaData.forEach(meta => {
            if (meta.group === "0x0028" && meta.element === "0x1053") {
                rescaleSlope = meta.value;
            }
            if (meta.group === "0x0028" && meta.element === "0x1052") {
                rescaleIntercept = meta.value;
            }
        });

        return { rescaleSlope, rescaleIntercept }
    }

    updatePropsParams = () => {
        const { metaData, dwvApp } = this.state;

        metaData.forEach(meta => {

            if (meta.group === "0x0018" && meta.element === "0x1152") {
                this.props.updateParams("mA", meta.value);
                this.props.onMAChange(parseFloat(meta.value));
            }

            if (meta.group === "0x0018" && meta.element === "0x0060") {
                this.props.updateParams("kV", meta.value);
                this.props.onKVChange(parseFloat(meta.value));
            }

            if (meta.group === '0x0018' && meta.element === '0x9327') {
                this.props.onChangeTablePosition(meta.value);
            }
            if (meta.group == '0x0028' && meta.element == '0x1050') {
                const { width, height } = dwvApp.getImageData();
                this.setState({ imageCenter: { x: width / 2, y: height / 2 } });
            }
            if (meta.group === '0x0020' && meta.element === '0x1041') {
                // czasami biblioteka tak jakby nie nadążała nad wczytywaniem nowej instancji i jeszcze stara
                // zostawała w pamięci, przez to meta.value było obiektem. Wtedy wyciągam wartość dla odpowiedniej
                // (aktualnej) instancji
                this.props.onChangeSliceLocation(!_.isObject(meta.value) ? meta.value : meta.value[this.props.instance]);
            }

            if (meta.group == "0x0028" && meta.element == "0x0030") {
                this.props.updateParams("pixelSpacing", meta.value);
                this.props.processPixelSpacing(meta.value);
            }
        });
    };

    convertRealDistanceToPixels = (distance) => {
        const { pixelSpacing } = this.props;
        return (distance / pixelSpacing.xSpacing) * 10;
    }

    drawCircles = ({imageCenter}, {settings: { ROINumber, distance, diameter }, circle}) => {
        const r = this.convertRealDistanceToPixels(diameter / 2);
        var circles = [];

        if (!(r > 0) || ROINumber < 1) { return; }

        if (!_.isEmpty(circle)) {
            const { center } = circle;
            this.drawCircle(center, circle.r);
        }

        const pxDistance = this.convertRealDistanceToPixels(distance);
        const ROIcenterPoints = this.generateROIcenterPoints(imageCenter, ROINumber, pxDistance);

        ROIcenterPoints.forEach(point => {
            this.drawCircle(point, r / 10);
            circles.push({ center: point, r: r / 10 });
        })

        this.props.updateDrawnCircles(circles);
    }

    generateROIcenterPoints = (center, ROINumber, distanceFromCenter) => {
        let centerPointsArray = [];

        const centerPoint = {
            x: center.x,
            y: center.y
        };
        centerPointsArray.push(centerPoint);

        for (let i = 0; i < ROINumber; ++i) {
            const point = {
                x: center.x + distanceFromCenter * Math.sin((2 * Math.PI / ROINumber) * i),
                y: center.y + distanceFromCenter * Math.cos((2 * Math.PI / ROINumber) * i) * (-1) // to start from top, not bottom
            };
            centerPointsArray.push(point);
        }
        return centerPointsArray;
    }

    onChangeTool = tool => {
        const { dwvApp, tools: { Draw: { options } } } = this.state;

        this.setState({ selectedTool: tool });
        dwvApp.setTool(tool);
        if (tool === "Draw") { 
            this.onChangeShape(options[0]); 
        }
    };

    turnOnMoveDrawingFeatures = () => {
        const { dwvApp } = this.state;
        if (!dwvApp.getDrawController())
            return ;
        
        const groups = dwvApp.getDrawController().getDrawLayer().getChildren();
        const draw = new dwv.tool.Draw(dwvApp);
        draw.activate(true);
        groups.forEach(group => {
            draw.setShapeOn(group);
        });
    };

    getTagByName = name => {
        const { metaData } = this.state;
        const tag = metaData.find(el => el.name === name);
        return tag ? tag : false;
    }

    onChangeShape = shape => {
        const { dwvApp } = this.state;
        dwvApp.setDrawShape(shape);
    }

    drawStateCircle = () => {
        const { dataLoaded } = this.state;
        const { circle: { center, r } } = this.props;

        if (!dataLoaded)
            return ;
        
        let shapeGroup = this.drawCircle(center, r, 'orange', 2);
        shapeGroup.eventListeners['dragend'] = [{
            name: 'dragend', handler: e => {
                this.props.handleDrag(e.target.attrs);
            }
        }]

        if (this.props.roiCircle && this.props.roiCircle.r > 0) {
            let roiShapeGroup = this.drawCircle(this.props.roiCircle.center, this.props.roiCircle.r);
            roiShapeGroup.eventListeners['dragend'] = [{
                name: 'dragend', handler: e => {
                    this.props.handleDragRoi(e.target.attrs);
                }
            }]
        }

        this.props.handleAfterDraw && this.props.handleAfterDraw();
    };

    drawStateCircles = () => {
        const { dwvApp, dataLoaded } = this.state;
        const { circles } = this.props;
        if (!dataLoaded)
            return ;

        dwvApp.deleteDraws();
        
        for (const circle of circles) {
            const { center: { x, y }, r } = circle;
            const lineStroke = this.props.lineStroke || 4;
            this.drawCircle(circle.center, circle.r, 'orange', lineStroke)
            if (this.props.roiMeasurementRatio) {
                const roiCenter = { x: x, y: y };
                const roiR = r * this.props.roiMeasurementRatio / 100;
                this.drawCircle(roiCenter, roiR, 'orange', 2);
            }
        }
        
        if (this.props.huDensity) {
            const canvas = document.getElementById("textLayer");
            const ctx = canvas.getContext("2d");
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            ctx.font = 'bold 10pt Verdana';
            ctx.fillStyle = "orange";
            ctx.strokeStyle = "orange"
            ctx.textAlign = "center";
    
            circles.forEach((c, idx) => {
                ctx.fillText(this.props.labels[idx], c.center.x + c.r + 10, c.center.y - c.r); 
            });
        }
        this.props.handleAfterDraw();
    }

    createDwvStyle = (color, strokeWidth, fontSize) => {
        const { dwvApp } = this.state;
        let style = dwvApp.getStyle();
        style['getLineColour'] = () => color;
        style['getScaledStrokeWidth'] = () => strokeWidth;
        style['getScaledFontSize'] = () => fontSize;
        return style;
    }

    drawCircle = (imageCenter, r, color='orange', stroke=4, fontSize=0) => {
        const { x, y } = imageCenter;
        const { dwvApp } = this.state;

        const point1 = new dwv.math.Point2D(x, y);
        const point2 = new dwv.math.Point2D(x + r, y + r);

        const style = this.createDwvStyle(color, stroke, fontSize);

        const shapeGroup =
            new dwv.tool.draw.EllipseFactory.prototype.create([point1, point2], style, dwvApp.getImage());

        const layer = dwvApp.getDrawController().getDrawLayer();
        layer.add(shapeGroup);
        layer.draw();

        return shapeGroup;
    }

    handleTagsModalVisibility = (shouldBeVisible) => {
        this.setState({
            showDicomTags: shouldBeVisible
        })
    }

    setupDropbox = app => {
        const layerContainer = app.getElement("layerContainer");

        if (layerContainer) {
            layerContainer.addEventListener("dragover", this.onDragOver);
            layerContainer.addEventListener("dragleave", this.onDragLeave);
            layerContainer.addEventListener("drop", this.onDrop);
        }

        const box = app.getElement(DROPBOX_CLASS_NAME);

        if (box) {
            const size = app.getLayerContainerSize();
            const dropBoxSize = (2 * size.height) / 3;
            box.setAttribute(
                "style",
                "width:" + dropBoxSize + "px;height:" + dropBoxSize + "px"
            );
        }
    };

    onDragOver = event => {
        const { dwvApp } = this.state;

        event.stopPropagation();
        event.preventDefault();

        const box = dwvApp.getElement(BORDER_CLASS_NAME);

        if (box && box.className.indexOf(HOVER_CLASS_NAME) === -1) {
            box.className += " " + HOVER_CLASS_NAME;
        }
    };

    onDragLeave = event => {
        const { dwvApp } = this.state;

        event.stopPropagation();
        event.preventDefault();

        const box = dwvApp.getElement(BORDER_CLASS_NAME + " hover");

        if (box && box.className.indexOf(HOVER_CLASS_NAME) !== -1) {
            box.className = box.className.replace(" " + HOVER_CLASS_NAME, "");
        }
    };

    hideDropbox = () => {
        const { dwvApp } = this.state;
        const box = dwvApp.getElement(DROPBOX_CLASS_NAME);
        if (box) { 
            box.parentNode.removeChild(box); 
        }
    };

    onSetDrawing = () => {
        const { dwvApp } = this.state;
        if (!this.props.activeConfiguration)
            return ;
        
        if (!this.props.dwvState)
            return ;

        const { activeConfiguration: { dwvState: { drawings, drawingsDetails } } } = this.props;
        if (drawings && drawingsDetails) {
            dwvApp.setDrawings(drawings, drawingsDetails);
        }
    }

    onGroupVisibility = () => {
        const { dwvApp } = this.state;

        if (!_.isArray(this.props.drawings))
            return;

        this.props.drawings.forEach(drawing => {
            const drawingDetails = { id: drawing.id };

            if (_.isEqual(drawing.WL, this.props.newWL) && !dwvApp.isGroupVisible(drawingDetails)) {
                dwvApp.toogleGroupVisibility(drawingDetails);
            }

            if (!_.isEqual(drawing.WL, this.props.newWL) && dwvApp.isGroupVisible(drawingDetails)) {
                dwvApp.toogleGroupVisibility(drawingDetails);
            }
        })
    }

    onDrop = event => {};

    _removeDrawingFromDrawingsDetails = (dwvJsonData, id) => {
        const drawingsDetails = {}
        Object.keys(dwvJsonData.drawingsDetails).forEach(key => {
            if (key !== id)
                drawingsDetails[`${key}`] = dwvJsonData.drawingsDetails[`${key}`]
        })
        return drawingsDetails
    }

    _removeDrawingFromDrawingsChildren = (dwvJsonData, id) => {
        return dwvJsonData.drawings.children[0].children.filter(child => child.attrs.id !== id);
    }
}

const StyledSettingOutlined = styled(SettingOutlined)`
    font-size: 18px !important;
    cursor: pointer;
    color: grey;
`;

DwvComponent.defaultProps = {
    saveState: () => null,
    onCanvasClick: (_1, _2) => null,
    onStateChange: (_) => null,
    addNewShape: (_) => null,
    deleteShape: (_) => null,
    getHuResult: (_) => null,
    handleDicomLoading: (_) => null,
    removeDrawing: (_) => null,
    handleDragDrawing: (_) => null,
    setPixelParam: (_) => null,
    updateParams: (_1, _2) => null,
    onMAChange: (_) => null,
    onKVChange: (_) => null,
    onChangeTablePosition: (_) => null,
    onChangeSliceLocation: (_) => null,
    processPixelSpacing: (_) => null,
    updateDrawnCircles: (_) => null,
    handleDrag: (_) => null,
    handleDragRoi: (_) => null,
    handleAfterDraw: () => null,
};

export default DwvComponent;
