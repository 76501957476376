import React, { PureComponent } from 'react';
import { Row, Col } from 'antd';
import styled from 'styled-components';
import moment from 'moment';
import { Markers, Legend } from "../../../utils/Models"
import { CustomTestCard, MainCard, CustomTable, CustomChart, IconButton, HistoryFilters, GreenCheckIcon, RedCrossIcon } from '../../common';
import { IRouter } from '../../../utils/Interfaces';
import _ from "lodash";
import { HistoryResultRowsDesign } from '../../constants/historyResultRowsDesign'
import { filterResults } from '../../common/FilterResults'

type State = {
    filters: {
        selectedTimeOption: string,
        date: any
    }
    selectedResult: any,
    testData: any,
    yMax: number,
    yMin: number
}

type Props = {
    router: IRouter,
    results: Array<any>,
    validTime: number,
    calculateNextStudy: (dateOfTest: any, validTime: number) => string,
}

type chartDataPart = {
    id: string,
    data: Array<any>,

}

class TableMovementHistory extends PureComponent<Props, State> {
    constructor(props: any) {
        super(props);

        this.state = {
            filters: {
                selectedTimeOption: 'all',
                date: null,
            },
            selectedResult: null,
            testData: null,
            yMax: 35,
            yMin: 0
        }
    }

    componentDidMount() {
        this.generateChartData();
    }

    render() {
        if (!this.state.selectedResult && this.props.results.length > 0) {
            this.changeSelectedResult(this.props.results[0]);
        }

        const { results, validTime, calculateNextStudy } = this.props;
        const { selectedResult, testData } = this.state;
        return (
            <>
                <Row gutter={[24, 24]}>
                    <Col span={4}>
                        <GreyStyledRow justify="center">
                            <IconButton
                                onClick={this.handleChangeToNewTest}
                                label='Nowy test'
                            />
                        </GreyStyledRow>
                        <WhiteStyledRow justify="center">
                            <Col span={18}>
                                <HistoryFilters
                                    filters={this.state.filters}
                                    onChange={this.handleFiltersChange}
                                    onFinish={this.handleFiltersSubmit}
                                />
                            </Col>
                            <Col span={18}>
                                <CustomTable
                                    columns={this.historyResultsColumns}
                                    data={filterResults(results, this.state.filters.selectedTimeOption, this.state.filters.date || null)}
                                    cursor='pointer'
                                    onRow={(record: any) => {
                                        return {
                                            onClick: () => this.changeSelectedResult(record)
                                        }
                                    }}
                                />
                            </Col>
                        </WhiteStyledRow>
                    </Col>
                    <Col span={20}>
                        <Row gutter={[24, 24]}>
                            <Col span={24}>
                                <CustomTestCard
                                    resultId={selectedResult && selectedResult.result.id}
                                    title='Ruch stołu'
                                    performer={selectedResult ? `${selectedResult.user.firstName} ${selectedResult.user.lastName}` : null}
                                    date={selectedResult ? selectedResult.result.dateOfTest : null}
                                    expirationDate={selectedResult ? calculateNextStudy(selectedResult.result.dateOfTest, validTime) : null}
                                    result={selectedResult ? selectedResult.result.passed : null} />
                            </Col>
                            <Col span={24}>
                                <MainCard title='Wyniki pomiaru'>
                                    <Col span={16}>
                                        <CustomTable
                                            columns={this.resultColumns}
                                            data={testData && testData.results} />
                                    </Col>
                                </MainCard>
                            </Col>
                            {selectedResult &&
                                <Col span={24}>
                                    <CustomChart legendDirection="row" yMax={this.state.yMax} yMin={this.state.yMin} data={this.generateChartData()} markers={this.markers} legend={this.legend} />
                                </Col>
                            }
                        </Row>
                    </Col>

                </Row>
            </>
        );
    }

    handleChangeToNewTest = () => {
        this.props.router.history.push(this.props.router.location.pathname.replace(/[^/]*$/, 'perform-test'));
    }

    changeSelectedResult = (newResult: any) => {
        this.setState({
            selectedResult: newResult,
            testData: JSON.parse(newResult.result.testData)
        })
    }

    handleFiltersChange = (filterName: string, newValue: any) => {
        this.setState(prevState => {
            prevState.filters[filterName] = newValue;
            return prevState;
        })
    }

    generateChartData = () => {
        const { results } = this.props;
        const { yMin, yMax } = this.state; 

        if (results.length === 0)
            return [];

        let values: Array<number> = [yMin, yMax];

        const measuredFront: chartDataPart = {
            id: "Rzeczywiste przesunięcie stołu do przodu",
            data: []
        }
        const measuredBack: chartDataPart = {
            id: "Rzeczywiste przesunięcie stołu do tyłu",
            data: []
        }

        results.forEach((nextResult: { result: { dateOfTest: any, testData: any } }) => {
            const params = JSON.parse(nextResult.result.testData);
            const dateOfTest = moment(nextResult.result.dateOfTest).format('YYYY-MM-DD h:mm:ss');

            const yF = params.results[0].measure;
            const yB = params.results[1].measure;

            values.push(yF);
            values.push(yB);


            measuredFront.data.push({
                x: dateOfTest, y: yF
            })
            measuredBack.data.push({
                x: dateOfTest, y: yB
            })
        })

        const minY = _.min(values) || this.state.yMin;
        const maxY = _.max(values) || this.state.yMax;

        this.setState({ yMin: minY, yMax: maxY })

        return [measuredFront, measuredBack]
        
    }

    handleFiltersSubmit = () => {
        alert('Filtrowanie!');
    }

    markers: Markers = [
        {
            axis: 'y',
            value: 30,
            lineStyle: { stroke: '#b0413e', strokeWidth: 1 },
            legend: 'Wartość odniesienia',
        },
    ];

    legend: Legend = [
        {
            anchor: "bottom-right",
            direction: "row",
            justify: false,
            translateX: 70,
            translateY: 50,
            itemsSpacing: 0,
            itemDirection: "left-to-right",
            itemWidth: 250,
            itemHeight: 45,
            itemOpacity: 0.75,
            symbolSize: 12,
            symbolShape: "circle",
            symbolBorderColor: "rgba(0, 0, 0, .5)",
            effects: [
                {
                    on: "hover",
                    style: {
                        itemBackground: "rgba(0, 0, 0, .03)",
                        itemOpacity: 1,
                    },
                },
            ],
        },
    ];

    resultColumns = [
        {
            title: "Nazwa",
            dataIndex: "name",
            key: "name"
        },
        {
            title: "Pomiar",
            dataIndex: "measure",
            key: "measure"
        },
        {
            title: "Odniesienie",
            dataIndex: "reference",
            key: "reference",
            render: (reference: number) => reference && reference.toFixed(2)
        },
        {
            title: "Odchylenie",
            dataIndex: "deviation",
            key: "deviation"
        },
        {
            title: "Poprawny",
            dataIndex: "result",
            key: "result",
            render: (result: boolean) => result ? <GreenCheckIcon /> : <RedCrossIcon />
        },
    ]

    historyResultsColumns = [
        {
            title: 'WYNIKI HISTORYCZNE',
            dataIndex: 'date',
            key: 'date',
            render: (_: any, row: any) => {
                const { selectedResult } = this.state;
                return {
                  props: {
                    style: 
                        {
                            background: selectedResult && selectedResult.result.id === row.result.id ? HistoryResultRowsDesign.SELECTED_RESULT_BACKGROUND_COLOR : null, 
                            color: selectedResult && selectedResult.result.id === row.result.id ? HistoryResultRowsDesign.SELECTED_RESULT_TEXT_COLOR : null, 
                            borderLeft: row.result.passed ? HistoryResultRowsDesign.BORDER_LEFT_TEST_PASSED_COLOR : HistoryResultRowsDesign.BORDER_LEFT_TEST_NOT_PASSED_COLOR, 
                            textAlign: HistoryResultRowsDesign.TEXT_ALIGN }
                        },
                    children: moment(row.result.dateOfTest).format('YYYY-MM-DD HH:mm')
                };
            }
        }
    ]
}

const GreyStyledRow = styled(Row)`
    background: #F6F7F8;
    padding: 20px 0px 20px 0px;
`

const WhiteStyledRow = styled(Row)`
    background: #FFFFFF;
    padding: 20px 0px 20px 0px;
`

export default TableMovementHistory;