import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import { Layout, Row, Col, message } from 'antd';
import styled from 'styled-components';

import { ProtectedRoute } from './views/ProtectedRoute';
import ChooseCustomerView from './views/ChooseCustomerView';
import OutpostsView from './views/OutpostsView';
import HardwaresView from './views/HardwaresView';
import TestsView from './views/TestsView';
import TestCasesView from './views/TestCasesView';
import { LoginView } from './views/LoginView';
import RegisterView from './views/RegisterView';
import TestHistoryView from './views/TestHistoryView';
import PerformExaminationView from './views/PerformExaminationView';
import TestConfigView from './views/TestConfigView';
import NewConfigView from './views/NewConfigView';
import AdminPanelView from './views/AdminPanelView';
import UploadDicomView from './views/UploadDicomView';
import { NavigationBreadcrumb, FullPageLoading, NavigationDropdown } from './components/common';
import { withStore } from "./store";

import { IRouter } from './utils/Interfaces'
import { response } from './components/constants/dictionaries';
import { api } from './api';
import { User } from './utils/Models';
import './App.css';

const { Header, Content } = Layout;

type State = {
    isLoading: boolean,
    buttonLoader: boolean,
    user: User,
    locations: Array<Location>,
    displayLoginError: boolean;
    displayRegisterError: boolean;
    errorCode: number;
}

type Location = {
    name: string,
    url: string
}

export class App extends React.Component<any, State> {
    state = {
        isLoading: true,
        buttonLoader: false,
        user: { id: undefined, isAdmin: false },
        locations: [],
        displayLoginError: false,
        displayRegisterError: false,
        errorCode: 0,
    }

    componentDidMount() {
        const { history, store } = this.props

        if (!this.isAllowedAnonymousPaths()) {
            this.getUserProfile();
        } else {
            this.setState({
                isLoading: false
            })
        }
        
        if (this.pageWasRefreshed(history, store)) {
            const loadedLocations = window.sessionStorage.getItem('locations')
            loadedLocations && store.DispatchAfterReload(JSON.parse(loadedLocations))
        }
    }

    render() {
        const { isLoading, user, buttonLoader } = this.state;

        if (isLoading) {
            return <FullPageLoading />
        }

        const isAuthenticated = this.isAuthenticated();
        
        return (
            <Switch>
                <Route 
                    path="/login" 
                    render={() => <LoginView loading={buttonLoader} onLogin={this.handleLogin} displayLoginError={this.state.displayLoginError}/>} 
                />
                <ProtectedRoute 
                    path='/choose-customer' 
                    component={ChooseCustomerView} 
                    isAuthenticated={isAuthenticated} 
                    authenticationPath='/login'
                />
                <Layout>
                    <StyledHeader>
                        <Row justify='space-between'>
                            <Col>
                                <NavigationBreadcrumb />
                            </Col>
                            <Col>
                                <NavigationDropdown onLogout={this.handleLogout}/>
                            </Col>
                        </Row>
                    </StyledHeader>
                    <Content style={{display: 'flex', justifyContent: 'center'}}>
                        <Layout style={{ maxWidth: '90%' }}>
                            <ProtectedRoute path='/browse/customer/:customerId/outposts' 
                                component={OutpostsView} isAuthenticated={isAuthenticated} authenticationPath='/login'/>
                            
                            <ProtectedRoute path="/browse/outpost/:outpostId/hardwares" 
                                component={HardwaresView} isAuthenticated={isAuthenticated} authenticationPath='/login'/>
                            
                            <ProtectedRoute path="/browse/hardware/:hardwareId/test-suits" 
                                component={TestsView} isAuthenticated={isAuthenticated} authenticationPath='/login'/>
                            
                            <ProtectedRoute path="/browse/test-suit/:testId/test-cases" 
                                component={TestCasesView} isAuthenticated={isAuthenticated} authenticationPath='/login'/>
                            
                            <ProtectedRoute path="/test-case/:testId/settings" 
                                component={TestConfigView} isAuthenticated={isAuthenticated} authenticationPath='/login'/>
                            
                            <ProtectedRoute path="/test-case/:testId/new" 
                                component={NewConfigView} isAuthenticated={isAuthenticated} authenticationPath='/login'/>
                            
                            <ProtectedRoute path="/test-case/:testId/test-results" 
                                component={TestHistoryView} isAuthenticated={isAuthenticated} authenticationPath='/login'/>
                            
                            <ProtectedRoute path="/test-case/:testId/perform-test" 
                                component={PerformExaminationView} isAuthenticated={isAuthenticated} authenticationPath='/login'/>

                            <ProtectedRoute path="/dicom/upload" 
                                component={UploadDicomView} isAuthenticated={isAuthenticated} authenticationPath='/login'/>

                            { user.isAdmin && <ProtectedRoute path="/admin-panel"
                                component={AdminPanelView} isAuthenticated={isAuthenticated} authenticationPath="/login"/>}
                        </Layout>
                    </Content>
                </Layout>
            </Switch>
        )
    }

    getUserProfile = () => {
        api
            .get("/auth/user")
            .then(response => response.data)
            .then(user => this.setState({ user: user || {}, isLoading: false }))
            .catch(() => this.setState({ isLoading: false }));
    }

    isAllowedAnonymousPaths() {
        const currentLocation = this.props.location;
        const allowedAnonymousPaths = ["/login"]

        return allowedAnonymousPaths.indexOf(currentLocation) !== -1; 
    }

    isAuthenticated = () => {
        const { isLoading, user } = this.state;
        return !(!isLoading && !user.id && !this.isAllowedAnonymousPaths())
    }

    handleLogin = (values: object) => {
        this.setState({ buttonLoader: true });
        api
            .post(`/auth/login`, { ...values })
            .then(response => response.data)
            .then(user => this.updateStateAndNavigate(user.user))
            .catch(error => this.handleUnsucessfulLogin(error))
            .finally(() => this.setState({ buttonLoader: false }));
    }

    handleRegister = (values: object) => {
        api
            .post(`/auth/register`, { ...values })
            .then(response => response.data)
            .then(() => message.success("Rejestracja przebiegła pomyślnie", 3))
            .then(() => this.props.history.push('/login'))
            .catch(error => this.handleUnsucessfulRegister(error))
    }

    updateStateAndNavigate = (user: User) => {
        this.setState({user: user, isLoading: false});

        user.isAdmin 
            ? this.props.history.push('/admin-panel')
            : this.props.history.push('/choose-customer');
    }

    handleUnsucessfulLogin = (error: any) => {
        if(error && error.response && error.response.status === response.BAD_REQUEST) {
            this.setState({
                displayLoginError: true,
                errorCode: error.response.data && error.response.data.status
            })
        }
    }

    handleUnsucessfulRegister = (error: any) => {
        if(error && error.response && error.response.status === response.BAD_REQUEST) {
            this.setState({
                displayRegisterError: true,
                errorCode: error.response.data && error.response.data.status
            })
        }
    }


    handleLogout = () => {
        api
            .get(`/auth/logout`)
            .then(() => this.setState({ user: {} }))
            .then(() => this.props.history.push('/login'))
    }

    pageWasRefreshed = (history: any, store: any) => history.location !== "/choose-customer" && store.state.count === 0;
}

const StyledHeader = styled(Header)`
    background: #1B1E24; 
    padding: 10px;
    margin-bottom: 32px; 
    padding-left: 5%;
    height: auto;
    line-height: 0px;
`

export default withStore(withRouter(App));