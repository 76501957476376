import React from 'react'
import styled from 'styled-components'

import logo from '../../resources/img/logo_qadra_dark.png';

export default function FullPageLoading() {
    return (
        <Container>
            <Logo src={logo}/>
            <Caption>Wczytywanie ...</Caption>
        </Container>
    )
}

const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #1B1E24;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column nowrap;
`

const Logo = styled.img`
    max-width: 300px;
    width: 80%;
`

const Caption = styled.div`
    margin-top: 50px;
    font-size: 30px;
    color: #236cad;
`