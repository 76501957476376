import React from 'react'
import styled from 'styled-components'
import { Form } from 'antd';
import logo from '../../resources/img/logo-mono-white.png';
import { PrimaryButton } from './Buttons';
import { FormLayoutType } from '../../utils/Models';
import { Link } from 'react-router-dom';

type Props = {
    title?: string,
    submitText?: string,
    children?: any,
    onSubmit?: (values: object) => void,
    layout?: FormLayoutType,
    showRegisterBtn?: boolean,
    loading?: boolean
}

export default function FormContainer(props: Props) {
    const [ form ] = Form.useForm();

    return (
        <Container>
            <Logo src={logo}/>
            <Shadow>
                <Header>{props.title}</Header>
                <Content>
                    <Form layout={props.layout} form={form} onFinish={values => props.onSubmit && props.onSubmit(values)}>
                        {props.children}
                        <PrimaryButton 
                            label={props.submitText ? props.submitText : "Wybierz"} 
                            style={{ textAlign: "right" }}
                            displayLoadingSpinner={props.loading}
                        />
                        {props.showRegisterBtn === true ?
                        <Link to='/register'>
                            <PrimaryButton label="Zarejestruj się" style={{float: 'right'}}></PrimaryButton>
                        </Link>
                        : null}
                    </Form>
                </Content>
            </Shadow>
        </Container>
    )
}

const Container = styled.div`
    width: 90%;
    max-width: 600px;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
`

const Logo = styled.img`
    max-width: 150px;
    margin-bottom: 25px;
`

const Header = styled.div`
    background-color: #22262e;
    padding: 15px;
    width: 100%;
    color: #fff;
`

const Content = styled.div`
    background-color: #fff;
    width: 100%;
    padding: 25px;
`

const Shadow = styled.div`
    box-shadow: 0px 0px 10px #222;
    border-radius: 0px 0px 10px 10px;
    width: 100%;
    overflow: hidden;
`