import React from 'react';
import styled from 'styled-components';
import moment from 'moment';

import { Row, Col, Progress } from 'antd';
import { CalendarOutlined, ToolOutlined } from '@ant-design/icons';

import dateFormats from '../../constants/dateFormats';
import { createTimeToNextStudyString } from '../../../utils/createTimeToNextStudyString';
import { getMinutesDifferenceToNextStudy, calculateProgressPercentage, getTileColor } from '../../../utils/calculateTileColorBasedOnTime';

export type Props = {
    id: string,
    title?: string,
    lastStudyDate?: string,
    nextStudyDate?: string,
    isTestSuitTile?: boolean,
    onClick?: (id: string) => void,
    onHistoryClick?: (id: string) => void,
    onPerformTestClick?: (id: string) => void,
    onSettingsClick?: (id: string) => void
}

const ResourceTile = (props: Props) => {        
    const minutesDifference = getMinutesDifferenceToNextStudy(props.nextStudyDate);
    return (
        <Tile color={getTileColor(minutesDifference)}>
            <TileInfoArea>
                <Row>
                    <Col span={props.isTestSuitTile ? 18 : 24}>
                        <Title onClick={() => props.onClick && props.onClick(props.id)}>{props.title}</Title>
                    </Col>
                    {props.isTestSuitTile && 
                        <Col span={6}>
                            <OpenSettingsArea onClick={() => props.onSettingsClick && props.onSettingsClick(props.id)}>
                                <Row>
                                    <Col><ToolOutlined />Ustawienia</Col>
                                </Row>
                            </OpenSettingsArea>
                        </Col>
                    }
                </Row>
                <TileStats>
                    <Row>
                        Czas do kolejnego badania: {createTimeToNextStudyString(minutesDifference)}
                    </Row>
                    <Row>
                        <Progress 
                            percent={calculateProgressPercentage(minutesDifference)} 
                            showInfo={false} 
                            strokeColor={'#FFFFFF'} 
                            trailColor={'#00689D'}
                            strokeWidth={5}
                        />
                    </Row>
                    <Row>
                        <Col><CalendarOutlined /></Col>
                        <Col offset={1}>Ostatnie badanie: {moment(props.lastStudyDate).format(dateFormats.DATE_TIME_SHORT)}</Col>
                    </Row>
                </TileStats>
            </TileInfoArea>
            {props.isTestSuitTile && 
                <Row>
                    <Col span={8}>
                        <HistoryButton onClick={() => props.onHistoryClick && props.onHistoryClick(props.id)}>
                            Historia
                        </HistoryButton>
                    </Col>
                    <Col span={16}>
                        <PerformTestButton onClick={() => props.onPerformTestClick && props.onPerformTestClick(props.id)}>
                            Wykonaj test
                        </PerformTestButton>
                    </Col>
                </Row>
            }
        </Tile>
    )
}

const Tile = styled.div`
    width: 100%;
    height: 100%;
    color: #FFFFFF;
    background: ${props => props.color};
    display: flex;
    justify-content: space-between;
    flex-flow: column nowrap;
`

const TileStats = styled.div``

const TileInfoArea = styled.div`
    height: 100%;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    flex-flow: column nowrap;
`

const Title = styled.div`
    font-size: 25px;
    font-weight: 400;
    cursor: pointer;
    margin-bottom: 30px;
`

const OpenSettingsArea = styled.div`
    cursor: pointer;
`

const HistoryButton = styled.div`
    background: #FFFFFF;
    color: #000000;
    padding: 8px 0px 8px 0px;
    text-align: center;
    cursor: pointer;
`

const PerformTestButton = styled.div`
    background: #1B3148;
    color: #FFFFFF;
    padding: 8px 0px 8px 0px;
    text-align: center;
    cursor: pointer;
`

export default ResourceTile;