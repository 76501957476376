import React from 'react';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';

export const GreenCheckIcon = () => {
    return (
        <CheckCircleOutlined style={{color: 'green'}} />
    );
}

export const RedCrossIcon = () => {
    return (
        <CloseCircleOutlined style={{color: 'red'}} />
    );
}