import React, { Component } from "react";
import { Row, Col, message, Divider, Spin } from "antd";
import moment from "moment";
import styled from "styled-components";
import { CustomCollapse, WindowConfiguration, AddConfiguration } from "../artefacts";
import DwvComponent from "../../common/Dwv/DwvComponent";
import { MainCard, CustomUpload, CenteredButton, CustomSpin } from "../../common";
import { api } from "../../../api";
import { IRouter } from "../../../utils/Interfaces";
import _ from "lodash";
import { WLSetting, Window, ArtefactsOffset } from "../../../utils/Models";
import { showConfirmModal, NEW_CONFIG_INFO } from '../../../utils/modalConfirm';

type Props = {
    selectedConfigId: string | null;
    configurations: Array<any>;
    router: IRouter;
    id: string;
    thumbnails: React.ReactNode;
    dicom: any;
    mainCardReloadIcon: React.ReactNode,
    handleChangeConfigId: (id: string) => void;
    fetchConfigurations: () => void;
    fetchThumbnails: () => void;
    onLoadingDicomFinish: () => void;
};

type State = {
    visible: boolean;
    configurations: Array<any>;
    selectedConfigurationId: string | null;
    dwvState: any;
    newWL: WLSetting;
    drawings: any;
    selectedTool: any;
    mode: "new" | "view";
    artefactsOffset: ArtefactsOffset;
    newConfiguration: {
        name: string;
        window: Window;
        artefacts: any;
    };
    isLoading: boolean;
    activeKeys: any
};

const DEFAULT_CONFIGURATION = {
    name: moment().format("YYYY-MM-DD HH:mm"),
    artefacts: null,
    window: {
        WL: [],
        name: "",
    },
};

class ArtefactsSettings extends Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            mode: "view",
            visible: false,
            configurations: [],
            artefactsOffset: [],
            selectedConfigurationId: null,
            dwvState: {},
            newWL: { level: 0, width: 0, name: '' },
            drawings: [],
            selectedTool: null,
            newConfiguration: DEFAULT_CONFIGURATION,
            isLoading: false,
            activeKeys: []
        };
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        if (this.props.configurations && this.props.configurations.length !== prevProps.configurations.length) {
            this.onChangeCollapse([this.getActiveConfigurationId()]);
        }
    }
    

    render() {
        const { configurations } = this.props;
        const { newWL, drawings, selectedTool, newConfiguration, artefactsOffset, isLoading, activeKeys } = this.state;
        const { window } = newConfiguration;

        return (
            <Spin spinning={isLoading} indicator={<CustomSpin tip='Zapisywanie konfiguracji' />}>
                <Row gutter={[16, 16]}>
                    <Col span={8}>
                        <CustomCollapse
                            collection={configurations}
                            activeKeys={activeKeys}
                            onChange={this.onChangeCollapse}
                            activeConfiguration={
                                <AddConfiguration
                                    changeToolToDraw={this.changeToolToDraw}
                                    configuration={newConfiguration}
                                    drawings={drawings}
                                    handleConfigurationName={this.handleConfigurationName}
                                    handleWLChange={this.handleWLChange}
                                    newWL={newWL}
                                    artefactsOffset={artefactsOffset}
                                    selectedTool={selectedTool}
                                />
                            }
                            addConfiguration={
                                <>
                                    <WindowConfiguration
                                        configName={newConfiguration.name}
                                        onNameChange={this.handleConfigurationName}
                                        changeWLSetting={this.changeWLSetting}
                                        changeWLName={this.changeWLName}
                                        addNewWLSetting={this.addNewWLSetting}
                                        deleteWLSetting={this.handleDeleteWindow}
                                        WLSettings={window}
                                        onFinish={() => {}}
                                    />
                                    <CenteredButton onClick={() => this.handleAddConfiguration()} label='Zapisz konfigurację' />
                                </>
                            }
                        />
                        <Divider />
                    </Col>
                    <Col span={12}>
                        <MainCard title='Obrazy w teście'>
                            <DwvComponent
                                {...this.props}
                                newWL={newWL}
                                drawings={drawings}
                                selectedTool={selectedTool}
                                handleDragDrawing={this.handleDragDrawing}
                                onStateChange={this.changeDwvState}
                                addNewShape={this.addNewShapeToList}
                                deleteShape={this.deleteShapeFromList}
                                canZoom={true}
                            />
                        </MainCard>
                    </Col>
                    <Col span={4}>
                        <MainCard title='Ostatnio dodane' icon={this.props.mainCardReloadIcon}>
                            <SideBar>{this.props.thumbnails}</SideBar>
                        </MainCard>
                    </Col>
                </Row>
            </Spin>
        );
    }

    handleAddConfiguration = () => {
        const { newConfiguration, drawings, dwvState, artefactsOffset } = this.state;
        const { testId } = this.props.router.match.params;

        if (!newConfiguration.name) {
            return message.error("Musisz wpisać nazwę konfiguracji.");
        }

        if (!this.props.dicom) {
            return message.error("Musisz wybrać plik dicom.");
        }

        const hasErrors = newConfiguration.window.WL.reduce((prev, curr) => {
            return prev || curr.name.length === 0 || !curr.width || !curr.level || isNaN(curr.width) || isNaN(curr.level);
        }, false);
        
        if (hasErrors)
            return message.error("Wszystkie ustawienia okna muszą mieć ustawioną nazwę, wysokość i szerokość.");

        const finalDrawings = drawings.map((d: any) => {
            const offset = artefactsOffset.find(a => a.id === d.id);
            if (offset) {
                return {
                    ...d,
                    id: d.id,
                    x: d.x + offset.x,
                    y: d.y + offset.y,
                };
            }
            return d;
        });

        const dwvStateCopy = Object.assign({}, dwvState);
        
        const children = dwvStateCopy.drawings && dwvStateCopy.drawings.children[0] && dwvStateCopy.drawings.children[0].children;

        children && children.map((c: any) => {
            const { id } = c.attrs;
            const offset = artefactsOffset.find(a => a.id === id);
            if (offset) {
                c.children[0].attrs.x = c.children[0].attrs.x + offset.x;
                c.children[0].attrs.y = c.children[0].attrs.y + offset.y;
                c.children[1].attrs.x = c.children[1].attrs.x + offset.x;
                c.children[1].attrs.y = c.children[1].attrs.y + offset.y;
            }
        });

        const finalConfiguration: {
            name: string;
            window: Window;
            artefacts: any;
            dwvState: any;
        } = {
            ...newConfiguration,
            artefacts: finalDrawings,
            dwvState: dwvState,
        };

        const body = {
            Content: JSON.stringify(finalConfiguration),
        };

        this.setState({ isLoading: true });
        api
            .post(`test-configurations/test-case/${testId}`, body)
            .then(() => this.setState({ mode: "view" }))
            .then(() => {
                this.setState({
                    newConfiguration: DEFAULT_CONFIGURATION,
                    drawings: [],
                    dwvState: {},
                    artefactsOffset: [],
                });
                this.props.fetchConfigurations();
            })
            .then(() => setTimeout(() => this.setState({ isLoading: false }), 2000))
            .catch(err => console.error(err));
    };

    onChangeCollapse = (keys: any) => {
        if (!keys)
            return this.setState({ activeKeys: [] });

        if (keys.indexOf("NEW_CONFIGURATION") >= 0 && this.props.configurations.length > 0) {
            return showConfirmModal(
                () => this.expandCollapse(keys),
                NEW_CONFIG_INFO
            );
        }

        this.expandCollapse(keys);
    }

    getActiveConfigurationId = () => {
        const { configurations } = this.props;
        
        const activeConfiguration = configurations.find(c => c.active === true);
        if (activeConfiguration) {
            return activeConfiguration.id;
        }
    };

    expandCollapse = (keys: any) => {
        this.setState({ activeKeys: keys });
    }

    changeToolToDraw = () => {
        if (!this.state.newWL.level || !this.state.newWL.width) {
            return message.error("Wybierz najpierw odpowiednie ustawienie okna!");
        }
        this.setState({ selectedTool: "Draw" });
    };

    addNewWLSetting = () => {
        const newSetting: WLSetting = {
            width: 0,
            level: 0,
            name: ''
        };
        this.setState(prevState => {
            const prevConfiguration = prevState.newConfiguration;
            return {
                newConfiguration: {
                    ...prevConfiguration,
                    window: { ...prevConfiguration.window, WL: [...prevConfiguration.window.WL, newSetting] },
                },
            };
        });
    };

    handleDeleteWindow = (index: number) => {
        this.setState(prevState => ({
            newConfiguration: {
                ...prevState.newConfiguration,
                window: {
                    ...prevState.newConfiguration.window,
                    WL: prevState.newConfiguration.window.WL.filter((_, filteredIndex) => filteredIndex !== index)
                }
            }
        }));
    }

    handleWLChange = (WLParam: WLSetting, name: string) => {
        this.setState({ newWL: { ...WLParam } });
    };

    handleConfigurationName = (e: string) => {
        this.setState(prevState => {
            return {
                newConfiguration: {
                    ...prevState.newConfiguration,
                    name: e,
                },
            };
        });
    };

    changeDwvState = (newState: any) => {
        this.setState({ dwvState: newState });
    };

    addNewShapeToList = (id: string) => {
        const { dwvState } = this.state;
        let newShapeData: any = null;

        dwvState.drawings &&
            dwvState.drawings.children.forEach((el: any) => {
                newShapeData = el.children.find((child: any) => child.attrs.id === id);
            });

        if (newShapeData) {
            const { x, y, radiusX, radiusY } = newShapeData.children[0].attrs;
            const newShape = {
                id: id,
                WL: this.state.newWL,
                x: x,
                y: y,
                radiusX: radiusX,
                radiusY: radiusY,
            };

            this.setState(prevState => {
                return {
                    drawings: [...prevState.drawings, newShape],
                    selectedTool: "ZoomAndPan",
                };
            });
        }
    };

    deleteShapeFromList = (id: string) => {
        this.setState(prevState => {
            return {
                ...prevState,
                drawings: prevState.drawings.filter((d: any) => d.id !== id),
                artefactsOffset: prevState.artefactsOffset.filter((d: any) => d.id !== id),
            };
        });
    };

    changeWLName = (index: number, newName: string) => {
        this.setState(prevState => {
            const newSettings = prevState.newConfiguration.window.WL.map((wl, wlIndex) => {
                if (wlIndex === index) {
                    wl.name = newName;
                }
                return wl;
            });

            return {
                newConfiguration: {
                    ...prevState.newConfiguration,
                    window: { ...prevState.newConfiguration.window, WL: newSettings },
                },
            };
        });
    }
    
    changeWLSetting = (param: 'width' | 'level', index: number, newValue: number) => {        
        this.setState(prevState => {
            const newSettings = prevState.newConfiguration.window.WL.map((wl, wlIndex) => {
                if (wlIndex === index) {
                    wl[param] = newValue;
                }
                return wl;
            });

            return {
                newConfiguration: {
                    ...prevState.newConfiguration,
                    window: { ...prevState.newConfiguration.window, WL: newSettings },
                },
                newWL: {
                    ...prevState.newWL,
                    width: param === 'width' ? newValue : prevState.newConfiguration.window.WL[index].width,
                    level: param === 'level' ? newValue : prevState.newConfiguration.window.WL[index].level,
                }
            };
        });
    };

    handleDragDrawing = (attrs: any) => {
        const { id, x, y } = attrs;

        const offset = { id, x, y };

        this.setState(prevState => {
            const hasOffset = prevState.artefactsOffset.find(o => o.id === id);
            if (!hasOffset) {
                return { artefactsOffset: [...prevState.artefactsOffset, offset] };
            }
            const artefactsOffset = prevState.artefactsOffset.map(o => (o.id === id ? offset : o));
            return { artefactsOffset };
        });
    };

    handleWindowNameChange = (e: string) => {
        this.setState(prevState => {
            return {
                newConfiguration: {
                    ...prevState.newConfiguration,
                    window: { ...prevState.newConfiguration.window, name: e },
                },
            };
        });
    };
}

const SideBar = styled.div`
    overflow: auto;
    height: 80vh;
`;

export default ArtefactsSettings;
