import React, { Component } from 'react';
import { Row, Col, Skeleton, Spin, Modal, message } from 'antd';
import { SyncOutlined } from '@ant-design/icons';
import { TestConfigurationCard, ImageCard } from '../components/common';
import CustomSpin from '../components/common/CustomSpin';
import { IRouter } from '../utils/Interfaces';
import { api } from "../api";
import { TestCase, Thumbnail } from "../utils/Models";
import {
    HuWaterPhantomSettings,
    UniformitySettings,
    NoiseLevelSettings,
    ArtefactsSettings,
    HuDensitySettings,
    GeometricCorrectnessSettings,
    HighContrastSettings
} from "../components/tests/settings"

type State = {
    selectedConfigId: string | null,
    type: string | null,
    configurations: Array<any>,
    testCase: TestCase,
    loading: boolean,
    dicomThumbnails: Array<Thumbnail>,
    selectedDicomId: string | null,
    dicom: any,
    loadingDicom: boolean,
    loadingThumbnails: boolean
}

class TestConfigView extends Component<IRouter, State> {
    constructor(props: any) {
        super(props);

        this.state = {
            selectedConfigId: null,
            testCase: { id: "", name: "", testSuitId: "", type: "", lastTestResultTime: "", nextTestResultTime: "" },
            loading: true,
            type: null,
            configurations: [],
            dicomThumbnails: [],
            dicom: null,
            selectedDicomId: null,
            loadingDicom: false,
            loadingThumbnails: false,
        }
    }

    componentDidMount() {
        this.fetchTestCase(this.props.match.params.testId);
        this.fetchConfigurations();
        this.fetchDicomData();
    }

    render() {
        const { selectedConfigId, testCase: { type, id }, loading, configurations, loadingDicom } = this.state;
        const { history, location, match } = this.props;

        const router = {
            history: history,
            location: location,
            match: match,
        };

        if (loading) {
            return <Skeleton />;
        }

        return (
            <Spin spinning={loadingDicom} size='large' indicator={<CustomSpin tip='Wczytywanie pliku DICOM...' />}>
                {type && type === "water_phantom" && 
                    <HuWaterPhantomSettings  
                        {...this.state}
                        id={id} 
                        router={router}
                        selectedConfigId={selectedConfigId}
                        thumbnails={this.generateDicomThumbnails()} 
                        fetchThumbnails={this.fetchDicomData} 
                        handleChangeConfigId={this.handleChangeConfigId}
                        fetchConfigurations={this.fetchConfigurations}
                        onLoadingDicomFinish={this.finishLoadingDicom}
                        mainCardReloadIcon={<SyncOutlined onClick={() => this.fetchDicomData()} />}
                    />
                }
                {type && type === "noise_level" && 
                    <NoiseLevelSettings 
                        {...this.state}
                        id={id} 
                        router={router}
                        selectedConfigId={selectedConfigId}
                        thumbnails={this.generateDicomThumbnails()} 
                        handleChangeConfigId={this.handleChangeConfigId}
                        fetchThumbnails={this.fetchDicomData}
                        onLoadingDicomFinish={this.finishLoadingDicom}
                        mainCardReloadIcon={<SyncOutlined onClick={() => this.fetchDicomData()} />}
                    />
                }
                {type && type === "uniformity" && 
                    <UniformitySettings
                        {...this.state}
                        selectedConfigId={selectedConfigId}
                        configurations={configurations}
                        handleChangeConfigId={this.handleChangeConfigId}
                        router={this.props}
                        fetchConfigurations={this.fetchConfigurations}
                        thumbnails={this.generateDicomThumbnails()}
                        fetchThumbnails={this.fetchDicomData}
                        onLoadingDicomFinish={this.finishLoadingDicom}
                        mainCardReloadIcon={<SyncOutlined onClick={() => this.fetchDicomData()} />}
                    />
                }
                {type && type === "artefacts" && 
                    <ArtefactsSettings
                        {...this.state}
                        id={id}
                        selectedConfigId={selectedConfigId}
                        configurations={configurations}
                        handleChangeConfigId={this.handleChangeConfigId}
                        router={this.props}
                        thumbnails={this.generateDicomThumbnails()}
                        fetchThumbnails={this.fetchDicomData}
                        fetchConfigurations={this.fetchConfigurations}
                        onLoadingDicomFinish={this.finishLoadingDicom}
                        mainCardReloadIcon={<SyncOutlined onClick={() => this.fetchDicomData()} />}
                    />}

            {type && type === "geometricCorrectness" &&
                <GeometricCorrectnessSettings 
                    id={id}
                    configurations={configurations}
                    selectedConfigId={selectedConfigId}
                    handleChangeConfigId={this.handleChangeConfigId}
                    router={this.props}
                    fetchConfigurations={this.fetchConfigurations}
                    onLoadingDicomFinish={this.finishLoadingDicom}
                />}


                {type && type === "highContrast" && 
                    <HighContrastSettings
                        {...this.state}
                        id={id}
                        selectedConfigId={selectedConfigId}
                        configurations={configurations}
                        handleChangeConfigId={this.handleChangeConfigId}
                        thumbnails={this.generateDicomThumbnails()}
                        fetchThumbnails={this.fetchDicomData}
                        router={this.props}
                        fetchConfigurations={this.fetchConfigurations}
                        onLoadingDicomFinish={this.finishLoadingDicom}
                        mainCardReloadIcon={<SyncOutlined onClick={() => this.fetchDicomData()} />} 
                    />
                }
                {type && type === "hu_density" && 
                    <HuDensitySettings
                        {...this.state}
                        id={id}
                        configurations={configurations}
                        router={this.props}
                        thumbnails={this.generateDicomThumbnails()}
                        fetchThumbnails={this.fetchDicomData}
                        fetchConfigurations={this.fetchConfigurations}
                        onLoadingDicomFinish={this.finishLoadingDicom}
                        mainCardReloadIcon={<SyncOutlined onClick={() => this.fetchDicomData()} />}
                    />
                }
            </Spin>
        )
    }

    fetchConfigurations = () => {
        const { testId } = this.props.match.params;

        api.get(`test-configurations/test-case/${testId}`)
            .then(res => res.data)
            .then(obj => this.handleConfigurationsLoad(obj))
            .catch(err => console.error(err));
    };

    fetchDicomData = () => {
        const { testId } = this.props.match.params;
        this.setState({ loadingThumbnails: true});

        api.get(`dicom/test-case/${testId}`)
            .then(res => res.data)
            .then(dicomData => this.fetchDicomsAndStoreThumbnails(dicomData))
            .catch(err => {
                this.setState({loadingThumbnails: false})

                if (err.response.status === 404) {
                    return message.error("Wystąpił problem z wczytywaniem plików DICOM. Prawdopodobnie zostały one przeniesione lub usunięte. Skontaktuj się z administratorem strony.");
                }

                return message.error("Wystąpił nieznany problem. Skontaktuj się z administratorem strony.");
            })
            .finally(() => this.setState({loadingThumbnails: false}));
    }
        
    fetchDicomsAndStoreThumbnails = (dicoms: any) => {
        this.setState({
            dicomThumbnails: [],
            loadingThumbnails: dicoms.length > 0
        }, () => {
            dicoms.forEach((dicom: any, index: number) => {
                const isLast = dicoms.length-1 === index;
                this.fetchSingleDicom(dicom, index, isLast);
            })
        })
    }
    
    fetchSingleDicom = (dicom: any, index: number, isLast: boolean) => {
        api
            .get(`dicom/${dicom.dicomObj.id}/image`, { responseType: 'blob' })
            .then(res => res.data)
            .then(thumbnail => this.storeDicomThumbnailData(dicom.dicomObj.id, URL.createObjectURL(thumbnail), dicom.description, index))
            .then(() => isLast && this.setState({ loadingThumbnails: false }))

    }

    storeDicomThumbnailData = (dicomId: string, thumbnailUrl: string, description: string, index: number) => {
        const newThumbnail: Thumbnail = {
            imgUrl: thumbnailUrl,
            dicomId: dicomId,
            description: description
        };

        this.setState(prevState => {
            prevState.dicomThumbnails[index] = newThumbnail;
            return {
                dicomThumbnails: prevState.dicomThumbnails
            }
        })
    }

    generateDicomThumbnails = () => {
        if (this.state.loadingThumbnails) {
            return <CustomSpin />;
        }

        return (
            <>
                {this.state.dicomThumbnails &&
                    this.state.dicomThumbnails.map(thumbnail => (
                        <ImageCard
                            description={thumbnail.description}
                            src={thumbnail.imgUrl}
                            onClick={() => this.handleChangeDicomId(thumbnail.dicomId)}
                        />
                    ))}
            </>
        );
    };

    handleChangeDicomId = (newId: string) => {        
        if (this.state.dicom) {
            return Modal.confirm({
                title: 'Potwierdź akcję',
                onOk: () => this.fetchNewDicom(newId),
                content: 'Czy na pewno chcesz wczytać nowy plik DICOM? Poprzednie dane konfiguracji zostaną utracone.',
                okText: 'Tak',
                cancelText: 'Nie',
            });
        }  
        return this.fetchNewDicom(newId);
    }

    fetchNewDicom = (dicomId: string) => {
        if (!this.state.loadingDicom) {
            this.setState({ loadingDicom: true });
        
            api
                .get(`dicom/${dicomId}`, { responseType: 'blob' })
                .then(res => res.data)
                .then(res => this.setState({ dicom: this.blobToFile(res, 'dicom.dcm'), selectedDicomId: dicomId }))
                .catch(err => console.error(err));
        }
    }

    handleConfigurationsLoad = (configObj: any) => {
        const activeConfiguration = configObj.configurations.find((config: any) => config.active);

        this.setState({
            configurations: configObj.configurations,
            selectedConfigId: activeConfiguration ? activeConfiguration.id : null,
            type: configObj.type,
        });
    };

    handleChangeConfigId = (newId: string) => {
        this.setState({
            selectedConfigId: newId,
        });
    };

    fetchTestCase = (id: string) => {
        api.get(`test-cases/${id}`)
            .then(res => res.data)
            .then(res => this.setState({ testCase: res, loading: false }));
    };

    handleSwitchToNewConfigCard = () => {
        const { testId } = this.props.match.params;
        this.props.history.push(`/test-case/${testId}/new`);
    };

    blobToFile(theBlob: any, fileName: any) {
        theBlob.lastModifiedDate = new Date();
        theBlob.name = fileName;
        return theBlob;
    }

    finishLoadingDicom = () => {
        this.setState({
            loadingDicom: false
        })
    }
}

export default TestConfigView;
