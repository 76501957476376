import React from 'react'
import { Form, Input, Alert } from 'antd';
import Container from '../components/common/Container';
import FormContainer from '../components/common/FormContainer';
import { message } from '../components/constants/dictionaries';

export type Props = {
    onLogin: (values: object) => void,
    displayLoginError: boolean,
    loading: boolean,
}

export const LoginView = (props:Props) => {
    return (
        <Container>
            <FormContainer
                loading={props.loading}
                title="Zaloguj do Qadra"
                onSubmit={(values) => props.onLogin(values)}
                submitText="Zaloguj"
            >
                { props.displayLoginError &&
                    <Alert 
                        message={message.INVALID_CREDENTIALS} 
                        type="error"
                        style={{ marginBottom: 25 }}
                    />
                }
                    <Form.Item name="email" label="Email">
                        <Input />
                    </Form.Item>
                    <Form.Item name="password" label="Hasło">
                        <Input type="password"/>
                </Form.Item>
            </FormContainer>
        </Container>
        )
}