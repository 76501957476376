import React, { Component } from 'react';

import { Row, Col, Card, message, Tooltip } from "antd";
import { PlusSquareOutlined } from '@ant-design/icons';
import {
    MainCard,
    CenteredButton,
    CustomUpload,
    ProcedureDescriptionModal,
    CustomInput,
    CustomTable,
    GreenCheckIcon,
    RedCrossIcon,
    StyledQuestionIcon
} from "../common";

import styled from "styled-components";
import { Circle, Point } from '../../utils/Models';
import { getCircleThrough3Point } from '../../utils/mathHelpers';
import DwvComponent from '../common/Dwv/DwvComponent';
import { api } from '../../api';
import { IRouter } from '../../utils/Interfaces';

type testParams = {
    givenDiameter: string,
    measuredDiameter: number,
    calculation: number,
    isSuccesfull: boolean,
}

type configuration = {
    id: string, 
    active: boolean,
    params: string,
}

type State = {
    visible: boolean,
    dwvState: any,
    drawings: any,
    dicom: any,
    isDicomLoaded: boolean,
    draw: boolean,
    points: Array<Point>,
    roiCircle: Circle,
    pixelParam: string,
    testPerformed: boolean,
    reset: boolean,
    params: testParams,
    activeConfiguration: configuration,
}

type Props = {
    router: IRouter,
    thumbnails: React.ReactNode,
    dicom: any,
    selectedDicomId: string | any,
    mainCardReloadIcon: React.ReactNode,
    fetchThumbnails: () => void,
    onLoadingDicomFinish: () => void,
    onSavingTestStart: () => void,
    onSavingTestFinish: () => void
}

export default class GeometricCorrectnessTest extends Component<Props, State>{
    constructor(props: any){
        super(props)
        
        this.state = {
            visible: false,
            dwvState: {},
            drawings: [],
            dicom: null,
            isDicomLoaded: false,
            draw: false,
            points: [],
            roiCircle: { center: { x: 0, y: 0 }, r: 0 },
            pixelParam: '',
            testPerformed: false,
            reset: false,
            params: {
                givenDiameter: '',
                measuredDiameter: 0,
                calculation: 0,
                isSuccesfull: false,
            },
            activeConfiguration: {
                id: '',
                active: false,
                params: ''
            }
        }
    }

    componentDidMount() {
        this.loadConfiguration();
    }

    render() {
        const {
            visible,
            draw,
            roiCircle,
            points,
            params: { givenDiameter },
            isDicomLoaded,
            testPerformed,
            reset,
        } = this.state;

        const canPerformTest = isDicomLoaded && points.length === 3 && givenDiameter;
        if(canPerformTest && !testPerformed) {
            this.performGCTest();
        }

        return (
            <>
                <Row justify='space-between'>
                    <Col>
                        <PerformExaminationDetailsCard
                            type="inner"
                            title={
                                <>
                                    <span>Geometryczna Poprawność </span>
                                    <CenteredButton
                                        disabled={!testPerformed}
                                        onClick={() => this.saveTestResult()}
                                        label="Zachowaj wynik testu"/>
                                    <CenteredButton
                                        onClick={() => this.openModal()}
                                        label="Opis procedury"/>
                                </>
                            }>
                        </PerformExaminationDetailsCard>
                        <MainCard title="Wyniki Pomiaru">
                            <Row gutter={[2, 2]} align="middle" justify="start">
                                    <Col>
                                        <CustomInput color="#D779F2" disabled label="Średnica obiektu (mm):" 
                                                     type="number" value={this.handleRadiusToMM((this.state.roiCircle.r) * 2)}/>
                                    </Col>
                                    <Col>
                                        <Tooltip title={"Zmierz średnicę obiektu"}>
                                            <StyledPlusIcon onClick={this.resetMeasurement}/>
                                        </Tooltip>
                                    </Col>
                                    <Col>
                                        <Tooltip title={"Zaznacz trzy punkty na obwodzie mierzonego obiektu"}>
                                            <StyledQuestionIcon />
                                        </Tooltip>
                                    </Col>
                            </Row>
                        </MainCard>
                        <MainCard title="Wynik Testu">
                            {testPerformed && this.displayResult()}
                        </MainCard>
                    </Col>
                    <Col xxl={{span: 12}} xl={{span: 11}} lg={{span: 10}}>
                        <MainCard title="Obrazy w teście">
                            <DwvComponent
                                {...this.props}
                                draw={draw}
                                circle={roiCircle}
                                handleDicomLoading={this.handleDicomLoading}
                                onCanvasClick={this.addPoint}
                                points={points}
                                setPixelParam={this.setPixelParam}
                                reset={reset}
                            />
                        </MainCard>
                    </Col>
                    <Col span={4}>
                        <MainCard title="Ostatnio dodane" icon={this.props.mainCardReloadIcon}>
                            <SideBar>
                                {this.props.thumbnails}
                            </SideBar>
                        </MainCard>
                    </Col>
                </Row>
                <ProcedureDescriptionModal
                    visible={visible}
                    title="Opis procedury"
                    closeModal={this.closeModal}/>
            </>
        );
    }

    loadConfiguration = () => {
        const { testId } = this.props.router.match.params;
        api
            .get(`test-configurations/test-case/${testId}/`)
            .then(res => res.data)
            .then(data => data.configurations.find((c:configuration) => c.active == true))
            .then((configuration) => {
                if(configuration) {
                    this.setState(prevState => ({ activeConfiguration: configuration, 
                                                 params: {...prevState.params, givenDiameter: JSON.parse(configuration.configuration).diameter} }))
                } else {
                    setTimeout(() =>  { 
                        return this.props.router.history.push(
                            this.props.router.location.pathname.replace(/[^/]*$/, 'settings'));
                        }
                    , 1000)
                }
            })
            .catch(err => console.error(err));
    }

    saveTestResult = () => {
        const { dwvState, drawings, params, testPerformed, activeConfiguration } = this.state;
        
        if (!testPerformed)
            return message.error('Nie wykonano testu.');

        this.setState({
            testPerformed: false,
        })

        this.props.onSavingTestStart()

        const result = {
            dwvState: dwvState,
            drawings: drawings,
            result: params
        };

        const body = {
            Passed: params.isSuccesfull,
            Deactivated: true,
            TestData: JSON.stringify(result),
            DicomId: this.props.selectedDicomId,
            TestConfigurationId: activeConfiguration.id 
        };
        
        const { testId } = this.props.router.match.params;

        api
            .post(`test-results/test-case/${testId}`, { ...body })
            .then(() => setTimeout(() => this.props.onSavingTestFinish(), 2000))
            .catch(err => message.error(`${err}`));   
    }

    openModal = () => {
        this.setState({ visible: true });
    };

    closeModal = () => {
        this.setState({ visible: false });
    };

    performGCTest = () => {
        const { roiCircle, params }  = this.state;
        if(roiCircle.r > 0 && params.givenDiameter) {
            const diameterOfCircleInMM = this.handleRadiusToMM(roiCircle.r) * 2;
            const calculation = Math.abs(parseFloat(params.givenDiameter) - diameterOfCircleInMM);
            const difference = calculation < 1;
            this.setState({
                testPerformed: true,
                params: { ...params, measuredDiameter: diameterOfCircleInMM, calculation: calculation, isSuccesfull: difference }
            })
        }
    }

    handleRadiusToMM = (r: number): number => {
        const { pixelParam } = this.state;
        if (parseFloat(pixelParam) > 0 && r > 0) {
            return parseFloat((r * parseFloat(pixelParam)).toFixed(2));
        }
        return parseFloat(r.toFixed(2));
    }

    resetMeasurement = () => {
        const { params, isDicomLoaded } = this.state;
        
        if( isDicomLoaded ){
            this.setState({
                draw: false, 
                points: [],
                params: {...params, measuredDiameter: 0, calculation: 0, isSuccesfull: false},
                reset: true,
                roiCircle: { center: { x: 0, y: 0 }, r: 0 },
                testPerformed: false,
            }, () => this.setState({
                reset: false
            }));
        }
    }

    displayResult = () => {
        const { params } = this.state;
        
        const dataSource = [
            {
                Nazwa: "Średnica",
                Pomiar: params.measuredDiameter,
                Odniesienie: parseFloat(params.givenDiameter).toFixed(2),
                Odchylenie: params.calculation.toFixed(2),
                Poprawny: params.isSuccesfull ? <GreenCheckIcon />: <RedCrossIcon />
            },
            {
                Nazwa: "Wynik testu",
                Poprawny: params.isSuccesfull ? <GreenCheckIcon />: <RedCrossIcon />
            }
        ]
        return <CustomTable data={dataSource} columns={this.columns} />
    }

    handleDicomLoading = (state: boolean) => {
        this.setState({ isDicomLoaded: state });
    };

    addPoint = (a: number, b: number) => {
        const { points, params: { givenDiameter } } = this.state;
        
        if(!givenDiameter) {
            message.warning('Nie podano średnicy.');
            return
        }

        if (points.length < 3 ) {
            const point: Point = { x: a, y: b };
            this.setState({ points: [...points, point] }, () => {
                if (this.state.points.length === 3) {
                const circle: Circle = getCircleThrough3Point(
                            this.state.points[0],
                            this.state.points[1],
                            this.state.points[2]
                            );
                    this.setState({
                        draw: true,
                        roiCircle: circle});
                }
            });
        }
    }

    setPixelParam = (e:any) => {
        this.setState({
            pixelParam: e
        });
    }

    columns = [
        {
            title: "Nazwa",
            dataIndex: "Nazwa",
            key: "Nazwa",
        },
        {
            title: "Pomiar",
            dataIndex: "Pomiar",
            key: "Pomiar",
        },
        {
            title: "Odniesienie",
            dataIndex: "Odniesienie",
            key: "Odniesienie",
        },
        {
            title: "Odchylenie",
            dataIndex: "Odchylenie",
            key: "Odchylenie",
        },
        {
            title: "Poprawny",
            key: "Poprawny",
            dataIndex: "Poprawny",
        },
    ];
}


const PerformExaminationDetailsCard = styled(Card)`
    .ant-card-head-title {
        font-size: 24px;
        font-weight: 600;
        color: #505458;
        padding-top: 25px;
        border: none;
    }
`;

const StyledPlusIcon = styled(PlusSquareOutlined)`
    margin-left: 10px;
    padding-top: 15px;
    font-size: 20px;
    color: grey;
    &:hover{
        color: black;
        cursor: pointer;
    }
`;

const Img = styled.img`
    width: 90%;
    height: 90%;
`;

const SideBar = styled.div`
    overflow: auto;
    height: 80vh;
`;
