import React, { Component } from "react";
import { Row, Col, message, Spin, Collapse, Button, Checkbox, Modal, Input } from "antd";
import { MainCard, ProcedureDescriptionModal } from "../../common";
import DwvComponent from "../../common/Dwv/DwvComponent";
import styled from "styled-components";
import { Point, Circle, ParamType } from "../../../utils/Models";
import { getCircleThrough3Point, handleRadiusToCm } from "../../../utils/mathHelpers";
import { api } from "../../../api";
import { IRouter } from "../../../utils/Interfaces";
import { configurationHuWater, referenceHuWater } from "../../../utils/Models";
import { Configuration } from "../HuWaterPhantom/";
import moment from "moment";
import _ from "lodash"
import { NEW_CONFIG_INFO } from "../../../utils/modalConfirm";

type referenceCandidateHuWater = {
    id: string;
    kV: string;
    mA: string;
    date: string;
}

type testResult = {
    user: any,
    result: {
        dateOfTest: string;
        deactivated: boolean;
        id: string;
        passed: boolean;
        performerId: string;
        testCaseId: string;
        testConfigurationId: string;
        testData: string;
    }
}

type testDataHu = {
    params: {
        kV: number | string;
        mA: number | string;
    },
    testData: {
        key: number;
        name: string;
        result: any;
    }[]
}

type ButtonType = "points" | "roi" | null;

type State = {
    visible: boolean;
    points: Array<Point> | [];
    draw: boolean;
    canChoosePoints: boolean;
    isDicomLoaded: boolean;
    buttonState: ButtonType;
    loading: boolean;
    pixelSpacing: string | null;
    mode: "CONFIGURATION" | "REFERENCE";
    results: Array<testResult>;
    choosenTestsForReference: Array<referenceCandidateHuWater>;
    newReference: referenceHuWater;
    mainPanelKey: string | Array<string>;
    secondaryPanelKey: string | Array<string>;
    newConfiguration: configurationHuWater,
    resetDrawings: boolean;
};

type Props = {
    loadingDicom: boolean;
    router: IRouter;
    thumbnails: React.ReactNode;
    dicom: any;
    selectedDicomId: string | any;
    selectedConfigId: string | null,
    handleChangeConfigId: (id: string) => void
    fetchThumbnails: () => void;
    id: string | null;
    configurations: { active: boolean; configuration: string; id: string; testCaseId: string }[];
    onLoadingDicomFinish: () => void;
    fetchConfigurations: () => void;
    mainCardReloadIcon: React.ReactNode,
};

const DEFAULT_CONFIGURATION = {
    name: moment().format("YYYY-MM-DD HH:mm"),
    kVDeviation: 0,
    mADeviation: 0,
    references: [],
    circle: { center: { x: 0, y: 0, offsetX: 0, offsetY: 0 }, r: 0,  },
    roiCircle: { center: { x: 0, y: 0, offsetX: 0, offsetY: 0 }, r: 0 },
    pixelSpacing: ''
};

export class HuWaterPhantomSettings extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            visible: false,
            points: [],
            draw: false,
            canChoosePoints: false,
            isDicomLoaded: false,
            buttonState: "points",
            loading: false,
            pixelSpacing: null,
            newConfiguration: DEFAULT_CONFIGURATION,
            mode: "CONFIGURATION",
            results: [],
            choosenTestsForReference: [],
            mainPanelKey: '',
            secondaryPanelKey: '',
            newReference: {
                name: moment().format("YYYY-MM-DD HH:mm"),
                active: true,
                averagekV: 0,
                averagemA: 0,
                choosenTests: []
            },
            resetDrawings: false
        };
    }

    componentDidMount() {
        this.fetchLastTestResults();
    }

    componentDidUpdate(prevProps: Props, _:State) {
        if (prevProps.loadingDicom && !this.props.loadingDicom) {
            this.setState({ newConfiguration: DEFAULT_CONFIGURATION });
            this.handleDeleteDrawings();
        }
            
    }

    render() {
        const {
            points,
            draw,
            newConfiguration: { circle, roiCircle },
            visible,
            loading,
            mainPanelKey,
            mode,
            isDicomLoaded,
            pixelSpacing,
            resetDrawings
        } = this.state;
         
        
        return (
            <Spin spinning={loading} tip='Trwa zapisywanie konfiguracji'>
                <Row gutter={[24, 24]}>
                    <Col span={7}>
                        <StyledCollapse>
                            <StyledPanel header="Konfiguracja" key="MAINPANEL">
                                <StyledCollapse accordion activeKey={mainPanelKey} onChange={(key) => this.handleChangeMainPanelKeys(key)}>
                                    <StyledPanel header="Nowa Konfiguracja" key="CONFIGURATION">
                                        <Configuration
                                            newConfiguration={this.state.newConfiguration}
                                            handleAddPoints={this.handleAddPoints}
                                            handleParamsChange={this.handleParamsChange}
                                            handleRadiusChange={this.handleRadiusChange}
                                            handleRadiusToCm={(r) => handleRadiusToCm(r, pixelSpacing)}
                                            handleRoi={this.handleRoi}
                                            isDicomLoaded={isDicomLoaded}
                                            points={points}
                                            onFinish={this.saveParams}
                                            handleChangeConfigurationName={this.handleChangeConfigurationName}
                                            handleDeleteDrawings={this.handleDeleteDrawings}
                                        />
                                    </StyledPanel> 
                                    {this.returnConfigurationsContent(this.props)}
                                </StyledCollapse>
                            </StyledPanel>
                        </StyledCollapse>
                    </Col>       
                    <Col span={12} style={{ display: mode === "CONFIGURATION" ? "block" : "none"}}>
                        <MainCard title='Obrazy w teście'>
                            <DwvComponent
                                {...this.props}
                                onCanvasClick={this.addPoint}
                                draw={draw}
                                circle={circle}
                                roiCircle={roiCircle}
                                points={points}
                                handleDrag={this.handleDrag}
                                handleDragRoi={this.handleDragRoi}
                                updateParams={this.handleParamsChange}
                                handleDicomLoading={this.handleDicomLoading}
                                handleSave={this.handleSave}
                                displayMoveButton={true}
                                waterPhantom={true}
                                reset={resetDrawings}
                            />
                        </MainCard>
                    </Col>
                    <Col span={4} style={{ display: mode === "CONFIGURATION" ? "block" : "none"}}>
                        <MainCard title='Ostatnio dodane' icon={this.props.mainCardReloadIcon}>
                            <SideBar>{this.props.thumbnails}</SideBar>
                        </MainCard>
                    </Col>
                </Row>
                <ProcedureDescriptionModal visible={visible} closeModal={this.closeModal} />
            </Spin>
        );
    }

    handleSelectedReferenceChange = (checked: Boolean, reference: referenceCandidateHuWater) => {
        this.setState(prevState => ({
            choosenTestsForReference: checked ? [...prevState.choosenTestsForReference, reference] : prevState.choosenTestsForReference.filter(r => r.id !== reference.id),
        }), () => this.setState(prevState => ({
            newReference: {
                ...prevState.newReference,
                averagemA: parseFloat(this.getAverageValue('mA') || '0'),
                averagekV: parseFloat(this.getAverageValue('kV') || '0'),
                choosenTests: prevState.choosenTestsForReference
            },
        })))
    }

    getAverageValue = (param: 'kV' | 'mA') => {
        const { choosenTestsForReference } = this.state;
        const average = choosenTestsForReference.reduce((a, b) => a + parseFloat(b[param]), 0) / choosenTestsForReference.length;
        return average ? average.toFixed(2) : 0
    }

    fetchLastTestResults = () => {
        const { testId } = this.props.router.match.params;
        api
            .get(`test-results/test-case/${testId}`)
            .then(res => res.data)
            .then(res => this.setState({ results: res.results }))
    }

    openModal = () => {
        this.setState({ visible: true });
    };

    returnConfigurationsContent = ({configurations}: Props) => {
        const { secondaryPanelKey, newReference } = this.state;

        if(configurations.length === 0 ) {
            return null
        }

        const confPanels = configurations.map(c => {
            const fromJson = this.configurationFromJson(c.configuration);
            let name = fromJson.name;
            if(c.active) {
                name += ' (Aktywna)';
            }
            return(
                <StyledPanel key={c.id} header={name}>
                    <Row>Średnica Fantomu: {handleRadiusToCm(fromJson.circle.r * 2, fromJson.pixelSpacing).toFixed(2)}</Row>
                    <Row>Środek pomiaru wodnego: [{`${fromJson.circle.center.x.toFixed(2)}, ${fromJson.circle.center.y.toFixed(2)}`}]</Row>
                    <Row>Środek ROI: [{`${fromJson.roiCircle.center.x.toFixed(2)}, ${fromJson.roiCircle.center.y.toFixed(2)}`}] </Row>
                    <Row>Odniesienie kV: {fromJson.kVDeviation}</Row>
                    <Row>Odniesienie mA: {fromJson.mADeviation}</Row>

                    {c.active && `Aktywna`}
                    {c.active && 
                        <StyledCollapse activeKey={secondaryPanelKey} onChange={key => this.handleChangeSecondaryPanelKeys(key)}>
                            <StyledPanel header="Dodaj wartość odniesienia" key="REFERENCES">
                                <Row>
                                    Nazwa:
                                    <Input value={newReference.name} onChange={(e) => this.onChangeReferenceName(e.target.value)} />
                                </Row>
                                <Row>
                                    kV: {this.getAverageValue('kV')}
                                </Row>
                                <Row>
                                    mA: {this.getAverageValue('mA')}
                                </Row>
                                <Row style={{marginTop: 10}}> Wybierz pomiary </Row>
                                {this.state.results
                                    .filter((config: testResult) => config.result.testConfigurationId === this.props.selectedConfigId)
                                    .map(config => {
                                        const dataSource:testDataHu = JSON.parse(config.result.testData).testData;
                                        
                                        const reference = {
                                            id: config.result.id,
                                            hu: dataSource[0].measurement,
                                            kV: dataSource[1].measurement,
                                            mA: dataSource[2].measurement,
                                            date: config.result.dateOfTest
                                        }

                                        return (
                                            <Row style={{ marginTop: 8 }}>
                                                <CCheckbox
                                                    key={config.result.id}
                                                    onChange={e => this.handleSelectedReferenceChange(e.target.checked, reference)}
                                                    checked={this.state.choosenTestsForReference.map(r => r.id).indexOf(config.result.id) >= 0}
                                                >
                                                    <Row>
                                                        {moment(config.result.dateOfTest).format("YYYY-MM-DD HH:mm")}
                                                        {' '}
                                                        HU: {reference.hu}, 
                                                        kV: {reference.kV}, 
                                                        mAs: {reference.mA}
                                                    </Row>
                                                </CCheckbox>
                                            </Row>
                                        );
                                    }
                                )}
                                <Button onClick={() => this.handleAddNewReference()}> Zapisz wartość odniesienia</Button>
                            </StyledPanel>
                            {
                                this.configurationHaveReferences(fromJson) 
                                ?   this.returnConfigurationReferences(fromJson.references)
                                :   null
                            }
                        </StyledCollapse>
                    }
                </StyledPanel>
            )
        })

        return confPanels
    }

    onChangeReferenceName = (name: string) => {
        this.setState(prevState => ({
            newReference: {
                ...prevState.newReference,
                name: name
            }
        }));
    }

    returnConfigurationReferences = (references: Array<referenceHuWater>) => {
        return (
            references.map((reference:referenceHuWater, index: number) => {
                let referenceName = reference.name;
                if(reference.active) {
                    referenceName += ' (aktywna)';
                }
                return (
                    <StyledPanel header={referenceName} key={index}>
                        <Row>kV {reference.averagekV}</Row>
                        <Row>mA {reference.averagemA} </Row>
                        <Row style={{marginTop: 10}}> Pomiary </Row>
                        {reference.choosenTests && reference.choosenTests.map((test: any, index: number) => (
                        <Col span={24} style={{ textAlign: 'left', marginTop: 8 }}>
                            {index + 1}.
                            Pomiar {moment(test.date).format("YYYY-MM-DD HH:mm")},
                            SNR {test.SNR},
                            kV {test.kV},
                            mA {test.mA}
                        </Col>
                    ))}

                        {reference.active 
                        ? <p>Aktywna</p>
                        : <Button onClick={() => this.activateReference(reference)}>Aktywuj</Button>}
                    </StyledPanel>
                )
            })
        )
    }

    configurationHaveReferences = (configuration: configurationHuWater): boolean => {
        return configuration.references && configuration.references.length >= 1;
    }

    configurationFromJson = (conf: string):configurationHuWater => {
        return JSON.parse(conf);
    }

    activateReference = (reference: referenceHuWater) => {
        const currentConf = this.getCurrentConfigurationParams();
        if (!currentConf) {
            return
        }
        const params = JSON.parse(currentConf.configuration as any);

        if (!params) {
            return
        }

        const previousActive = params.references.find((ref: referenceHuWater) => ref.active == true);
        const currentlyActive = params.references.find((ref: referenceHuWater) => 
            ref.averagekV === reference.averagekV && 
            ref.averagemA === reference.averagemA && 
            reference.active === false
        )

        if(previousActive) {
            previousActive.active = false;
        }
        currentlyActive.active = true;

        this.setState({loading: true, newReference: currentlyActive}, () => {
            const updatedConf = {
                Content: JSON.stringify({
                    ...params
                })
            }
            this.handleSaveChanges(currentConf, updatedConf);
        });
    }

    handleChangeMainPanelKeys = (keys: Array<string> | string) => {
        this.setState({ mainPanelKey: keys });
        if(_.isString(keys)) {
            return this.handleKeyAsString(keys as string);
        } 

        this.handleKeyAsArray(keys as Array<string>);        
    }

    handleChangeSecondaryPanelKeys = (keys: Array<string> | string ) => {
        this.setState({ secondaryPanelKey: keys });

        if(_.isString(keys)) {
            return this.handleKeyAsString(keys as string);
        } 

        this.handleKeyAsArray(keys as Array<string>);    
    }

    handleKeyAsString = (key: string) => {
        if(key === "CONFIGURATION") {
            const text = NEW_CONFIG_INFO;
            if (this.props.configurations.length > 0) {
                this.showPromptModal((p) => {}, '', text, () => {this.setState({mainPanelKey: this.props.selectedConfigId || ''})});
            }
            
            this.setState({ mode: "CONFIGURATION"});
        }

        if (this.isConfigurationPanelSelected(key)) {
            this.props.handleChangeConfigId(key);
        } 
    }

    showPromptModal = (handleOkCallback: (p: any) => void, param: any,  text: string, handleCancelCallback?:() => void) => {
        Modal.confirm({
            content: text,
            okText: 'TAK',
            cancelText: 'NIE',
            onOk() {
                handleOkCallback(param);
            },
            onCancel() {
                if(handleCancelCallback) {
                    handleCancelCallback();
                }
            }
        })
    }

    handleKeyAsArray = (key: Array<string>) => {
        if(key && key.length >= 1) {
            this.handleKeyAsString(key[0]);
        }
    }

    isConfigurationPanelSelected = (key: string) => {
        return key && key.length > 30;
    }

    isReferencePanelSelected = (key: string) => {
        return !isNaN(+key) || key === "REFERENCES";
    }
    handleAddNewReference = () => {
        const currentConf = this.getCurrentConfigurationParams();

        if(!currentConf) {
            return 
        }

        this.setState({ loading: true })

        const { newReference } = this.state;
        const params: configurationHuWater = JSON.parse(currentConf.configuration);
        const activeRef = params.references.find((ref: any) => ref.active == true);
        
        if(activeRef) {
            activeRef.active = false;
        }
        
        params.references.push(newReference);

        const updatedConf = {
            Content: JSON.stringify({
                ...params
            })
        }

        this.handleSaveChanges(currentConf, updatedConf);
    }

    handleSaveChanges = (currentConf: any, updatedConf: any) => {
        api
            .patch(`/test-cases/test-configurations/${currentConf.id}`, updatedConf)
            .then(res => res.data)
            .then(() => this.props.fetchConfigurations())
            .then(() => this.handleRecalculateChoosenTestsResults())
            .catch(err => console.error(err))
            .finally(() =>  this.setState({ loading: false }));
    }


    handleRecalculateChoosenTestsResults =  async () => {
        const { results, newReference} = this.state;
        const configuration = this.getCurrentConfigurationParams();
        if(!configuration) {
            return
        }
        const parsedConfiguration: configurationHuWater = JSON.parse(`${configuration.configuration}`);
        const testPerformedOnCurrentConfigId = results.filter(this.findSelectedTests);
        
        for (let test of testPerformedOnCurrentConfigId) {
            await this.changeTestDataAndSaveToDatabase(test, newReference, parsedConfiguration);
        }
    }

    findSelectedTests = (config: testResult) => {
        const { selectedConfigId } = this.props;
        const { choosenTestsForReference } = this.state;
        return config.result.testConfigurationId === selectedConfigId && choosenTestsForReference.find(test => test.id === config.result.id)
    }

    changeTestDataAndSaveToDatabase = async (test: testResult, newReference: referenceHuWater, currentConfiguration: configurationHuWater) => {
        const newTestData = []
        const testData: testDataHu["testData"] = JSON.parse(test.result.testData).testData;
        const params: testDataHu["params"] = JSON.parse(test.result.testData).values;

        const oldHUData = testData.find((config:any) => config.key === "1");
        newTestData.push(oldHUData);

        const hu_result = oldHUData && oldHUData.result;

        const kv_deviation = (parseFloat(`${newReference.averagekV}`) / +params.kV - 1) * 100; 
        const kv_result = Math.abs(kv_deviation) <= currentConfiguration.kVDeviation;   

        const kVData = {
            key: "2",
            name: "kV",
            measurement: params.kV,
            reference: newReference.averagekV, 
            deviation: `${kv_deviation.toFixed(2)}%`,
            result: kv_result,
        };
        newTestData.push(kVData);

        const mA_deviation = ( ( newReference.averagemA / +params.mA ) - 1) * 100; 

        const mA_result = Math.abs(mA_deviation) <= currentConfiguration.mADeviation; 
        const mAData = {
            key: "3",
            name: "mA",
            measurement: params.mA,
            reference: newReference.averagemA, 
            deviation: `${mA_deviation.toFixed(2)}%`,
            result: mA_result,
        };
        newTestData.push(mAData);


        const result = {
            key: "4",
            name: "Wynik testu",
            result: mA_result && kv_result && hu_result            
        };

        newTestData.push(result);

        const patchTestResultContent = {
            values: params,
            testData: newTestData
        }

        const requestBody = {
            Passed: mA_result && kv_result && hu_result,
            TestData: JSON.stringify(patchTestResultContent),
            Deactivated: test.result.deactivated
        }
        
        await api
                .patch(`test-results/${test.result.id}`, { ...requestBody })
                .catch(err => console.error(err));
        
    } 


    getCurrentConfigurationParams = () => {
        const { configurations, selectedConfigId } = this.props;
        return configurations.find((conf: {active: boolean, configuration: string, id: string, testCaseId: string}) => conf.id === selectedConfigId);
    }


    handleAddPoints = () => {
        const { isDicomLoaded, newConfiguration: { circle: { r } } } = this.state;

        if (!isDicomLoaded) {
            return message.info("Aby dodać pomiar wybierz plik Dicom");
        }

        if (r > 0) {
            this.handleRoi();
        }

        this.setState({ canChoosePoints: true });
    };

    handleRoi = () => {
        const { isDicomLoaded, points } = this.state;

        if (!isDicomLoaded || points.length === 0) {
            return message.info("Aby móc zaznaczyć środek okręgu ROI musisz zaznaczyć 3 wymagane puntky");
        }

        this.setState({
            canChoosePoints: true,
            draw: false,
            buttonState: "roi",
        });
    };

    closeModal = () => {
        this.setState({ visible: false });
    };

    // TODO: handleDragRoi i handleRoi dodać jako jedną funkcję z dodatkowym parametrem

    handleDragRoi = (newAttrs: any) => {
        this.setState(prevState => {
            const { newConfiguration } = prevState;
            const { roiCircle } = newConfiguration;
            return {
                newConfiguration: {
                    ...newConfiguration,
                    roiCircle: {
                        ...roiCircle,
                        center: {
                            ...roiCircle.center,
                            offsetX: newAttrs.x,
                            offsetY: newAttrs.y
                        }
                    }
                }
            }
        })
    }

    handleDrag = (newAttrs: any) => {
        this.setState(prevState => {
            const { newConfiguration } = prevState;
            const { circle } = newConfiguration;
            return {
                newConfiguration: {
                    ...newConfiguration,
                    circle: {
                        ...circle,
                        center: {
                            ...circle.center,
                            offsetX: newAttrs.x,
                            offsetY: newAttrs.y
                        }
                    }
                }
            }
        })
    }

    handleRadiusChange = (value: number) => {
        this.setState(prevState => {
            const circle: Circle = {
                center: prevState.newConfiguration.circle.center,
                r: value,
            };
            return { newConfiguration: { ...prevState.newConfiguration, circle } };
        });
    };

    addPoint = (a: number, b: number) => {
        const {
            points,
            canChoosePoints,
            buttonState,
            newConfiguration: { circle },
        } = this.state;

        switch (buttonState) {
            case "points":
                if (points.length < 3 && canChoosePoints) {
                    const point: Point = { x: a, y: b };
                    this.setState({ points: [...points, point] }, () => {
                        if (this.state.points.length === 3) {
                            const circle: Circle = getCircleThrough3Point(
                                this.state.points[0],
                                this.state.points[1],
                                this.state.points[2]
                            );
                            this.setState(prevState => {
                                return {
                                    draw: true,
                                    newConfiguration: {
                                        ...prevState.newConfiguration, circle, roiCircle: {
                                            center: { x: circle.center.x, y: circle.center.y },
                                            r: 0.1 * circle.r,
                                        },
                                    },
                                    canChoosePoints: false,
                                };
                            });
                        }
                    });
                }
                break;
            case "roi":
                if (circle.r > 0 && canChoosePoints) {
                    this.setState(prevState => {
                        return {
                            draw: true,
                            newConfiguration: {
                                ...prevState.newConfiguration,
                                roiCircle: {
                                    center: { x: a, y: b },
                                    r: 0.1 * circle.r,
                                },
                            },
                            canChoosePoints: false,
                        };
                    });
                }
            default:
                break;
        }
    };

    handleDicomLoading = (state: boolean) => {
        this.setState({ isDicomLoaded: state });
    };

    handleChangeConfigurationName = (name: string) => {
        this.setState(prevState => {
            return { newConfiguration: { ...prevState.newConfiguration, name } };
        });
    };

    saveParams = () => {
        const {
            newConfiguration: { circle, roiCircle },
            points
        } = this.state;

        if (circle.r <= 0) {
            message.warning(`Nie utworzono okręgu, ilość zaznaczonych punktów ${points.length}`);
        } else if (roiCircle.r <= 0) {
            message.warning("Nie utworzono okręgu ROI");
        } else {
            this.handleSave();
        }
    };

    handleSave = () => {
      
        const { newConfiguration, pixelSpacing } = this.state;
        const { circle, roiCircle } = newConfiguration;

        const { id } = this.props;

        const resultCircle: Circle = {
            r: circle.r,
            center: {
                x: circle.center.x + (circle.center.offsetX || 0),
                y: circle.center.y + (circle.center.offsetY || 0),
            }
        } 
        
        const resultRoiCircle: Circle = {
            r: roiCircle.r,
            center: {
                x: roiCircle.center.x + (roiCircle.center.offsetX || 0),
                y: roiCircle.center.y + (roiCircle.center.offsetY || 0),
            }
        }

        const config = JSON.stringify({
            ...newConfiguration,
            circle: resultCircle,
            roiCircle: resultRoiCircle,
            pixelSpacing: pixelSpacing
        });

        this.setState({loading: true}, () => {
            api
                .post(`test-configurations/test-case/${id}`, { Content: config })
                .then((res) => {
                    message.success("Utworzono nową konfigurację");
                    this.props.fetchConfigurations();
                    this.setState({ loading: false, newConfiguration: DEFAULT_CONFIGURATION, mode: "CONFIGURATION", mainPanelKey: res.data.id });
                })
                .catch(err => {
                    message.warning("Nie udało się utworzyć konfiguracji");
                    this.setState({ loading: false });
                });
        })
    };

    handleParamsChange = (label: ParamType, value: number | string) => {
        switch (label) {
            case "name":
                this.setState(prevState => {
                    return { newConfiguration: { ...prevState.newConfiguration, name: `${value}`}}
                });
                break;

            case "kVDeviation":
                this.setState(prevState => {
                    return { newConfiguration: { ...prevState.newConfiguration, kVDeviation: +value } };
                });
                break;

            case "mADeviation":
                this.setState(prevState => {
                    return { newConfiguration: { ...prevState.newConfiguration, mADeviation: +value } };
                });
                break;

            case "pixelSpacing":
                this.setState({ pixelSpacing: `${value}` });
                break;

            default:
                break;
        }
    };

    handleDeleteDrawings = () => {
        this.setState(prevState => ({ resetDrawings: !prevState.resetDrawings, canChoosePoints: true, draw: false, points: [], newConfiguration: {...prevState.newConfiguration, circle: { center: { x: 0, y: 0, offsetX: 0, offsetY: 0 }, r: 0 }}}));
    }
}

export default HuWaterPhantomSettings;

const SideBar = styled.div`
    overflow: auto;
    height: 80vh;
`;

const StyledCollapse = styled(Collapse)`
    background-color: rgb(31, 56, 83);
    color: #ffffff;
    text-align: center;
    .ant-collapse-item {
        .ant-collapse-header {
            color: #ffffff;
        }
    }
`;

const StyledPanel = styled(Collapse.Panel)`
    .anticon.anticon-right.ant-collapse-arrow {
        color: #ffffff;
    }
`;

const CCheckbox = styled(Checkbox)`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 0px !important ;
`;