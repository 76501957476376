import React, { Component } from "react";
import { Col, Row, Spin, message, Statistic, Collapse, Space} from "antd";
import styled from "styled-components";
import {
    CustomInput,
    NewTestConfigurationCard,
    ActionButton,
} from "../../common";
import { IRouter } from "../../../utils/Interfaces";
import { api } from "../../../api";
import { showConfirmModal, NEW_CONFIG_INFO } from '../../../utils/modalConfirm';

type State = {
    diameter: number,
    confName: string,
    loading: boolean,
    activeKeys: any
}

type Props = {
    id: string,
    configurations: Array<any>,
    selectedConfigId: string | null
    handleChangeConfigId: (id: string) => void,
    router: IRouter,
    fetchConfigurations: () => void,
    onLoadingDicomFinish: () => void;
}

export default class GeometricCorrectnessSettings extends Component<Props, State> {
    constructor(props: Props){
        super(props)
        this.state = {
            diameter: 0,
            confName: '',
            loading: false,
            activeKeys: []
        }
    }

    render() {
        const { confName, loading, activeKeys } = this.state; 
        const { configurations, handleChangeConfigId } = this.props;
        const activeConfId = this.getActiveItemId();
        
        return (
            <Row justify="start">
            <Col span={8}>
            <Spin spinning={loading} >
                <StyledCollapse>
                    <Collapse.Panel header='Konfiguracje' key='MAINPANEL'>
                        <StyledCollapse defaultActiveKey={activeConfId} activeKey={activeKeys} onChange={this.onChangeCollapse} accordion={true}>
                            <StyledPanel header="Nowa Konfiguracja" key='NEW_CONFIGURATION'>
                                <NewTestConfigurationCard 
                                    testName="Geometryczna poprawność obrazu"
                                    configName={confName}
                                    onNameChange={(e) => this.setState({ confName: e })}
                                    onFinish={this.handleSaveConfiguration}
                                    children={this.generateNewTestConfigurationCardContent()}
                                />
                            </StyledPanel>
                            {configurations.map((item: any, key: number) => {
                                let configurationName = JSON.parse(item.configuration).name;
                                if (item.id === activeConfId) {
                                    configurationName += ' (Aktywna)';
                                }
                                return (
                                    <StyledPanel key={item.id} header={`${configurationName}`}>
                                        <Space direction='vertical'>
                                            {this.showSelectedConfigParams(item)}
                                        </Space>
                                    </StyledPanel>
                                )
                            })}
                        </StyledCollapse>
                    </Collapse.Panel>
                </StyledCollapse>
            </Spin>
            </Col>
            </Row>
        );
    }

    showSelectedConfigParams = (item: any) => {
        const configuration = item && JSON.parse(item.configuration)
        const { diameter } = configuration;

        return (
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <CustomStatistic title="Wartośc odchylenia (średnica mm)" value={diameter} />
                </Col>
            </Row>
        )
    }

    handleSaveConfiguration = () => {
        if(this.validateConfigurationInput()) {
            const { diameter, confName } = this.state; 
            
            this.setState({ loading: true });
            
            const body = {
                Content: JSON.stringify({ diameter: diameter, name: confName }),
            }

            api
                .post(`test-configurations/test-case/${this.props.id}`, body)
                .then(() => message.success("Utworzono nową konfigurację"))
                .then(() => this.props.fetchConfigurations())
                .catch(() => message.warning("Nie udało się utworzyć konfiguracji"))
                .finally(() => this.setState({ loading: false, diameter: 0, confName: ''}));
        }
    }

    validateConfigurationInput = () => {
        const { diameter, confName } = this.state;

        if (isNaN(diameter) || diameter === null) {
            message.warning("Należy podać wartość odniesienia.");
            return false
        }

        if(confName.trim().length === 0) {
            message.warning("Należy podać nazwę konfiguracji.");
            return false
        }
        
        return true
    }

    generateNewTestConfigurationCardContent = () => {
        const { diameter, loading } = this.state;

        return (
            <Col span={24}>
                <CustomInput
                    type="number"
                    color="#D779F2"
                    label="Odniesienie (średnica mm): "
                    onChange={e => this.setState({ diameter: e })}
                    value={diameter}
                    disabled={loading}
                    min={0}
                />
            </Col>
           
        )
    }

    getActiveItemId = () => {
        const { configurations } = this.props;
        if (configurations.length >= 1){
            return configurations.find(conf => conf.active == true).id;
        }
    }

    onChangeCollapse = (keys: any) => {
        if (!keys)
            return this.setState({ activeKeys: [] });

        if (keys.indexOf("NEW_CONFIGURATION") >= 0 && this.props.configurations.length > 0) {
            return showConfirmModal(
                () => this.expandCollapse(keys),
                NEW_CONFIG_INFO
            );
        }

        this.expandCollapse(keys);
    }

    expandCollapse = (keys: any) => {
        this.setState({ activeKeys: keys });
    }
}


const CustomStatistic = styled(Statistic)`
    .ant-statistic-content {
        font-size: 18px;
    }
`

const StyledCollapse = styled(Collapse)`
    background-color: rgb(31, 56, 83);
    color: #ffffff;
    text-align: center;
    .ant-collapse-item {
        .ant-collapse-header {
            color: #ffffff;
        }
    }
`;

const ActiveLabel = styled.label`
    font-size: 20px;
`;

const StyledPanel = styled(Collapse.Panel)`
    .anticon.anticon-right.ant-collapse-arrow {
        color: #ffffff;
    }
`;