import React, { ReactNode } from 'react';
import { Row, Col, Divider } from 'antd';
import styled from 'styled-components';
import { ActionButton, CustomInput } from '../common';

export type Props = {
    testName: string,
    configName: string,
    refValues?: Array<number>,
    onNameChange: (name: string) => void,
    onValueChange?: (value: number, index?: number) => void,
    onFinish?: () => void,
    children: ReactNode
}

const NewTestConfigurationCard = (props: Props) => {     
    return (
        <ConfigCard>
            <NameArea>
                <Row gutter={[16, 8]} justify="space-between">
                    <Col span={24}>
                        <Header>Konfiguracja testu "{props.testName}"</Header>
                    </Col>
                </Row>
            </NameArea>
            <InputsArea>
                <Row gutter={[16, 8]}>
                    <Col span={24}>
                        <Header>Dane konfiguracji</Header>
                    </Col>
                    <Col span={24}>
                        <CustomInput 
                            label={<GreyLabel>Nazwa konfiguracji</GreyLabel>}
                            color='#D779F2'
                            value={props.configName}
                            onChange={props.onNameChange}
                        />
                    </Col>
                    <Col span={24}>
                        <Header>Parametry</Header>
                    </Col>
                        {props.children}
                    <Divider />
                    <Col span={24}>
                        <ActionButton 
                            label='Stwórz i ustaw jako aktywną'
                            onClick={props.onFinish}
                        />
                    </Col>
                </Row>
            </InputsArea>
        </ConfigCard>
    )
}

const ConfigCard = styled.div`
    width: 100%;
`

const Header = styled.div`
    font-size: 20px;
`

const NameArea = styled.div`
    background: #F6F7F8;
    padding: 20px;    
`
const InputsArea = styled.div`
    background: #FFFFFF;
    padding: 20px;    
`

const GreyLabel = styled.span`
    color: #817B94;
`

export default NewTestConfigurationCard;