import React, { Component } from "react";
import FormContainer from "../components/common/FormContainer";
import { Form, Select, Spin } from "antd";
import { IRouter } from "../utils/Interfaces";
import Container from "../components/common/Container";
import { api } from "../api";
import { Customer } from "../utils/Models";
import { withStore } from "../store";
import CustomSpin from "../components/common/CustomSpin";

const { Option } = Select;

type State = {
    customers: Array<Customer>;
    isLoading: boolean;
};

class ChooseCustomerView extends Component<IRouter, State> {
    constructor(props: any) {
        super(props);

        this.state = {
            customers: [],
            isLoading: true,
        };
    }

    componentDidMount() {
        this.props.store.dispatch({
            type: "CLEAR"
        });
        this.fetchCustomers();
    }

    render() {
        const { customers, isLoading } = this.state;

        if (isLoading) {
            return <CustomSpin />;
        }

        if (customers && customers.length === 1)
        {
            this.handleCompleteForm({customer: customers[0].id})
        }

        const defaultValue = customers[0] && customers[0].name ? customers[0].name : "";
        
        return (
            <Container>
                <FormContainer
                    title='Wybierz placówkę'
                    submitText='Wybierz'
                    onSubmit={this.handleCompleteForm}>
                    <Form.Item name='customer' label='Placówka'>
                        <Select defaultValue={defaultValue}> Form.Sub
                            {customers &&
                                customers.map(customer => (
                                    <Option value={customer.id}>
                                        {customer.name}
                                    </Option>
                                ))}
                        </Select>
                    </Form.Item>
                </FormContainer>
            </Container>
        );
    }

    fetchCustomers = () => {
        api.get("/user/customers")
            .then(res => res.data)
            .then(res => this.setState({ customers: res }))
            .catch(err => err)
            .finally(() => this.setState({ isLoading: false }));
    };

    handleCompleteForm = (values: any) => {
        const { customers } = this.state;

        if (customers.length === 0)
            return;

        const selectedCustomerId = values.customer ? values.customer : customers[0].id;
        localStorage.setItem('customerId', selectedCustomerId);
        const url = `/browse/customer/${selectedCustomerId}/outposts`;
        this.props.store.DispatchAdd(customers, selectedCustomerId, url)
        this.props.history && this.props.history.push(url);
        
    };
}

export default withStore(ChooseCustomerView);
