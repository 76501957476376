import React, { Component } from "react";
import { Row, Col, Card, message, Spin, Typography } from "antd";
import {
    MainCard,
    CenteredButton,
    CustomTable,
    CustomUpload,
    ProcedureDescriptionModal,
    GreenCheckIcon,
    RedCrossIcon
} from "../common";
import DwvComponent from "../../components/common/Dwv/DwvComponent";
import styled from "styled-components";
import _ from "lodash";
import { api } from "../../api";
import { IRouter } from "../../utils/Interfaces";
import { ParamType, configurationHuWater, referenceHuWater } from "../../utils/Models";
import { TEXT_RESULT_COLUMNS } from "../constants/table"
import { handleRadiusToCm } from '../../utils/mathHelpers';

type State = {
    visible: boolean;
    draw: boolean;
    loading: boolean;
    isDicomLoaded: boolean;
    performHu: boolean;
    activeConfiguration: configurationHuWater & {id: string};
    testConfiguration: {
        kV: number;
        mA: number;
    },
    dataSource: any;
    hu: string | null;
    result: boolean;
    pixelSpacing: string | null;
};

type Props = {
    router: IRouter,
    thumbnails: React.ReactNode,
    dicom: any,
    selectedDicomId: string | any,
    mainCardReloadIcon: React.ReactNode,
    fetchThumbnails: () => void,
    onLoadingDicomFinish: () => void,
    onSavingTestStart: () => void,
    onSavingTestFinish: () => void
}

export default class HuWaterPhantomTest extends Component<Props, State> {
    constructor(props: any) {
        super(props);

        this.state = {
            visible: false,
            draw: false,
            isDicomLoaded: false,
            dataSource: [],
            performHu: false,
            hu: null,
            loading: false,
            testConfiguration: {
                kV: 0, mA: 0, 
            },
            activeConfiguration: {
                roiCircle: { center: { x: 0, y: 0 }, r: 0 },
                kVDeviation: 0, mADeviation: 0,
                circle: { center: { x: 0, y: 0 }, r: 0 },
                name: "",
                references: [],
                id: "",
                pixelSpacing: ''
            },
            result: false,
            pixelSpacing: null
        };
    }

    componentDidMount() { this.fetchConfigurations(); }

    componentDidUpdate(_: Props, prevState: State) {
        if (prevState.hu !== this.state.hu) {
            this.getHuWaterTableData();
        }
    }

    render() {
        const {
            visible,
            draw,
            activeConfiguration,
            dataSource,
            performHu,
            pixelSpacing
        } = this.state;

        return (
            <>
                <Row justify='space-between'>
                    <Col>
                        <PerformExaminationDetailsCard
                            type="inner"
                            title={
                                <>
                                    <span>HU fantomu wodnego</span>
                                    <CenteredButton
                                        onClick={() => this.saveTestResult()}
                                        label="Zachowaj wynik testu"/>
                                    <CenteredButton
                                        onClick={() => this.openModal()}
                                        label="Opis procedury"/>
                                </>
                            }>
                        </PerformExaminationDetailsCard>
                        <MainCard title="Konfiguracja">
                            <Row gutter={[16, 16]}>
                                <Col span={2} />
                                <Col span={10}>
                                    <Typography.Text strong>Średnica Fantomu: {(handleRadiusToCm(activeConfiguration.circle.r, pixelSpacing) * 2).toFixed(2)}</Typography.Text>
                                </Col>
                                <Col span={12}>
                                </Col>
                            </Row>
                            <Row gutter={[16, 16]}>
                                <Col span={2} />
                                <Col span={10}>
                                    <Typography.Text strong>Średnica ROI: {(handleRadiusToCm(activeConfiguration.roiCircle.r, pixelSpacing) * 2).toFixed(2)}</Typography.Text>
                                </Col>
                                <Col span={12}>
                                </Col>
                            </Row>
                            <Row gutter={[16, 16]}>
                                <Col span={2} />
                                <Col span={10}>
                                    <Typography.Text strong>Odchylenie kV: {(activeConfiguration.kVDeviation).toFixed(2)}</Typography.Text>
                                </Col>
                                <Col span={12}>
                                </Col>
                            </Row>
                            <Row gutter={[16, 16]}>
                                <Col span={2} />
                                <Col span={10}>
                                    <Typography.Text strong>Odchylenie mA: {(activeConfiguration.mADeviation).toFixed(2)}</Typography.Text>
                                </Col>
                                <Col span={12}>
                                </Col>
                            </Row>
                            {this.configurationHaveReference(activeConfiguration)
                                ? this.getActiveReference(activeConfiguration)
                                : <p> Brak referencji </p>}

                        </MainCard> 
                        <MainCard title="Wyniki pomiaru">
                            <CustomTable
                                columns={TEXT_RESULT_COLUMNS}
                                data={dataSource}/>
                        </MainCard>
                    </Col>
                    <Col xxl={{span: 12}} xl={{span: 11}} lg={{span: 10}}>
                        <MainCard title="Obrazy w teście">
                            
                            <Spin
                                spinning={this.state.loading}
                                tip="Trwa wykonywanie testu...">
                                <DwvComponent
                                    {...this.props}
                                    draw={draw}
                                    circle={activeConfiguration.roiCircle}
                                    performHu={performHu}
                                    handleDicomLoading={this.handleDicomLoading}
                                    updateParams={this.handleParamsChange}
                                    getHuResult={this.getHuResult}
                                />
                            </Spin>
                        </MainCard>
                    </Col>
                    <Col span={4}>
                        <MainCard title="Ostatnio dodane" icon={this.props.mainCardReloadIcon}>
                            <SideBar>
                                {this.props.thumbnails}
                            </SideBar>
                        </MainCard>
                    </Col>
                </Row>
                <ProcedureDescriptionModal
                    visible={visible}
                    title="Opis procedury"
                    closeModal={this.closeModal}/>
            </>
        );
    }

    configurationHaveReference = (configuration: configurationHuWater) => {
        return configuration.references && configuration.references.length >= 1
    }

    getActiveReference = (configuration: configurationHuWater) => {
        const activeReference = configuration.references.find((ref: referenceHuWater) => ref.active === true);
        if(!activeReference) {
            return
        }
        return (
            <>
                <Row gutter={[16, 16]}>
                    <Col span={12}>
                        <Typography.Text strong>
                            Odniesienie:
                        </Typography.Text>
                    </Col>
                </Row>
                <Row gutter={[16, 16]}>
                    <Col span={2} />
                    <Col span={10}>
                        <Typography.Text strong>kV: {activeReference.averagekV}</Typography.Text>
                    </Col>
                    <Col span={12}>
                    </Col>
                </Row>
                <Row gutter={[16, 16]}>
                    <Col span={2} />
                    <Col span={10}>
                        <Typography.Text strong>mA: {activeReference.averagemA}</Typography.Text>
                    </Col>
                    <Col span={12}>
                    </Col>
                </Row>
            </>
        )

    }

    handleParamsChange = (label: ParamType, value: number) => {
        switch (label) {
            case "kV":
                this.setState(prevState=> {
                    return {
                        testConfiguration: {...prevState.testConfiguration, kV: value}
                    }
                })
                break;
            case "mA":
                this.setState(prevState=> {
                    return {
                        testConfiguration: {...prevState.testConfiguration, mA: value}
                    }
                })
                break;

            case "pixelSpacing":
                this.setState({ pixelSpacing: `${value}` });
                break;

            default:
                break;
        }
    };

    handleDicomLoading = (state: boolean) => { 
        if(state){
            return this.setState({ isDicomLoaded: state },  () => this.handlePerformTest()); 
        }
        return this.setState({ draw: false, performHu: false, hu: null}); 
    };

    handlePerformTest = ( ) => {
        const { isDicomLoaded, activeConfiguration: {circle: { r }} } = this.state;

        if (!isDicomLoaded) {
           return message.info("Nie zuplodowano pliku Dicom");
        }
        if(r <= 0){
            return message.info("Brak aktywnej konfiguracji");
        }

        this.setState({
            draw: true,
            performHu: true,
            loading: true,
        })
    }

    openModal = () => { this.setState({ visible: true }); };

    closeModal = () => { this.setState({ visible: false }); };

    getHuResult = (result: any) => {
        this.setState({
            hu: result,
            loading: false,
            performHu: false,
        });
    };

    saveTestResult = () => {
        const {
            testConfiguration,
            activeConfiguration,
            dataSource,
            isDicomLoaded,
        } = this.state;

        const { testId } = this.props.router.match.params;

        if (!isDicomLoaded) {
           return message.error("Nie wybrano pliku Dicom");
        } 
        if (activeConfiguration.roiCircle.r === 0) {
            return message.error("Brak aktywnej konfiguracji");
        } 

        this.props.onSavingTestStart()

        const result = {
            values: testConfiguration,
            testData: dataSource,
        };

        const body = {
            Passed: this.state.result,
            Deactivated: true,
            TestData: JSON.stringify(result),
            TestConfigurationId: activeConfiguration.id,
            DicomId: this.props.selectedDicomId
        };

        api
            .post(`test-results/test-case/${testId}`, { ...body })
            .then(() => setTimeout(() => this.props.onSavingTestFinish(), 2000))
            .catch(err => console.error(err));
        
    };

    handleGetActiveConfigurations = (data: { active: boolean; configuration: string; id: string; testCaseId: string }[]) => {
        if(data.length === 0){
            message.error('Brak aktywnej konfiguracji. Przekierowanio do ustawień.')
            return this.handleRedirectToSettingsView();
        }

        const activeConfiguration = data.find(c => c.active === true);

        if(!activeConfiguration){
            return;
        }

        const { id } = activeConfiguration;
        const configuration = JSON.parse(activeConfiguration.configuration);

        this.setState({activeConfiguration: {...configuration, id} })
    }

    handleRedirectToSettingsView = () => {
        this.props.router.history.push(
            this.props.router.location.pathname.replace(/[^/]*$/, "settings")
        );
    };

    fetchConfigurations = () => {
        const { match: { params: { testId } } } = this.props.router;

        api.get(`test-configurations/test-case/${testId}`)
            .then(res => res.data)
            .then(res => res.configurations)
            .then(res => {
                if(_.isEmpty(res) || res === null){
                    setTimeout(() =>  { 
                        return this.props.router.history.push(
                            this.props.router.location.pathname.replace(/[^/]*$/, 'settings'));
                        }
                    , 1000)
                }
                return this.handleGetActiveConfigurations(res);
            })
            .catch(er => {});
    };

    getHuWaterTableData =  () => {
        const { testConfiguration: { kV, mA }, activeConfiguration, hu } = this.state;
        const  {kVDeviation, mADeviation, references } = activeConfiguration;
        const activeReference = references.find((reference: any) => reference.active === true);

        let dataSource = [];

        const parseHu = hu && parseFloat(hu);
        const fixedHu = parseHu && parseHu.toFixed(2);

        const resultHu = !isNaN(parseHu as number) && Math.abs(parseHu as number) < 5;

        const huData = {
            key: "1",
            name: "Wartość HU",
            measurement: fixedHu,
            reference: 0,
            deviation: fixedHu,
            result: resultHu,
        };

        dataSource.push(huData);

        const referenceKV = activeReference ? activeReference.averagekV : 0

        const kVDeviationData = (referenceKV / kV - 1) * 100;
        const kVResult = Math.abs(kVDeviationData) <= kVDeviation;

        const kVData = {
            key: "2",
            name: "kV",
            measurement: kV,
            reference: activeReference ? referenceKV : '--',
            deviation: activeReference ? kVDeviationData.toFixed(2) : '--',
            result: kVResult || !activeReference,
        };

        dataSource.push(kVData);

        const referenceMA = activeReference ? activeReference.averagemA : 0
        const mADeviationData = (parseFloat(`${referenceMA}`) / +mA - 1) * 100;
        const mAResult = Math.abs(mADeviationData) <= mADeviation;
        const mAData = {
            key: "3",
            name: "mA",
            measurement: mA,
            reference: activeReference ? referenceMA : '--',
            deviation: activeReference ? mADeviationData.toFixed(2) : '--',
            result: mAResult || !activeReference,
        };

        dataSource.push(mAData);

        const isTestCaseValid = huData.result && kVData.result && mAData.result;

        const result = {
            key: "4",
            name: "Wynik testu",
            result: isTestCaseValid
        };

        dataSource.push(result);

        this.setState({ 
            dataSource: dataSource, 
            result: Boolean(isTestCaseValid)
        });
    };
}

const PerformExaminationDetailsCard = styled(Card)`
    .ant-card-head-title {
        font-size: 24px;
        font-weight: 600;
        color: #505458;
        padding-top: 25px;
        border: none;
    }
`;

const SideBar = styled.div`
    overflow: auto;
    height: 80vh;
`;
