import React, { Component } from "react";
import { ResourceTilesPage } from "../components/common/";
import { IRouter } from "../utils/Interfaces";
import { TestSuit } from "../utils/Models";
import { api } from "../api";
import { withStore } from "../store";
import CustomSpin from "../components/common/CustomSpin"

type State = {
    testSuits: Array<TestSuit>;
    isLoading: boolean;
};

class TestsView extends Component<IRouter, State> {
    constructor(props: any) {
        super(props);

        this.state = {
            testSuits: [],
            isLoading: true,
        };
    }

    componentDidMount() {
        this.fetchTestSuits();
        this.props.store.setToindex(3);
    }

    render() {
        const { testSuits, isLoading } = this.state;

        if (isLoading) {
            return <CustomSpin />;
        }

        return (
            <ResourceTilesPage
                resources={testSuits}
                onTileClick={this.chooseTest}
            />
        );
    }

    fetchTestSuits = () => {
        const { hardwareId } = this.props.match.params;

        api.get(`test-suits/hardware/${hardwareId}`)
            .then(res => res.data)
            .then(res => this.setState({ testSuits: res }))
            .finally(() => this.setState({ isLoading: false }));
    };

    chooseTest = (id: string) => {
        const { testSuits } = this.state;

        const url = `/browse/test-suit/${id}/test-cases`;

        this.props.store.DispatchAdd(testSuits, id, url);

        this.props.history.push(url);
    };
}

export default withStore(TestsView);
