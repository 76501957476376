import React, {useState} from "react";
import styled from "styled-components";
import moment from "moment";
import _ from "lodash";
import { Collapse, Space, Row, Col, Statistic, Button, Input } from "antd";
import { ActionButton, CustomInput } from "../../common";
import { showConfirmModal, NEW_CONFIG_INFO } from "../../../utils/modalConfirm";

const { Panel } = Collapse;

type Configuration = {
    testNumber: number;
    kVDeviation: number;
    mADeviation: number;
    averagekV: number;
    averagemA: number;
    kV: number;
    mA: number;
    hu: number[];
    name: string;
}

type Props = {
    testNo: number;
    collection: Array<any>;
    content: (item: any) => any | string;
    // callback: (item: string) => void;
    onChange?: (item: any) => void;
    activeId?: string;
    buttonText?: string;
    addConfiguration?: JSX.Element;
    referencesList?: JSX.Element;
    activeKey?: number | null;
    handleHistoryResults: (state: boolean, newConfigId: string) => void;
    doesConfigurationHaveReferences?: boolean,
    addNewReference?: (configurationId: string) => void,
    activateReference?: (reference: any, configurationId: string) => void,
    inputCallback: (name: string) => void
    newReference?: any
};

const CustomCollapse = (props: Props): any => {
    const { collection, content, onChange, activeId, testNo, addConfiguration, activeKey, newReference, referencesList } = props;
    const [configurationName, setConfigurationName] = useState("");
    const [activeKeys, setActiveKeys] = useState([]);

    const onChangeCollapse = (keys: any) => {
        if (!keys)
            return setActiveKeys([]);

        if (keys.indexOf("NEW_CONFIGURATION") >= 0 && collection.length > 0) {
            return showConfirmModal(
                () => expandCollapse(keys),
                NEW_CONFIG_INFO
            );
        }

        expandCollapse(keys);
    }

    const expandCollapse = (keys: any) => {
        setActiveKeys(keys);
    }

    const onChangeReferencesCollapse = (item: any, keys: string | Array<string>) => {
        const shouldReferencesBeVisible = keys.indexOf('NEW_REFERENCE') >= 0;
        props.handleHistoryResults(shouldReferencesBeVisible, item.id);
    }

    return (
        <StyledCollapse onChange={onChange} defaultActiveKey={[`${activeKey}`]}>
            <Panel header='Konfiguracje' key='1'>
                <StyledCollapse defaultActiveKey={[]} activeKey={activeKeys} onChange={onChangeCollapse} accordion={true}>
                    <Panel header='Dodaj konfigurację' key='NEW_CONFIGURATION'>
                        {addConfiguration}
                    </Panel>
                    {collection.map((item: any) => {
                        const {
                            result: {
                                params: { name, kV, mA, kVDeviation, mADeviation },
                            },
                            references
                        } = JSON.parse(item.configuration);

                        if(name && !name.includes(configurationName)){
                            return;
                        }

                        return (
                            <StyledPanel key={item.id} header={name} >
                                <Space direction='vertical'>
                                    <Row gutter={[16, 16]}>
                                        <Col span={24}>
                                            <CustomStatistic title='Nazwa konfiguracji' value={name} />
                                        </Col>
                                    </Row>
                                    <Row gutter={[16, 16]}>
                                        <Col span={12}>
                                            <CustomStatistic title='kV' value={kV} precision={2}/>
                                        </Col>
                                        <Col span={12}>
                                            <CustomStatistic title='mA' value={mA} precision={2}/>
                                        </Col>
                                    </Row>
                                    <Row gutter={[16, 16]}>
                                        <Col span={12}>
                                            <CustomStatistic title='Odchylenie kV' value={kVDeviation} precision={2} />
                                        </Col>
                                        <Col span={12}>
                                            <CustomStatistic title='Odchylenie mA' value={mADeviation} precision={2} />
                                        </Col>
                                    </Row>
                                    {props.doesConfigurationHaveReferences &&
                                        <StyledCollapse defaultActiveKey={[]} onChange={keys => onChangeReferencesCollapse(item, keys)}>
                                            <StyledPanel header='Dodaj wartość odniesienia' key="NEW_REFERENCE">
                                                {newReference && 
                                                    <>
                                                        <Row>
                                                            Nazwa:
                                                            <Input value={newReference.name} onChange={(e) => props.inputCallback && props.inputCallback(e.target.value)} />
                                                        </Row>
                                                        
                                                        <Row>kV: {newReference.kV.toFixed(2)}</Row>
                                                        <Row>mA: {newReference.mA.toFixed(2)}</Row>
                                                        {newReference.avgHu.map((hu: number, index: number) =>
                                                            <Row>HU obszar {index + 1}: {hu.toFixed(2)}</Row>
                                                        )}
                                                    </>
                                                }
                                                {referencesList}
                                                <ActionButton 
                                                    label='Zapisz wartość odniesienia' 
                                                    onClick={() => props.addNewReference && props.addNewReference(item.id)} 
                                                    style={{marginTop: 20}}
                                                />
                                            </StyledPanel>
                                            {references && references.map((reference: any, index: number) => {
                                                const activityText = reference.active ? '(aktywna)' : '';
                                                
                                                return (
                                                    <StyledPanel header={`${reference.name} ${activityText}`} key={`REFERENCE${index}`}>
                                                        <Row>kV: {reference.kV.toFixed(2)}</Row>
                                                        <Row>mA: {reference.mA.toFixed(2)}</Row>
                                                        {reference.avgHu.map((hu: number, index: number) => 
                                                            <Row>HU obszar {index + 1}: {hu.toFixed(2)}</Row>
                                                        )}
                                                        {reference.testCases && 
                                                            <Row style={{marginTop: 15}}>
                                                                <Col span={24} style={{textAlign: 'left'}}>Pomiary:</Col>
                                                                {reference.testCases.map((test: any, index: number) => (
                                                                    <Col span={24} style={{textAlign: 'left', marginTop: 8}}>
                                                                        {index+1}. 
                                                                        Pomiar {moment(test.params.date).format("YYYY-MM-DD HH:mm")}, 
                                                                        kV {test.params.kV},
                                                                        mA {test.params.mA}, 
                                                                        [{test.params.avgHu.map((singleHu: number) => singleHu.toFixed(2) + ' ')}]
                                                                    </Col>
                                                                ))}
                                                            </Row>
                                                        }
                                                        {!reference.active && 
                                                            <Button 
                                                                type="link" 
                                                                onClick={() => props.activateReference && props.activateReference(reference, item.id)}
                                                            >
                                                                Aktywuj
                                                            </Button>
                                                        }
                                                    </StyledPanel>    
                                                );
                                            }
                                            )}
                                        </StyledCollapse>
                                    }
                                </Space>
                            </StyledPanel>
                        );
                    })}
                </StyledCollapse>
            </Panel>
        </StyledCollapse>
    );
};

const StyledCollapse = styled(Collapse)`
    background-color: rgb(31, 56, 83);
    color: #ffffff;
    text-align: center;
    .ant-collapse-item {
        .ant-collapse-header {
            color: #ffffff;
        }
    }
`;

const ActiveLabel = styled.label`
    font-size: 20px;
`;

const StyledPanel = styled(Panel)`
    .anticon.anticon-right.ant-collapse-arrow {
        color: #ffffff;
    }
`;

const Title = styled.h4`
    font-size: 16px;
`;

const CustomStatistic = styled(Statistic)`
    .ant-statistic-content {
        font-size: 18px;
    }
`;


export default CustomCollapse;

export { StyledCollapse };
