import React from 'react';
import { Row, Col, Progress } from 'antd';
import { CalendarOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import moment from 'moment';

import dateFormats from '../constants/dateFormats';
import { createTimeToNextStudyString } from '../../utils/createTimeToNextStudyString';
import { getMinutesDifferenceToNextStudy, calculateProgressPercentage, getTileColor } from '../../utils/calculateTileColorBasedOnTime';

export type Props = {
    id: string,
    title?: string,
    lastStudyDate?: string,
    nextStudyDate?: string,
    onClick?: (id: string) => void
}

const ExtendedResourceTile = (props: Props) => {        
    const minutesDifference = getMinutesDifferenceToNextStudy(props.nextStudyDate);

    return (
        <Tile color={getTileColor(minutesDifference)}>
            <Row>
                <Title onClick={() => props.onClick && props.onClick(props.id)}>{props.title}</Title>
            </Row>
            <Row>
                Czas do kolejnego badania: {createTimeToNextStudyString(minutesDifference)}
            </Row>
            <Row>
                <Progress 
                    percent={calculateProgressPercentage(minutesDifference)} 
                    showInfo={false} 
                    strokeColor={'#FFFFFF'} 
                    trailColor={'#00689D'} 
                />
            </Row>
            <Row>
                <Col><CalendarOutlined /></Col>
                <Col offset={1}>
                    Ostatnie badanie:
                    {props.lastStudyDate ? moment(props.lastStudyDate).format(dateFormats.DATE_TIME_SHORT) : 'Brak danych'}
                </Col>
            </Row>
        </Tile>
    )
}

const Tile = styled.div`
    width: 100%;
    background: ${props => props.color};
    color: #FFFFFF;
    padding: 20px;
`

const Title = styled.div`
    font-size: 25px;
    font-weight: 600;
    cursor: pointer;
    margin-bottom: 30px;
`

export default ExtendedResourceTile;