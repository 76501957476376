import React, { Component } from 'react';
import { ResourceTilesPage } from '../components/common';
import { IRouter } from '../utils/Interfaces';
import { Outpost } from '../utils/Models';
import { api } from '../api';
import { withStore } from "../store";
import CustomSpin from "../components/common/CustomSpin"

type State = {
    outposts: Array<Outpost>,
    isLoading: boolean
}

class OutpostsView extends Component<IRouter, State> {
    constructor(props: any) {
        super(props);

        this.state = {
            outposts: [],
            isLoading: true
        }
    }
    
    componentDidMount() {
        this.fetchOutposts();
        this.props.store.setToindex(1);
    }

    render() {
        const { outposts, isLoading } = this.state;
        
        if (isLoading) {
            return <CustomSpin />;
        }

        return (
            <ResourceTilesPage 
                resources={outposts}
                onTileClick={this.chooseOutpost}
            />
        );
    }

    chooseOutpost = (id: string) => {
        const { outposts } = this.state;

        const url = `/browse/outpost/${id}/hardwares`;

        this.props.store.DispatchAdd(outposts, id, url);

        this.props.history.push(url);
    }

    fetchOutposts = () => {
        const { customerId } = this.props.match.params;
        
        api
            .get(`/outposts/customer/${customerId}`)
            .then(res => res.data)
            .then(res => this.setState({ outposts: res }))
            .finally(() => this.setState({ isLoading: false }));
    }

}

export default withStore(OutpostsView);