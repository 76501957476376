import React from 'react';
import { Input, InputNumber, Row, Col } from 'antd';
import styled from 'styled-components';

export type Props =  {
    color?: string,
    label?: React.ReactNode,
    disabled?: boolean,
    value?: any,
    index?: number,
    onChange?: (value: any, index?: number) => void,
    type?: 'text' | 'number',
    placeholder?: string,
    step?: number,
    min?: number,
    formatter?: any,
    max?: number,
    parser?: any
    style?: any
}

const CustomInput = (props : Props) => {
    return (
        <Row>
            {props.label && <Col span={24}><Row justify="start">{props.label}</Row></Col>}
            <Col span={24}>
                {props.type !== 'number' && 
                    <CInput 
                        color={props.color} 
                        onChange={(e) => props.onChange && props.onChange(e.target.value, props.index)}
                        disabled={props.disabled || false}
                        value={props?.value}
                        placeholder={props.placeholder || ""}
                        style={{ ...props.style }}
                    />
                }

                {props.type === 'number' && 
                    <CInputNumber
                        color={props.color} 
                        onChange={(e) => props.onChange && props.onChange(e, props.index)}
                        disabled={props.disabled || false}
                        placeholder={props.placeholder || ""}
                        value={props.value}
                        step={props.step || 0.01}
                        min={props.min}
                        decimalSeparator=','
                        max={props.max}
                        formatter={props.formatter}
                        parser={props.parser}
                    />
                }
            </Col>
        </Row>
    )
}

const CInput = styled(Input)`
    border-left: ${props => props.color && `3px solid ${props.color}`};
    width: 100%;
    margin-bottom: 8px;
`

const CInputNumber = styled(InputNumber)`
    border-left: ${props => props.color && `3px solid ${props.color}`};
    width: 100%;
    margin-bottom: 8px;
`

export default CustomInput;