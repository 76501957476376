import React from 'react';
import { Table, Input, Row, Col } from 'antd';

class TagsTable extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            filters: {
                name: '',
                tag: '',
            }
        };
    }

    columns = [
        {
            title: 'Tag',
            key: 'tag',
            render: (row) => `(${this.hideInitial0x(row.group)}, ${ this.hideInitial0x(row.element)})`
        },
        {
            title: 'Nazwa',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'Wartość',
            dataIndex: 'value',
            key: 'value'
        },
    ];

    render() {
        const { data, filters } = this.state;
        
        return (       
            <>
                <Row gutter={16}>
                    <Col>
                        Tag (grupa, element):
                        <Input value={filters.tagGroup} onChange={(e) => this.handleChangeFilter('tag', e.target.value)} />
                    </Col>
                    <Col>
                        Nazwa tagu:
                        <Input value={filters.name} onChange={(e) => this.handleChangeFilter('name', e.target.value)}/>
                    </Col>
                </Row>
                <Table 
                    dataSource={this.generateFilteredData()} 
                    columns={this.columns}
                    pagination={false} 
                />
            </>     
        );
    }

    generateFilteredData = () => {
        const { data } = this.props;
        const { filters } = this.state;
        let filteredData = data;

        const tag = filters.tag.split(',').map(el => el.trim());
        
        if (tag && tag.length > 0 && tag[0].length > 0) {
            filteredData = filteredData.filter(el => el.group.toLowerCase().indexOf(tag[0].toLowerCase()) >= 0);
        }
        if (tag && tag.length > 1 && tag[1].length > 0) {
            filteredData = filteredData.filter(el => el.element.toLowerCase().indexOf(tag[1].toLowerCase()) >= 0);
        }
        if (filters.name) {
            filteredData = filteredData.filter(el => el.name.toLowerCase().indexOf(filters.name.toLowerCase()) >= 0);
        }

        return filteredData;
    }

    handleChangeFilter = (filterName, newValue) => {
        this.setState(prevState => ({
            filters: {
                ...prevState.filters,
                [filterName]: newValue
            }
        }))
    }

    hideInitial0x(initialTag) {
        const index = initialTag.indexOf('0x');
        if (index < 0) { 
            return initialTag;
        }
        return initialTag.substring(index + 2);
    }
}


export default TagsTable;
