import React, { Component } from "react";
import { Row, Col, Card, Slider, message, Spin } from "antd";
import styled from "styled-components";
import _ from "lodash";

import { 
    MainCard, 
    CenteredButton, 
    CustomTable, 
    CustomUpload, 
    ImageCard, 
    ProcedureDescriptionModal, 
    CustomSelect, 
    CustomSpin
} from "../common";
import DwvComponent from '../common/Dwv/DwvComponent';
import { api } from '../../api';
import axios, { Canceler } from 'axios';
import { IRouter } from '../../utils/Interfaces';

type DbSeries = {
    seriesId: string,
    firstDicomId: string,
    instanceCount: number,
    description: string
}

type SeriesThumbnail = {
    imgUrl: string,
}

type Series = {
    id: string,
    instanceCount: number,
    thumbnail: SeriesThumbnail
    description: string
}

type State = {
    visible: boolean,
    dicom: any,
    series: Array<Series>
    selectedSeriesId: any,
    currentInstanceNumber: number,
    tablePosition: number | null,
    sliceLocation: number | null,
    loadingDicom: boolean,
    existingInstanceNumbers: Array<number>
}

type Props = {
    router: IRouter,
    onSavingTestStart: () => void,
    onSavingTestFinish: () => void
}

let cancelToken: any;

export default class LocalisationLightsTest extends Component<Props, State> {
    constructor(props: any) {
        super(props)

        this.state = {
            visible: false,
            dicom: null,
            series: [],
            selectedSeriesId: null,
            currentInstanceNumber: 1,
            tablePosition: null,
            sliceLocation: null,
            loadingDicom: false,
            existingInstanceNumbers: []
        }
    }

    componentDidMount() {        
        this.fetchDicomSeries();
    }
    
    render() {
        const { visible, series, selectedSeriesId, dicom, currentInstanceNumber, tablePosition, sliceLocation, loadingDicom, existingInstanceNumbers } = this.state;
        const position = tablePosition || sliceLocation;

        return (
            <Spin spinning={loadingDicom} size='large' indicator={<CustomSpin tip='Wczytywanie pliku DICOM...' />}>
                <Row gutter={[24, 24]}>
                    <Col span={8}>
                        <PerformExaminationDetailsCard
                            type="inner"
                            title={
                                <>
                                    <span>Światła lokalizacyjne</span>
                                    <CenteredButton onClick={this.saveTestResult} label="Zachowaj wynik testu" />
                                    <CenteredButton onClick={() => this.openModal()} label="Opis procedury" />
                                </>}>

                            <Title> Parametry wejściowe </Title>
                            Pozycja warstwy: {position || 'Brak danych'}
                            
                        </PerformExaminationDetailsCard>
                        <MainCard title="Wyniki pomiaru">
                            {position && (Math.abs(position) < 5 ? 'Prawidłowy' : 'Nieprawidłowy')}
                            {!position && 'Nieprawidłowy parametr pozycji warstwy'}
                        </MainCard>
                    </Col>
                    <Col span={12}>
                        <MainCard title="Podgląd pliku">
                            <DwvComponent
                                dropBoxText="Wybierz serię z listy po prawej stronie."
                                dicom={dicom}
                                onChangeTablePosition={(position: number) => this.setState({ tablePosition: position })}
                                onChangeSliceLocation={(location: number | null) => this.setState({ sliceLocation: location })}
                                instance={currentInstanceNumber}
                                onLoadingDicomFinish={() => this.setState({ loadingDicom: false })}
                            />
                            <Slider
                                max={Math.max.apply(null, existingInstanceNumbers)}
                                disabled={selectedSeriesId === null}
                                onChange={this.onInstanceNumberChange}
                                value={currentInstanceNumber}
                                marks={this.createMarksDictionary(existingInstanceNumbers)}
                                step={null}
                            />
                            {currentInstanceNumber && selectedSeriesId &&
                                <Row>
                                    Instancja {currentInstanceNumber} / {series.find(s => s.id == selectedSeriesId)?.instanceCount}
                                </Row>
                            }
                        </MainCard>
                    </Col>
                    <Col span={4}>
                        <MainCard title='Serie'>
                            <SideBar>
                                {series.map(s => (
                                    <ImageCard 
                                        description={s.description}
                                        src={s.thumbnail.imgUrl}
                                        onClick={() => this.handleChangeSeries(s.id)} 
                                    />
                                ))}
                            </SideBar>
                        </MainCard>
                    </Col>
                </Row>
                <ProcedureDescriptionModal visible={visible} title="Opis procedury" steps={steps} closeModal={this.closeModal} />
            </Spin>
        );
    }
    
    saveTestResult = async () => {
        const { selectedSeriesId, currentInstanceNumber, sliceLocation, tablePosition } = this.state;
        const { testId } = this.props.router.match.params;

        const location = tablePosition || sliceLocation;
        
        if (!location || !selectedSeriesId || !currentInstanceNumber) 
            return message.error('Nieprawidłowe wykonanie testu! Obraz nie spełnia oczekiwanych parametrów.');
        
        this.props.onSavingTestStart();

        const testData = {
            location: location,
            seriesId: selectedSeriesId,
            instanceNumber: currentInstanceNumber
        };

        const body = {
            Passed: Math.abs(location) < 5,
            Deactivated: true, // dummy
            TestData: JSON.stringify(testData),
            TestConfigurationId: null,
            DicomId: await this.fetchIdOfLoadedDicom()
        };

        api
            .post(`test-results/test-case/${testId}`, {...body})
            .then(() => setTimeout(() => this.props.onSavingTestFinish(), 2000))    
            .catch(err => console.error(err));   
    }

    fetchIdOfLoadedDicom = async () => {
        const { selectedSeriesId, currentInstanceNumber } = this.state;
        const dicomResp = await api.get(`dicom/series/${selectedSeriesId}/${currentInstanceNumber}/dicomId`); 
        return dicomResp.data;
    }

    fetchExistingInstanceNumbers = async (seriesId: string) => {
        const existingInstanceNumber = await api.get(`/dicom/series/${seriesId}/instances`);
        return existingInstanceNumber.data;
    }

    openModal = () => { this.setState({ visible: true }) }
    
    closeModal = () => { this.setState({ visible: false }) }
    
    fetchDicomSeries = () => {
        const { testId } = this.props.router.match.params;
        api
            .get(`dicom/series/test-case/${testId}`)
            .then(res => res.data)
            .then(series => {
                series.forEach((s: DbSeries) => {
                    api
                        .get(`dicom/${s.firstDicomId}/image`, {responseType: 'blob'})
                        .then(res => res.data)
                        .then(thumbnail => this.storeSeriesData(s, thumbnail))
                })
            }) 
            .catch(err => console.error(err));
    }

    storeSeriesData = (series: DbSeries, thumbnailBlob: Blob) => {
        const newSeries: Series = {
            id: series.seriesId,
            instanceCount: series.instanceCount,
            thumbnail: {
                imgUrl: URL.createObjectURL(thumbnailBlob),
            },
            description: series.description
        }
        
        this.setState(prevState => ({
            series: [...prevState.series, newSeries]
        }))
    }

    handleChangeSeries = async (newSeriesId: any) => {
        this.setState({ loadingDicom: true })

        const existingInstanceNumbers = await this.fetchExistingInstanceNumbers(newSeriesId);
        const minInstanceNumber = Math.min.apply(null, existingInstanceNumbers);

        this.fetchDicomFromSeries(newSeriesId, minInstanceNumber);
        
        this.setState({
            selectedSeriesId: newSeriesId,
            currentInstanceNumber: minInstanceNumber,
            existingInstanceNumbers: existingInstanceNumbers
        })
    }

    fetchDicomFromSeries = (seriesId: string, instanceNumber: number) => {        
        if (typeof cancelToken != typeof undefined) {
            cancelToken.cancel("Operation canceled due to new request.");
        }
      
        cancelToken = axios.CancelToken.source();

        api
            .get(`dicom/series/${seriesId}/${instanceNumber}`, { responseType: 'blob', cancelToken: cancelToken.token })
            .then(res => res.data)
            .then(res => this.setState({ dicom: this.blobToFile(res, 'dicom.dcm') }))
            .catch((err) => !axios.isCancel(err) && this.handleNoDicomInstance(seriesId, instanceNumber));
    }
    
    onInstanceNumberChange = (instanceNumber: number) => {
        const { selectedSeriesId } = this.state;
        this.setState({ loadingDicom: true })

        this.fetchDicomFromSeries(selectedSeriesId, instanceNumber);

        this.setState({
            currentInstanceNumber: instanceNumber
        })
    }


    blobToFile(theBlob: any, fileName: any) {
        theBlob.lastModifiedDate = new Date();
        theBlob.name = fileName;
        return theBlob;
    }

    handleNoDicomInstance = (seriesId: string, instanceNumber: number) => {
        message.error(`W serii ${seriesId} nie ma pliku DICOM o numerze instancji ${instanceNumber}.`);
        
        this.setState({
            dicom: null,
            tablePosition: null,
            sliceLocation: null,
            loadingDicom: false
        })
    }

    createMarksDictionary = (existingInstances: Array<number>) => {
        const marksDict = {};
        existingInstances.forEach(instance => {
            marksDict[instance] = ''
        })
        return marksDict;
    }
}

const steps = [
    "Umieść fantom do testów pola świetlnego na statywie",
    "Ustaw SID na 100 cm",
    "Ustaw promień centralny na środek fantomu. Upewnij się, że linie centrujące pola świetlnego tzw. „krzyż” są prostopadłe do wszystkich krawędzi fantomu",
    "Ustaw kolimację przysłon promieniowania do (oznaczonego na fantomie) pola o rozmiarze 26x26 cm",
    "Ustaw aparat rtg w trybie „statyw z kratką”.",
    "Włóż do szyn kolimatora aluminiowy filtr jednorodny 25 mm",
    "Ustaw wysokie napięcie na 75kV i natężenie prądu na 200mA.",
    "Wykonaj ekspozycję",
    "Wyślij otrzymany obraz do systemu Qadra",
    "Wykonaj pomiary na obrazie w systemie Qadra"
]

const description = "2020/07/03 09:22:10 AEC zmiana natężenia stoł Wysokie 70 kV 5,6 mAs 13 ms"

const PerformExaminationDetailsCard = styled(Card)`
    .ant-card-head-title {
        font-size: 24px;
        font-weight: 600;
        color: #505458;
        padding-top: 25px;
        border: none;
    }
`;

const IconText = styled.div`
    margin-left:10px;
`

const Img = styled.img`
    width: 90%;
    height: 90%;
`

const SideBar = styled.div`
    overflow: auto;
    height: 80vh;
`

const Title = styled.h4`
    font-size: 16px;
`;
