import React, { Component } from "react";
import { Row, Col, Card, message } from "antd";
import styled from "styled-components";
import _ from "lodash";

import { MainCard, CenteredButton, CustomTable, CustomUpload, ImageCard, ProcedureDescriptionModal, GreenCheckIcon, RedCrossIcon } from "../common";
import { Circle } from '../../utils/Models';
import DwvComponent from '../common/Dwv/DwvComponent';
import { getPixelsInsideCircleOnSurface, transformToGrayScale } from '../../utils/highContrastTestMath';
import { api } from '../../api';
import { IRouter } from '../../utils/Interfaces';

type Result = {
    name: string,
    measure?: number | null,
    reference?: number | null,
    deviation?: string | null,
    result: boolean
}


type State = {
    visible: boolean,
    configuration: any,
    settings: {
        name: string | null,
        diameter: number | null,
        ROINumber: number | null,
        distance: number | null,
        kV: number | null,
        kVDeviation: number | null,
        mA: number | null,
        mADeviation: number | null
    },
    pixelSpacing: { xSpacing: number, ySpacing: number },
    circles: Array<Circle>,
    imageData: any,
    current_image: number | 0,
    results: Array<Result>,
    imageKV: number | null,
    imageMA: number | null
}

type Props = {
    router: IRouter,
    thumbnails: React.ReactNode,
    dicom: any,
    selectedDicomId: string | any,
    mainCardReloadIcon: React.ReactNode,
    fetchThumbnails: () => void,
    onLoadingDicomFinish: () => void,
    onSavingTestStart: () => void,
    onSavingTestFinish: () => void
}

export default class UniformityTest extends Component<Props, State> {
    constructor(props: any) {
        super(props)

        this.state = {
            visible: false,
            configuration: null,
            settings: {
                name: null,
                diameter: null,
                ROINumber: null,
                distance: null,
                kV: null,
                kVDeviation: null,
                mA: null,
                mADeviation: null
            },
            pixelSpacing: { xSpacing: 0, ySpacing: 0 },
            circles: [],
            imageData: null,
            current_image: 0,
            results: [],
            imageKV: null,
            imageMA: null
        }
    }

    componentDidMount() {
        this.fetchTestConfigurations();
    }

    componentDidUpdate(_: Props, prevState: State) {
        if (this.state.circles !== prevState.circles) {
            this.performTest();
        }
    }
    
    render() {
        const { visible, settings, results } = this.state;

        return (
            <>
                <Row justify='space-between'>
                    <Col>
                        <PerformExaminationDetailsCard
                            type="inner"
                            title={
                                <>
                                    <span>Jednorodność obrazu</span>
                                    <CenteredButton onClick={this.saveTestResult} label="Zachowaj wynik testu" />
                                    <CenteredButton onClick={() => this.openModal()} label="Opis procedury" />
                                </>}>

                            {settings.name && 
                                <>
                                    <Row>Nazwa: {settings.name}</Row>
                                    <Row>Średnica (cm): {settings.diameter}</Row>
                                    <Row>Odległość od środka (cm): {settings.distance}</Row>
                                    <Row>Ilość ROI: {settings.ROINumber}</Row>
                                    <Row>Odniesienie KV: {settings.kV}</Row>
                                    <Row>Odchylenie KV: {settings.kVDeviation}%</Row>
                                    <Row>Odniesienie MA: {settings.mA}</Row>
                                    <Row>Odchylenie MA: {settings.mADeviation}%</Row>
                                </>
                            }

                        </PerformExaminationDetailsCard>
                        <MainCard title="Wyniki pomiaru">
                            <CustomTable columns={this.columns} data={results} />
                        </MainCard>
                    </Col>
                    <Col xxl={{span: 12}} xl={{span: 11}} lg={{span: 10}}>
                        <MainCard title="Obrazy w teście">
                            
                            <DwvComponent
                                {...this.state}
                                {...this.props}
                                processPixelSpacing={this.unpackAndStorePixelSpacing}
                                updateDrawnCircles={(circles: Array<Circle>) => this.setState({ circles: circles })}
                                settings={settings}
                                onKVChange={(kV: number) => this.setState({ imageKV: kV })}
                                onMAChange={(mA: any) => this.setState({ imageMA: mA })}
                                readOnly={true}
                                updateImageData={(data: any, idx: number) => this.setState({ imageData: data,  current_image: idx})}/>
                        </MainCard>
                    </Col>
                    <Col span={4}>
                        <MainCard title="Ostatnio dodane" icon={this.props.mainCardReloadIcon}>
                            <SideBar>
                                {this.props.thumbnails}
                            </SideBar>
                        </MainCard>
                    </Col>
                </Row>
                <ProcedureDescriptionModal visible={visible} title="Opis procedury" steps={steps} closeModal={this.closeModal} />
            </>
        );
    }

    performTest = () => {
        const { circles, imageData, current_image, imageKV, imageMA, settings } = this.state;
        let results: Array<Result> = [];
        let HUSumArray: Array<number> = [];

        // w imageData mamy objekt Image
        // w current_image mamy nr obecnej klatki.
        const rsi = imageData.getRescaleSlopeAndIntercept(current_image)
        circles.forEach(circle => {
            const pixelsInsideCircle = getPixelsInsideCircleOnSurface(
                circle, 
                imageData.getGeometry().getSize().getNumberOfColumns(),
                imageData.getBuffer()[current_image]
            );
            const HUs:Array<number>=[];
            pixelsInsideCircle.forEach( v => HUs.push(rsi.apply(v)));
            const avgHU = HUs.reduce((a, b) => a + b, 0) / (HUs.length || 1);
            HUSumArray.push(avgHU);
        })

        // Jednak porównujemy do jednego ROI w środku
        // const HUreference = HUSumArray.reduce((a, b) => a + b, 0) / HUSumArray.length;
        const HUreference = HUSumArray[0];

        HUSumArray.forEach((HU, index) => {
            const result: Result = {
                name: index === 0 ? 'Obszar środek' : `Obszar ${index}`,
                measure: HU,
                reference: HUreference,
                deviation: (HU - HUreference).toFixed(2).toString(),
                result: settings.diameter && settings.diameter <= 20 ? Math.abs(HU - HUreference) < 10 : Math.abs(HU - HUreference) < 20 
    
            }
            results.push(result);
        })

        const kVDeviation = imageKV && settings.kV ? (imageKV / settings.kV - 1) * 100 : 0;
        const mADeviation = imageMA && settings.mA ? (imageMA / settings.mA - 1) * 100 : 0;


        const kVResult: Result = {
            name: 'kV',
            measure: imageKV,
            reference: settings.kV,
            deviation: `${kVDeviation.toFixed(2)}%`,
            result: !_.isNull(settings.kVDeviation) ? Math.abs(kVDeviation) <= settings.kVDeviation : false
        }
        const mAResult: Result = {
            name: 'mA',
            measure: imageMA,
            reference: settings.mA,
            deviation: `${mADeviation.toFixed(2)}%`,
            result: !_.isNull(settings.mADeviation) ? Math.abs(mADeviation) <= settings.mADeviation : false
        }

        results.push(kVResult);
        results.push(mAResult);

        const finalResult = {
            name: 'Wynik końcowy',
            result: results.findIndex(r => r.result === false) < 0
        }

        results.push(finalResult);

        this.setState({
            results: results
        })
    }

    saveTestResult = () => {
        const { results, configuration, settings } = this.state;
        const { testId } = this.props.router.match.params;
        const { selectedDicomId } = this.props;

        this.props.onSavingTestStart()


        const testData = {
            results: results,
            settings: settings,
            configurationId: configuration.id
        }
        
        const body = {
            Passed: _.isUndefined(results.find(result => !result.result)),
            Deactivated: true, // dummy
            TestData: JSON.stringify(testData),
            TestConfigurationId: configuration.id,
            DicomId: selectedDicomId
        }

        api
            .post(`test-results/test-case/${testId}`, {...body})   
            .then(() => setTimeout(() => this.props.onSavingTestFinish(), 2000))
            .catch(err => console.error(err));

    }

    openModal = () => { this.setState({ visible: true }) }
    
    closeModal = () => { this.setState({ visible: false }) }
    
    fetchTestConfigurations = () => {
        const { testId } = this.props.router.match.params;

        api
            .get(`/test-configurations/test-case/${testId}`)
            .then(res => res.data)
            .then(data => data.configurations)
            .then(con => { 
                if(_.isEmpty(con) || con === null){
                    setTimeout(() =>  { 
                        return this.props.router.history.push(
                            this.props.router.location.pathname.replace(/[^/]*$/, 'settings'));
                        }
                    , 1000)
                }
                return this.findActiveConfiguration(con)
            })
            .catch(err => console.error(err));
    }

    findActiveConfiguration = (configurations: Array<any>) => {
        const activeConfiguration = configurations.find(config => config.active === true);

        this.setState({
            configuration: activeConfiguration,
            settings: JSON.parse(activeConfiguration.configuration)
        })
    }

    unpackAndStorePixelSpacing = (spacing: string) => {
        const backslashIndex = spacing.indexOf('\\'); // single backslash
        
        if (!backslashIndex) { return ; }

        const xSpacing = spacing.slice(0, backslashIndex);
        const ySpacing = spacing.slice(backslashIndex + 1, spacing.length);
        
        this.setState({
            pixelSpacing: { xSpacing: parseFloat(xSpacing), ySpacing: parseFloat(ySpacing) }
        })
    }

    columns = [
        {
            title: "Nazwa",
            dataIndex: "name",
            key: "name"
        },
        {
            title: "Pomiar",
            dataIndex: "measure",
            key: "measure",
            render: (measure: number) => measure && measure.toFixed(2)
        },
        {
            title: "Odniesienie",
            dataIndex: "reference",
            key: "reference",
            render: (reference: number) => reference && reference.toFixed(2)
        },
        {
            title: "Odchylenie",
            dataIndex: "deviation",
            key: "deviation"
        },
        {
            title: "Poprawny",
            dataIndex: "result",
            key: "result",
            render: (result: boolean) => result ? <GreenCheckIcon /> : <RedCrossIcon />
        },
    ]
}

const steps = [
    "Umieść fantom do testów pola świetlnego na statywie",
    "Ustaw SID na 100 cm",
    "Ustaw promień centralny na środek fantomu. Upewnij się, że linie centrujące pola świetlnego tzw. „krzyż” są prostopadłe do wszystkich krawędzi fantomu",
    "Ustaw kolimację przysłon promieniowania do (oznaczonego na fantomie) pola o rozmiarze 26x26 cm",
    "Ustaw aparat rtg w trybie „statyw z kratką”.",
    "Włóż do szyn kolimatora aluminiowy filtr jednorodny 25 mm",
    "Ustaw wysokie napięcie na 75kV i natężenie prądu na 200mA.",
    "Wykonaj ekspozycję",
    "Wyślij otrzymany obraz do systemu Qadra",
    "Wykonaj pomiary na obrazie w systemie Qadra"
]

const PerformExaminationDetailsCard = styled(Card)`
    .ant-card-head-title {
        font-size: 24px;
        font-weight: 600;
        color: #505458;
        padding-top: 25px;
        border: none;
    }
`;

const SideBar = styled.div`
    overflow: auto;
    height: 80vh;
`

const Title = styled.h4`
    font-size: 16px;
`;
