import React from "react";
import { Col, Row, Typography } from "antd";
import { CustomInput, ActionButton } from "../../common";
import { CustomCollapse } from "../../common";
import styled from "styled-components";

type Props = {
    testNo: number;
    configurations: any;
    content: any;
    activeConfigurationId: string;
    callback: (item: any) => void;
};

const ConfigList = ({ testNo, configurations, content, callback, activeConfigurationId }: Props) => {
    return (
        <div>
            <Row gutter={[16, 16]}>
                <Col span={16}>
                    <Title>Określona liczba pomiarów w teście</Title>
                </Col>
                <Col span={8}>
                    <CustomInput type='number' min={1} value={testNo} disabled step={1} />
                </Col>
            </Row>

            <CustomCollapse
                collection={configurations}
                content={content}
                activeId={activeConfigurationId}
                callback={callback}/>
        </div>
    );
};

const Title = styled.h4`
    font-size: 16px;
`;

export default ConfigList;
