import React, { ReactNode } from 'react';
import { Row, Col } from 'antd';
import styled from 'styled-components';
import _ from 'lodash';

import { CustomSelect, ActionButton } from '../common';
import { api } from "../../api";

type ConfigObject = {
    id: string,
    configuration: string,
    active: boolean
}

export type Props = {
    testName: string,
    configList: Array<ConfigObject>,
    selectedConfigId: string | null,
    onChangeConfigId: (id: string) => void,
    onSwitchToNewConfigCard: () => void,
    fetchConfigurations?: () => void,
    params: ReactNode
}

const TestConfigurationCard = (props: Props) => {     
    
    const currentConfigurationIsNotActive = () => {
        if(props.configList && props.configList.length > 0) {
            const currentlyActiveConfiguration = findActiveConf();
            if(currentlyActiveConfiguration) {
                return currentlyActiveConfiguration.id !== props.selectedConfigId;
            }
            return false
        }
        return false
    }

    const findActiveConf = () => {
       return props.configList.find(conf => conf.active === true);
    }

    return (
        <ConfigCard>
            <ActionArea>
                <Row gutter={[16, 8]} justify="space-between">
                    <Col span={24}>
                        <Header>Konfiguracja testu "{props.testName}"</Header>
                    </Col>
                    <Col span={24}>Lista konfiguracji</Col>
                    <Col span={16}>
                        <CustomSelect 
                            options={props.configList && props.configList.map(config => {
                                let parsedConfig = JSON.parse(config.configuration);
                                let name = `${parsedConfig.name}`;
                                if(config.active) {
                                    name = `${parsedConfig.name} (aktywna)`;
                                }
                                
                                return {
                                    value: config.id,
                                    label: name
                                }
                            })}
                            selectedOption={props.selectedConfigId}
                            onChange={props.onChangeConfigId}
                        />
                    </Col>
                    <Col span={6}>
                        <ActionButton 
                            label='Nowa'
                            onClick={props.onSwitchToNewConfigCard}
                        />
                    </Col>
                </Row>
            </ActionArea>
            <InfoArea>
                <Row gutter={[16, 8]}>
                    <Col span={24}>
                        <Header>Parametry</Header>
                    </Col>
                    {props.params}
                </Row>
            </InfoArea>
        </ConfigCard>
    )
}

const ConfigCard = styled.div`
    width: 100%;
`

const Header = styled.div`
    font-size: 20px;
`

const ActionArea = styled.div`
    background: #F6F7F8;
    padding: 20px;    
`
const InfoArea = styled.div`
    background: #FFFFFF;
    padding: 20px;    
`

const GreyLabel = styled.span`
    color: #817B94;
`


export default TestConfigurationCard;