import React, { Component } from "react";
import { Row, Col, Typography, message, Spin, Statistic } from "antd";
import { MainCard, CustomUpload } from "../../common";
import { IRouter } from "../../../utils/Interfaces";
import { Circle, Point, ParamType } from "../../../utils/Models";
import DwvComponent from "../../common/Dwv/DwvComponent";
import { getCircleThrough3Point } from "../../../utils/mathHelpers";
import TestHistory from "../../TestHistory";
import { CustomCollapse, Configuration } from "../huDensity";
import { showConfirmModal, NEW_CONFIG_INFO } from "../../../utils/modalConfirm";
import { api } from "../../../api";

import _ from "lodash";
import styled from "styled-components";
import moment from "moment";

const { Text } = Typography;

type Props = {
    loadingDicom: boolean;
    router: IRouter;
    thumbnails: React.ReactNode;
    dicom: any;
    selectedDicomId: string | any;
    fetchThumbnails: () => void;
    fetchConfigurations: () => void;
    id: string | null;
    configurations: { active: boolean; configuration: string; id: string; testCaseId: string }[];
    onLoadingDicomFinish: () => void;
    mainCardReloadIcon: React.ReactNode,
};

export type referenceHuDensity = {
    name: string,
    active: boolean;
    kV: number;
    mA: number;
    avgHu: Array<number>;
    testCases: Array<any>
}

type ConfigurationType = {
    testNumber: number;
    kVDeviation: number;
    mADeviation: number;
    averagekV: number;
    averagemA: number;
    kV: number;
    mA: number;
    hu: number[];
    name: string;
};

type State = {
    isNewConfig: boolean;
    loading: boolean;
    isDicomLoaded: boolean;
    hasValidReferenceValue: boolean;
    activeConfiguration: { params: ConfigurationType; id: string; active: boolean };
    newConfiguration: ConfigurationType;
    circle: Circle;
    circles: Circle[];
    areasInfo: Array<{averageHu: number, index: number, active: boolean, pointsAmount: number, name: string, editing: boolean}>,
    hu: string[];
    testNumber: number;
    collapseActiveKey: number | null;
    referenceTestCases: any[];
    points: Point[];
    roiMeasurementRatio: number;
    dwvState: any;
    mode: 'REFERENCE' | 'CONFIGURATION';
    configurationForReferencesId: string,
    dicomActions: {
        draw: boolean;
        performHu: boolean;
        canChoosePoints: boolean;
    };
    results: Array<any>,
    newReference: referenceHuDensity
};

const DEFAULT_CONFIGURATION: ConfigurationType = {
    testNumber: 0,
    kVDeviation: 5,
    mADeviation: 5,
    averagekV: 0,
    averagemA: 0,
    kV: 0,
    mA: 0,
    hu: [],
    name: moment().format("YYYY-MM-DD HH:mm"),
};

export default class HuDensitySettings extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            loading: false,
            isNewConfig: false,
            activeConfiguration: { params: DEFAULT_CONFIGURATION, id: "", active: false },
            newConfiguration: DEFAULT_CONFIGURATION,
            roiMeasurementRatio: 50,
            circle: { center: { x: 0, y: 0 }, r: 0 },
            circles: [],
            areasInfo: [],
            testNumber: 0,
            hu: [],
            dwvState: null,
            referenceTestCases: [],
            isDicomLoaded: false,
            points: [],
            hasValidReferenceValue: false,
            collapseActiveKey: null,
            mode: "CONFIGURATION",
            configurationForReferencesId: '',
            dicomActions: {
                draw: false,
                performHu: false,
                canChoosePoints: false,
            },
            results: [],
            newReference: {
                name: moment().format("YYYY-MM-DD HH:mm"),
                active: true,
                kV: 0,
                mA: 0,
                avgHu: [],
                testCases: []
            }
        };
    }

    componentDidMount() {
        this.fetchLastTestResults();
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        if (this.props.configurations.length > 0 && !_.isEqual(prevProps.configurations, this.props.configurations)) {
            this.getActiveConfiguration();
        }

        if (
            this.state.referenceTestCases.length >= 5 &&
            this.state.referenceTestCases.length &&
            this.state.referenceTestCases.length !== prevState.referenceTestCases.length
        ) {
            this.handleCalculateReferenceValue();
        }

        if (!this.props.loadingDicom && prevProps.loadingDicom)
            this.setState(prevState => {
                return {
                    areasInfo: [],
                    circles: [],
                    dicomActions: { ...prevState.dicomActions, draw: !prevState.dicomActions.draw },
                    newConfiguration: DEFAULT_CONFIGURATION
                }
            })
    }

    render() {
        const {
            activeConfiguration: { id },
            newConfiguration,
            circles,
            dicomActions: { canChoosePoints },
            points,
            collapseActiveKey,
            testNumber,
            referenceTestCases,
            roiMeasurementRatio,
            areasInfo,
            newReference,
        } = this.state;

        const { configurations } = this.props;

        return (
            <Row gutter={[16, 16]}>
                <Col span={8}>
                    <CustomCollapse
                        content={this.configurationList}
                        collection={configurations}
                        testNo={testNumber}
                        activeId={id}
                        activeKey={collapseActiveKey}
                        handleHistoryResults={this.handleHistoryResults}
                        doesConfigurationHaveReferences={true}
                        addNewReference={this.addNewReferenceToConfiguration}
                        activateReference={this.activateReference}
                        newReference={newReference}
                        inputCallback={this.onChangeReferenceName}
                        referencesList={
                            <TestHistory
                                action={this.handleAddReferenceTestCases}
                                configuration={this.state.activeConfiguration}
                                type='hu_density'
                                testCaseId={this.props.router.match.params.testId}
                                visible={true}
                                setVisible={() => null}
                                title='Wartości odniesienia'
                                configId={this.state.configurationForReferencesId}
                            />
                        }
                        addConfiguration={
                            <Configuration
                                handleRemoveArea={this.handleRemoveArea}
                                roiMeasurementRatio={roiMeasurementRatio}
                                handleroiMeasurementRatioChange={this.handleroiMeasurementRatioChange}
                                circles={circles}
                                onFinish={this.handleSaveConfigurationRequest}
                                configuration={newConfiguration}
                                huAreas={this.huAreas}
                                canChoosePoints={canChoosePoints}
                                handleConfigurationNameChange={this.handleConfigurationNameChange}
                                handleHistoryResults={this.handleHistoryResults}
                                handleOnAddMeasurmentClick={this.handleOnAddMeasurmentClick}
                                handleParamsChange={this.handleParamsChange}
                                handleRemoveCircle={this.handleRemoveCircle}
                                points={points}
                                referenceTestCases={referenceTestCases}
                                testNumber={testNumber}
                                areasInfo={areasInfo}
                                handleAddArea={this.handleAddArea}
                                handleChangeAreaName={this.handleChangeAreaName}
                                setEditableStateToArea={this.setEditableStateToArea}
                            />
                        }
                    />
                </Col>

                <Col span={12}>{this.handleRenderConfigurationStep()}</Col>
                <Col span={4}>
                    <MainCard title='Ostatnio dodane' icon={this.props.mainCardReloadIcon}>
                        <SideBar>{this.props.thumbnails}</SideBar>
                    </MainCard>
                </Col>
            </Row>
        );
    }

    fetchLastTestResults = () => {
        const { testId } = this.props.router.match.params;
        
        api
            .get(`test-results/test-case/${testId}`)
            .then(res => res.data)
            .then(res => this.setState({ results: res.results }))
    }

    confirm() {
        showConfirmModal(this.saveTestResult, NEW_CONFIG_INFO);
    }

    setEditableStateToArea = (index: number, state: boolean) => {
        const { areasInfo } = this.state;
        const searchedArea = areasInfo.find(area => area.index === index);
        if (!searchedArea)
            return

        searchedArea.editing = state;

        this.setState(prevState => {
            return {
                areasInfo: [...prevState.areasInfo.filter(a => a.index !== index), searchedArea].sort((a, b) => a.index - b.index)
            }
        })
    }

    calculateVectorAverageAndReturnNewVector = (arr: Array<Array<number>>) => {
        if (arr.length < 1)
            return [];
        
        const predictedNestedArrayLength = arr[0].length;
        
        arr.forEach(el => {
            if (el.length !== predictedNestedArrayLength) {
                throw('Incorrect arguments passed to the function. All nested arrays must have same length.');
            }
        })
        
        const newArray: Array<number> = [];
        
        for (let i=0; i<predictedNestedArrayLength; ++i) {
            const rowAverage = arr.reduce((prev, curr) => prev + curr[i], 0) / arr.length;
            newArray.push(rowAverage);
        }
        
        return newArray;
    }

    addNewReferenceToConfiguration = (configurationId: string) => {
        const { referenceTestCases, newReference } = this.state;

        const currentConf = this.props.configurations.find(config => config.id === configurationId);
        
        const params = JSON.parse(currentConf?.configuration as any);
        if(_.isArray(params.references)){
            const activeRef = params.references.find((ref: any) => ref.active == true);
            if(activeRef) {
                activeRef.active = false;
            }
            params.references.push(newReference);
        }

        const updatedConf = {
            Content: JSON.stringify({
                ...params
            })
        }

        this.handleSaveChanges(currentConf, updatedConf);
    }

    handleRecalculateChoosenTestsResults = async () => {
        const { referenceTestCases, newReference } = this.state;
        const configuration = this.props.configurations.find(config => config.id === this.state.configurationForReferencesId);

        for (let test of referenceTestCases) {
            await this.changeTestDataAndSaveToDatabase(test, newReference, configuration);
        }
    }

    changeTestDataAndSaveToDatabase = async (test: any, newReference: any, configuration: any) => {
        const { results } = this.state;
        const result = results.find(r => r.result.id === test.id);
        const jsonResult = JSON.parse(result.result.testData);
        const dataSource = jsonResult.dataSource;
        const params = jsonResult.values;

        const newTestData = []

        params.hu.map((hu: any, idx: number) => {
            const huRef = parseFloat(newReference.avgHu[idx]);
            const huResult = {
                key: idx,
                name: `Obszar ${idx + 1}`,
                measurement: hu.toFixed(2),
                reference: huRef.toFixed(2),
                deviation: (hu - huRef).toFixed(2),
                result: Math.abs(hu - huRef) < 20,
            };
            newTestData.push(huResult);
        });

        const kResultDeviation = ((newReference.kV / params.kV) - 1) * 100;
        const kVResult = {
            key: params.hu.length,
            name: "kV",
            measurement: params.kV,
            reference: newReference.kV,
            deviation: kResultDeviation,
            result: Math.abs(kResultDeviation) < params.kVDeviation,
        };

        newTestData.push(kVResult);

        const mAResultDeviation = ((newReference.mA / params.mA) - 1) * 100;
        const mAResult = {
            key: params.hu.length + 1,
            name: "mA",
            measurement: params.mA,
            reference: newReference.mA,
            deviation: mAResultDeviation,
            result: Math.abs(mAResultDeviation) < params.mADeviation,
        };

        newTestData.push(mAResult);

        const passed = !Boolean(newTestData.find(el => !el.result));
        const finalResult = {
            key: params.hu.length + 2,
            name: "Wynik testu",
            result: passed,
        };
        newTestData.push(finalResult);

        const patchTestResultContent = {
            values: params,
            dataSource: newTestData
        }

        const requestBody = {
            Passed: passed,
            TestData: JSON.stringify(patchTestResultContent),
            Deactivated: false // dummy
        }

        await api
                .patch(`test-results/${test.id}`, { ...requestBody })
                .catch(err => console.error(err));
        
    } 

    updateReferenceObject = (choosenReferences: any[]) => {
        if (choosenReferences.length === 0)
            return null;
        
        this.setState(prevState => ({
            ...prevState,
            newReference: {
                ...prevState.newReference,
                kV: choosenReferences.reduce(((prev, current) => prev + current.params.kV), 0) / choosenReferences.length,
                mA: choosenReferences.reduce(((prev, current) => prev + current.params.mA), 0) / choosenReferences.length,
                avgHu: this.calculateVectorAverageAndReturnNewVector(choosenReferences.map(el => el.params.avgHu)),
                active: true,
                testCases: choosenReferences
            }
        }));
    }

    onChangeReferenceName = (name: string) => {
        this.setState(prevState => ({
            newReference: {
                ...prevState.newReference,
                name: name
            }
        }));
    }

    areReferencesEqual = (reference1: any, reference2: any) => {
        return (
            _.isEqual(reference1, reference2) &&
            reference1.averagekV === reference2.averagekV && 
            reference1.averagemA === reference2.averagemA && 
            reference1.active === reference2.active
        );
    };

    activateReference = (reference: any, configurationId: string) => {
        const currentConf = this.props.configurations.find(config => config.id === configurationId);
        const configurationToChange = _.cloneDeep(currentConf);
        const params = JSON.parse(configurationToChange?.configuration as any);

        params.references = params.references.map((ref: any) => {
            ref.active = this.areReferencesEqual(ref, reference);
            return ref;
        })

        const updatedConf = {
            Content: JSON.stringify({
                ...params
            })
        }
        this.handleSaveChanges(currentConf, updatedConf);
    }

    handleSaveChanges = (currentConf: any, updatedConf: any) => {        
        api
            .patch(`/test-cases/test-configurations/${currentConf.id}`, updatedConf)
            .then(res => res.data)
            .then(() => this.props.fetchConfigurations())
            .then(() => this.handleRecalculateChoosenTestsResults())
            .then(() => message.success('Dodano nowe wartości odniesienia.'))
            .catch(err => console.error(err))
            .finally(() =>  this.setState({ loading: false }));
    }

    handleRenderConfigurationStep = () => {
        const {
            circle,
            dwvState,
            circles, points,
            dicomActions: { draw, performHu, canChoosePoints },
            roiMeasurementRatio,
            mode,
            areasInfo,
        } = this.state;

        const {
            router: {
                match: {
                    params: { testId },
                },
            },
        } = this.props;

        return (
            <>
                <MainCard title='Obrazy w teście' style={{display: mode ==="CONFIGURATION" ? "block": "none"}}>
                    <Spin spinning={this.state.loading} tip='Trwa wykonywanie testu...'>
                            <DwvComponent
                                {...this.props}
                                handleDicomLoading={this.handleIsDicomLoadedState}
                                onCanvasClick={this.handleAddPoint}
                                handleAfterDraw={this.handleAfterDraw}
                                updateParams={this.handleParamsChange}
                                saveState={this.handleSaveDwvState}
                                circle={circle}
                                circles={circles}
                                labels={areasInfo.map(area => area.name)}
                                idwvState={dwvState}
                                points={points}
                                roiMeasurementRatio={roiMeasurementRatio}
                                drawCircles={draw}
                                performHu={performHu}
                                huDensity={true}
                                canChoosePoints={canChoosePoints}
                                getHuResult={this.getHuResult}
                            />
                    </Spin>
                </MainCard>
            </>
        );
    };
    getActiveConfiguration = () => {
        const { configurations } = this.props;

        if (configurations.length === 0)
            return;

        const activeConfiguration = configurations.find(c => c.active == true);

        if (!activeConfiguration)
            return;

        const parsedConfiguration = JSON.parse(activeConfiguration.configuration);

        this.setState({
            activeConfiguration: {
                id: activeConfiguration.id,
                active: true,
                params: parsedConfiguration.result.params,
            },
        });
    };

    handleHistoryResults = (state: boolean, configurationId: string) => {
        this.setState({
            configurationForReferencesId: configurationId
        });
    };

    handleAfterDraw = () => {
        this.setState(prevState => {
            return {
                dicomActions: { ...prevState.dicomActions, draw: false },
                points: [],
            };
        });
    };

    handleSaveDwvState = (state: any) => {
        this.setState({ dwvState: state });
    }
    handleRemoveCircle = (circle: Circle) => {
        this.setState(prevState => {
            const circles = prevState.circles.filter(c => !_.isEqual(c, circle))
            return {
                circles,
                dicomActions: { ...prevState.dicomActions, draw: true }
            }

        })
    }

    handleIsDicomLoadedState = (state: boolean) => {
        this.setState({ isDicomLoaded: state });
        if (state) {
            this.setState({ isNewConfig: true });
        }
    };

    handleOnAddMeasurmentClick = (area: {averageHu: number, index: number, active: boolean, pointsAmount: number}) => {
        return this.setState(prevState => ({
            dicomActions: { ...prevState.dicomActions, canChoosePoints: true },
            areasInfo: prevState.areasInfo.map(a => {
                if (a.index === area.index) {
                    a.active = true;
                }
                return a;
            })
        }));
    };

    handleAddReferenceTestCases = (testCases: any[]) => {        
        this.updateReferenceObject(testCases);
        
        if (testCases.length >= 5) {
            return this.setState({ referenceTestCases: testCases, hasValidReferenceValue: true });
        }
        this.setState({ referenceTestCases: testCases });
    };

    configurationList = (item: any) => {
        const data = JSON.parse(item.configuration);

        const {
            result: {
                params: { kV, mA, kVDeviation, mADeviation, name },
            },
        } = data;

        return (
            <>
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <CustomStatistic title='Nazwa konfiguracji' value={name} />
                    </Col>
                </Row>
                <Row gutter={[16, 16]}>
                    <Col span={12}>
                        <CustomStatistic title='kV' value={kV} />
                    </Col>
                    <Col span={12}>
                        <CustomStatistic title='mA' value={mA} />
                    </Col>
                </Row>
                <Row gutter={[16, 16]}>
                    <Col span={12}>
                        <CustomStatistic title='Odchylenie kV' value={kVDeviation} />
                    </Col>
                    <Col span={12}>
                        <CustomStatistic title='Odchylenie mA' value={mADeviation} />
                    </Col>
                </Row>
            </>
        );
    };

    handleTestNoChange = (e: number) => {
        this.setState(prevState => {
            return {
                newConfiguration: { ...prevState.newConfiguration, testNumber: e },
            };
        });
    };

    handleParseConfigurations = () => {
        const { configurations } = this.props;

        const result: any[] = [];

        for (const item of configurations) {
            const data = JSON.parse(item.configuration);
            const configuration = { ...item, configuration: data };
            result.push(configuration);
        }
        return result;
    };

    handleConfirmTestNoChange = () => {
        const {
            newConfiguration: { testNumber },
        } = this.state;

        if (testNumber === 0) {
            return message.warn("Liczba pomiarów nie powinna wynosić 0");
        }

        this.setState({
            testNumber: testNumber,
        });
    };

    huAreas = () => {
        const { hu } = this.state;
        const huResult: JSX.Element[] = [];

        for (const i in hu) {
            const value = (
                <>
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <Text>Średnie HU na obszarze {parseInt(i) + 1}</Text>
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col span={24}>{parseFloat(hu[i]).toFixed(2)}</Col>
                    </Row>
                </>
            );
            huResult.push(value);
        }

        return huResult;
    };

    handleSaveConfigurationRequest = () => {
        const { testNumber, isDicomLoaded, hu, circles, referenceTestCases } = this.state;
        
        if (!isDicomLoaded) {
            message.error("Nie wybrano pliku Dicom");
        } else if (circles.length !== testNumber) {
            message.error("Niewystarczająca ilość pomiarów");
        } else if (referenceTestCases.length < 5) {
            this.confirm();
        } else {
            this.saveTestResult();
        }
    };

    handleAddArea = () => {
        const { isDicomLoaded, areasInfo } = this.state;
        const newArea = {averageHu: 0, index: areasInfo.length, active: false, pointsAmount: 0, name: `Obszar ${areasInfo.length}`, editing: false}; 

        if (!isDicomLoaded) {
            return message.info("Aby dodać pomiar wybierz plik Dicom");
        }

        if (areasInfo.find(a => a.active)) {
            return message.error("Najpierw dokończ zaznaczanie punktów dla wybranego kółka.");
        }

        this.setState(prevState => {
            return {
                ...prevState,
                areasInfo: [
                    ...prevState.areasInfo,
                    newArea
                ],
                testNumber: prevState.testNumber + 1
            }
        }, () => {
            const { testNumber } = this.state;

            if (testNumber === 0) {
                return message.info("Aby dodać pomiar zdefiniuj liczbę pomiarów");
            }
            this.handleOnAddMeasurmentClick(newArea)
        })
    }
    
    handleRemoveArea = (index: number) => {
        this.setState(prevState => {
            return {
                areasInfo: prevState.areasInfo.filter(area => area.index !== index).map(area => {
                    if (area.index < index)
                       return { ...area }

                    return { ...area, index: area.index - 1}
                }),
                circles: [...prevState.circles.filter((_, idx) => idx!== index)],
                dicomActions: { ...prevState.dicomActions, draw: !prevState.dicomActions.draw },
                testNumber: prevState.testNumber - 1
            }
        })
    }

    handleChangeAreaName = (area: {averageHu: number, index: number, active: boolean, pointsAmount: number, name: string}, newName: string) => {
        this.setState(prevState => { 
            return {
                areasInfo: prevState.areasInfo.map(a => {
                    if (a.index === area.index)
                        a.name = newName;
                    return a
                    }),
                dicomActions: { ...prevState.dicomActions, draw: true }
        }});
    }

    saveTestResult = () => {
        const { newConfiguration, testNumber, hu, circles, referenceTestCases, roiMeasurementRatio } = this.state;

        const { testId } = this.props.router.match.params;

        const roiCircles = circles.map(c => {
            return {...c, r: c.r * roiMeasurementRatio / 100 }
        }); 

        const result = {
            params: { ...newConfiguration, hu, referenceTestCases, circles: roiCircles, testNumber },
        };

        const testData = JSON.stringify({ result, references: [] });

        api.post(`test-configurations/test-case/${testId}`, { Content: testData })
            .then(() => {
                message.success("Utworzono nową konfigurację");
                this.props.fetchConfigurations();
                this.setState({ loading: false, newConfiguration: DEFAULT_CONFIGURATION, collapseActiveKey: 1 });
            })
            .catch(err => {
                message.warning("Nie udało się utworzyć konfiguracji");
                this.setState({ loading: false });
            });
    };

    getHuResult = (result: string[]) => {
        if (result.length !== this.state.areasInfo.length) {
            return message.error('Niepoprawna liczba obszarów.');
        }
        this.setState(prevState => ({
            hu: result,
            loading: false,
            dicomActions: { ...prevState.dicomActions, performHu: false },
            areasInfo: prevState.areasInfo.map((area, index) => {
                const averageHuStr = parseFloat(result[index]).toFixed(2);
                area.averageHu = parseFloat(averageHuStr);
                return area;
            })
        }));
    };

    handleParamsChange = (label: ParamType, value: number) => {
        switch (label) {
            case "kV":
                this.setState(prevState => {
                    return { newConfiguration: { ...prevState.newConfiguration, kV: value } };
                });
                break;
            case "mA":
                this.setState(prevState => {
                    return { newConfiguration: { ...prevState.newConfiguration, mA: value } };
                });
                break;
            case "mADeviation":
                this.setState(prevState => {
                    return { newConfiguration: { ...prevState.newConfiguration, mADeviation: value } };
                });
                break;
            case "kVDeviation":
                this.setState(prevState => {
                    return { newConfiguration: { ...prevState.newConfiguration, kVDeviation: value } };
                });
                break;
            case "averagekV":
                this.setState(prevState => {
                    return { newConfiguration: { ...prevState.newConfiguration, averagekV: value } };
                });
                break;

            case "averagemA":
                this.setState(prevState => {
                    return { newConfiguration: { ...prevState.newConfiguration, averagemA: value } };
                });
                break;
            default:
                break;
        }
    };

    handleConfigurationNameChange = (name: string) => {
        this.setState(prevState => {
            return { newConfiguration: { ...prevState.newConfiguration, name } };
        });
    };

    handleroiMeasurementRatioChange = (value: number) => {
        this.setState({roiMeasurementRatio: value})
    }

    handleCalculateReferenceValue = () => {
        const { referenceTestCases } = this.state;
        let values: number[] = [];
        for (let index = 0; index < this.state.activeConfiguration.params.testNumber; index++) {
            const value =
                referenceTestCases.reduce((a: any, b) => a + parseFloat(b.params.avgHu[index]), 0) /
                (referenceTestCases.length || 1);
            values.push(value);
        }
        const kv = referenceTestCases.reduce((a, b) => a + b.params.kV, 0) / (referenceTestCases.length || 1);
        const mA = referenceTestCases.reduce((a, b) => a + b.params.mA, 0) / (referenceTestCases.length || 1);

        this.setState(prevState => {
            return { newConfiguration: { ...prevState.newConfiguration, hu: values, averagekV: kv, averagemA: mA } };
        });
    };

    handleAddPoint = (a: number, b: number) => {
        const {
            points,
            dicomActions: { canChoosePoints },
            circles,
            testNumber,
            areasInfo
        } = this.state;

        if (!areasInfo.find(a => a.active))
            return message.error("Wybierz obszar.");
        
        if (!(points.length < 3) || !canChoosePoints || !(circles.length < testNumber))
            return ;
        
        const point: Point = { x: a, y: b };

        this.setState(prevState => ({
                points: [...prevState.points, point],
                areasInfo: prevState.areasInfo.map(area => {
                    if (area.active) {
                        area.pointsAmount += 1;
                    }
                    return area;
                })
            }), () => {
                if (this.state.points.length === 3) {
                    this.handleAddCircle();
                }                
            }
        );
    };

    handleAddCircle = () => {
        const circle: Circle = getCircleThrough3Point(
            this.state.points[0],
            this.state.points[1],
            this.state.points[2]
        );
        this.setState(prevState => ({
            circles: [...prevState.circles, circle],
            dicomActions: { ...prevState.dicomActions, draw: true, canChoosePoints: false },
            areasInfo: prevState.areasInfo.map(area => {
                area.active = false;
                return area;
            }),
            circle: circle,
        }),
            () => {
                if (this.state.circles.length === this.state.testNumber) {
                    this.onAddingCirclesFinish();
                }
            }
        );
    }

    onAddingCirclesFinish = () => {
        this.setState(prevState => {
            return {
                dicomActions: { ...prevState.dicomActions, performHu: true },
                loading: true,
            };
        });
    }
}

const SideBar = styled.div`
    overflow: auto;
    height: 80vh;
`;

const CustomStatistic = styled(Statistic)`
    .ant-statistic-content {
        font-size: 18px;
    }
`;
