import React from 'react'
import { Card } from "antd";
import styled from "styled-components";

const { Meta } = Card;

export type Props = {
    src?: string,
    alt?:string,
    description?: string,
    onClick?: () => void
}

const ImageCard = (props: Props) => {
    return (
        <StyledCard
            hoverable
            cover={<Img alt={props.alt || ""} src={props.src ? props.src : "https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png"} />}
            onClick={props.onClick}
        >
            <Meta description={props.description || description} />
        </StyledCard>
    )
}

const description =
    "2020/07/03 09:22:10 AEC zmiana natężenia stoł Wysokie 70 kV 5,6 mAs 13 ms";

const Img = styled.img`
    width: 100%;
    padding-bottom: 5px;
`

const StyledCard = styled(Card)`
    margin-bottom:10px;
    padding: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;

    &:hover {
        background: #0090d9;

        .ant-card-meta-description {
            color: #fff;
        }
    }
`

export default ImageCard;