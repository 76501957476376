import axios from 'axios';
import { response } from './components/constants/dictionaries';
import { showErrorModal } from './utils/modalConfirm';

export const api = axios.create({
    baseURL: `/api`,
});

api.interceptors.response.use(
    function (response: any) {
        return response;
    },
    function (error: any) {
        console.log(error);
        if(error.response) {
            if(error.response.status === response.UNAUTHORIZED && window.location.hash.indexOf('/login') === -1) {
                showErrorModal('Twoja sesja wygasła. Zaloguj się ponownie.');
                const url = '/#/login';
                window.location.replace(url);
                return false;
            }
        }

        return Promise.reject(error);
    }
)