import React, { useState } from "react";
import styled from "styled-components";
import { Collapse, Space, Row, Col, Statistic } from "antd";
import { ActionButton, CustomInput } from "../../common";
import _ from "lodash";

const { Panel } = Collapse;

type Props = {
    collection: Array<any>;
    onChange?: (item: any) => void;
    activeConfiguration: JSX.Element; 
    activeId?: string;
    buttonText?: string;
    addConfiguration?: JSX.Element;
    activeKeys: any
};

const CustomCollapse = (props: Props): any => {
    const { collection, onChange, activeId, addConfiguration, activeConfiguration, activeKeys } = props;
    
    return (
        <StyledCollapse activeKey='0'>
            <Panel header='Konfiguracje' key='0'>
                <StyledCollapse onChange={onChange} activeKey={activeKeys} accordion={true}>
                    <Panel header='Nowa konfiguracja' key='NEW_CONFIGURATION'>
                        {addConfiguration}
                    </Panel>
                    
                    {collection.map((c: any) => {
                        const { active, configuration, id } = c;
                        const { name, window } = JSON.parse(configuration);
                        const { WL } = window; 
                        
                        return (
                            <StyledPanel key={id} header={`${name} ${active ? '(aktywna)' : ''}`}>
                                <Space direction='vertical'>
                                    {WL.map((v:{level: number, width: number, name: string}) => (
                                        <>
                                            <Row>
                                                {v.name} (W/L): {v.width}/{v.level}
                                            </Row>
                                        </>
                                    ))}
                                </Space>
                            </StyledPanel>
                        );
                    })}
                </StyledCollapse>
            </Panel>
        </StyledCollapse>
    );
};

const StyledCollapse = styled(Collapse)`
    background-color: rgb(31, 56, 83);
    color: #ffffff;
    text-align: center;
    .ant-collapse-item {
        .ant-collapse-header {
            color: #ffffff;
        }
    }
`;

const ActiveLabel = styled.label`
    font-size: 20px;
`;

const StyledPanel = styled(Panel)`
    .anticon.anticon-right.ant-collapse-arrow {
        color: #ffffff;
    }
`;

const Title = styled.h4`
    font-size: 16px;
`;

const CustomStatistic = styled(Statistic)`
    .ant-statistic-content {
        font-size: 18px;
    }
`;

export default CustomCollapse;

export { StyledCollapse };
