import React, { Component } from 'react';
import { Row, Col } from 'antd';
import styled from 'styled-components';
import moment from 'moment';

import { CustomTestCard, MainCard, CustomTable, IconButton, HistoryFilters, CustomChart, RedCrossIcon, GreenCheckIcon } from '../../common';
import { IRouter } from '../../../utils/Interfaces';
import { HistoryResultRowsDesign } from '../../constants/historyResultRowsDesign'
import { filterResults } from '../../common/FilterResults'

type State = {
    filters: {
        selectedTimeOption: string,
        date: any
    }
    selectedResult: any,
    testData: any
}

type Props = {
    router: IRouter,
    results: Array<any>,
    validTime: number,
    calculateNextStudy: (dateOfTest: any, validTime: number) => string,
}

type chartDataPart = {
    id: string,
    data: Array<any>
}

class GeometricCorrectnessHistory extends Component<Props, State> {
    constructor(props: any) {
        super(props);
        
        this.state = {
            filters: {
                selectedTimeOption: 'all',
                date: null,
            },
            selectedResult: null,
            testData: null
        }
    }

    componentDidMount() {
        const { results } = this.props;
        if (results.length > 0) {
            this.changeSelectedResult(results[0]);
        }
    }

    render() {
        const { results, validTime, calculateNextStudy } = this.props;
        const { selectedResult, testData } = this.state;

        return (
            <>
                <Row gutter={[24, 24]}>
                    <Col span={4}>
                        <GreyStyledRow justify="center">
                            <IconButton
                                onClick={this.handleChangeToNewTest}
                                label='Nowy test'
                            />
                        </GreyStyledRow>
                        <WhiteStyledRow justify="center">
                            <Col span={18}>
                                <HistoryFilters 
                                    filters={this.state.filters}
                                    onChange={this.handleFiltersChange}
                                    onFinish={this.handleFiltersSubmit}
                                />
                            </Col>
                            <Col span={18}>
                                <CustomTable
                                    columns={this.historyResultsColumns}
                                    data={filterResults(results, this.state.filters.selectedTimeOption, this.state.filters.date || null)}
                                    cursor='pointer'
                                    onRow={(record: any) => {
                                        return {
                                          onClick: () => this.changeSelectedResult(record)
                                        }
                                    }}
                                />
                            </Col>
                        </WhiteStyledRow>
                    </Col>
                    <Col span={20}>
                        <Row gutter={[24, 24]}>
                            <Col span={24}>
                                <CustomTestCard
                                    resultId={selectedResult && selectedResult.result.id}
                                    title='Geometryczna Poprawność Obrazu'
                                    performer={selectedResult ? `${selectedResult.user.firstName} ${selectedResult.user.lastName}` : null}
                                    date={selectedResult ? selectedResult.result.dateOfTest : null}
                                    expirationDate={selectedResult ? calculateNextStudy(selectedResult.result.dateOfTest, validTime) : null}
                                    result={selectedResult ? selectedResult.result.passed : null}
                                />
                            </Col>
                            <Col span={8}>
                                <MainCard title='Parametry wejściowe'>
                                    {testData && <Row><Col><strong>Średnica: {testData.result.givenDiameter} (mm)</strong></Col></Row>}
                                </MainCard>
                            </Col>
                            <Col span={16}>
                                {testData && 
                                    <MainCard title='Wyniki pomiaru'>
                                        <Col span={16}>
                                            <CustomTable
                                                columns={this.resultColumns}
                                                data={this.unpackTestResultData(testData.result)}/>
                                        </Col>
                                    </MainCard>
                                }
                            </Col>
                            {selectedResult && 
                                <Col span={24}>
                                    <CustomChart data={this.generateChartData()}/>
                                </Col>
                            }
                        </Row>
                    </Col>
                </Row>

            </>
        );
    }
    
    handleChangeToNewTest = () => {
        this.props.router.history.push(this.props.router.location.pathname.replace(/[^/]*$/, 'perform-test'));
    }

    changeSelectedResult = (newResult: any) => {
        this.setState({
            selectedResult: newResult,
            testData: JSON.parse(newResult.result.testData)
        })
    }

    handleFiltersChange = (filterName: string, newValue: any) => {
        this.setState(prevState => {
            prevState.filters[filterName] = newValue;
            return prevState;
        })
    }

    handleFiltersSubmit = () => {
        alert('Filtrowanie!');
    }

    unpackTestResultData = (testResult: any) => {
        const result = []
        let message;
        
        testResult.isSuccesfull 
        ? message = <GreenCheckIcon />
        : message = <RedCrossIcon />;
        result.push({
            measurment: testResult.measuredDiameter.toFixed(2), 
            reference: testResult.givenDiameter.toFixed(2),
            deviation: testResult.calculation.toFixed(2),
            name: 'Średnica', 
            result: message })
        
        result.push({name: 'Wynik testu', result: message })
        
        return result
    }

    generateChartData = () => {
        const { results } = this.props;
        if (results.length > 0) {
            const reference: chartDataPart = {
                id: "Odniesienie",
                data: []
            }
            const measured:chartDataPart = {
                id: "Pomiar",
                data: []
            }

            results.forEach(nextResult => {
                const result = JSON.parse(nextResult.result.testData).result;
                const dateOfTest = moment(nextResult.result.dateOfTest).format('YYYY-MM-DD h:mm:ss');
               
                reference.data.push({
                    x: dateOfTest, y: result.givenDiameter.toFixed(2)
                })
                measured.data.push({
                    x: dateOfTest, y: result.measuredDiameter.toFixed(2)
                })
            })

            return [reference, measured]
        }

        const data = [
            {
                id: "SNR",
                data: [
                    { x: "2018-08-15 16:05:20", y: 17.89 },
                    { x: "2018-09-14 16:05:20", y: 19.56 },
                    { x: "2018-10-14 16:05:20", y: 18.05 },
                    { x: "2018-11-13 16:05:20", y: 20.39 },
                    { x: "2018-12-13 16:05:20", y: 21.9 },
                    { x: "2019-01-12 16:05:20", y: 23.41 },
                    { x: "2019-02-11 16:05:20", y: 22.09 },
                ],
            },
            {
                id: "TEST ",
                data: [
                    { x: "2018-08-15 16:05:20", y: 12.89 },
                    { x: "2018-09-14 16:05:20", y: 9.56 },
                    { x: "2018-10-14 16:05:20", y: 23.05 },
                    { x: "2018-11-09 16:05:20", y: 21.39 },
                    { x: "2018-12-13 16:05:20", y: 19.9 },
                    { x: "2019-01-12 16:05:20", y: 16.41 },
                    { x: "2019-02-11 16:05:20", y: 10.09 },
                ],
            },
        ];
        return data;
    }
    
    resultColumns = [
        {
            title: 'Nazwa',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Pomiar',
            dataIndex: 'measurment',
            key: 'measurment',
        },
        {
            title: 'Odniesienie',
            dataIndex: 'reference',
            key: 'reference',
        },
        {
            title: 'Odchylenie',
            dataIndex: 'deviation',
            key: 'deviation',
        },
        {
            title: 'Poprawny',
            dataIndex: 'result',
            key: 'result',
        },
    ]
    
    historyResultsColumns = [
        {
            title: 'WYNIKI HISTORYCZNE',
            dataIndex: 'date',
            key: 'date',
            render: (_: any, row: any) => {
                const { selectedResult } = this.state;
                return {
                  props: {
                    style: 
                        {
                            background: selectedResult && selectedResult.result.id === row.result.id ? HistoryResultRowsDesign.SELECTED_RESULT_BACKGROUND_COLOR : null, 
                            color: selectedResult && selectedResult.result.id === row.result.id ? HistoryResultRowsDesign.SELECTED_RESULT_TEXT_COLOR : null, 
                            borderLeft: row.result.passed ? HistoryResultRowsDesign.BORDER_LEFT_TEST_PASSED_COLOR : HistoryResultRowsDesign.BORDER_LEFT_TEST_NOT_PASSED_COLOR, 
                            textAlign: HistoryResultRowsDesign.TEXT_ALIGN }
                        },
                    children: moment(row.result.dateOfTest).format('YYYY-MM-DD HH:mm')
                };
            }
        }
    ]
}


const GreyStyledRow = styled(Row)`
    background: #F6F7F8;
    padding: 20px 0px 20px 0px;
`

const WhiteStyledRow = styled(Row)`
    background: #FFFFFF;
    padding: 20px 0px 20px 0px;
`

export default GeometricCorrectnessHistory;