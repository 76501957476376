import React, { useState } from "react";
import { Input, Row } from "antd";
import { IconButton } from "../common";
import { EditOutlined, AimOutlined } from "@ant-design/icons";
import styled from "styled-components";

declare const IconType: ["edit", "draw"];

export declare type IconType = typeof IconType[number];

export type Props = {
    color: string;
    label?: string;
    disabled?: boolean;
    type: IconType;
    value: string;
    onChange?: (value: string) => void;
};

const ColouredInputIcon = (props: Props) => {
    const [disabled, setDisabled] = useState(props.disabled);

    return (
        <CContainer>
            {props.label && (
                <Row>
                    <CLabel>{props.label}</CLabel>
                </Row>
            )}
            <Row>
                <CInputIcon>
                    <CInput
                        color={props.color}
                        onChange={e =>
                            props.onChange && props.onChange(e.target.value)
                        }
                        disabled={disabled || false}
                        value={props.value}
                    />
                    {props.type === "draw" ? (
                        <IconButton
                            width='40px'
                            onClick={() => setDisabled(false)}
                            style={{ padding: 0 }}
                            icon={<AimOutlined />}
                        />
                    ) : (
                        <IconButton
                            color='#d1dade'
                            width='40px'
                            onClick={() => setDisabled(false)}
                            style={{
                                padding: 0,
                                border: "none",
                                color: "#5e5e5e",
                            }}
                            icon={<EditOutlined />}
                        />
                    )}
                </CInputIcon>
            </Row>
        </CContainer>
    );
};

const CLabel = styled.span`
    font-weight: 700;
`;

const CInputIcon = styled.div`
    display: flex;
    flex-direction: row;
`;

const CContainer = styled.div`
    padding: 26px;
`;

const CInput = styled(Input)`
    border-left: 3px solid ${props => props.color};
    margin-bottom: 8px;
    margin-right: 10px;
`;

export default ColouredInputIcon;
