import React from 'react';
import { RedCrossIcon, GreenCheckIcon } from '../common';

const TEXT_RESULT_COLUMNS = [
    {
        title: "Nazwa",
        dataIndex: "name",
        key: "name",
    },
    {
        title: "Pomiar",
        dataIndex: "measurement",
        key: "measurement",
    },
    {
        title: "Odniesienie",
        dataIndex: "reference",
        key: "reference",
    },
    {
        title: "Odchylenie",
        dataIndex: "deviation",
        key: "deviation",
    },
    {
        title: "Poprawny",
        key: "result",
        dataIndex: "result",
        render: (result: boolean) => result ? React.createElement(GreenCheckIcon) : React.createElement(RedCrossIcon)
        
    },
];


export {
    TEXT_RESULT_COLUMNS
}