import React from "react";
import { CustomInput, ActionButton, Text } from "../../common";
import { Point, ParamType, configurationHuWater, referenceHuWater } from "../../../utils/Models";
import { Tooltip, Button, Row, Col, Divider } from "antd";
import { AimOutlined, DeleteOutlined } from "@ant-design/icons";

const { Title } = Text;

type Props = {
    handleDeleteDrawings: () => void;
    handleParamsChange: (label: ParamType, value: number) => void;
    points: Point[];
    handleRadiusChange: (e: number) => void;
    handleRadiusToCm: (e: number) => number;
    handleAddPoints: () => void;
    handleChangeConfigurationName: (e: string) => void;
    handleRoi: () => void;
    isDicomLoaded: boolean;
    onFinish: () => void;
    newConfiguration: configurationHuWater
};

const Configuration = ({
    handleDeleteDrawings,
    handleRadiusToCm,
    handleParamsChange,
    handleAddPoints,
    handleChangeConfigurationName,
    onFinish,
    points,
    newConfiguration: { circle, roiCircle, kVDeviation, mADeviation, name },
}: Props) => {
    const [radius, setRadius] = React.useState<number>(0);

    const onCircleRadiusDisplay = () => {
        const r = handleRadiusToCm(circle.r);
        return r;
    };

    React.useEffect(() => {
        const r = onCircleRadiusDisplay();
        setRadius(r);
    }, [circle.r]);

    return (
        <>
            <Title> Dane konfiguracji</Title>

            <CustomInput
                type='text'
                color='#D779F2'
                label='Nazwa konfiguracji'
                onChange={handleChangeConfigurationName}
                value={name}
            />

            <Title> Parametry</Title>

            <Row gutter={[16, 16]}>
                <Col span={20}>
                    <CustomInput
                        type='number'
                        color='#D779F2'
                        label='Średnica fantomu'
                        value={(2 * radius).toFixed(2)}
                        disabled={true}
                        min={0}
                    />
                </Col>
                <Col span={2}>
                    <Tooltip title='Zaznacz trzy punkty na obwodzie fantomu'>
                        <Button
                            onClick={() => handleAddPoints()}
                            icon={<AimOutlined />}
                            style={{ marginTop: 22, backgroundColor: "#0082D4", color: "#FFFFFF" }}
                        />
                    </Tooltip>
                </Col>
                <Col span={2}>
                    {points.length >= 3 &&
                        <Tooltip title="Narysuj od nowa">
                            <Button 
                                onClick={handleDeleteDrawings}
                                icon={<DeleteOutlined />} 
                                style={{ marginTop: 22, backgroundColor: '#FFF', color: '#f5222d' }}
                            />      
                        </Tooltip>
                    }
                </Col>
            </Row>

            <Row gutter={[16, 16]}>
                <Col span={12}>
                    <CustomInput
                        color='#D779F2'
                        label='Środek pomiaru fantomu wodnego'
                        disabled={true}
                        value={`[ ${(circle.center.x + (circle.center.offsetX || 0)).toFixed(2)} , ${(circle.center.y + (circle.center.offsetY || 0)).toFixed(2)} ]`}
                    />
                </Col>
                <Col span={12}>
                    <CustomInput
                        color='#D779F2'
                        label='Środek ROI'
                        disabled={true}
                        value={`[ ${(roiCircle.center.x + (roiCircle.center.offsetX || 0)).toFixed(2)} , ${(roiCircle.center.y + (roiCircle.center.offsetY || 0)).toFixed(2)} ]`}
                    />
                </Col>
            </Row>
            <Row gutter={[16, 16]}>
                <Col span={12}>
                    <CustomInput
                        type='number'
                        color='#D779F2'
                        value={kVDeviation}
                        label='Odchylenie kV'
                        onChange={e => handleParamsChange("kVDeviation", e)}
                        min={0}
                    />
                </Col>
                <Col span={12}>
                    <CustomInput
                        type='number'
                        color='#D779F2'
                        value={mADeviation}
                        label='Odchylenie mA'
                        onChange={e => handleParamsChange("mADeviation", e)}
                        min={0}
                    />
                </Col>
            </Row>

            <Divider />

            <Col span={24}>
                <ActionButton label='Stwórz i ustaw jako aktywną' onClick={onFinish} />
            </Col>
        </>
    );
};

export default Configuration;
