import React, { Component } from 'react';
import { ResourceTilesPage } from '../components/common';
import { IRouter } from '../utils/Interfaces';
import { Hardware } from '../utils/Models';
import { api } from '../api';
import { withStore } from "../store"
import CustomSpin from "../components/common/CustomSpin"
 
type State = {
    hardwares: Array<Hardware>,
    isLoading: boolean
}

class HardwaresView extends Component<IRouter, State> {
    constructor(props: any) {
        super(props);
        
        this.state = {
            hardwares: [],
            isLoading: true
        }
    }
    
    componentDidMount() {
        this.fetchHardwares();
        this.props.store.setToindex(2);
    }
    
    render() {
        const { hardwares, isLoading } = this.state;
        
        if (isLoading) {
            return <CustomSpin />;
        }

        return (
            <ResourceTilesPage 
                resources={hardwares}
                onTileClick={this.chooseHardware}
            />
        );
    }

    fetchHardwares = () => {
        const { outpostId } = this.props.match.params;
        
        api
            .get(`/hardwares/outpost/${outpostId}`)
            .then(res => res.data)
            .then(res => this.setState({ hardwares: res }))
            .finally(() => this.setState({ isLoading: false }))
    }

    chooseHardware = (id: string) => {    

        const { hardwares } = this.state;

        const url = `/browse/hardware/${id}/test-suits`;

        this.props.store.DispatchAdd(hardwares, id, url);

        this.props.history.push(url);
    }
}

export default withStore(HardwaresView);