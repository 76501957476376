import React from 'react';
import styled from 'styled-components';

export type Props =  {
    tip?: string,
}

const CustomSpin = (props : Props) => {
    return (
        <StyledDiv>
        <Loader />
            <Text>{props.tip || "Wczytywanie..."}</Text>
        </StyledDiv>
    )
}

const StyledDiv = styled.div`
margin-top: 15%;
`
const Text = styled.div`
margin-top: 20px;
align-items: center;
display: flex;
justify-content: center;
font-weight: bold;
font-size: medium;
color: #1B1E24;
`

const Loader = styled.div`
border: 5px solid #F3EFE0;
border-top: 5px solid #1B1E24;
border-radius: 50%;
transform-origin: center center;
width: 90px;
height: 90px;
animation: spin 1s linear infinite;
position: relative;
margin: 0 auto;
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`

export default CustomSpin;