import React from 'react';
import { Row, Col } from 'antd';
import moment from 'moment';

import ResourceTile  from './ResourceTile';

type Resource = {
    id: string,
    name: string,
    lastTestResultTime: string,
    nextTestResultTime: string,
    hasConfiguration: boolean,
}

type Props = {
    resources?: Array<any>
    onTileClick?: (id: string) => void,
    isTestSuitPage?: boolean,
    onHistoryClick?: (id: string) => void,
    onPerformTestClick?: (id: string) => void,
    onSettingsClick?: (id: string) => void,
}

const ResourceTilesPage = (props: Props) => {    
    return (
        <Row gutter={[16, 16]}>
            {props.resources && props.resources.map(resource =>
                <Col md={6} span={24} key={resource.id}>
                    <ResourceTile
                        id={resource.id}
                        title={resource.name}
                        lastStudyDate={resource.lastTestResultTime}
                        nextStudyDate={resource.nextTestResultTime}
                        onClick={props.onTileClick}
                        onHistoryClick={props.onHistoryClick}
                        onPerformTestClick={props.onPerformTestClick}
                        onSettingsClick={props.onSettingsClick}
                        isTestSuitTile={props.isTestSuitPage}
                        configuration={resource.hasConfiguration}
                        type='advanced'
                    />
                </Col>
            )}
        </Row>
    )
}

export default ResourceTilesPage;