import React from 'react';
import styled from 'styled-components';
import moment from 'moment';

import { Row, Col, Progress } from 'antd';
import { CalendarOutlined, ToolOutlined } from '@ant-design/icons';

import dateFormats from '../constants/dateFormats';
import { createTimeToNextStudyString } from '../../utils/createTimeToNextStudyString';
import { getMinutesDifferenceToNextStudy, calculateProgressPercentage, getTileColor } from '../../utils/calculateTileColorBasedOnTime';

export type Props = {
    id: string,
    title?: string,
    lastStudyDate?: string,
    nextStudyDate?: string,
    isTestSuitTile?: boolean,
    onClick?: (id: string) => void,
    onHistoryClick?: (id: string) => void,
    onPerformTestClick?: (id: string) => void,
    onSettingsClick?: (id: string) => void,
    configuration?: boolean,
    type?: 'basic' | 'advanced',
    color?: string
}

const ResourceTile = (props: Props) => {        
    const minutesDifference = getMinutesDifferenceToNextStudy(props.nextStudyDate);
    return (
        <Tile color={props.color || getTileColor(minutesDifference)} onClick={() => props.onClick && props.onClick(props.id)}>
            <TileInfoArea>
                <Row align={props.type === 'basic' ? 'middle' : 'top'} style={{height: '100%'}}>
                    <Col span={props.isTestSuitTile ? 18 : 24}>
                        <Title margin={props.type === 'basic' ? 0 : 30}>{props.title}</Title>
                    </Col>
                    {props.isTestSuitTile && props.configuration &&
                        <Col span={6} style={{marginBottom: props.type === 'basic' ? 0 : 30}}>
                            <OpenSettingsArea onClick={() => props.onSettingsClick && props.onSettingsClick(props.id)}>
                                <Row>
                                    <Col><ToolOutlined />Ustawienia</Col>
                                </Row>
                            </OpenSettingsArea>
                        </Col>
                    }
                </Row>
                {props.type === 'advanced' &&
                    <TileStats>
                        <Row>
                            Czas do kolejnego badania: {createTimeToNextStudyString(minutesDifference)}
                        </Row>
                        <Row>
                            <Progress 
                                percent={calculateProgressPercentage(minutesDifference)} 
                                showInfo={false} 
                                strokeColor={'#FFFFFF'} 
                                trailColor={'#00689D'}
                                strokeWidth={5}
                            />
                        </Row>
                        <Row>
                            <Col>
                                <CalendarOutlined />
                            </Col>
                            <Col offset={1}>
                                Ostatnie badanie: &nbsp;
                                {props.lastStudyDate ? moment(props.lastStudyDate).format(dateFormats.DATE_TIME_SHORT) : 'Brak danych'}
                            </Col>
                        </Row>
                    </TileStats>
                }
            </TileInfoArea>
            {props.isTestSuitTile && 
                <Row>
                    <Col span={8}>
                        <HistoryButton onClick={() => props.onHistoryClick && props.onHistoryClick(props.id)}>
                            Historia
                        </HistoryButton>
                    </Col>
                    <Col span={16}>
                        <PerformTestButton onClick={() => props.onPerformTestClick && props.onPerformTestClick(props.id)}>
                            Wykonaj test
                        </PerformTestButton>
                    </Col>
                </Row>
            }
        </Tile>
    )
}

const Tile = styled.div`
    width: 100%;
    height: 100%;
    min-height: 150px;
    color: #FFFFFF;
    background: ${props => props.color};
    display: flex;
    justify-content: space-between;
    flex-flow: column nowrap;
    cursor: pointer;
`

const TileStats = styled.div``

const TileInfoArea = styled.div`
    height: 100%;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    flex-flow: column nowrap;
`

const Title = styled.div<{margin: number}>`
    font-size: 25px;
    font-weight: 400;
    margin-bottom: ${props => props.margin}px;
`

const OpenSettingsArea = styled.div`
    cursor: pointer;
`

const HistoryButton = styled.div`
    background: #FFFFFF;
    color: #000000;
    padding: 8px 0px 8px 0px;
    text-align: center;
    cursor: pointer;
`

const PerformTestButton = styled.div`
    background: #1B3148;
    color: #FFFFFF;
    padding: 8px 0px 8px 0px;
    text-align: center;
    cursor: pointer;
`

export default ResourceTile;