import React, { useState, useEffect } from "react";
import { Props } from "./common/CustomModal";
import { Checkbox, Row, Col, Typography, Divider } from "antd";
import { api } from "../api";
import _ from "lodash";
import { TestType } from "../utils/Models";
import moment from "moment";
import styled from "styled-components";
import { ActionButton } from "./common";

const { Title, Text } = Typography;

type ExtendedProps = Props & {
    testCaseId: string;
    type: TestType;
    action: (data: any) => void;
    configuration?: any;
    handleParamsChange?: (label: any, value: number) => void;
    configId: string
};

type NoiseParams = {
    kV: number;
    mA: number;
    noise: number;
    date: Date;
};

const TestHistory = (props: ExtendedProps) => {
    const [testCases, setTestCases] = useState<Array<any>>([]);
    const [choosenTestCases, setChoosenTestCases] = useState<Array<any>>([]);

    useEffect(() => {
        api.get(`test-results/test-case/${props.testCaseId}`)
            .then(res => res.data)
            .then(res => res.results)
            .then(res => setTestCases(res));
    }, []);

    const getFilteredAndSortedTestResults = (testResults: any) => {
        const filteredData = testResults
            .filter((test: any) => test.result.testConfigurationId === props.configId)
            .filter((_: any, index: number) => index < 20)
            .sort((test1: any, test2: any) => moment(test1.result.dateOfTest) < moment(test2.result.dateOfTest) ? 1 : -1);
        
        return filteredData;
    }


    const handleContent = () => {
        switch (props.type) {
            case "noise_level":
                return (
                    <>
                        <Row gutter={[16, 16]}>
                            <Col span={10}>
                                <Row gutter={[16, 16]}>
                                    <Col span={24}>
                                        <Text strong>Średnie wartości</Text>
                                    </Col>
                                </Row>
                                <Row gutter={[16, 16]}>
                                    <Col span={2} />
                                    <Col span={14}>
                                        <Text>Poziom szumu</Text>
                                    </Col>
                                    <Col span={8}>
                                        {(
                                            choosenTestCases.reduce((a, b) => a + b.params.noise, 0) /
                                            (choosenTestCases.length || 1)
                                        ).toFixed(2)}
                                    </Col>
                                </Row>
                                <Row gutter={[16, 16]}>
                                    <Col span={2} />
                                    <Col span={14}>
                                        <Text>kV</Text>
                                    </Col>
                                    <Col span={8}>
                                        {(
                                            choosenTestCases.reduce((a, b) => a + b.params.kV, 0) /
                                            (choosenTestCases.length || 1)
                                        ).toFixed(2)}
                                    </Col>
                                </Row>
                                <Row gutter={[16, 16]}>
                                    <Col span={2} />
                                    <Col span={14}>
                                        <Text>mA</Text>
                                    </Col>
                                    <Col span={8}>
                                        {(
                                            choosenTestCases.reduce((a, b) => a + b.params.mA, 0) /
                                            (choosenTestCases.length || 1)
                                        ).toFixed(2)}
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={14}>
                                {testCases.map(c => (
                                    <CCheckbox key={c.result.id} onChange={e => onChange(e, c)}>
                                        <CTitle level={5}>
                                            Pomiar {moment(c.result.dateOfTest).format("YYYY-MM-DD HH:mm")}
                                        </CTitle>
                                    </CCheckbox>
                                ))}
                            </Col>
                        </Row>
                    </>
                );

            case "hu_density":
                return (
                    <>
                        <Row gutter={[8, 8]}>
                            <Col span={24} style={{textAlign: 'start', marginTop: 10}}>Wybierz pomiary:</Col>
                            <Col span={24}>
                                {!_.isEmpty(testCases) && getFilteredAndSortedTestResults(testCases).map((c: any) => {
                                    const parsedResult = JSON.parse(c.result.testData).values;

                                    return (
                                        <CCheckbox key={c.result.id} onChange={e => onChange(e, c)}>
                                            <>
                                                <Row>
                                                    Pomiar {moment(c.result.dateOfTest).format("YYYY-MM-DD HH:mm")},
                                                    kV {parsedResult.kV},
                                                    mA {parsedResult.mA}
                                                </Row>
                                                <Row>
                                                    [{parsedResult.hu.map((singleHu: number) => singleHu.toFixed(2) + ' ')}]
                                                </Row>
                                            </>
                                        </CCheckbox>
                                    )})
                                }
                            </Col>
                        </Row>
                    </>
                );
            default:
                break;
        }
    };

    const huAreas = () => {
        const {
            params: { testNumber },
        } = props.configuration;

        let result: JSX.Element[] = [];

        for (let index = 1; index <= testNumber; index++) {
            const value =
                choosenTestCases.reduce((a: any, b) => a + parseFloat(b.params.avgHu[index - 1]), 0) /
                (choosenTestCases.length || 1);
            const area = (
                <Col span={24}>
                    Średnie HU na obszarze {index}, {value.toFixed(2)}
                </Col>
            );
            result.push(area);
        }
        return result;
    };

    const onChange = (e: any, c: any) => {
        const { checked } = e.target;
        const {
            result: { testData, id },
        } = c;
        const { values, dataSource } = JSON.parse(testData);

        let testParams: any = [];

        if (props.type === "hu_density") {
            const huDensityParams: any = {
                kV: parseFloat(values.kV),
                mA: parseFloat(values.mA),
                avgHu: values.hu,
                date: c.result.dateOfTest
            };
            testParams = huDensityParams;
        } else if (props.type === "noise_level") {
            const noiseParams: NoiseParams = {
                kV: parseFloat(values.kV),
                mA: parseFloat(values.mA),
                noise: parseFloat(dataSource[0].measurement),
                date: c.result.dateOfTest
            };
            testParams = noiseParams;
        }

        if (checked === true) {
            const newChoosenTestCases = [...choosenTestCases, { id, params: testParams }];
            setChoosenTestCases(newChoosenTestCases);
            props.action(newChoosenTestCases);
        } else {
            const newChoosenTestCases = [...choosenTestCases.filter(c => c.id !== id)];
            setChoosenTestCases(newChoosenTestCases);
            props.action(newChoosenTestCases);
        }
    };

    return <Content visible={props.visible}>{handleContent()} </Content>;
};


const Content = styled.div<{visible: boolean}>`
    display: ${props => props.visible === false ? "none" : "flex"};
`
const CCheckbox = styled(Checkbox)`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 0px !important ;
`;

const CTitle = styled(Title)`
    margin-bottom: 0 !important;
`;
export default TestHistory;
