import React from 'react'
import { Button, Row } from "antd"
import { CustomInput, ActionButton } from "../../common"
import styled from "styled-components";
import { WLSetting } from "../../../utils/Models"
import _ from "lodash"

type Props = {
    configuration: any,
    handleConfigurationName: (e: string) => void,
    changeToolToDraw: () => void,
    handleWLChange: (WLParam: WLSetting, name: string) => void,
    newWL: WLSetting;
    drawings: any,
    selectedTool: any,
    artefactsOffset: {id: string, x: number | undefined, y: number | undefined}[]
}
const AddConfiguration = ({ artefactsOffset, configuration, newWL, selectedTool, drawings, handleConfigurationName, changeToolToDraw, handleWLChange }: Props) => {
    return (
        <>
            <CustomInput onChange={e => handleConfigurationName(e)} label="Nazwa konfiguracji"></CustomInput>
            <Title>{configuration.window.name}</Title>
            {!_.isEmpty(configuration) && configuration.window.WL.map((WLParam: any, index: number) =>
                <>
                    <Button
                        type="link"
                        onClick={() => handleWLChange(WLParam, configuration.window.name)}
                        style={{ padding: 0 }}
                    >
                        {`Ustawienia okna ${index + 1}`} {_.isEqual(newWL.level, WLParam.level) && _.isEqual(newWL.width, WLParam.width) && '(Aktywne)'}
                    </Button>
                    <Row>Szerokość: {WLParam.width}</Row>
                    <Row>Poziom: {WLParam.level}</Row>
                </>
            )}
            <Title> Artefakty </Title>
            {
                drawings
                    .filter((d: any) => _.isEqual(d.WL, newWL))
                    .map((d: any, index: number) => {
                        const offset = artefactsOffset.find(a => a.id === d.id);
                        if(offset){
                            return <Row>{`Artefakt ${index + 1}, ${(d.x + offset.x).toFixed(2)} ${(d.y + offset.y).toFixed(2)} ${(d.radiusX).toFixed(2)} ${(d.radiusY).toFixed(2)}`}</Row>
                        }
                        return <Row>{`Artefakt ${index + 1}, ${(d.x).toFixed(2)} ${(d.y).toFixed(2)} ${(d.radiusX).toFixed(2)} ${(d.radiusY).toFixed(2)}`}</Row>
                    })
            }
            <ActionButton
                label='Dodaj / Edytuj artefakt'
                onClick={changeToolToDraw}
            />
        </>
    )
}

const Title = styled.h4`
    font-size: 16px;
`;


export default AddConfiguration
