import React from "react";
import styled from "styled-components";
import { Collapse, Space } from "antd";
import { ActionButton } from "./Buttons";
import _ from "lodash";

const { Panel } = Collapse;

type Props = {
    collection: Array<any>;
    content: (item: any) => any | string;
    callback: (item: string) => void;
    onChange?: (item: any) => void;
    activeId?: string;
    buttonText?: string;
    addConfigurationPanel?: JSX.Element;
};

const CustomCollapse = (props: Props): any => {
    const { collection, content, onChange, callback, activeId, addConfigurationPanel } = props;
    return (
        <StyledCollapse onChange={onChange}>
            <Panel header='Konfiguracje' key='1'>
                <StyledCollapse defaultActiveKey={[`${activeId}`]}>
                    {collection.map((item: any, key: number) => (
                        <StyledPanel key={item.id} header={`Konfiguracja ${key}`}>
                            <Space direction='vertical'>
                                {_.isFunction(content) ? content(item) : content}
                                {item.id === activeId ? (
                                    <ActiveLabel>Aktywna </ActiveLabel>
                                ) : (
                                    <ActionButton
                                        label={props.buttonText || "Aktywuj"}
                                        onClick={() => callback(item)}
                                    />
                                )}
                            </Space>
                        </StyledPanel>
                    ))}
                </StyledCollapse>
            </Panel>
                                {addConfigurationPanel && <Panel header='Dodaj konfigurację' key='2'>{addConfigurationPanel}</Panel>}
        </StyledCollapse>
    );
};

const StyledCollapse = styled(Collapse)`
    background-color: rgb(31, 56, 83);
    color: #ffffff;
    text-align: center;
    .ant-collapse-item {
        .ant-collapse-header {
            color: #ffffff;
        }
    }
`;

const ActiveLabel = styled.label`
    font-size: 20px;
`;

const StyledPanel = styled(Panel)`
    .anticon.anticon-right.ant-collapse-arrow {
        color: #ffffff;
    }
`;

export default CustomCollapse;

export { StyledCollapse };
