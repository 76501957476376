import React, { Component } from 'react';
import { Row, Col } from 'antd';
import styled from 'styled-components';
import moment from 'moment';
import { CustomTestCard, MainCard, CustomTable, IconButton, CustomChart, HistoryFilters, GreenCheckIcon, RedCrossIcon } from '../../common';
import { IRouter } from '../../../utils/Interfaces';
import calculateNextStudyDate from '../../../utils/calculateNextStudyDate';
import { HistoryResultRowsDesign } from '../../constants/historyResultRowsDesign'
import { filterResults } from '../../common/FilterResults'

type State = {
    filters: {
        selectedTimeOption: string,
        date: any
    }
    selectedResult: any,
    testData: any
}

type Props = {
    router: IRouter,
    results: Array<any>,
    validTime: number,
    calculateNextStudy: (dateOfTest: any, validTime: number) => string,
}

class NoiseLevelHistory extends Component<Props, State> {
    constructor(props: any) {
        super(props);
        
        this.state = {
            filters: {
                selectedTimeOption: 'all',
                date: null,
            },
            selectedResult: null,
            testData: null
        }
    }

    render() {
        if (!this.state.selectedResult && this.props.results.length > 0) {
            this.changeSelectedResult(this.props.results[0]);
        }
        
        const { results, validTime, calculateNextStudy } = this.props;
        const { selectedResult, testData } = this.state;

        return (
            <>
                <Row gutter={[24, 24]}>
                    <Col span={4}>
                        <GreyStyledRow justify="center">
                            <IconButton
                                onClick={this.handleChangeToNewTest}
                                label='Nowy test'/>
                        </GreyStyledRow>
                        <WhiteStyledRow justify="center">
                            <Col span={18}>
                                <HistoryFilters 
                                    filters={this.state.filters}
                                    onChange={this.handleFiltersChange}
                                    onFinish={this.handleFiltersSubmit}/>
                            </Col>
                            <Col span={18}>
                                <CustomTable
                                    columns={this.historyResultsColumns}
                                    data={filterResults(results, this.state.filters.selectedTimeOption, this.state.filters.date || null)}
                                    cursor='pointer'
                                    onRow={(record: any) => {
                                        return {
                                          onClick: () => this.changeSelectedResult(record)
                                        }
                                    }}/>
                            </Col>
                        </WhiteStyledRow>
                    </Col>
                    <Col span={20}>
                        <Row gutter={[24, 24]}>
                            <Col span={24}>
                                <CustomTestCard
                                    resultId={selectedResult && selectedResult.result.id}
                                    title='Wartość poziomu szumu'
                                    performer={`${selectedResult && selectedResult.user.firstName || ""} ${selectedResult && selectedResult.user.lastName || ""}`}
                                    date={selectedResult && selectedResult.result.dateOfTest || ""}
                                    expirationDate={selectedResult && calculateNextStudy(selectedResult.result.dateOfTest, validTime)}
                                    result={ selectedResult && selectedResult.result.passed || ""}/>
                            </Col>
                            <Col span={24}>
                                <MainCard title='Wyniki pomiaru'>
                                    <Col span={24}>
                                        <CustomTable
                                            columns={this.resultColumns}
                                            data={testData && testData.testData}/>
                                    </Col>
                                </MainCard>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </>
        );
    }
    
    handleChangeToNewTest = () => {
        this.props.router.history.push(this.props.router.location.pathname.replace(/[^/]*$/, 'perform-test'));
    }

    changeSelectedResult = (newResult: any) => {
        this.setState({
            selectedResult: newResult,
            testData: JSON.parse(newResult.result.testData)
        })
    }

   handleFiltersChange = (filterName: string, newValue: any) => {
        this.setState(prevState => {
            prevState.filters[filterName] = newValue;
            return prevState;
        })
    }

    handleFiltersSubmit = () => {
        alert('Filtrowanie!');
    }

    handleChartData = () => {
        const { results } = this.props;
        const chartData = [];

        return null;
    }

    resultColumns = [
        {
            title: "Nazwa",
            dataIndex: "name",
            key: "name"
        },
        {
            title: "Pomiar",
            dataIndex: "measurement",
            key: "measurement"
        },
        {
            title: "Odniesienie",
            dataIndex: "reference",
            key: "reference"
        },  
        {
            title: "Odchylenie",
            dataIndex: "deviation",
            key: "deviation"
        },  
        {
            title: "Poprawny",
            key: "result",
            dataIndex: "result",
            render: (result: boolean) => result ? <GreenCheckIcon /> : <RedCrossIcon />
        },
    ]
    
    historyResultsColumns = [
        {
            title: 'WYNIKI HISTORYCZNE',
            dataIndex: 'date',
            key: 'date',
            render: (_: any, row: any) => {
                const { selectedResult } = this.state;
                return {
                  props: {
                    style: 
                        {
                            background: selectedResult && selectedResult.result.id === row.result.id ? HistoryResultRowsDesign.SELECTED_RESULT_BACKGROUND_COLOR : null, 
                            color: selectedResult && selectedResult.result.id === row.result.id ? HistoryResultRowsDesign.SELECTED_RESULT_TEXT_COLOR : null, 
                            borderLeft: row.result.passed ? HistoryResultRowsDesign.BORDER_LEFT_TEST_PASSED_COLOR : HistoryResultRowsDesign.BORDER_LEFT_TEST_NOT_PASSED_COLOR, 
                            textAlign: HistoryResultRowsDesign.TEXT_ALIGN }
                        },
                    children: moment(row.result.dateOfTest).format('YYYY-MM-DD HH:mm')
                };
            }
        }
    ]
}

const GreyStyledRow = styled(Row)`
    background: #F6F7F8;
    padding: 20px 0px 20px 0px;
`

const WhiteStyledRow = styled(Row)`
    background: #FFFFFF;
    padding: 20px 0px 20px 0px;
`

export default NoiseLevelHistory;