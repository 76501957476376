import React, { Component } from "react";
import { Spin, Modal, message } from 'antd';
import HighResolutionContrastTest from '../components/tests/HighResolutionContrastTest';
import CustomSpin from "../components/common/CustomSpin"
import { ImageCard } from "../components/common";
import { IRouter } from '../utils/Interfaces';
import { ArtefactsTest, HuWaterPhantomTest, UniformityTest, NoiceLevelTest, GeometricCorrectnessTest, TableMovementTest, HuDensityTest, LocalisationLightsTest } from '../components/tests';
import { Thumbnail , TestType } from '../utils/Models';
import { api } from '../api';
import { SyncOutlined } from '@ant-design/icons';

type State = {
    visible: boolean,
    params : Array<any>,
    dicom: any,
    selectedDicomId: string | null,
    testInfo: { type: TestType } | null,
    dicomThumbnails: Array<Thumbnail>,
    isLoading: boolean,
    loadingDicom: boolean,
    savingTest: boolean,
    loadingThumbnails: boolean
}

export default class PerformExaminationView extends Component<IRouter, State> {
    constructor(props: any) {
        super(props)

        this.state = {
            visible: false,
            params: [],
            dicom: null,
            selectedDicomId: null,
            testInfo: null,
            dicomThumbnails: [],
            isLoading: true,
            loadingDicom: false,
            savingTest: false,
            loadingThumbnails: true,
        }
    }

    componentDidMount() {
        const { testId } = this.props.match.params;
        api
            .get(`test-cases/${testId}`)
            .then(res => res.data)
            .then(testInfo => this.setState({ testInfo: testInfo}))
            .catch(err => console.error(err));

        this.fetchDicomData();
    }

    render() {
        const { testInfo, loadingThumbnails } = this.state;

        return (
            <Spin 
                spinning={this.shouldSpin()} 
                size='large' 
                indicator={<CustomSpin tip={this.generateSpinningTip()} />}
            >
                {testInfo && testInfo.type === 'artefacts' && 
                    <ArtefactsTest 
                        {...this.state} 
                        router={this.props} 
                        thumbnails={this.generateDicomThumbnails()} 
                        fetchThumbnails={this.fetchDicomData}
                        onLoadingDicomFinish={this.finishLoadingDicom}
                        mainCardReloadIcon={<SyncOutlined onClick={() => this.fetchDicomData()} spin={loadingThumbnails}/>}
                        onSavingTestStart={this.handleSavingTestStart}
                        onSavingTestFinish={this.handleSavingTestFinish}
                    />
                }
                {testInfo && testInfo.type === 'uniformity' && 
                    <UniformityTest 
                        {...this.state} 
                        router={this.props} 
                        thumbnails={this.generateDicomThumbnails()} 
                        fetchThumbnails={this.fetchDicomData}
                        onLoadingDicomFinish={this.finishLoadingDicom}
                        mainCardReloadIcon={<SyncOutlined onClick={() => this.fetchDicomData()} spin={loadingThumbnails}/>}
                        onSavingTestStart={this.handleSavingTestStart}
                        onSavingTestFinish={this.handleSavingTestFinish}
                    />
                }
                {testInfo && testInfo.type === 'water_phantom' && 
                    <HuWaterPhantomTest 
                        {...this.state} 
                        router={this.props} 
                        thumbnails={this.generateDicomThumbnails()} 
                        fetchThumbnails={this.fetchDicomData}
                        onLoadingDicomFinish={this.finishLoadingDicom}
                        mainCardReloadIcon={<SyncOutlined onClick={() => this.fetchDicomData()} spin={loadingThumbnails}/>}
                        onSavingTestStart={this.handleSavingTestStart}
                        onSavingTestFinish={this.handleSavingTestFinish}
                    />
                }
                {testInfo && testInfo.type === 'highContrast' && 
                    <HighResolutionContrastTest 
                        {...this.state} 
                        router={this.props} 
                        thumbnails={this.generateDicomThumbnails()} 
                        fetchThumbnails={this.fetchDicomData}
                        onLoadingDicomFinish={this.finishLoadingDicom}
                        mainCardReloadIcon={<SyncOutlined onClick={() => this.fetchDicomData()} spin={loadingThumbnails}/>}
                        onSavingTestStart={this.handleSavingTestStart}
                        onSavingTestFinish={this.handleSavingTestFinish}
                        loadingDicom={this.state.loadingDicom}
                    />
                }
                {testInfo && testInfo.type === 'noise_level' && 
                    <NoiceLevelTest 
                        {...this.state} 
                        router={this.props} 
                        thumbnails={this.generateDicomThumbnails()} 
                        fetchThumbnails={this.fetchDicomData}
                        onLoadingDicomFinish={this.finishLoadingDicom}
                        mainCardReloadIcon={<SyncOutlined onClick={() => this.fetchDicomData()} spin={loadingThumbnails}/>}
                        onSavingTestStart={this.handleSavingTestStart}
                        onSavingTestFinish={this.handleSavingTestFinish}
                    />
                }
                {testInfo && testInfo.type === 'geometricCorrectness' && 
                    <GeometricCorrectnessTest 
                        {...this.state} 
                        router={this.props} 
                        thumbnails={this.generateDicomThumbnails()} 
                        fetchThumbnails={this.fetchDicomData}
                        onLoadingDicomFinish={this.finishLoadingDicom}
                        mainCardReloadIcon={<SyncOutlined onClick={() => this.fetchDicomData()} spin={loadingThumbnails}/>}
                        onSavingTestStart={this.handleSavingTestStart}
                        onSavingTestFinish={this.handleSavingTestFinish}
                    />
                }
                {testInfo && testInfo.type === 'hu_density' && 
                    <HuDensityTest 
                        {...this.state} 
                        router={this.props} 
                        thumbnails={this.generateDicomThumbnails()} 
                        fetchThumbnails={this.fetchDicomData}
                        onLoadingDicomFinish={this.finishLoadingDicom}
                        mainCardReloadIcon={<SyncOutlined onClick={() => this.fetchDicomData()} spin={loadingThumbnails}/>}
                        onSavingTestStart={this.handleSavingTestStart}
                        onSavingTestFinish={this.handleSavingTestFinish}
                    />
                }
                {testInfo && testInfo.type === 'table_movement' && 
                    <TableMovementTest 
                        router={this.props} 
                        onSavingTestStart={this.handleSavingTestStart}
                        onSavingTestFinish={this.handleSavingTestFinish}
                    />
                }
                {testInfo && testInfo.type === 'LOCALISATION_LIGHTS' && 
                    <LocalisationLightsTest 
                        router={this.props} 
                        onSavingTestStart={this.handleSavingTestStart}
                        onSavingTestFinish={this.handleSavingTestFinish}
                    />
                }

            </Spin>
        )
    }

    fetchDicomData = () => {
        const { testId } = this.props.match.params;
        this.setState({ loadingThumbnails: true});

        api
            .get(`dicom/test-case/${testId}`)
            .then(res => res.data)
            .then(dicomData => this.fetchDicomsAndStoreThumbnails(dicomData))
            .catch(err => {
                this.setState({isLoading: false, loadingThumbnails: false})

                if (err.response.status === 404) {
                    return message.error("Wystąpił problem z wczytywaniem plików DICOM. Prawdopodobnie zostały one przeniesione lub usunięte. Skontaktuj się z administratorem strony.");
                }

                return message.error("Wystąpił nieznany problem. Skontaktuj się z administratorem strony.");
            })
            .finally(() => this.setState({isLoading: false, loadingThumbnails: false}));
    }
        
    fetchDicomsAndStoreThumbnails = (dicoms: any) => {
        this.setState({
            dicomThumbnails: [],
            loadingThumbnails: dicoms.length > 0
        }, () => {
            dicoms.forEach((dicom: any, index: number) => {
                const isLast = dicoms.length-1 === index;
                this.fetchSingleDicom(dicom, index, isLast);
            })
        })
    }
    
    fetchSingleDicom = (dicom: any, index: number, isLast: boolean) => {
        api
            .get(`dicom/${dicom.dicomObj.id}/image`, { responseType: 'blob' })
            .then(res => res.data)
            .then(thumbnail => this.storeDicomThumbnailData(dicom.dicomObj.id, URL.createObjectURL(thumbnail), dicom.description, index))
            .then(() => isLast && this.setState({ loadingThumbnails: false }))

    }

    storeDicomThumbnailData = (dicomId: string, thumbnailUrl: string, description: string, index: number) => {
        const newThumbnail: Thumbnail = {
            imgUrl: thumbnailUrl,
            dicomId: dicomId,
            description: description
        };

        this.setState(prevState => {
            prevState.dicomThumbnails[index] = newThumbnail;
            return {
                dicomThumbnails: prevState.dicomThumbnails
            }
        })
    }
    
    shouldSpin = () => {
        const { isLoading, loadingDicom, savingTest } = this.state;
        
        return isLoading || loadingDicom || savingTest;
    }
    
    generateSpinningTip = () => {
        const { isLoading, loadingDicom, savingTest } = this.state;

        if (isLoading)
            return 'Wczytywanie...';

        if (loadingDicom)
            return 'Wczytywanie pliku DICOM...';

        if (savingTest)
            return 'Zapisywanie wyniku testu...';
    }

    handleChangeDicomId = (newId: string) => {        
        if (this.state.dicom) {
            return Modal.confirm({
                title: 'Potwierdź akcję',
                onOk: () => this.fetchNewDicom(newId),
                content: 'Czy na pewno chcesz wczytać nowy plik DICOM? Poprzednie dane wykonania testu zostaną utracone.',
                okText: 'Tak',
                cancelText: 'Nie',
            });
        }  
        return this.fetchNewDicom(newId);
    }

    fetchNewDicom = (dicomId: string) => {
        if (!this.state.loadingDicom) {
            this.setState({ loadingDicom: true });
        
            api
                .get(`dicom/${dicomId}`, { responseType: 'blob' })
                .then(res => res.data)
                .then(res => this.setState({ dicom: this.blobToFile(res, 'dicom.dcm'), selectedDicomId: dicomId }))
                .catch(err => console.error(err));
        }
    }

    handleSavingTestStart = () => {
        this.setState({
            savingTest: true
        })
    }

    handleSavingTestFinish = () => {
        this.setState({ 
            savingTest: false 
        }, () => this.handleChangeToHistoryView());
    }

    handleChangeToHistoryView = () => {
        this.props.history.push(this.props.location.pathname.replace(/[^/]*$/, 'test-results'));
    }

    finishLoadingDicom = () => {
        this.setState({
            loadingDicom: false
        })
    }

    blobToFile(theBlob: any, fileName: any) {
        theBlob.lastModifiedDate = new Date();
        theBlob.name = fileName;
        return theBlob;
    }

    generateDicomThumbnails = () => {
        if (this.state.loadingThumbnails) {
            return <CustomSpin />;
        }

        return (
            <>
                {this.state.dicomThumbnails && this.state.dicomThumbnails.map(thumbnail => 
                    <ImageCard 
                        description={thumbnail.description}
                        src={thumbnail.imgUrl}
                        onClick={() => this.handleChangeDicomId(thumbnail.dicomId)} 
                    />
                )}
            </>
        );
    }

    openModal = () => { this.setState({ visible: true }) }

    closeModal = () => { this.setState({ visible: false }) }

    saveParams = () => {
        const {params} = this.state;
        if(params.length>0){

        }
        else{
            alert('Uzupełnij Brakujące parametry')
        }
    }
}
